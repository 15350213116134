﻿import {ActionModal, translate} from "@soap/modules";
import React from "react";

export function CancelSharedAssessment(props) {
    const {sharedAssessmentId, assessmentTitle} = props;
    return (
        <ActionModal title={translate("Cancel") + " " + translate("Shared Assessment")} auth={true}
                     command={{
                         $type: 'C211v1_CancelSharedAssessment',
                         C211_SharedAssessmentId: sharedAssessmentId
                     }}>
            <p>{`${translate("Please confirm you want to cancel this Share")} ${translate("of")} ${assessmentTitle}?`}</p>
            <br/>
            <p>All exposed links will be invalidated and return an error after sharing is stopped.</p>
        </ActionModal>
    );
}