﻿import {useStyletron} from "baseui";
import React, {useEffect, useState} from "react";
import {MessageCard} from "baseui/message-card";
import quoteData from "/quotes.json";
import {SpaceAround, SpaceTopAndBottom} from "../z40-uicomp/layout";

function convertDayToNumber() {
    // Get the current date
    const currentDate = new Date();

    // Get the current day of the year
    const dayOfYear = getDayOfYear(currentDate);

    // Convert the day of the year to a number from 1 to 70
    const convertedNumber = dayOfYear % 70 + 1;

    return convertedNumber;
}

// Helper function to get the day of the year
function getDayOfYear(date) {
    const start = new Date(date.getFullYear(), 0, 0);
    const diff = date - start;
    const oneDay = 1000 * 60 * 60 * 24;
    const dayOfYear = Math.floor(diff / oneDay);

    return dayOfYear;
}


export function QuoteCard() {


    const [css, theme] = useStyletron();

    const viewStates = {
        loading: 0,
        displayQuote: 1
    };

    const [quote, setQuote] = useState('');
    const [viewState, setViewState] = useState(0);
    const [author, setAuthor] = useState('');


    const bgImageUrlKey = "quote-card-bgimage-url";
    const bgImageAltKey = "quote-card-bgimage-alt";


    useEffect(() => {
        const imageIndex = convertDayToNumber();
        
        const abortController = new AbortController();
        const getPhoto = async () => {
            
            let result = await fetch('https://api.unsplash.com/photos/random?client_id=PzPEG2kfovlxdLqnE8R0Crbjfilv4yv7B5donlhyQhg&auto=format&orientation=landscape&topics=xHxYTMHLgOc',
                {signal: abortController.signal});

            result = await result.json();

            if (!sessionStorage.getItem(bgImageUrlKey)) {
                sessionStorage.setItem(bgImageUrlKey, result.urls.regular);
                sessionStorage.setItem(bgImageAltKey, result.alt_description);
            }
        };

        (async () => {
            setQuote(quoteData[imageIndex].quote);
            setAuthor(quoteData[imageIndex].author);
            await getPhoto();
            setViewState(viewStates.displayQuote);
        })();

        return () => {
            abortController.abort(); // cancel pending fetch request on component unmount
        };

    }, []);

    switch (viewState) {
        case viewStates.loading:
            return null;
        case viewStates.displayQuote:
            return (
                <div style={{maxWidth: "500px"}}>
                    <SpaceTopAndBottom>
                        <MessageCard
                            heading={quote}
                            paragraph={author}
                            backgroundColorType={"dark"}
                            image={{
                                src: sessionStorage.getItem(bgImageUrlKey),
                                ariaLabel: sessionStorage.getItem(bgImageAltKey),
                            }}
                            backgroundColor={theme.colors.backgroundAccent}
                            overrides={
                                {
                                    Root: {
                                        component: (x) => <div
                                            style={{
                                                backgroundColor: "#e0e0e0",
                                                borderRadius: "8px",
                                                overflow: "hidden"
                                            }}>
                                            <SpaceAround>
                                                <div style={{
                                                    borderRadius: "3px",
                                                    overflow: "hidden"
                                                }}>{x.children[0]}</div>
                                                {x.children[1]}</SpaceAround></div>,
                                    }
                                    ,
                                    Image: {
                                        style: ({$theme}) => ({
                                            backgroundColor: $theme.colors.backgroundSecondary,
                                            height: "250px"
                                        })
                                    }
                                }
                            }
                        /></SpaceTopAndBottom></div>);
    }


}