export async function refreshTokens (refreshFunctionFromComponentWithUseTokenRefreshRequired, accessToken) {
    const jwt = parseJwt(accessToken);

    /* if we update the roles in the backend we need to reload the JWT
    * this is a helper function you can call to help debug what the problem is with a token
    * that isn't picking up new roles. it's not necessary unless there is a problem.
    * normally just call refresh({ignoreCache:true}) */
    
    
    if (!jwt["https://soap.idaam/app_metadata"]?.Roles?.length &&
        !jwt["https://soap.idaam/roles"]?.length) {
        console.error("refreshing tokens, missing metadata roles AND context roles");
        await refreshFunctionFromComponentWithUseTokenRefreshRequired({ignoreCache: true});
    } else if (!jwt["https://soap.idaam/app_metadata"]?.Roles?.length) {
        console.error("refreshing tokens, missing metadata roles");
        await refreshFunctionFromComponentWithUseTokenRefreshRequired({ignoreCache: true});
    } else if (!jwt["https://soap.idaam/roles"]?.length) {
        console.error("refreshing tokens, missing context roles");
        await refreshFunctionFromComponentWithUseTokenRefreshRequired({ignoreCache: true});
    }

    function parseJwt(token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
};