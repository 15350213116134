﻿import * as React from 'react';

import {QuestionTable} from "../z50-domaincomp/question-table";
import {useStyletron} from "baseui";
import {CenteredColumn} from "../z40-uicomp/centered-column";
import {Frame} from "../z40-uicomp/frame";

import {CandidateTable} from "../z50-domaincomp/candidate-table";

export function CandidateLibrary() {

    const [css, theme] = useStyletron();
    
    
    return (<Frame>
        <CenteredColumn>
            <CandidateTable/>
        </CenteredColumn>
    </Frame>);
}