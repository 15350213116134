﻿import {
    AggregateList,
    EntityMenu, SecondaryActionMenuButton,
    toTypeName, useCommandValidation,
    useDomainEvent, useEvent,
    useLazyCommand,
    useLazyQuery, uuidv4,
    ValidationNotification
} from "@soap/modules";
import React, {useEffect, useRef, useState} from "react";
import {CenteredCard} from "../../z40-uicomp/centered-card";
import {SpaceAround, SpaceTop} from "../../z40-uicomp/layout";
import {AddMultipleChoiceAnswer} from "../../z50-domaincomp/drawers/add-multiple-choice-answer";
import {Button, KIND, SIZE} from "baseui/button";

import {EditQuestionTopNav} from "../../z50-domaincomp/drawers/edit-question-menu";
import {EditMultipleChoiceAnswer} from "../../z50-domaincomp/drawers/edit-multiple-choice-answer";

function DefineOptions({questionId, questionType, setFormKey}) {

    const {validateCommand} = useCommandValidation();
    
    const questionTypeKey = questionType.key;
    const [c152Trigger] = useLazyCommand("C152v1_EditQuestionDefineOptions");

    useDomainEvent({
        eventName: "answer-added",
        conversationId: "create-question",
        onEventReceived: (e, ev) => {
            const {text, isCorrect} = e;
            const newEl = {Value: text, "Is Correct": isCorrect};
            setOptions(oldArray => [...oldArray, newEl]);
        }
    });

    useEvent({
        eventName: "E125v1_QuestionUpdated",
        onEventReceived:(e) => {
            setFormKey(uuidv4());
        }
    })

    const [, e128] = useLazyQuery("C153v1_GetC152FormData", {
        // $type: "C139v1_GetC133FormData",
        C153_QuestionId: questionId
    });

    useEffect(() => {
        if (e128) {
            setOptions(e128.e128_Options.map(o => ({
                "Is Correct": o.e128_IsCorrect,
                "Value": o.e128_OptionText
            })));
        }
    }, [e128]);

    const answerRemovedTrigger = useDomainEvent({
        eventName: "answer-removed",
        conversationId: "edit-question",
        onEventReceived: (e, ev) => {
            const {text} = e;
            setOptions(oldArray => {
                return oldArray.filter(i => i.Value !== text);
            });
        }
    });

    useDomainEvent({
        eventName: "answer-edited",
        conversationId: "edit-question",
        onEventReceived: (e, ev) => {
            const {oldText, text, isCorrect} = e;
            const newEl = {Value: text, "Is Correct": isCorrect};
            setOptions(oldArray => {
                const index = oldArray.findIndex(i => i.Value === oldText);
                const newArray = [...oldArray];
                newArray[index] = newEl;
                return newArray;
            });
        }
    });

    const [options, setOptions] = useState([]);
    const [validationNotification, setValidationNotification] = useState();
    const submitButtonRef = useRef(null);

    const instruction = ({
        "single-selection": <p>Define all possible answers, only one can be correct.</p>,
        "multiple-choice": <p>Define all possible answers, at least one must be false.</p>,
        "specific-string": <><p>Define all possible correct answers.</p><p>Answers are case-insensitive when marking results.</p></>,
    })[questionTypeKey];


    return (
        <SpaceAround>
            {instruction}
            Answers are case-insensitive when marking results.
            <AggregateList
                title={"Answers"}
                aggregates={options}
                headerColumns={["Value", "Is Correct"]}
                hiddenFields={["e110_LongId"]}
                entityMenus={{
                    "root-individual": new EntityMenu(null, [
                        () => <AddMultipleChoiceAnswer isCorrectDefault={questionTypeKey === "specific-string"}
                                                       disableIsCorrect={questionTypeKey === "specific-string"}/>
                    ]),
                    "root-Items": new EntityMenu(null, [
                        (entity) => <Button style={{width:"100%"}} //* fill menu width 
                                            size={SIZE.compact} kind={KIND.tertiary}
                                            onClick={() => answerRemovedTrigger({text: entity.Value})}>Remove</Button>,
                        (entity) => <EditMultipleChoiceAnswer answer={entity.Value}
                                                              disableIsCorrect={questionTypeKey === "specific-string"}
                                                              isCorrect={entity["Is Correct"]} />
                    ]),
                    
                    
                    
                }}
            />
            <SpaceTop>
                <ValidationNotification errorMsg={validationNotification}/>
                <div style={{
                    display: "flex",
                    justifyContent: "flex-end"
                }}>
                    <Button kind={KIND.primary} size={SIZE.compact} ref={submitButtonRef} onClick={async () => {
                        // build command here
                        const command = {
                            $type: toTypeName("C152v1_EditQuestionDefineOptions"),
                            headers: [],
                            C152_QuestionId: questionId,
                            C152_QuestionTypeKey: questionTypeKey,
                            C152_MultipleChoiceOptions: {
                                allEnumerations: options.map(o => ({key: o.Value, value: o.Value})),
                                selectedKeys: options.filter(o => o["Is Correct"] === true).map(o => o.Value)
                            }
                        };

                        const messages = await validateCommand(command);

                        if (messages) {
                            setValidationNotification(messages.map((m,i) => <p key={i}>{m}</p>));
                            submitButtonRef.current.scrollIntoView(false);
                        } else {
                            // send command
                            c152Trigger(command);
                        }
                    }}>Save</Button>
                </div>
            </SpaceTop>
        </SpaceAround>

    );

}


export function EditQuestionDefineOptions({questionId, questionType, selectedItemId}) {


    const completeEdit = useDomainEvent({
        eventName: "edit-complete",
        conversationId: "edit-question",
    });

    const [formKey, setFormKey] = useState(uuidv4());
    
    return (<CenteredCard onBack={() => completeEdit()} title={"Edit Question"}>
            <EditQuestionTopNav questionId={questionId} questionType={questionType} selectedItemId={selectedItemId}/>
            <DefineOptions key={formKey} questionId={questionId} questionType={questionType} setFormKey={setFormKey} />
        </CenteredCard>
    );
}