﻿import {useStyletron} from "baseui";
import {AggregateList, EntityMenu, toTypeName, useDomainEvent, useEvent, useLazyCommand, uuidv4} from "@soap/modules";
import {LabelLarge} from "baseui/typography";
import {InlineEditInput} from "../../../z40-uicomp/inline-edit-input";
import {EditAssessmentAddSection} from "../../../z50-domaincomp/drawers/edit-assessment-add-section";
import {RemoveAssessmentSection} from "../../../z50-domaincomp/modals/remove-assessment-section";
import {EditAssessmentAddQuestion} from "../../../z50-domaincomp/drawers/edit-assessment-add-question";
import {RemoveAssessmentQuestion} from "../../../z50-domaincomp/modals/remove-assessment-question";
import React, {useRef} from "react";
import {calcPoints} from "../edit-assessment-questions";
import {timingModeKeys} from "../../../z50-domaincomp/constants/timing-mode-keys";


export function EditQuestionList({assessment, setAssessment}) {

    const [css, theme] = useStyletron();
    const headerColumns = ["Title", "Points", "Points2", "Points3"];
    const hiddenFields = ["QuestionId", "SectionId", "TimeLimit", "TimeLimit2"];

    if (assessment.TimingMode === timingModeKeys.PerSection) {
        headerColumns.push("TimeLimit");
    }
    if (assessment.TimingMode === timingModeKeys.PerQuestion) {
        headerColumns.push("TimeLimit2");
    }

    const gridConversationId = useRef(uuidv4());
    const expansionTrigger = useDomainEvent({
        eventName: "force-aggregate-list-expansion-state",
        channel:"ui",
        conversationId: gridConversationId.current
    });
    
    useEvent({
        eventName: "E159v1_QuestionAddedToAssessment",
        onEventReceived: (event) => {
            const questionId = event.e159_QuestionId;
            const sectionId = assessment.Sections.find(s => s.Questions.some(q => q.QuestionId === questionId)).SectionId;
            
            expansionTrigger({
                mappings:[
                    {
                        propertyKey: "root-Items",
                        locatorFunction:(section) => section.SectionId == sectionId
                    },
                    {
                        propertyKey: "Questions-Items",
                        locatorFunction:(question) => question.QuestionId == questionId
                    }
                ]
            })
        }
    }, []);

    const [updatePointsTrigger] = useLazyCommand("C196v1_UpdateAssessmentPointsValue");
    const [updateTimingsTrigger] = useLazyCommand("C195v1_UpdateAssessmentTimeLimits");
    
    
    return (
        
        <AggregateList aggregates={assessment.Sections}
                       conversationId={gridConversationId.current}
                       title={<LabelLarge>Sections</LabelLarge>}
                       hiddenFields={hiddenFields}
                       rowColour={theme.colors.accent}
                       headerColumns={headerColumns}
                       expandedFields={["Questions"]}
                       displayOptions={{
                           hideItemCountToggle: true
                       }}
                       propertyRenderer={{
                           "Points3": (propertyValue, parentObject) => <><InlineEditInput
                               type="number"
                               min="10"
                               max="3600"
                               buildCommand={(v) => {
                                   const points = v;
                                   return {
                                       $type: toTypeName("C196v1_UpdateAssessmentPointsValue"), //* required for pre-submission validation
                                       C196_AssessmentId: assessment.AssessmentId,
                                       C196_QuestionId: parentObject.QuestionId,
                                       C196_Points: Number(points),
                                   };
                               }}
                               afterCommandValidation={(c, v) => {
                                   const cloned = JSON.parse(JSON.stringify(assessment));
                                   cloned.Sections.flatMap(s => s.Questions).find(q => q.QuestionId === parentObject.QuestionId)
                                       .Points3 = Number(v);
                                   calcPoints(cloned);
                                   setAssessment(cloned);
                                   updatePointsTrigger(c);

                               }}
                               initialValue={parentObject.Points3}
                           /></>,
                           "TimeLimit": (propertyValue, parentObject) => <InlineEditInput
                               type="number"
                               min="10"
                               max="3600"
                               buildCommand={(v) => {
                                   const timeInSeconds = v;
                                   return {
                                       $type: toTypeName("C195v1_UpdateAssessmentTimeLimits"), //* required for pre-submission validation
                                       C195_AssessmentId: assessment.AssessmentId,
                                       C195_AssessmentTimingModeKey: timingModeKeys.PerSection,
                                       C195_SectionId: parentObject.SectionId,
                                       C195_TimeInSeconds: Number(timeInSeconds),
                                   };
                               }}
                               afterCommandValidation={(c, v) => {
                                   const cloned = JSON.parse(JSON.stringify(assessment));
                                   cloned.Sections.find(s => s.SectionId === parentObject.SectionId)
                                       .TimeLimit = Number(v);
                                   setAssessment(cloned);
                                   updateTimingsTrigger(c);

                               }}
                               initialValue={parentObject.TimeLimit}
                           />,
                           "TimeLimit2": (propertyValue, parentObject) => <InlineEditInput
                               type="number"
                               min="10"
                               max="3600"
                               buildCommand={(v) => {
                                   const timeInSeconds = Number(v);
                                   return {
                                       $type: toTypeName("C195v1_UpdateAssessmentTimeLimits"), //* required for pre-submission validation
                                       C195_AssessmentId: assessment.AssessmentId,
                                       C195_AssessmentTimingModeKey: timingModeKeys.PerQuestion,
                                       C195_QuestionId: parentObject.QuestionId,
                                       C195_TimeInSeconds: timeInSeconds,
                                   };
                               }}
                               afterCommandValidation={(c, v) => {
                                   const cloned = JSON.parse(JSON.stringify(assessment));
                                   cloned.Sections.flatMap(z => z.Questions).find(s => s.QuestionId === parentObject.QuestionId)
                                       .TimeLimit2 = Number(v);
                                   setAssessment(cloned);
                                   updateTimingsTrigger(c);
                               }}
                               initialValue={parentObject.TimeLimit2}
                           />
                       }}
                       entityMenus={{
                           "root-individual": new EntityMenu(null, [
                               () => <EditAssessmentAddSection assessmentId={assessment.AssessmentId}/>
                           ]),
                           "root-Items": new EntityMenu(null, [
                               
                               (section) => <EditAssessmentAddQuestion assessmentId={assessment.AssessmentId}
                                                                       sectionId={section.SectionId}
                                                                       sectionTitle={section.Title}
                                                                       existingQuestionIds={assessment.Sections.flatMap(s => s.Questions).map(q => q.QuestionId)}/>,
                               (section) => <RemoveAssessmentSection assessmentId={assessment.AssessmentId}
                                                                     sectionId={section.SectionId}
                                                                     sectionTitle={section.Title}/>
                           ]),
                           "Questions-Items": new EntityMenu(null, [
                               (question) => <RemoveAssessmentQuestion assessmentId={assessment.AssessmentId}
                                                                       questionId={question.QuestionId}
                                                                       questionTitle={question.Title}/>,

                           ]),
                       }}
        />
    );
}