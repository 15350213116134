﻿import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import {useAuth} from "./useAuth";
import config from '../soap/config'
import {useIsConfigLoaded} from "./systemStateHooks";


/*
 This hook must be called inside the Auth0Provider HOC and finish loading in order for certain functions like ProtectedRoutes to work.  
 It seems to be the call to the useAuth0 hook that is required to be inside in order to cause auth0 to finished initialising
 and the isLoaded and isAuthenticated properties to update.
 */
export const useLogin = () => {

    const {
        isLoading,
        isAuthenticated,
        error,
        user,
        loginWithRedirect,
        logout
    } = useAuth0();

    const {
        authReady,
        authEnabled
    } = useAuth("Login");
    
    const configLoaded = useIsConfigLoaded("Login");
    
    const fullyLoadedWithoutError = !error && !isLoading && authReady;

    return {
        status: {
            userName: user?.name,
            error: error?.message,
            isLoading,
            isAuthenticated
        },
        signUp: (redirectUri, authParams) => {
            if (fullyLoadedWithoutError && authEnabled) {
                loginWithRedirect({
                    appState: {
                        returnTo: redirectUri
                    },
                    authorizationParams: {
                        screen_hint: "signup",
                        redirect_uri: redirectUri ?? window.location.origin,
                        ...authParams
                    }
                });
            }
        },
        logIn: (redirectUri) => {
            if (fullyLoadedWithoutError && authEnabled) {
                loginWithRedirect({
                    appState: {
                        returnTo: redirectUri
                    },
                    authorizationParams: {
                        redirect_uri: redirectUri ?? window.location.origin
                    }
                });
            }
        },
        logOut: (redirectUri) => {
            if (isAuthenticated) {
                config.auth0.isAuthenticated = false;
                config.auth0.accessToken = null;
                config.auth0.identityToken = null;
                logout({
                    logoutParams: {
                        returnTo: redirectUri ?? window.location.origin
                    }
                });
            }
        }
    };
};