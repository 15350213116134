﻿import {AutoForm, useDomainEvent, useEvent, uuidv4} from "@soap/modules";
import {CenteredCard} from "../../z40-uicomp/centered-card";
import React, {useState} from "react";
import {EditAssessmentTopNav} from "../../z50-domaincomp/drawers/edit-assessment-menu";

export function EditAssessmentTags({assessmentId, assessmentTitle, selectedItemId}) {


    const hiddenFields = ["C186_AssessmentId"];
    const [autoFormKey, setAutoFormKey] = useState(uuidv4());

    useEvent({
        eventName: "E149v1_AssessmentUpdated",
        onEventReceived:(e) => {
            setAutoFormKey(uuidv4())
        }
    })
    
    return (<CenteredCard title={"Edit Assessment"} onBack={() => location.href = "#/assessments/assessment-library"} maxWidth={600}>
            <EditAssessmentTopNav assessmentId={assessmentId} assessmentTitle={assessmentTitle}
                                  selectedItemId={selectedItemId}/>
            <AutoForm
                title={"Edit Assessment Tags"}
                query={{
                    $type: "C187v1_GetC186FormData",
                    "C187_AssessmentId": assessmentId
                }}
                key={autoFormKey}
                hiddenFields={hiddenFields}
                submitText={"Save"}
            />
        </CenteredCard>
    );
}