﻿import {ActionDrawer, AutoForm, bus, translate, useDomainEvent} from "@soap/modules";
import React from "react";
import {CenteredCard} from "../../z40-uicomp/centered-card";
import {AddIcon} from "../../z20-icons/icons";


export function EditAssessmentAddSection({assessmentId}) {
    
    return (
        <ActionDrawer  drawerHeader={<>{translate("Add")} {translate("Section")}</>} menuText={<><AddIcon />{translate("Add")} {translate("Section")}</>} auth={true}>
            <AutoForm 
                query={{
                    $type: "C190v1_GetC189FormData",
                    C190_AssessmentId: assessmentId
                }}
                submitText={translate("Add")}
                hiddenFields={["C189_AssessmentId"]}
                afterSubmit={() => {
                    bus.closeAllDialogs();
                }}
            />
        </ActionDrawer>
    );
}

