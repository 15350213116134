﻿import React from "react";
import {optional, types, validateArgs} from "../soap/util";
import {useStyletron} from "baseui";
import {ANCHOR, Drawer} from "baseui/drawer";
import bus from "../soap/bus";
import {HeadingMedium} from "baseui/typography";


export function  DialogDrawer(props) {

    const {title, children, viewHeight} = props;

    validateArgs(
        [{viewHeight}, types.number, optional]
    );

    const [css, theme] = useStyletron();

    return (<Drawer
        isOpen={true}
        onClose={() => {
            //* when closing via corner X
            bus.closeAllDialogs(); /* if you close yourself, 
                close anything behind you (e.g. popover menus) or any descendants 
                this could be a problem if there were double layered drawers but I don't think we have any, you could always make it a parameter if there were. */
        }}
        autoFocus
        anchor={ANCHOR.top}
        overrides={{
            DrawerContainer: {
                style: ({$theme}) => ({
                    height: viewHeight ? viewHeight + "vh" : "fit-content",
                    maxWidth: "fit-content",
                    minWidth: "320px",
                    left: "50%",
                    transform: "translate(-50%, 0)"
                })
            }
        }
        }
    >
        <div
            className={css({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            })}
        >
            <div className={css({
                marginBottom: theme.sizing.scale900
            })}>
                <HeadingMedium>{title}</HeadingMedium>
            </div>
            <div>
                {children}
            </div>
        </div>
    </Drawer>);
}