﻿import {toTypeName, useDomainEvent, useLazyCommand} from "@soap/modules";
import {useStyletron} from "baseui";
import {InlineEditInput} from "../../../z40-uicomp/inline-edit-input";
import {SpaceBottom, SpaceRight, SpaceTopAndBottom} from "../../../z40-uicomp/layout";
import {Card} from "baseui/card";
import {ALIGN, Radio, RadioGroup} from "baseui/radio";
import React from "react";
import {timingModeKeys} from "../../../z50-domaincomp/constants/timing-mode-keys";
import {CenteredCard} from "../../../z40-uicomp/centered-card";

export function TimingModeOptions({assessment}) {
    const trigger = useDomainEvent({
        eventName: "timing-mode-changed",
        conversationId: "edit-assessment-questions"
    });

    const [updateTimingMode] = useLazyCommand("C197v1_UpdateAssessmentTimingMode");
    const [updateTimings] = useLazyCommand("C195v1_UpdateAssessmentTimeLimits");

    const [css, theme] = useStyletron();

    return (
        <>
            <SpaceTopAndBottom>
                <CenteredCard maxWidth={800} backgroundColor={theme.colors.accent600}>
                    
                    <SpaceBottom>
                    Select How The Assessment Should Be Timed (<em>all times in seconds</em>)
                    </SpaceBottom>
                    
                    <RadioGroup
                        value={assessment.TimingMode}
                        onChange={e => {
                            const newTimingMode = e.currentTarget.value;
                            updateTimingMode({
                                C197_AssessmentId: assessment.AssessmentId,
                                C197_AssessmentTimingModeKey: newTimingMode,
                            });
                            trigger({newTimingMode: newTimingMode});
                        }}
                        name="timing_mode"
                        align={ALIGN.horizontal}
                    >
                        <Radio value={timingModeKeys.NotTimed}>Not Timed</Radio>
                        <Radio value={timingModeKeys.PerAssessment}>Overall Assessment</Radio>
                        <Radio value={timingModeKeys.PerSection}>Per Section</Radio>
                        <Radio value={timingModeKeys.PerQuestion}>Per Question</Radio>
                    </RadioGroup>
                    {assessment.TimingMode === timingModeKeys.PerAssessment ?
                        <div style={{display: "flex", alignItems: "baseline"}}>
                            <div><SpaceRight>Overall Time Limit (sec)</SpaceRight> </div>  
                            <InlineEditInput
                                type="number"
                                min="10"
                                max="3600"
                                buildCommand={(v) => {
                                    const timeInSeconds = v;
                                    return {
                                        $type: toTypeName("C195v1_UpdateAssessmentTimeLimits"),  //* required for pre-submission validation
                                        C195_AssessmentId: assessment.AssessmentId,
                                        C195_AssessmentTimingModeKey: timingModeKeys.PerAssessment,
                                        C195_TimeInSeconds: Number(timeInSeconds),
                                    };
                                }}
                                afterCommandValidation={(c) => {
                                    updateTimings(c);
                                }}
                                initialValue={assessment.OverallTimeLimit}
                            /></div> : null}
                </CenteredCard>
                
            </SpaceTopAndBottom>
        </>
    );
}