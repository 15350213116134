﻿
export default {
    "$CannotChangeSubscriptionWithPendingCurrencyChange": "I'm sorry, but you cannot upgrade a subscription with immediate effect when there is a pending currency change. Please reverse the currency change by updating your billing details from the My Profile screen or wait for the next billing cycle to make changes.",
    "$CannotUpgradeToExistingPlan": "I'm sorry, but you are trying to upgrade the plan you are already on. We will redirect you to the pricing page to choose another plan.",
    "$ErrorModalHeader": "Uh Oh, There is a problem.",
    "$footer": "At ATest we provide a unique set of tools enabling you to fully\n" +
        " customise tests and assessments from start to finish. We also provide you with the ability to manage\n" +
        " candidates, assessments and question libraries with a tagging feature to group and\n" +
        " collate information",
    "$linkExpired": "This link has expired and can no longer be used.\nPlease request a new one from the sender.",
    "$linkInvalid": "This link is invalid. Please check the address, request a new invitation from the sender, or use the Contact Us form to get help.",
    "$subAccountWarning": "If you choose a plan with sub accounts it cannot be downgraded to a plan without. All plans are still fully customisable.",
    "$OneLineProductDescription": "ATest provides a powerful secure way to create and manage online assessments, tests and quizzes with affordable and customisable pricing.",
    "$Welcome": "Welcome Back",
    "About Us": "About Us",
    "activate/deactivate any user, including other admins":"activate/deactivate any user, including other admins",
    "Add Answer": "Add Answer",
    "Add Candidate": "Add Candidate",
    "Add Question": "Add Question",
    "Add": "Add",
    "Admin Rights, allow a user to":"Admin Rights, allow a user to",
    "All Completed. Nothing to do.":"All Completed. Nothing to do.",
    "All exposed links will be invalidated and return an error after revoking.": "All exposed links will be invalidated and return an error after revoking.",
    "Archive Candidate": "Archive Candidate",
    "Archive Question": "Archive Question",
    "Archive": "Archive",
    "Archived Assessments": "Archived Assessments",
    "Archived candidates": "Archived candidates",
    "Archived Questions": "Archived Questions",
    "Archived": "Archive",
    "are not removed from Assessment Results": "are not removed from Assessment Results",
    "are removed from any Assessments where they are referenced": "are removed from any Assessments where they are referenced",
    "Assessment Icon": "Assessment Icon",
    "Assessment Library": "Assessment Library",
    "Assessment Results for Archived Assessments will still be visible throughout the system, if you wish to remove an Assessment and its results entirely, use Delete": "Assessment Results for Archived Assessments will still be visible throughout the system, if you wish to remove an Assessment and its results entirely, use Delete",
    "Assessment Results will remain visible for Archived candidates": "Assessment Results will remain visible for Archived candidates",
    "Assessment Results": "Assessment Results",
    "Assessment Tags": "Assessment Tags",
    "Assessment": "Assessment",
    "Assessments": "Assessments",
    "ATest Logo": "ATest Logo",
    "ATest Robot": "ATest Robot",
    "ATest": "ATest",
    "can be reactivated if you have available sub-accounts":"can be reactivated if you have available sub-accounts",
    "can be viewed by using the Include Archived switch": "can be viewed by using the Include Archived switch",
    "can be viewed using the Include Archived switch": "can be viewed using the Include Archived switch",
    "can no longer be edited": "can no longer be edited",
    "Cancel": "Cancel",
    "Candidate Library": "Candidate Library",
    "Candidate Tags": "Candidate Tags",
    "Candidate": "Candidate",
    "Candidates": "Candidates",
    "cannot be edited": "cannot be edited",
    "cannot login":"cannot login",
    "cannot be reactivated if you do not have available sub-accounts":"cannot be reactivated if you do not have available sub-accounts",
    "cannot be shared and any Shares are cancelled": "cannot be shared and any Shares are cancelled",
    "Change": "Change",
    "change the subscription":"change the subscription",
    "close the account":"close the account",
    "Collaborate": "Collaborate",
    "Contact Us": "Contact Us",
    "Continue": "Continue",
    "count towards your sub-account limit":"count towards your sub-account limit",
    "Create Assessment": "Create Assessment",
    "Create Question": "Create Question",
    "Create Report": "Create Report",
    "Create": "Create",
    "Create New": "Create New",
    "Customise": "Customise",
    "Deactivate":"Deactivate",
    "Deactivate User":"Deactivate User",
    "Deactivated Users":"Deactivated Users",
    "Delete Assessment": "Delete Assessment",
    "Delete Candidate": "Delete Candidate",
    "Delete Question": "Delete Question",
    "Delete": "Delete",
    "Deleted Questions": "Deleted Questions",
    "deleted results": "deleted results",
    "deleted shares.": "deleted shares.",
    "Deleting a Candidate will also delete": "Deleting a Candidate will also delete",
    "Deleting an Assessment will": "Deleting an Assessment will",
    "Dismiss": "Dismiss",
    "do not count towards your sub-account limit":"do not count towards your sub-account limit",
    "Edit": "Edit",
    "Edit Answer": "Edit Answer",
    "Error loading":"Error loading",
    "Export This Candidate's Data": "Export This Candidate's Data",
    "Export Data": "Export Data",
    "Features": "Features",
    "Filter By Tag": "Filter By Tag",
    "File is invalid.":"File is invalid.",
    "File is too big.":"File is too big.",
    "Free Text": "Free Text",
    "Great Things Can Happen": "Great Things Can Happen",
    "Hello": "Hello",
    "Help": "Help",
    "Historical Data such as Archived Items are counted against your plan limits": "Historical Data such as Archived Items are counted against your plan limits",
    "Historical Data such as Archived Questions are counted against your plan limits": "Historical Data such as Archived Questions are counted against your plan limits",
    "Home": "Home",
    "If you only wish to hide the Assessment from this list, use Archive instead": "If you only wish to hide the Assessment from this list, use Archive instead",
    "If you wish only to hide the Question, use Archive instead": "If you wish only to hide the Question, use Archive instead",
    "If you wish to remove a candidate altogether, use Delete instead": "If you wish to remove a candidate altogether, use Delete instead",
    "If you wish to remove the Question from all Assessments, use Delete instead": "If you wish to remove the Question from all Assessments, use Delete instead",
    "If you wish to retain any of this candidates data, use Archive instead": "If you wish to retain any of this candidates data, use Archive instead",
    "Images by": "Images by",
    "Include Archived": "Include Archived",
    "Instructions": "Instructions",
    "Invitations": "Invitations",
    "Invitation": "Invitation",
    "Invite": "Invite",
    "Join Our Team": "Join Our Team",
    "Login": "Login",
    "Logout": "Logout",
    "Make Admin":"Make Admin",
    "Make User An Admin":"Make User An Admin",
    "Mark Question": "Mark Question",
    "Maximum Size":"Maximum Size",
    "Mission": "Mission",
    "Multiple Choice": "Multiple Choice",
    "My Profile": "My Profile",
    "Need Help?": "Need Help?",
    "Needs Marking": "Needs Marking",
    "Needs Questions": "Needs Questions",
    "Needs Sharing": "Needs Sharing",
    "Next": "Next",
    "No": "No",
    "of": "of",
    "Once downgraded, your data will not be removed, but if you are over the free limit in any area that feature will now be restricted. You can always upgrade again to resume full access.": "Once downgraded, your data will not be removed, but if you are over the free limit in any area that feature will now be restricted. You can always upgrade again to resume full access.",
    "Once your plan is cancelled you will have until your renewal date to continue with your current level of access.": "Once your plan is cancelled you will have until your renewal date to continue with your current level of access.",
    "Once you mark this question it cannot be changed":"Once you mark this question it cannot be changed",
    "Options": "Options",
    "Organisation": "Organisation",
    "Organisation Name": "Organisation Name",
    "Our Features": "Our Features",
    "Our Mission": "Our Mission",
    "Our Plans": "Our Plans",
    "Please confirm you want to archive the candidate": "Please confirm you want to archive the candidate",
    "Please confirm you want to archive the Question": "Please confirm you want to archive the Question",
    "Please confirm you want to archive this assessment": "Please confirm you want to archive this assessment",
    "Please confirm you want to cancel this Share": "Please confirm you want to cancel this Share",
    "Please confirm you want to cancel your subscription and return to the free plan.": "Please confirm you want to cancel your subscription and return to the free plan.",
    "Please confirm you want to deactivate the user": "Please confirm you want to deactivate the user",
    "Please confirm you want to delete the candidate": "Please confirm you want to delete the candidate",
    "Please confirm you want to delete the Question": "Please confirm you want to delete the Question",
    "Please confirm you want to Delete this assessment": "Please confirm you want to Delete this assessment",
    "Please confirm you want to give Admin rights to":"Please confirm you want to give Admin rights to",
    "Please confirm you want to reactivate the user":"Please confirm you want to reactivate the user",
    "Please confirm you want to remove the question": "Please confirm you want to remove the question",
    "Please confirm you want to remove the section": "Please confirm you want to remove the section",
    "Please confirm you want to remove the tag": "Please confirm you want to remove the tag",
    "Please confirm you want to revoke this": "Please confirm you want to revoke this",
    "Please confirm you want to revoke this invitation":"Please confirm you want to revoke this invitation",
    "Please confirm you want to revoke Admin rights from": "Please confirm you want to revoke Admin rights from",
    "Point Value": "Point Value",
    "Postcode": "Postcode",
    "Pricing": "Pricing",
    "Privacy Policy": "Privacy Policy",
    "Question Added to Section":"Question Added to Section",
    "Question Library": "Question Library",
    "Question Tag": "Question Tag",
    "Question Tags": "Question Tags",
    "Question Text": "Question Text",
    "Question": "Question",
    "Questions & Sections": "Questions & Sections",
    "Questions": "Questions",
    "Reactivate":"Reactivate",
    "Reactivate User":"Reactivate User",
    "Reactivated Users":"Reactivated Users",
    "Read about":  "Read about",
    "Recently Completed": "Recently Completed",
    "remove all its Assessment Results permanently, including from a Candidates' Assessment history": "remove all its Assessment Results permanently, including from a Candidates' Assessment history",
    "remove all its Shares": "remove all its Shares",
    "Remove Section": "Remove Section",
    "Remove Tag": "Remove Tag",
    "Remove": "Remove",
    "Revoke Admin":"Revoke Admin",
    "Revoke Admin Rights":"Revoke Admin Rights",
    "Reporting": "Reporting",
    "Reports Library": "Reports Library",
    "Resend": "Resend",
    "Results": "Results",
    "Revoke": "Revoke",
    "Revoked": "Revoked",
    "Revoke User Invitation":"Revoke User Invitation",
    "Save": "Save",
    "Section": "Section",
    "Select from a List": "Select from a List",
    "Select Existing Question": "Select Existing Question",
    "Send Another Message": "Send Another Message",
    "Send": "Send",
    "Share": "Share",
    "Shared Assessment links are revoked for Archived Candidates": "Shared Assessment links are revoked for Archived Candidates",
    "Shared Assessment": "Shared Assessment",
    "Shared Assessments": "Shared Assessments",
    "Shared": "Shared",
    "Shares": "Shares",
    "Show Only Results That Need Marking": "Show Only Results That Need Marking",
    "Sign Up!": "Sign Up!",
    "Specific Value(s)": "Specific Values(s)",
    "Subscription": "Subscription",
    "Tag": "Tag",
    "Tags": "Tags",
    "Thank you": "Thank you",
    "There are no recent assessments.":"There are no recent assessments.",
    "This assessment has":"This assessment has",
    "Terms and Conditions": "Terms and Conditions",
    "Time Limit (in seconds)": "Time Limit (in seconds)",
    "The assessment has": "The assessment has",
    "their Assessment Results": "their Assessment Results",
    "their Shared Assessment links": "their Shared Assessment links",
    "This is a GDPR-compliant operation which will permanently destroy all data on file for this Candidate.": "This is a GDPR-compliant operation which will permanently destroy all data on file for this Candidate.",
    "This action cannot be undone": "This action cannot be undone",
    "This will not remove the question from any previously shared assessments.": "This will not remove the question from any previously shared assessments.",
    "This will not remove the section from any previously shared assessments.": "This will not remove the section from any previously shared assessments.",
    "This will remove it from any associated items.": "This will remove it from any associated items.",
    "Title/Description": "Title/Description",
    "True/False": "True/False",
    "Update":"Update",
    "Update Billing Details": "Update Billing Details",
    "Update Billing Currency":"Update Billing Currency",
    "User": "User",
    "View": "View",
    "We will respond shortly": "We will respond shortly",
    "will remain part of any Assessments until removed": "will remain part of any Assessments until removed",
    "With The Right Software": "With The Right Software",
    "Yes": "Yes",
};
