﻿import {AggregateView, CenterSpinner, useDomainEvent, useEvent} from "@soap/modules";
import React, {useState} from "react";
import {CenteredColumn} from "../../z40-uicomp/centered-column";
import {Centered, RightAlign, SpaceBottom, SpaceTopAndBottom} from "../../z40-uicomp/layout";
import {BackLinkWithTitle} from "../../z40-uicomp/back-link-with-title";
import {CenteredCard} from "../../z40-uicomp/centered-card";
import {StyledForwardLinkWithTitle} from "../../z40-uicomp/forward-link-with-title";
import {sharedAssessmentTypes} from "../../z50-domaincomp/constants/shared-assessment-types";
import {componentViewMode} from "../../z50-domaincomp/constants/component-view-mode";

import {useLink} from "../../hooks/useLink";
import {LabelMedium, MonoParagraphMedium, ParagraphMedium} from "baseui/typography";


export function AssessmentShared() {

    const viewStates = {
        loading: 1,
        showConfirmation: 2,
        noCandidatesFound: 3
    };

    const back = useDomainEvent({
        eventName: "back-to-share-assessment",
        conversationId: "share-assessment"
    });

    const generateLink = useLink();
    const [viewState, setViewState] = useState(viewStates.loading);
    const [assessmentShare, setAssessmentShare] = useState();

    useEvent({
        eventName: "E166v1_AssessmentShared",
        onEventReceived: (e166) => {
            setAssessmentShare(e166);
            setViewState(viewStates.showConfirmation);
        }
    }, []);

    useEvent({
        eventName: "Soap.Interfaces.Messages.E001v1_MessageFailed, Soap.Interfaces.Messages",
        onEventReceived: (event, envelope) => {
            const codes = event.e001_ErrorCodes;
            if (codes && codes.includes("f22e08c5-beca-4e6c-90db-02ff83126761")) {
                setViewState(viewStates.noCandidatesFound);
            }
        }
    }, []);

    const hiddenFields = ["e166_SharedAssessmentId", "e166_ShareTypeKey"];

    function generateResult(shareTypeKey, sharedAssessmentId, shareDetails) {
        const link = generateLink([sharedAssessmentId, shareDetails], {
            host: window.location.origin,
            componentViewMode: shareTypeKey === sharedAssessmentTypes.ByIFrame ? componentViewMode.IFrame : componentViewMode.Website
        });

        if (shareTypeKey === sharedAssessmentTypes.ByIFrame) {
            return (<div style={{
                width:"300px"
            }}>
                    <LabelMedium>Insert the following HTML in your site<br/> where you would like the test to appear:</LabelMedium>
                    <br/>                    
                    <MonoParagraphMedium>
                        {"<!-- BEGIN HTML snippet -->"}
                        {`
                        <iframe src=\"${link}\" style=\"border:none;\" />
                        `}
                        {"<!-- END HTML snippet -->"}
                    </MonoParagraphMedium>
        </div>
            );
        }
        if (shareTypeKey === sharedAssessmentTypes.ByLink) {
            return link;
        }
        return shareDetails;
    }

    switch (viewState) {
        case viewStates.showConfirmation:
            return (
                <CenteredColumn maxWidth={600}>
                    <SpaceTopAndBottom>
                        <AggregateView
                            displayMode={true}
                            propertyRenderer={{
                                "e166_Shares": (value, entity) => {
                                    return value.map((shareDetails) => (<SpaceBottom><p key={shareDetails} style={{
                                        wordBreak: "break-all"
                                    }}>{
                                        generateResult(entity.e166_ShareTypeKey, assessmentShare.e166_SharedAssessmentId, shareDetails)
                                    }</p></SpaceBottom>));
                                }
                            }}
                            hiddenFields={hiddenFields}
                            aggregate={assessmentShare}
                            title={"Assessment Shared Successfully"}/>
                    </SpaceTopAndBottom>
                    <RightAlign>
                        <StyledForwardLinkWithTitle title={"To Assessments"}
                                                    onPress={() => location.href = '#/assessments/assessment-library'}/>
                    </RightAlign>
                </CenteredColumn>
            );
        case viewStates.loading:
            return <CenterSpinner/>;
        case viewStates.noCandidatesFound:
            return (
                <CenteredCard maxWidth={600}>
                    <SpaceTopAndBottom>
                        <BackLinkWithTitle title={"Back"} onPress={() => back()}/>
                    </SpaceTopAndBottom>
                    <Centered>No Candidates found that match this criteria</Centered>
                </CenteredCard>
            );
    }
}

