﻿import * as React from 'react';
import {useStyletron} from "baseui";

import {AggregateList, translate} from "@soap/modules";
import {CenteredCard} from "../z40-uicomp/centered-card";
import {useSoapPaginator} from "./soap-paginator";
import {LinkButton} from "../z40-uicomp/buttons";
import {Centered, FullWidth, SpaceTopAndBottom} from "../z40-uicomp/layout";

export function NeedsMarkingWidget() {

    const [css, theme] = useStyletron();
    const pageSize = 5;
    const {
        fetchPage,
        currentPageRecords: e176,
        recordCount,
        resetRecordSet,
        currentPage
    } = useSoapPaginator("E176v1_GotAssessmentResults", "C218v1_GetAssessmentResults", pageSize, {
        C218_NeedsMarkingOnly: true
    });
    const hiddenFields = ["e176_ContinuationToken", "e176_PageNumber", "e176_RecordCount", "e176_AssessmentId", "e176_AssessmentResultId", "e176_Version", "e176_DateTaken", "e176_Candidate", "e176_Score", "e176_NeedsMarking", "e176_Status"];
    const renderLink = (value, entity) => <LinkButton
        onClick={() => location.href = `#/assessments/${entity.e176_AssessmentId}/results/${entity.e176_AssessmentResultId}/1`}>{value}</LinkButton>;

    return (
            <SpaceTopAndBottom>
                <CenteredCard showRobot={false} title={translate("Needs Marking")}>
                    {e176?.e176_AssessmentResults?.length ?
                    <AggregateList
                        aggregates={e176?.e176_AssessmentResults}
                        headerColumns={["e176_Candidate", "e176_Assessment"]}
                        hiddenFields={hiddenFields}
                        propertyRenderer={{
                            e176_Assessment: renderLink
                        }}
                    /> : (<Centered>{translate("All Completed. Nothing to do.")}</Centered>)}
                </CenteredCard>
            </SpaceTopAndBottom>
    );
}