import {Frame} from "../z40-uicomp/frame";
import {CenteredCard} from "../z40-uicomp/centered-card";
import * as React from "react";
import {HeadingLarge, HeadingMedium, HeadingSmall, HeadingXLarge, HeadingXSmall} from "baseui/typography";
import {SpaceBottom, SpaceTop} from "../z40-uicomp/layout";
import {FloatingCard} from "../z40-uicomp/floatingCard";
import {PlasmicComponent, PlasmicRootProvider} from "@plasmicapp/loader-react";
import {PLASMIC} from "../modules/plasmic";
import {translate} from "@soap/modules";
import {useStyletron} from "baseui";

import {CenteredColumn} from "../z40-uicomp/centered-column";
export function Mission() {
    const [css, theme] = useStyletron();
    return (
        <Frame>
            <SpaceBottom>
                <PlasmicRootProvider loader={PLASMIC}>
                    <CenteredCard showRobot={false} title={<HeadingXLarge>{translate("Our Mission")}</HeadingXLarge>} maxWidth={1024}>
                        <PlasmicComponent component="OurMission" />
                    </CenteredCard>
                </PlasmicRootProvider>
            </SpaceBottom>
        </Frame>
    );
}