﻿import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import {useStyletron} from "baseui";
import {
    AggregateList,
    EntityMenu,
    SecondaryActionMenuButton,
    translate,
    useEvent,
    useLazyQuery,
    uuidv4
} from "@soap/modules";
import {KIND} from "baseui/button";
import {EditCandidate} from "./drawers/edit-candidate";
import {ArchiveCandidate} from "./modals/archive-candidate";
import {TagSelect} from "./tag-select";
import {SoapPaginator, useSoapPaginator} from "./soap-paginator";
import {LinkButton} from "../z40-uicomp/buttons";
import {DeleteCandidate} from "./modals/delete-candidate";
import {IncludeArchivedCheckbox} from "../z40-uicomp/include-archived-checkbox";
import {KIND as TAGKIND, Tag, VARIANT} from "baseui/tag";
import {DownloadCandidateData} from "./drawers/download-candidate-data";
import {useDeviceType} from "../hooks/useDeviceType";
import {AddIcon, EditIcon, ViewIcon} from "../z20-icons/icons";

export function CandidateTable(props) {

    const [css, theme] = useStyletron();
    const pageSize = 5;

    const {
        fetchPage,
        currentPageRecords,
        resetRecordSet,
        recordCount,
        currentPage
    } = useSoapPaginator("E131v1_GotCandidates", "C158v1_GetCandidates", pageSize, {});

    const [refreshTags, e103] = useLazyQuery("C119v1_GetTags", {
        C119_TagTypes: {
            SelectedKeys: ['candidate']
        }
    });

    const {isDesktop} = useDeviceType();

    useEvent({
        eventName: "E135v1_CandidateUpdated",
        onEventReceived: (event) => {
            fetchPage();
        }
    }, [fetchPage]);

    useEvent({
        eventName: "E136v1_CandidateArchived",
        onEventReceived: (e, v) => {
            fetchPage();
        }
    }, [fetchPage]);

    useEvent({
        eventName: "E183v1_CandidateDeleted",
        onEventReceived: (e, v) => {
            fetchPage();
        }
    }, [fetchPage]);

    useEvent({
        eventName: "E108v1_TagUpdated",
        onEventReceived: (e, v) => {
            fetchPage();
            refreshTags();
        }
    }, [fetchPage, refreshTags]);

    useEvent({
        eventName: "E106v1_TagRemoved",
        onEventReceived: (e, v) => {
            fetchPage();
            refreshTags();
        }
    }, [fetchPage, refreshTags]);

    useEvent({
        eventName: "E105v1_TagAdded",
        onEventReceived: (e, v) => {
            refreshTags();
        }
    }, [refreshTags]);


    const [includeArchived, setIncludeArchived] = useState(false);

    /* start tags */
    const [selectedTags, setSelectedTags] = useState([]);
    const [tableKey, setTableKey] = useState(uuidv4());

    const selectOptions = useMemo(() => e103?.e103_Tags.map(x => ({
        label: x.e103_Title,
        id: x.e103_LongId
    })) ?? [], [e103]);

    const onOptionsChange = useCallback((selectedOptions) => {
        //* trigger always seems to run first even if its placed after set statements.
        if (selectedOptions.length) {
            fetchPage(1, {
                C158_PipeSeparatedTagIds: selectedOptions.map(t => t.id).join('|'),
                C158_IncludeArchived: includeArchived
            });
        } else {
            resetRecordSet()
        }
        setSelectedTags(selectedOptions);
        setTableKey(uuidv4()); //get rid of any previous state (e.g. expanded rows)
    }, [fetchPage]);
    /* end tags */


    const actionsArray = useMemo(() => {
        const archiveAllowed = entity => entity.e131_Archived !== true;
        const editAllowed = entity => entity.e131_Archived !== true;

        return [
            (entity) => editAllowed(entity) ? <EditCandidate candidateId={entity.e131_CandidateId}/> : null,
            (entity) => archiveAllowed(entity) ? <ArchiveCandidate candidateName={entity.e131_Name}
                                                                   candidateId={entity.e131_CandidateId}/> : null,
            (entity) => <DeleteCandidate candidateName={entity.e131_Name}
                                         candidateId={entity.e131_CandidateId}/>,

            (entity) => <DownloadCandidateData candidateId={entity.e131_CandidateId}/>
        ];
    }, []);

    const headerColumns = useMemo(() => {
        const c = ["e131_Name", "e131_Email", "e131_Assessment", "e131_Date", "e131_Score"];
        if (isDesktop) {
            c.push("e131_TagsApplied");
        }
        return c;
    }, [isDesktop]);

    return (<>
            <AggregateList
                key={tableKey}
                title={translate("Candidates")}
                aggregates={currentPageRecords?.e131_Candidates}
                rowColour={theme.colors.accent}
                headerColumns={headerColumns}
                hiddenFields={["e131_CandidateId", "e131_Name", "e131_AssessmentId", "e131_AssessmentResultId", "e131_Version"]}
                propertyRenderer={{
                    "e131_Assessment": (value, entity) => <><LinkButton
                        onClick={() => location.href = `#/assessments/${entity.e131_AssessmentId}/results/${entity.e131_AssessmentResultId}/2`}>{value}</LinkButton> v{entity.e131_Version}</>,
                    "e131_TagsApplied": (value) => value.map(i => <Tag closeable={false}
                                                                       variant={VARIANT.solid}
                                                                       kind={TAGKIND.neutral}
                                                                       key={i}>{i}</Tag>),
                }}
                entityMenus={{
                    "root-individual": new EntityMenu(null, [
                        () => <TagSelect options={selectOptions} value={selectedTags} onChange={onOptionsChange}/>,
                        () => <IncludeArchivedCheckbox archived={includeArchived} onChange={(includeArchived) => {
                            resetRecordSet({
                                C158_PipeSeparatedTagIds: selectedTags.map(t => t.id).join('|'),
                                C158_IncludeArchived: includeArchived
                            });
                            setIncludeArchived(includeArchived);
                        }}/>,
                        () => <SecondaryActionMenuButton kind={KIND.tertiary}
                                                         onClick={() => location.href = "#/candidates/candidate-library/add-candidate"}>
<AddIcon />{translate("Add Candidate")}</SecondaryActionMenuButton>,
                        () => <SecondaryActionMenuButton kind={KIND.tertiary}
                                                         onClick={() => location.href = "#/candidates/candidate-library/tags"}>{<>
                            <EditIcon /><>{translate("Tags")}</>
                        </>}</SecondaryActionMenuButton>,
                    ]),
                    "root-Items": new EntityMenu(null, actionsArray),
                }}
            />
            <SoapPaginator pageSize={pageSize}
                           page={currentPage}
                           recordCount={recordCount}
                           onPageChanged={(page) => {
                               fetchPage(page);
                           }}
            />
        </>
    );
}