﻿import * as React from 'react';
import {useEffect, useState} from 'react';
import {useStyletron} from "baseui";
import {Frame} from "../z40-uicomp/frame";
import {
    HeadingMedium,
    HeadingSmall,
    LabelXSmall,
    ParagraphLarge,
    ParagraphSmall,
    ParagraphXSmall
} from "baseui/typography";
import {CenteredColumn} from "../z40-uicomp/centered-column";
import {Centered, SpaceRight, SpaceTop} from "../z40-uicomp/layout";
import {
    CenterSpinner,
    getHeader,
    headerKeys, translate,
    useAuth,
    useEvent,
    useLazyCommand,
    useLazyQuery,
    useLogin
} from "@soap/modules";
import robot1 from '../z30-images/robot1.png';
import robot2 from '../z30-images/robot2.png';
import robot3 from '../z30-images/robot3.png';
import {Slider} from "baseui/slider";
import {LineItem} from "../z40-uicomp/lineItem";
import {ActionButton} from "../z40-uicomp/actionButton";
import {Select, SIZE} from "baseui/select";
import {Row} from "../z40-uicomp/row";
import {PricingPane} from "./pricingPane";
import {Roles} from "../hooks/useEnumerations";
import {TalkBadge} from "../z40-uicomp/talk-badge";

const toMoney = function (price) {
    return Number.isInteger(price) ? price : price.toFixed(2);
}


export function Pricing() {

    const [beginOrderTrigger] = useLazyCommand('C167v1_BeginOrder');
    const maintenance = window.ATest.maintenance === true;
    
    //* view state
    const viewStates = {
        waiting: 1,
        showPlans: 2
    };
    const [viewState, setViewState] = React.useState(1);
    const [byoUnitPrices, setByoUnitByoUnitPrices] = React.useState();
    const [fixedPlans, setFixedPlans] = React.useState();
    const [currency, setCurrency] = React.useState();
    const [country, setCountry] = React.useState(window.ATest.PaddleCountryCode);
    const [taxMode, setTaxMode] = React.useState();

    //* styling 
    const [css, theme] = useStyletron();

    //* auth status
    const {
        signUp,
    } = useLogin();
    const {
        authReady,
        authEnabled,
        isAuthenticated,
    } = useAuth("pricing.js");
    
    const [getProfile, gotProfile] = useLazyQuery('C166v1_GetUserProfile');

    const [getSubscriptions, gotSubscriptions] = useLazyQuery('C170v1_GetSubscriptionPlanDetails', {
        C170_CountryCode: country
    });

    useEffect(() => {
        if (authReady) {
            if (isAuthenticated) { //* user is logged in
                if (!gotProfile) {
                    getProfile();
                } else if (gotSubscriptions && gotProfile && viewState === viewStates.waiting) {
                    setPlansAndPrices();
                    if (gotProfile.e138_QueuedPlan.e138_PlanKey2 == 'byo') {
                        setByoSubAccounts([gotProfile.e138_QueuedPlan.e138_Credit2.e138_TotalSubAccounts]);
                        setByoAssessments([gotProfile.e138_QueuedPlan.e138_Credit2.e138_TotalAssessments]);
                        setByoQuestions([gotProfile.e138_QueuedPlan.e138_Credit2.e138_TotalQuestions]);
                        setByoCandidates([gotProfile.e138_QueuedPlan.e138_Credit2.e138_TotalCandidates]);
                        setByoShares([gotProfile.e138_QueuedPlan.e138_Credit2.e138_AssessmentsTakenPerMonth]);
                    }
                    setViewState(viewStates.showPlans);
                }
            } else if (gotSubscriptions && viewState === viewStates.waiting) {
                /* if your not logged in show all plans, this might mean a user that does have an account chooses the plan
                they already have if this is the case we will return them to the pricing page */
                setPlansAndPrices();
                setViewState(viewStates.showPlans);
            }
        }

        function setPlansAndPrices() {

            /* 
            on pricing page we should values based on detected location unless they are logged in and have an existing subscription
            then we use those details and in this case they should not be editable by the user     
             */
            const taxNotIncluded = "Tax not included";
            setCurrencies(gotSubscriptions.e141_Pricing.e141_Currencies.allEnumerations.map(x => ({key: x.key, value: x.key})));
            setCurrency(gotSubscriptions.e141_Pricing.e141_BillingCurrency);
            setCountry(gotSubscriptions.e141_Pricing.e141_BillingCountry);
            setTaxMode(gotSubscriptions.e141_Pricing.e141_TaxMode === "external" ? taxNotIncluded : "");
            
            setByoUnitByoUnitPrices({
                shares: gotSubscriptions.e141_Pricing.e141_PricePerCandidateShare,
                candidates: gotSubscriptions.e141_Pricing.e141_PricePerCandidate,
                questions: gotSubscriptions.e141_Pricing.e141_PricePerQuestion,
                assessments: gotSubscriptions.e141_Pricing.e141_PricePerAssessment,
                subAccounts: gotSubscriptions.e141_Pricing.e141_PricePerSubAccount
            });
            setFixedPlans({
                free: new Plan(gotSubscriptions.e141_Freebee),
                lite: new Plan(gotSubscriptions.e141_Lite),
                extra: new Plan(gotSubscriptions.e141_Extra)
            });
        }
    }, [authReady, gotProfile, gotSubscriptions]);

    class Plan {
        constructor(plan) {
            this.key = plan.e141_PlanKey;
            this.version = plan.e141_Version;
            this.title = plan.e141_PlanName;
            this.shares = plan.e141_PlanCredit.e141_CandidateShares;
            this.candidates = plan.e141_PlanCredit.e141_Candidates;
            this.questions = plan.e141_PlanCredit.e141_Questions;
            this.assessments = plan.e141_PlanCredit.e141_Assessments;
            this.tagLimit = plan.e141_PlanCredit.e141_TagLimit;
            this.subAccounts = plan.e141_PlanCredit.e141_SubAccounts;
        }
    }

    const [byoShares, setByoShares] = React.useState([20]);
    const [byoCandidates, setByoCandidates] = React.useState([25]);
    const [byoQuestions, setByoQuestions] = React.useState([25]);
    const [byoAssessments, setByoAssessments] = React.useState([10]);
    const [byoSubAccounts, setByoSubAccounts] = React.useState([0]);

    const sliderOverrides = {
        InnerThumb: () => null,
        ThumbValue: () => null,
        TickBar: () => null
    };

    function calculateByoPlanPrice() {
        const price = byoUnitPrices.shares * byoShares +
            byoUnitPrices.candidates * byoCandidates +
            byoUnitPrices.questions * byoQuestions +
            byoUnitPrices.assessments * byoAssessments +
            byoUnitPrices.subAccounts * byoSubAccounts;
        return toMoney(price);
    }

    function calculateFixedPlanPrice(plan) {
        const price = byoUnitPrices.shares * plan.shares +
            byoUnitPrices.candidates * plan.candidates +
            byoUnitPrices.questions * plan.questions +
            byoUnitPrices.assessments * plan.assessments +
            byoUnitPrices.subAccounts * plan.subAccounts;
        return toMoney(price);
    }

    function onBuy(planKey) {
        //* start the stateful order process
        const c167 = {
            C167_PlanKey: planKey,
            C167_Country: country,
            C167_ChosenCurrency: currency
        };
        if (planKey === "byo") {
            c167.C167_ByoCandidateShares = byoShares[0];
            c167.C167_ByoCandidates = byoCandidates[0];
            c167.C167_ByoQuestions = byoQuestions[0];
            c167.C167_ByoAssessments = byoAssessments[0];
            c167.C167_ByoSubAccounts = byoSubAccounts[0];
        }
        beginOrderTrigger(c167);
        setViewState(viewStates.waiting);
    }

    useEvent({
        eventName: "E139v1_OrderProcessStarted",
        onEventReceived: (event) => {
            const orderPageUrl = window.location.origin + '/#/order/' + window.btoa(getHeader(event, headerKeys.statefulProcessId));
            //* send the user to the signup/login page. Once they have created an account or logged in then take them to the order page
            if (isAuthenticated) {
                //* user logged in already go straight to request order page
                window.location.assign(orderPageUrl);
            } else {
                signUp(orderPageUrl);
            }
        }
    });

    const currentPlanKey = gotProfile?.e138_QueuedPlan.e138_PlanKey2;
    const isOnBusinessPlan = gotProfile?.e138_IsOnAPlanWithSubAccounts;
    const orgRoleKey =  gotProfile?.e138_OrgRoleKey;
    const [currencies, setCurrencies] = useState([]);
    const existingBilling = isAuthenticated && gotSubscriptions?.e141_Pricing.e141_HasExistingSubscription;
    
    
    
    switch (viewState) {
        case viewStates.waiting:
            return <CenterSpinner/>;
        case viewStates.showPlans:
            return (
                <Frame>
                    <CenteredColumn>
                        <div className={css({
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginBottom: '25px'
                        })}
                        >
                            <SpaceTop>
                                <Row spacing={"3px"} alignItems={"center"} flexWrap={"wrap"}>
                                    <div>
                                        <SpaceRight>
                                        <HeadingMedium style={{
                                            fontWeight: 400
                                        }}>
                                            Choose the Best Plan for You.
                                        </HeadingMedium>
                                        {existingBilling ? null :
                                            <ParagraphSmall>
                                                Get started with our FreeBee plan (no credit card required)
                                            </ParagraphSmall>
                                        }
                                        </SpaceRight>
                                    </div>
                                    
                                    
                                    <div><ParagraphSmall>Prices in</ParagraphSmall></div>
                                    <Select
                                        disabled={existingBilling}
                                        size={SIZE.mini}
                                        options={currencies}
                                        backspaceRemoves={false}
                                        clearable={false}
                                        deleteRemoves={false}
                                        escapeClearsValue={false}
                                        required={true}
                                        searchable={false}
                                        value={[{key: currency}]}
                                        labelKey="value"
                                        valueKey="key"
                                        onChange={params => {
                                            const currencyCode = params?.value[0]?.key;
                                            setViewState(viewStates.waiting);
                                            getSubscriptions({
                                                C170_CountryCode: country,
                                                C170_CurrencyCode: currencyCode
                                            });
                                        }}
                                    />
                                    {existingBilling ? <div><ParagraphSmall> change your billing currency from the profile page</ParagraphSmall></div> : null}
                                </Row>
                                {orgRoleKey === Roles.OrgUser ?
                                    <div className={css({
                                        display: 'flex',
                                        marginTop:"9px",
                                        justifyContent: 'space-evenly',
                                        padding: "12px",
                                        backgroundColor: theme.colors.accent600,
                                        borderRadius: "6px",
                                        flexWrap: 'wrap'
                                    })}><ParagraphSmall>Speak to your Organisation Admin to change the subscription plan</ParagraphSmall></div>
                                    : null}
                            </SpaceTop>
                        </div>
                        <div className={css({
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '10px'
                        })}>
                            <div className={css({
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                padding: "12px",
                                backgroundColor: theme.colors.accent600,
                                borderRadius: "6px",
                                flexWrap: 'wrap'
                            })}>
                                
                                <PricingPane title={fixedPlans.free.title}>
                                    <img width={"95%"} height={"95%"} src={robot1}/>
                                    <LineItem><Centered><HeadingSmall>Free Forever</HeadingSmall></Centered></LineItem>
                                    <LineItem><strong>{fixedPlans.free.shares}</strong> candidate shares per
                                        month</LineItem>
                                    <ActionButton
                                        text={currentPlanKey === fixedPlans.free.key ? "Your Plan" : (!!gotProfile ? "Switch To This Plan" : "Get Started")}
                                        disabled={currentPlanKey === fixedPlans.free.key || maintenance || isOnBusinessPlan}
                                        onClick={() => onBuy(fixedPlans.free.key)}/>
                                    <LineItem><strong>{fixedPlans.free.candidates}</strong> candidates</LineItem>
                                    <LineItem><strong>{fixedPlans.free.questions}</strong> questions</LineItem>
                                    <LineItem><strong>{fixedPlans.free.assessments}</strong> assessments</LineItem>
                                    <LineItem>Limited to {fixedPlans.free.tagLimit} tags</LineItem>
                                    <LineItem>No access to sub accounts</LineItem>
                                </PricingPane>
                                <PricingPane title={<>{fixedPlans.lite.title}</>}>
                                    <img src={robot2}/>
                                    <LineItem><Centered><HeadingSmall>{calculateFixedPlanPrice(fixedPlans.lite)} {currency}<LabelXSmall>{taxMode}</LabelXSmall></HeadingSmall></Centered></LineItem>
                                    <LineItem><strong>{fixedPlans.lite.shares}</strong> candidate shares per
                                        month</LineItem>
                                    <ActionButton
                                        text={currentPlanKey === fixedPlans.lite.key ? "Your Plan" : (!!gotProfile ? "Switch To This Plan" : "Buy Now")}
                                        disabled={currentPlanKey === fixedPlans.lite.key || maintenance || isOnBusinessPlan}
                                        onClick={() => onBuy(fixedPlans.lite.key)}/>
                                    <LineItem><strong>{fixedPlans.lite.candidates}</strong> candidates</LineItem>
                                    <LineItem><strong>{fixedPlans.lite.questions}</strong> questions</LineItem>
                                    <LineItem><strong>{fixedPlans.lite.assessments}</strong> assessments</LineItem>
                                    <LineItem>Unlimited Tagging</LineItem>
                                    <LineItem>No access to sub accounts</LineItem>
                                </PricingPane>
                                    
                                <PricingPane title={<>{fixedPlans.extra.title}</>}>
                                    <img className={css({
                                        marginTop: "15px"
                                    })} src={robot3} width={"90%"} height={"90%"}/>

                                    <LineItem><Centered><HeadingSmall>{calculateFixedPlanPrice(fixedPlans.extra)} {currency}<LabelXSmall>{taxMode}</LabelXSmall></HeadingSmall></Centered></LineItem>

                                    <LineItem><strong>{fixedPlans.extra.shares}</strong> candidate shares per
                                        month</LineItem>
                                    <ActionButton
                                        text={currentPlanKey === fixedPlans.extra.key ? "Your Plan" : (!!gotProfile ? "Switch To This Plan" : "Buy Now")}
                                        disabled={currentPlanKey === fixedPlans.extra.key || maintenance || (isOnBusinessPlan && orgRoleKey !== Roles.OrgAdmin) }
                                        onClick={() => onBuy(fixedPlans.extra.key)}/>
                                    <LineItem><strong>{fixedPlans.extra.candidates}</strong> candidates</LineItem>
                                    <LineItem><strong>{fixedPlans.extra.questions}</strong> questions</LineItem>
                                    <LineItem><strong>{fixedPlans.extra.assessments}</strong> assessments</LineItem>
                                    <LineItem>Unlimited Tagging</LineItem>
                                    <LineItem>Up to {fixedPlans.extra.subAccounts} sub accounts<sup>&dagger;</sup></LineItem>
                                </PricingPane>
                                <PricingPane title={"Build Your Own"}>
                                    <div className={css({})}>
                                        <ActionButton
                                            disabled={maintenance || (isOnBusinessPlan && orgRoleKey !== Roles.OrgAdmin)}
                                            text={currentPlanKey === "byo" ? "Change Custom Plan" : (!!gotProfile ? "Switch To This Plan" : "Buy Now")}
                                            onClick={() => onBuy("byo")}/>
                                        <LineItem
                                            noDivider><Centered><HeadingSmall>{calculateByoPlanPrice()} {currency}<LabelXSmall>{taxMode}</LabelXSmall></HeadingSmall></Centered></LineItem>
                                        <LineItem noDivider><strong>{byoShares}</strong> candidate shares per
                                            month <br/>{byoUnitPrices.shares} {currency} each
                                            <Slider
                                                value={byoShares}
                                                min={20}
                                                max={500}
                                                step={20}
                                                onChange={({value}) => value && setByoShares(value)}
                                                onFinalChange={({value}) => console.log(value)}
                                                overrides={sliderOverrides}
                                            /></LineItem>
                                        <LineItem noDivider><strong>{byoCandidates}</strong> candidates<br/>
                                            {byoUnitPrices.candidates} {currency} each
                                            <Slider
                                                value={byoCandidates}
                                                min={25}
                                                max={1000}
                                                step={25}
                                                onChange={({value}) => value && setByoCandidates(value)}
                                                onFinalChange={({value}) => console.log(value)}
                                                overrides={sliderOverrides}
                                            /></LineItem><LineItem
                                        noDivider><strong>{byoQuestions}</strong> questions<br/>
                                        {byoUnitPrices.questions} {currency} each
                                        <Slider
                                            value={byoQuestions}
                                            min={25}
                                            max={250}
                                            step={25}
                                            onChange={({value}) => value && setByoQuestions(value)}
                                            onFinalChange={({value}) => console.log(value)}
                                            overrides={sliderOverrides}
                                        /></LineItem><LineItem
                                        noDivider><strong>{byoAssessments}</strong> assessments<br/>
                                        {byoUnitPrices.assessments} {currency} each
                                        <Slider
                                            value={byoAssessments}
                                            min={10}
                                            max={250}
                                            step={10}
                                            onChange={({value}) => value && setByoAssessments(value)}
                                            onFinalChange={({value}) => console.log(value)}
                                            overrides={sliderOverrides}
                                        /></LineItem>
                                        <LineItem><strong>{byoSubAccounts}</strong> sub accounts <sup>&dagger;</sup> <br/>
                                            {byoUnitPrices.subAccounts} {currency} each
                                            <Slider
                                            value={byoSubAccounts}
                                            min={0}
                                            max={75}
                                            step={3}
                                            onChange={({value}) => value && setByoSubAccounts(value)}
                                            onFinalChange={({value}) => console.log(value)}
                                            overrides={sliderOverrides}
                                        /></LineItem>
                                        <LineItem>Unlimited Tagging</LineItem>
                                    </div>
                                </PricingPane>
                                
                            </div>
                        </div>
                        <Centered><ParagraphSmall>&dagger;{translate("$subAccountWarning")}</ParagraphSmall></Centered>
                    </CenteredColumn>
                </Frame>
            );
    }


}