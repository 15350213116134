import bus from '../soap/bus';
import commandHandler from '../soap/command-handler';
import {useIsConfigLoaded} from "./systemStateHooks";
import {useEffect, useState} from "react";
import {useAuth} from "./useAuth";
import {toTypeName} from "../soap/messages";
import config from "../soap/config";

export function useCommand(command, sendCommand = true) {
    
    const hookName = "useCommand";
    const configLoaded = useIsConfigLoaded(hookName);
    const { authReady, refresh, authEnabled } = useAuth(hookName);
    const [lastConversationId, setLastConversationId] = useState();
    
    useEffect(() => {

        if (config.debug.hooks) {
            const timeString = new Date().getTime().toString();
            console.warn(`status of ${hookName} at useEffect at ${timeString} `,
                JSON.stringify(
                    {
                        configLoaded, 
                        authEnabled,
                        authReady,
                        sendCommand
                    }));
        }
        
        if (configLoaded && authReady && sendCommand) {
            
            if (command["isJsProxy"] !== true) {
                //* convert from class short name to assembly qualified short name
                //* if its a proxy its already correct and you cannot set it. autoform would be on case where this is true
                command.$type = toTypeName(command.$type);    
            }
            
            if (!command.headers) {
                command.headers = [];
            }
            
            const conversationId = commandHandler.handle(command, () => null, 0, refresh);
            setLastConversationId(conversationId);
            
            return function cleanup() {
                /*
                ConversationId is used to terminate the default subscription when CloseConversation is called
                useEffect's cleanup behaviour will terminate each previous subscription when a new command is sent. In this case if you resend
                using the same hook before the first command's response has been processed you won't process the first command's result.              
                 */
                if (config.debug.hooks) console.warn(
                    `${hookName} destructor ran because one of dependencies changed or the component was ` +
                    "unmounted resulting in termination of any outstanding conversation.");
                
                //* close conversation when component is unloaded or a new command is sent    
                bus.closeConversation(conversationId);
            };
        }
        /* we need the latest refresh but we don't want to trigger a resend of the command if it's already been sent.
        I think react will get the latest version of any dependent objects used in the useEffect closure even if
        they are not in the dependencies list it just won't get them until something that is in the list changes.
        Otherwise, i would have to store refresh in state and use a second useEffect to update it
        */
    }, [configLoaded, authReady, sendCommand, refresh]);

    return lastConversationId;
}
