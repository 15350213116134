import React, {useState} from 'react';
import {AggregateListSecondaryActionsMenu} from "./ActionMenu";
import {FileView} from "./FileView";
import {Button, KIND, SHAPE, SIZE} from "baseui/button";
import DOMPurify from "dompurify";
import {
    StyledTable,
    StyledTableBodyCell,
    StyledTableBodyRow,
    StyledTableHeadCell,
    StyledTableHeadRow
} from "baseui/table-semantic";
import {uuidv4} from "../soap/util";
import {StatefulPanel} from "baseui/accordion";
import {CreateViewButtonForAggregateList} from "./ViewButton";
import {useDomainEvent} from "../hooks/useDomainEvent";



const DataTypes = {
    PrimitiveArray: "primitive-array",
    ObjectArray: "object-array",
    Object: "object"
};

export function ArrayRenderer(props) {

    const [expandedRows, setExpandedRows] = useState([]);
    const [initialExpansionCalculated, setInitialExpansionCalculated] = useState(false);

    const {
        propertyKey,
        arrayOfObjects,
        propertyRenderer,
        labelRenderer,
        entityMenus,
        hiddenFields,
        expandedFields,
        expandedFieldsFirstObjectOnly,
        accordionMode = true,
        conversationId,
        headerColumns,
        dataType = DataTypes.ObjectArray,
        rowColour = "white",
        displayMode,
        displayOptions
    } = props;
    
    const isRoot = propertyKey === "root-Items";
    const hasMenu = entityMenus && entityMenus[propertyKey];
    const baseColour = displayMode ? "white" : colourNameToHex(rowColour.toLowerCase());
    const baseColourText = isDark(baseColour) && !displayMode ? "white" : "black";

    //console.warn(100, propertyKey, conversationId);
    useDomainEvent({
        eventName:"force-aggregate-list-expansion-state",
        channel:"ui",
        conversationId: conversationId,
        onEventReceived: (e,v) => {
            //console.warn(e, propertyKey);
            /* 
            structure should be
            {
                mappings: [
                    {
                        propertyKey: "e152_Whatever-Items",
                        locatorFunction: i => i.id === "something"
                    }
                ]               
            }
             */
            const mappings = e.mappings;
            const mapping = mappings.find(m => m.propertyKey === propertyKey);
            if (mapping) {
                const itemIndex = arrayOfObjects.findIndex(o => mapping.locatorFunction(o));
                if (itemIndex >= 0) {
                    if (mapping.newState === "closed") {
                        setExpandedRows([expandedRows.splice(itemIndex, 1)])   
                    } else {
                        setExpandedRows([itemIndex]);
                    }
                }
            }
        }
    });
    
    {
        if (arrayOfObjects.length > 0) {

            //* BUILD TITLE ROW

            const firstObjectKeys = Object.keys(arrayOfObjects[0]);
            //* reduce header columns set to just those for this object
            const titleColumns = [...headerColumns.filter(x => firstObjectKeys.includes(x))];
            //* if there are none use the object type as a placeholder
            if (titleColumns.length === 0) titleColumns.push("$type");
            //* create an array to hold the components, and a column for the left side control
            let titleComponentArray = [" "];
            for (const titleColumn of titleColumns) { //* respecting the order specified in the incoming prop array
                //* add a column
                titleComponentArray.push(ConvertObjectKeyToLabel(titleColumn, labelRenderer, undefined));
            }
            if (hasMenu) titleComponentArray.push(" "); //* add column for controls at the end


            //* BUILD THE DATA ROWS
            const rows = {
                items: [],
                dataType
            };

            //*  if this key is listed in the expanded keys prop, always expand the row
            //console.warn(1, propertyKey, initialExpansionCalculated, expandedRows, expandedFields, expandedFieldsFirstObjectOnly);
            if (!initialExpansionCalculated) {
                if (expandedFields.includes(`${propertyKey}-Items`)) {
                    const arrayOfAllIndexes = [...Array(arrayOfObjects.length).keys()];

                    setExpandedRows(arrayOfAllIndexes);
                } else if (expandedFieldsFirstObjectOnly.includes(`${propertyKey}-Items`)) {
                    setExpandedRows([0]);
                }
                setInitialExpansionCalculated(true);
            }


            //* add a row for each object in array
            for (const [index, obj] of arrayOfObjects.entries()) {

                //* create empty row object
                const rowObject = {
                    rowIndex: index,
                    rowComponentArray: [],
                    panelObject: null,
                };

                //* when you render the row, if the contents are a base type treat differently
                if (rows.dataType === DataTypes.PrimitiveArray) {
                    //* base type arrays are not object so they have no properties with keys
                    // just add the item in a single column
                    rowObject.rowComponentArray.push(<span key={index}>{obj}</span>);
                } else {
                    //* add a column to row for each title column
                    for (const titleColumn of titleColumns) {
                        //* add data to the column, you could add data which is itself an array table this way 
                        // but it's not advisable from a UI perspective, it will distort the layout too much
                        rowObject.rowComponentArray.push(<ObjectProperty propertyKey={titleColumn}
                                                                         propertyValue={obj[titleColumn]}
                                                                         parentObject={obj}
                                                                         propertyRenderer={propertyRenderer}
                                                                         labelRenderer={labelRenderer}
                                                                         conversationId={conversationId}
                                                                         entityMenus={entityMenus}
                                                                         hiddenFields={hiddenFields}
                                                                         expandedFields={expandedFields}
                                                                         expandedFieldsFirstObjectOnly={expandedFieldsFirstObjectOnly}
                                                                         headerColumns={headerColumns}
                                                                         displayMode={displayMode}
                                                                         displayOptions={displayOptions}
                        />);
                    }
                    

                    //* BUILD OBJECT PANEL
                    const panelObj = removeExcludedFields(obj);

                    if (!!panelObj) rowObject.panelObject = panelObj;
                }

                rows.items.push(rowObject);
            }

            // if (propertyKey === "e105_Countries-ArrayItems") { //useful callsite for debugging
            //     console.log("a",titleComponentArray, "b",titleColumns, "c",rows, "d",arrayOfObjects);
            // }
            
            //* PRINT TO SCREEN

            return PrintRows(propertyKey, titleComponentArray, rows, hiddenFields, headerColumns);

        } else return null;

    }

    function removeExcludedFields(obj) {
        const hiddenFieldsEx = ["$type", "headers", "validate", "types",]; //* validate, types, headers would be present if the root event was passed in
        const objEntries = Object.entries(obj).filter(([key, _]) => !hiddenFieldsEx.includes(key)); //* select the fields not excluded from each object
        return objEntries.length > 0 ? Object.fromEntries(objEntries) : null;
    }

    function PrintRows(propertyKey, titleComponentArray, rows, hiddenFields, headerColumns) {

        const colSpan = titleComponentArray.length;

        return (<><StyledTable>
                <thead>
                {rows.dataType === DataTypes.ObjectArray ? PrintTitleRow(titleComponentArray) : null}
                </thead>
                <tbody >
                {rows.items.map((r, i) => {
                    if (rows.dataType === DataTypes.Object) {
                        return PrintObjectPanel(r.panelObject, colSpan, hiddenFields, headerColumns, "o" + i);
                    } else {
                        const isLastRow = i === rows.items.length - 1;
                        let hasNoObjectPanelFieldsLeftAfterFiltration = true; //* DataTypes.PrimitiveArray default 
                        if (rows.dataType === DataTypes.ObjectArray) {
                            const filteredEntries = Object.entries(r.panelObject).filter(x => !hiddenFields.includes(x[0]) && !headerColumns.includes(x[0]));
                            hasNoObjectPanelFieldsLeftAfterFiltration = filteredEntries.length === 0;
                        }
                        return expandedRows.includes(i)
                            ? [PrintHeaderRow(r, isLastRow, hasNoObjectPanelFieldsLeftAfterFiltration, "h" + i), PrintObjectPanel(r.panelObject, colSpan, hiddenFields, headerColumns, "o" + i)]
                            : PrintHeaderRow(r, isLastRow, hasNoObjectPanelFieldsLeftAfterFiltration, "h" + i)
                    }
                })}
                </tbody>
            </StyledTable></>
        );

    }

    function colourNameToHex(colour) {
        const colours = {
            "aliceblue": "#f0f8ff",
            "antiquewhite": "#faebd7",
            "aqua": "#00ffff",
            "aquamarine": "#7fffd4",
            "azure": "#f0ffff",
            "beige": "#f5f5dc",
            "bisque": "#ffe4c4",
            "black": "#000000",
            "blanchedalmond": "#ffebcd",
            "blue": "#0000ff",
            "blueviolet": "#8a2be2",
            "brown": "#a52a2a",
            "burlywood": "#deb887",
            "cadetblue": "#5f9ea0",
            "chartreuse": "#7fff00",
            "chocolate": "#d2691e",
            "coral": "#ff7f50",
            "cornflowerblue": "#6495ed",
            "cornsilk": "#fff8dc",
            "crimson": "#dc143c",
            "cyan": "#00ffff",
            "darkblue": "#00008b",
            "darkcyan": "#008b8b",
            "darkgoldenrod": "#b8860b",
            "darkgray": "#a9a9a9",
            "darkgreen": "#006400",
            "darkkhaki": "#bdb76b",
            "darkmagenta": "#8b008b",
            "darkolivegreen": "#556b2f",
            "darkorange": "#ff8c00",
            "darkorchid": "#9932cc",
            "darkred": "#8b0000",
            "darksalmon": "#e9967a",
            "darkseagreen": "#8fbc8f",
            "darkslateblue": "#483d8b",
            "darkslategray": "#2f4f4f",
            "darkturquoise": "#00ced1",
            "darkviolet": "#9400d3",
            "deeppink": "#ff1493",
            "deepskyblue": "#00bfff",
            "dimgray": "#696969",
            "dodgerblue": "#1e90ff",
            "firebrick": "#b22222",
            "floralwhite": "#fffaf0",
            "forestgreen": "#228b22",
            "fuchsia": "#ff00ff",
            "gainsboro": "#dcdcdc",
            "ghostwhite": "#f8f8ff",
            "gold": "#ffd700",
            "goldenrod": "#daa520",
            "gray": "#808080",
            "green": "#008000",
            "greenyellow": "#adff2f",
            "honeydew": "#f0fff0",
            "hotpink": "#ff69b4",
            "indianred ": "#cd5c5c",
            "indigo": "#4b0082",
            "ivory": "#fffff0",
            "khaki": "#f0e68c",
            "lavender": "#e6e6fa",
            "lavenderblush": "#fff0f5",
            "lawngreen": "#7cfc00",
            "lemonchiffon": "#fffacd",
            "lightblue": "#add8e6",
            "lightcoral": "#f08080",
            "lightcyan": "#e0ffff",
            "lightgoldenrodyellow": "#fafad2",
            "lightgrey": "#d3d3d3",
            "lightgreen": "#90ee90",
            "lightpink": "#ffb6c1",
            "lightsalmon": "#ffa07a",
            "lightseagreen": "#20b2aa",
            "lightskyblue": "#87cefa",
            "lightslategray": "#778899",
            "lightsteelblue": "#b0c4de",
            "lightyellow": "#ffffe0",
            "lime": "#00ff00",
            "limegreen": "#32cd32",
            "linen": "#faf0e6",
            "magenta": "#ff00ff",
            "maroon": "#800000",
            "mediumaquamarine": "#66cdaa",
            "mediumblue": "#0000cd",
            "mediumorchid": "#ba55d3",
            "mediumpurple": "#9370d8",
            "mediumseagreen": "#3cb371",
            "mediumslateblue": "#7b68ee",
            "mediumspringgreen": "#00fa9a",
            "mediumturquoise": "#48d1cc",
            "mediumvioletred": "#c71585",
            "midnightblue": "#191970",
            "mintcream": "#f5fffa",
            "mistyrose": "#ffe4e1",
            "moccasin": "#ffe4b5",
            "navajowhite": "#ffdead",
            "navy": "#000080",
            "oldlace": "#fdf5e6",
            "olive": "#808000",
            "olivedrab": "#6b8e23",
            "orange": "#ffa500",
            "orangered": "#ff4500",
            "orchid": "#da70d6",
            "palegoldenrod": "#eee8aa",
            "palegreen": "#98fb98",
            "paleturquoise": "#afeeee",
            "palevioletred": "#d87093",
            "papayawhip": "#ffefd5",
            "peachpuff": "#ffdab9",
            "peru": "#cd853f",
            "pink": "#ffc0cb",
            "plum": "#dda0dd",
            "powderblue": "#b0e0e6",
            "purple": "#800080",
            "rebeccapurple": "#663399",
            "red": "#ff0000",
            "rosybrown": "#bc8f8f",
            "royalblue": "#4169e1",
            "saddlebrown": "#8b4513",
            "salmon": "#fa8072",
            "sandybrown": "#f4a460",
            "seagreen": "#2e8b57",
            "seashell": "#fff5ee",
            "sienna": "#a0522d",
            "silver": "#c0c0c0",
            "skyblue": "#87ceeb",
            "slateblue": "#6a5acd",
            "slategray": "#708090",
            "snow": "#fffafa",
            "springgreen": "#00ff7f",
            "steelblue": "#4682b4",
            "tan": "#d2b48c",
            "teal": "#008080",
            "thistle": "#d8bfd8",
            "tomato": "#ff6347",
            "turquoise": "#40e0d0",
            "violet": "#ee82ee",
            "wheat": "#f5deb3",
            "white": "#ffffff",
            "whitesmoke": "#f5f5f5",
            "yellow": "#ffff00",
            "yellowgreen": "#9acd32"
        };

        if (typeof colours[colour.toLowerCase()] != 'undefined')
            return colours[colour.toLowerCase()];

        return colour;
    }

    function isDark(c) {
        c = c.replace(`#`, ``);
        const rgb = parseInt(c, 16);   // convert rrggbb to decimal
        const r = (rgb >> 16) & 0xff;  // extract red
        const g = (rgb >> 8) & 0xff;  // extract green
        const b = (rgb >> 0) & 0xff;  // extract blue
        const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

        //luma 0..255 0 is darkest
        return (luma < 128); // pick a different colour
    }

    function shadeColor(hexColor, magnitude) {
        hexColor = hexColor.replace(`#`, ``);
        if (hexColor.length === 6) {
            const decimalColor = parseInt(hexColor, 16);
            let r = (decimalColor >> 16) + magnitude;
            r > 255 && (r = 255);
            r < 0 && (r = 0);
            let g = (decimalColor & 0x0000ff) + magnitude;
            g > 255 && (g = 255);
            g < 0 && (g = 0);
            let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
            b > 255 && (b = 255);
            b < 0 && (b = 0);
            return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
        } else {
            return hexColor;
        }
    }

    function PrintHeaderRow(rowObject, isLastRow, hasNoObjectPanel, parentRowIndex) {

        const {rowComponentArray, panelObject, rowIndex} = rowObject;

        //* add controls to the end
        if (hasMenu) rowComponentArray.push(<div style={{display: "flex", justifyContent: "flex-end"}} onClick={(e) => {
            // const clickOriginatedInDescendant = (e.currentTarget.contains(e.target) && e.currentTarget !== e.target);
            // var attributeNames = Array.from(e.target.attributes).map(x => x.name);
            // console.warn(e.target);
            // if (clickOriginatedInDescendant || attributeNames.includes('data-baseweb')) { 
            //     // data=baseweb is on all button control, but not on table cell
            //     e.stopPropagation(); //swallow clicks on controls don't let them trigger row expansion/collapse
            // }
        }}>
            {CreateViewButtonForAggregateList(propertyKey, entityMenus, panelObject)}&nbsp;
            <AggregateListSecondaryActionsMenu propertyKey={propertyKey} entityMenus={entityMenus}
                                               entity={panelObject}/>
        </div>);

        const isExpanded = expandedRows.includes(rowIndex);
        const isLastRowOfList = isLastRow && !isRoot;
        const isRowBeforeExpandedRow = expandedRows.includes(rowIndex+1);

        function rowClick(e) {
            if (!hasNoObjectPanel) {
               if (!!e?.target?.type) {
                   //if you are not clicking on the row itself or the expand toggle
                   if (e.target.name !== "expand-toggle") {
                       return;
                   }
               }
               if (isExpanded) {
                   setExpandedRows(oldArray => oldArray.filter(x => x !== rowIndex));
               } else {
                   if (accordionMode===true) {
                       setExpandedRows([rowIndex]);
                   } else {
                       setExpandedRows(oldArray => [rowIndex, ...oldArray]);    
                   }
                }
            }
        }
        
        return (
            <StyledTableBodyRow key={parentRowIndex}>
                {dataType === DataTypes.PrimitiveArray ? null :
                    <StyledTableBodyCell key={"r0"} $style={{
                        width: "1px",
                        borderTopLeftRadius: "12px",
                        verticalAlign: "middle",
                        borderBottomWidth: isLastRowOfList || !isRoot || (isRowBeforeExpandedRow && baseColour !== "#ffffff") ? "0px" : "1px",
                        borderBottomStyle: isExpanded ? "none" : "solid",
                        backgroundColor: isExpanded && isRoot ? baseColour : "white",
                        ":hover": {
                            cursor: hasNoObjectPanel ? 'default' : 'pointer',
                            backgroundColor: isExpanded && isRoot? baseColour : "white"
                        },
                        color: isExpanded && isRoot? baseColourText : "black",
                    }} onClick={rowClick}>
                        
                        {hasNoObjectPanel || displayMode ? <span></span> :
                            <ExpandToggle isExpanded={isExpanded}
                                          onExpand={rowClick}
                                          onCollapse={rowClick} />
                        }
                    </StyledTableBodyCell>}


                {rowComponentArray.map((component, i, arr) => {
                    const isLastColumn = i === arr.length-1;
                    const isControlsColumn = (hasMenu && isLastColumn);
                    const style = {
                        paddingLeft: "16px",
                        paddingRight: "16px",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        verticalAlign: "middle",
                        borderBottomStyle: isExpanded ? "none" : "solid",
                        borderBottomWidth: isLastRowOfList || !isRoot ? "0px" : "1px",
                        backgroundColor: isExpanded && isRoot ? baseColour : "white",
                        ":hover": {
                            cursor: hasNoObjectPanel ? 'default' : 'pointer',
                            backgroundColor: isExpanded && isRoot ? baseColour : "white"
                        },
                        color: isExpanded && isRoot ? baseColourText : "black"
                    };
                    if (isControlsColumn) style.width="1px";
                    
                    return <StyledTableBodyCell key={i} $style={style} onClick={!isControlsColumn ? rowClick : undefined}>{component}</StyledTableBodyCell>;
                })}
            </StyledTableBodyRow>
        );
    }

    function PrintTitleRow(titleStringArray) {

        const style = {
            zIndex: 0,
        };
        if (displayMode) style.fontSize = "inherit";
        if (expandedRows.includes(0)) style.borderBottom = "0px";
        
        return (<StyledTableHeadRow>
            {
                titleStringArray.map((columnName, i) => <StyledTableHeadCell
                        style={style}
                        key={i}
                    >
                        {columnName}
                    </StyledTableHeadCell>
                )}</StyledTableHeadRow>);
    }

    function PrintObjectPanel(panelObject, colSpan, hiddenFields, headerColumns, parentRowIndex) {

        const entries = Object.entries(panelObject);
        const filteredEntries = entries.filter(x => !hiddenFields.includes(x[0]) && !headerColumns.includes(x[0]));

        return !!panelObject ? (
            <StyledTableBodyRow key={parentRowIndex} style={{
                backgroundColor: "white"
            }}>
                <StyledTableBodyCell colSpan={colSpan} $style={{                     
                    borderBottomWidth: "0px",
                    paddingLeft:  "0px",
                    paddingRight: "0px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                }}>
                    {
                        //* add controls to the top
                        (hasMenu && dataType === DataTypes.Object) ? (<div style={{
                            display: "flex",
                            backgroundColor: "white"
                        }}>
                            {CreateViewButtonForAggregateList(propertyKey, entityMenus, panelObject)}&nbsp;
                            <AggregateListSecondaryActionsMenu propertyKey={propertyKey} entityMenus={entityMenus}
                                                               entity={panelObject}/>
                        </div>) : null
                    }
                    {filteredEntries.length > 0 ?
                        (<StyledTable>
                            <tbody>
                            {filteredEntries.map((kvPair, i) => {
                                const isLastRow = i === filteredEntries.length - 1;
                                const isFirstRow = i === 0;
                                const [key, value] = kvPair;

                                return (
                                    <StyledTableBodyRow key={i}>
                                        <StyledTableBodyCell $style={{  //* spacing cell on left
                                            paddingLeft: "0px",
                                            paddingRight: "0px",
                                            borderBottomWidth: "0px",
                                            backgroundColor: "white",
                                            ":hover": {
                                                backgroundColor: "white"
                                            },
                                        }}>
                                        </StyledTableBodyCell>
                                        <StyledTableBodyCell $style={{
                                            borderBottomWidth: !displayMode && isLastRow ? "1px": "0px",
                                            borderTop:!displayMode && isFirstRow && !isRoot ?  "1px solid lightgrey" : "none",
                                            borderLeft:  (!displayMode) ? "1px solid lightgrey" : "none",
                                            borderBottomLeftRadius: isLastRow ? "12px" : "0px",
                                            
                                            ":hover": {
                                                backgroundColor: "white",
                                            },
                                            
                                            
                                            backgroundColor: "white",
                                            color: "black",
                                        }}>
                                            {<span style={{

                                            }}>{ConvertObjectKeyToLabel(key, labelRenderer, panelObject)}</span>}
                                        </StyledTableBodyCell>
                                        <StyledTableBodyCell $style={{
                                            borderBottomWidth: !displayMode && isLastRow  ? "1px": "0px",
                                            borderTop:!displayMode && isFirstRow && !isRoot ?  "1px solid lightgrey" : "none",
                                            ":hover": {
                                                backgroundColor: "white"
                                            },
                                            backgroundColor: "white",
                                            color: "black"
                                            
                                        }}>
                                            <ObjectProperty propertyKey={key} propertyValue={value}
                                                            parentObject={panelObject}
                                                            propertyRenderer={propertyRenderer}
                                                            labelRenderer={labelRenderer}
                                                            conversationId={conversationId}
                                                            entityMenus={entityMenus}
                                                            hiddenFields={hiddenFields}
                                                            expandedFields={expandedFields}
                                                            expandedFieldsFirstObjectOnly={expandedFieldsFirstObjectOnly}
                                                            headerColumns={headerColumns}
                                                            displayMode={displayMode}
                                                            displayOptions={displayOptions}
                                            />
                                        </StyledTableBodyCell>
                                    </StyledTableBodyRow>);
                            })}
                            </tbody>
                        </StyledTable>) : null}
                </StyledTableBodyCell>
            </StyledTableBodyRow>
        ) : null;
    }


    function ConvertObjectKeyToLabel(key, labelRenderer, parentObject) {

        const charArray = Array.from(key.substring(key.indexOf("_") + 1)).map(z => convertPascalToPhrase(z));
        const keyString = charArray.join('');
        if (labelRenderer &&
            labelRenderer[key]) {
            return labelRenderer[key](keyString, parentObject);
        } else {
            return keyString;
        }

        function convertPascalToPhrase(pascal) {
            let result = pascal.replace(/([A-Z])/g, " $1");
            result = result.charAt(0) + result.slice(1);
            result = result.replace(/\d+$/, "");
            return result;
        }
    }

}


function ExpandToggle({isExpanded, onExpand, onCollapse, hideButton}) {
    
    const overrides = hideButton ? {
        BaseButton: {
            style: ({ $theme }) => ({
                backgroundColor: "white",
                ":hover": {
                    backgroundColor: "white"
                },
            })
        }
    } : undefined;
        
    return isExpanded ?
        (<Button name="expand-toggle" 
            overrides={overrides} size={SIZE.mini} kind={hideButton ? KIND.tertiary : KIND.secondary} shape={SHAPE.circle} onClick={onCollapse}><span style={{
            marginLeft: "2px",
            transform: "rotate(90deg)"
        }}>&rsaquo;</span></Button>) : (
            <Button  name="expand-toggle" 
                     overrides={overrides} size={SIZE.mini} kind={hideButton ? KIND.tertiary : KIND.secondary} shape={SHAPE.circle} onClick={onExpand}>&rsaquo;</Button>);
}

function ArrayPropertyTitle( {expanded, itemCount}) {
    
    return (<>
        <span>{itemCount} {itemCount === 1 ? "item" :"items"}</span>
        {<ExpandToggle 
            isExpanded={expanded} 
           hideButton={true} 
        />}
    </>);
}


function ObjectProperty({
                            propertyKey,
                            propertyValue,
                            parentObject,
                            expandedFields,
                            propertyRenderer,
                            labelRenderer,
                            conversationId,
                            hiddenFields,
                            entityMenus,
                            expandedFieldsFirstObjectOnly,
                            headerColumns,
                            displayMode,
                            displayOptions
                        }) {

    const blobMetaMarkerGuid = "20fb62ff-9dd3-436e-a356-eceb335c2572";
    const isExpanded = expandedFields.includes(propertyKey);

    const [open, setOpen] = useState(isExpanded);

    if (propertyRenderer &&
        propertyRenderer[propertyKey]) {
        return propertyRenderer[propertyKey](propertyValue, parentObject);
    } else {
        if (propertyValue instanceof Array) {
            const content = <ArrayRenderer propertyKey={propertyKey + "-Items"}
                                           arrayOfObjects={propertyValue}
                                           propertyRenderer={propertyRenderer}
                                           labelRenderer={labelRenderer}
                                           conversationId={conversationId}
                                           entityMenus={entityMenus}
                                           hiddenFields={hiddenFields}
                                           expandedFields={expandedFields}
                                           expandedFieldsFirstObjectOnly={expandedFieldsFirstObjectOnly}
                                           headerColumns={headerColumns}
                                           displayMode={displayMode}
                                           displayOptions={displayOptions}
                                           dataType={getArrayDataType(propertyValue)}
            />;
            if (displayMode || displayOptions?.hideItemCountToggle) {
               return content;                 
            } else {
                return (<StatefulPanel onChange={obj => setOpen(obj.expanded)}
                                       initialState={{expanded: open}}
                                       title={<ArrayPropertyTitle
                                           expanded={open}
                                           itemCount={propertyValue.length}/>
                                       }
                                       overrides={{
                                           Header: {
                                               style: ({$theme}) => ({
                                                   justifyContent: "flex-start",
                                                   paddingLeft: "0px",
                                                   paddingTop: "0px",
                                                   paddingRight: "7px",
                                                   paddingBottom: "0px",

                                               })
                                           },
                                           Content: {
                                               style: ({$theme}) => ({
                                                   paddingLeft: "0px",
                                                   paddingTop: "0px",
                                                   paddingRight: "0px",
                                                   paddingBottom: "0px",
                                               })
                                           },
                                           PanelContainer: {
                                               style: ({$theme}) => ({
                                                   borderBottomStyle: "none"
                                               })
                                           },
                                           ToggleIcon: {
                                               component: ({$value}) => null
                                           }
                                       }}>{content}</StatefulPanel>);
            }
        } else if (IsChildObject(propertyValue)) {
            return <ArrayRenderer propertyKey={propertyKey}
                                  arrayOfObjects={[propertyValue]}
                                  propertyRenderer={propertyRenderer}
                                  labelRenderer={labelRenderer}
                                  conversationId={conversationId}
                                  entityMenus={entityMenus}
                                  hiddenFields={hiddenFields}
                                  expandedFields={expandedFields}
                                  expandedFieldsFirstObjectOnly={expandedFieldsFirstObjectOnly}
                                  headerColumns={headerColumns}
                                  displayMode={displayMode}
                                  displayOptions={displayOptions}
                                  dataType={DataTypes.Object}
            />;
        } else {
            let value;
            if (typeof propertyValue === typeof '') {
                //string
                if (isIsoDate(propertyValue)) {
                    //date
                    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
                    value = new Date(propertyValue).toLocaleDateString(undefined, dateOptions);
                } else if (propertyValue.includes("<")) {
                    let clean = DOMPurify.sanitize(propertyValue, {USE_PROFILES: {html: true}});
                    value = <div dangerouslySetInnerHTML={{__html: clean}}/>;
                } else {
                    value = <span style={{whiteSpace: "pre-wrap"}}>{propertyValue}</span>;
                }
            } else if (typeof propertyValue === typeof true) {
                //true
                value = propertyValue.toString();
                value = value[0].toUpperCase() + value.slice(1);
            } else if (typeof propertyValue === typeof 1) {
                //number
                value = propertyValue.toString();
            } else if (typeof propertyValue === typeof undefined) {
                //undefined
                value = '- - -';
            } else if (typeof propertyValue === typeof {}) {
                if (propertyValue === null) {
                    //null
                    value = "- - -" ;
                } else if (propertyValue.blobMetaMarker === blobMetaMarkerGuid) {
                    //blob
                    value = <FileView blobMeta={propertyValue}/>;
                } else {
                    value = "## error ##";
                }
            }
            return value;
        }
    }

    function isIsoDate(str) {
        //EXPECTS JS PRECISION ON FRACTIONAL SECONDS TO BE 3
        const d = Date.parse(str);
        return !isNaN(d) && new Date(d).toISOString() === str;
    }

    function isPrimitive(arg) {
        return typeof arg === 'boolean' || typeof arg === 'number' || typeof arg === 'string';
    }

    function getArrayDataType(arr) {
        if (arr.length === 0) return DataTypes.ObjectArray;
        return isPrimitive(arr[0]) ? DataTypes.PrimitiveArray : DataTypes.ObjectArray;
    }

    function IsChildObject(propertyValue) {
        return typeof propertyValue === typeof {} &&
            typeof propertyValue !== typeof '' &&
            propertyValue !== null &&
            propertyValue.blobMetaMarker !== blobMetaMarkerGuid;
    }
}

