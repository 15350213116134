import config from "../soap/config";
import {useCallback} from "react";
import {toTypeName} from "../soap/messages";

export function useCommandValidation() {

    return {
        validateCommand: useCallback(async (command, schema = undefined) => {

            if (!command.headers) command.headers = [];
            if (schema && command.$type && toTypeName(schema) !== command.$type) throw "The command.$type property value and the schema argument provided have conflicting values";
            if (schema) command.$type = toTypeName(schema);
            if (!command.$type && !schema) throw "The command object must always have a $type property; either provide the schema argument to validateCommand(obj, schema) or update the command object like so `command.$type = toTypeName('C999_CommandName')`";
            
            const validationEndpoint = `${config.vars.functionAppRoot}/ValidateMessage?type=${encodeURIComponent(command.$type)}`;

            let response = await fetch(validationEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(command) // body data type must match "Content-Type" header
            });

            const msg = await response.text();

            if (msg) {
                let messages = msg.split('\r\n').filter(x => x !== "");
                return messages;
            } else {
                return undefined;
            }

        }, [])
    };
}