import {useCallback} from "react";

export function useFormatTimerString() {

    const getTimerOutputFromSeconds = useCallback((seconds) => {
        const timerMinutes = seconds >= 60 ? (seconds - (seconds % 60)) / 60 : 0;
        const timerSeconds = seconds < 60 ? seconds : seconds % 60;
        const timerMinutesString = timerMinutes > 0 ? (String(timerMinutes) + " minute" + (timerMinutes === 1 ? "" : "s")) : "";
        const timerSecondsString = timerSeconds > 0 ? ( (timerMinutes > 0 ? "and " : "") + String(timerSeconds) + " second" + (timerSeconds === 1 ? "": "s")) : "";
        return `${timerMinutesString} ${timerSecondsString}`;
    }, []);

    const getTimerOutput = useCallback((milliseconds) => {
        const seconds = milliseconds / 1000;
        const timerMinutes = seconds >= 60 ? (seconds - (seconds % 60)) / 60 : 0;
        const timerSeconds = seconds < 60 ? seconds : seconds % 60;
        return `${String(timerMinutes).padStart(2, '0')}:${String(timerSeconds).padStart(2, '0')}`;
    }, []);
    
    return {
        fromSeconds: getTimerOutputFromSeconds,
        fromMilliseconds: getTimerOutput
    };
}