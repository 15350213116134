import {Frame} from "../z40-uicomp/frame";
import {CenteredCard} from "../z40-uicomp/centered-card";
import {translate} from "@soap/modules";
import * as React from "react";
import {Cell, Grid} from "baseui/layout-grid";
import {useStyletron} from "baseui";
import {HeadingLarge, HeadingXLarge, MonoHeadingXSmall, ParagraphSmall} from "baseui/typography";
import {SpaceBottom, SpaceLeft, SpaceTop, SpaceTopAndBottom} from "../z40-uicomp/layout";
import {PlasmicComponent, PlasmicRootProvider} from "@plasmicapp/loader-react";
import {PLASMIC} from "../modules/plasmic";


function FeatureRow({concept, title, img, description}) {
    const [css, theme] = useStyletron();

    return (
        [
            <Cell key={"image"} span={6}>
                <div className={css({
                    marginTop: "70px"
                })}>
                    <img src={img} width={"100%"}/>
                </div>
            </Cell>,
            <Cell span={6} key={"text"}>
                <div
                    className={css({
                        marginTop: "70px"
                    })}
                >
                    <SpaceLeft override={"50px"}>
                            
                            <MonoHeadingXSmall className={css({
                                color: theme.colors.accent500
                            })}>{concept}</MonoHeadingXSmall>
                        
                        <SpaceBottom>
                            <HeadingLarge>{title}</HeadingLarge>
                        </SpaceBottom>
                        <ParagraphSmall className={css({
                            color: theme.colors.accent500
                        })}>
                           {description}
                        </ParagraphSmall>
                    </SpaceLeft>
                </div>
            </Cell>
        ]
    )
}

export function Features() {

    const [css, theme] = useStyletron();
    
    return (
        <Frame>
            <SpaceBottom>
            <PlasmicRootProvider loader={PLASMIC}>
                <CenteredCard showRobot={false} title={<HeadingXLarge>{translate("Our Features")}</HeadingXLarge>} maxWidth={1024}>
                    <PlasmicComponent component="OurFeatures" />
                </CenteredCard>
            </PlasmicRootProvider>
            </SpaceBottom>
        </Frame>
    );
}