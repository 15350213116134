import React, {useMemo} from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import {Route} from "react-router-dom";
import {types, validateArgs} from "../soap/util";
import {CenterSpinner} from "./CenterSpinner.jsx";
import {useAuth} from "../hooks/useAuth";

function ProtectedComponent({component, returnTo}) {
    /* each time you call withAuthenticationRequired you get a different new HOC
    if you don't memoize it you will get a new instance each time you use it 
    in a route even if the props are the same.
     */
    const MemoizedComponent = useMemo(() => {
        return withAuthenticationRequired(component, {
            loginOptions: {
                authorizationParams: {
                    /* auth0 security doesn't validate the fragment, so if using hash routing you don't need to have the url for each one registered, nor do you need a specific callback page,
                    you only need the origin to be in the callback it will load the SPA with the hash routing so load the correct component
                    but for some reason I don't know if changes the url to read as the origin callback url, to fix this you need to set the returnTo  
                     */
                    redirect_uri: returnTo
                }
            },
            onRedirecting: () => <CenterSpinner/>,
            returnTo: returnTo
        });
    }, [component, returnTo]);

    return <MemoizedComponent/>;
}

export function ProtectedRoute({path, component, ...args}) {

    /* 3 scenarions
    1. from a protectedcomponent (this one)
        this should come to a callback page and you need to redirect again from there 
        onRedirect will do the redirect
    2. when calling logIn() or signUp() with redirect_uri
        any urls that are not the origin plus any fragment need to be specified as callback urls
    3. when logging in or signing up without params
        this should redirect to the origin    
     */

    validateArgs(
        [{path}, types.string]
    );

    const {
        authReady,
        authEnabled
    } = useAuth(`ProtectedRoute [${path}]`);


    if (authReady) {
        if (authEnabled) {
            return (<Route path={path} {...args}>
                <ProtectedComponent component={component} returnTo={window.location.href}/>
            </Route>);

        } else {
            return <Route path={path} component={component} {...args}  />;
        }
    }
    return <CenterSpinner/>;
}


    



