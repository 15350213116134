﻿import {CenteredCard} from "../../z40-uicomp/centered-card";
import {AutoForm, useDomainEvent, useEvent, uuidv4} from "@soap/modules";
import React, {useState} from "react";
import {HeadingMedium, HeadingSmall, LabelLarge, ParagraphLarge} from "baseui/typography";
import {EditQuestionTopNav} from "../../z50-domaincomp/drawers/edit-question-menu";

export function EditQuestionValue({questionId, questionType, questionName, selectedItemId}) {

    const completeEdit = useDomainEvent({
        eventName: "edit-complete",
        conversationId: "edit-question"
    });
    
    const hiddenFields=["C148_QuestionId"];

    const [autoFormKey, setAutoFormKey] = useState(uuidv4());

    useEvent({
        eventName: "E125v1_QuestionUpdated",
        onEventReceived:(e) => {
            setAutoFormKey(uuidv4())
        }
    })
    
    if(questionType.allowPartialPoints===false) hiddenFields.push("C148_AllowPartialCredit");

    return (<CenteredCard onBack={() => completeEdit()} title={"Edit Question"}>
            <EditQuestionTopNav questionId={questionId} questionType={questionType} selectedItemId={selectedItemId} />
            <AutoForm
                title={"Edit the Question Value"}
                key={autoFormKey}
                query={{
                    $type: "C149v1_GetC148FormData",
                    "C149_QuestionId": questionId
                }}
                hiddenFields={hiddenFields}
                submitText={"Save"}
            />
        </CenteredCard>
    );
}

