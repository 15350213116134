﻿import {
    ActionDrawer,
    CenterSpinner,
    SecondaryActionMenuButton,
    SecondaryActionMenuItem,
    useLazyQuery
} from "@soap/modules";
import React, {useEffect, useState} from "react";
import {Button, KIND, SIZE} from "baseui/button";

export function GeneratePreviewLink({assessmentId}) {

    const [trigger, e180] = useLazyQuery("C222v1_GetPreviewLink");

    const viewStates = {
        waiting: 0,
        generating: 1
    };

    const [viewState, setViewState] = useState(viewStates.waiting);

    useEffect(() => {
        if (e180) {
            location.href=e180.e180_Link;    
        }
    }, [e180]);

    switch (viewState) {
        case viewStates.waiting:
            return (
                <Button style={{width:"100%"}} kind={KIND.tertiary} size={SIZE.compact} onClick={() => {
                    setViewState(viewStates.generating)
                    trigger({
                        C222_AssessmentId: assessmentId
                    });
                }} >Preview</Button> 
            )
        case viewStates.generating:
            return <CenterSpinner />;
    }
}
