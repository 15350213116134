import {useEvent} from "./useEvent";
import {useAuth} from "./useAuth";
import {useEffect, useRef, useState} from "react";
import {uuidv4} from "../soap/util";
import config from "../soap/config";

export function useTokenRefreshRequired(onTokenRefreshRequired) {
    
    const {refresh, tokenConversationId, accessToken, idToken} = useAuth("useTokenRefreshRequired")
    const lastConversationId = useRef();
    const tokenRefreshRequired = "4d96bfef-adc5-4818-89f2-9147c6d75e47";
    
    useEvent({
        eventName: "Soap.Interfaces.Messages.E001v1_MessageFailed, Soap.Interfaces.Messages",
        onEventReceived: (event) => {
            (async () => {
                if (event.e001_ErrorCodes?.includes(tokenRefreshRequired)) {
                    lastConversationId.current = uuidv4();
                    await refresh({
                        ignoreCache: true,
                        conversationId: lastConversationId.current
                    });
            }})();
        }
    });

    useEffect(() => {
        if (tokenConversationId && tokenConversationId === lastConversationId.current) { //* meaning it is not the initial setting of the token and its for this refresh
            const beforeId = lastConversationId.current;
            lastConversationId.current = null;
            if (config.debug.auth) {
                console.warn("running onTokenRefreshRequired", beforeId, tokenConversationId);
            }
            /* access and id tokens should be updated in useAuth at the time the conversationids match.
            Pass them to the callback though as its not guaranteed that they will be updated in the parent yet.
            They are guaranteed to be updated though in anything that uses the config directly, such as the command-handler */
            onTokenRefreshRequired(idToken, accessToken);
            
        }
    }, [idToken, accessToken, tokenConversationId]);
}

