import * as React from 'react';
import {useState} from 'react';
import {Button, KIND, SIZE} from 'baseui/button';
import {Modal, ModalBody, ModalButton, ModalFooter, ModalHeader,} from 'baseui/modal';
import {RightAlign} from "./layout";
import robot from "/z30-images/robot-s.png"
import {bus, optional, types, useAuth, validateArgs} from "@soap/modules";

export default function ActionDialog(props) {

    const {
        title, auth, children, 
        menuButtonKind=KIND.tertiary, 
        menuButtonSize=SIZE.compact, 
        closeAllDialogsOnSuccess,
        button1Text = "OK",
        onButton1ClickAsync = async () => {
        },
        onButton1SuccessAsync = async () => {
            close();
        },
        button2Text,
        onButton2ClickAsync = async () => {
        },
        onButton2SuccessAsync = async () => {
        }
    } = props;
    let {menuText, dialogHeader} = props;

    menuText = menuText ?? title;
    dialogHeader = dialogHeader ?? title;

    const {requireAuth} = useAuth("ActionDrawer");

    const [hideDialog, setHideDialog] = useState(true);

    function close() {
        setHideDialog(true);
        if (closeAllDialogsOnSuccess) {
            bus.closeAllDialogs();
        }
    }

    validateArgs(
        [{auth}, types.boolean, optional]
    );

    return menuText ? (<>
        <Button
            style={{width: "100%"}} //* fill menu width 
            size={menuButtonSize} kind={menuButtonKind} onClick={() => {
            if (auth) {
                requireAuth(() => {
                    setHideDialog(false);
                })
            } else {
                setHideDialog(false);
            }
        }} children={menuText}/>
        {hideDialog ? null : <DialogModal modalHeader={dialogHeader} close={close} button1Text={button1Text}
                                          onButton1ClickAsync={onButton1ClickAsync}
                                          onButton1SuccessAsync={onButton1SuccessAsync} button2Text={button2Text}
                                          onButton2ClickAsync={onButton2ClickAsync}
                                          onButton2SuccessAsync={onButton2SuccessAsync}>{children}</DialogModal>}
    </>) : null;
}


export function DialogModal({
                                modalHeader,
                                close,
                                children,
                                button1Text = "OK",
                                onButton1ClickAsync,
                                onButton1SuccessAsync,
                                button2Text,
                                onButton2ClickAsync,
                                onButton2SuccessAsync
                            }) {

    return (
        <React.Fragment>
            <Modal closeable={false} isOpen={true} autoFocus={false}>
                <ModalHeader>{modalHeader}<RightAlign><img src={robot}
                                                           height={"50px"}/></RightAlign></ModalHeader>
                <ModalBody>
                    {children}
                </ModalBody>
                <ModalFooter>
                    {button1Text ?
                        <ModalButton kind={KIND.secondary} size={SIZE.compact} onClick={
                            () => {
                                (async function () {
                                    if (onButton1ClickAsync) {
                                        const result = await onButton1ClickAsync();
                                        if (result === false) return;
                                    }
                                    if (onButton1SuccessAsync) {
                                        await onButton1SuccessAsync();
                                    }
                                    close();
                                })();
                            }
                        }>
                            {button1Text}
                        </ModalButton> : null}
                    {button2Text ?
                        <ModalButton kind={KIND.secondary} size={SIZE.compact} onClick={
                            () => {
                                (async function () {
                                    if (onButton2ClickAsync) {
                                        const result = await onButton2ClickAsync();
                                        if (result === false) return;
                                    }
                                    if (onButton2SuccessAsync) {
                                        await onButton2SuccessAsync();
                                    }
                                    close();
                                })();
                            }
                        }>
                            {button2Text()}
                        </ModalButton> : null}
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}