﻿import {AutoForm, bus, translate, useDomainEvent, useEvent, uuidv4} from "@soap/modules";
import React, {useState} from "react";
import {CenteredCard} from "../../z40-uicomp/centered-card";
import {EditAssessmentTopNav} from "../../z50-domaincomp/drawers/edit-assessment-menu";

export function EditAssessmentInstructions({assessmentId, assessmentTitle, selectedItemId}) {
    
    const [autoFormKey, setAutoFormKey] = useState(uuidv4());
    
    useEvent({
        eventName: "E149v1_AssessmentUpdated",
        onEventReceived:(e) => {
            setAutoFormKey(uuidv4())
        }
    })
    
    return (
        <CenteredCard title={"Edit Assessment"} onBack={() => location.href = "#/assessments/assessment-library"} maxWidth={600}>
            <EditAssessmentTopNav assessmentId={assessmentId} assessmentTitle={assessmentTitle} selectedItemId={selectedItemId} />
            <AutoForm 
                title={"Edit"}
                query={{
                    $type: "C185v1_GetC184FormData",
                    C185_AssessmentId: assessmentId
                }}
                submitText={translate("Save")}
                hiddenFields={["C184_AssessmentId"]}
                key={autoFormKey}
            /></CenteredCard>
    );
}

