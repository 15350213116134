﻿import React, {useCallback, useEffect, useState} from "react";
import ErrorModal from "../z40-uicomp/error-modal";
import {SpaceBottom, SpaceTopAndBottom} from "../z40-uicomp/layout";
import {TalkBadge} from "../z40-uicomp/talk-badge";
import {LinkButton} from "../z40-uicomp/buttons";
import {StatefulPanel} from "baseui/accordion";
import {Input, SIZE} from "baseui/input";
import {Textarea} from "baseui/textarea";
import {
    toTypeName, translate,
    useApiErrors,
    useAuth,
    useCommandValidation,
    useLazyCommand,
    ValidationNotification
} from "@soap/modules";
import {MonoParagraphSmall, ParagraphXSmall} from "baseui/typography";

export function ErrorComponent({defaultErrorMessage = "An Error Occurred"}) {

    const {validateCommand} = useCommandValidation();
    
    const viewStates = {
        hidden: 1,
        hasDefaultMessage: 2,
        hasCustomMessage: 3,
        sendEmail: 4
    };
    const [viewState, setViewState] = useState(viewStates.hidden);
    const [customerMessage, setCustomerMessage] = useState("");
    const [customerEmail, setCustomerEmail] = useState("");
    const [customerName, setCustomerName] = useState("");
    const {
        isAuthenticated
    } = useAuth("error-component.js");
    const [sendC117] = useLazyCommand("C117v1_ContactUs");
    const {errors, clearErrors} = useApiErrors();
    const errorMessages = errors.map(x => x.errorMessage);
    const errorCodes = errors.flatMap(x => x.errorCodes);
    const messageIds = errors.map(x => x.messageId);
    const [commandValidationMessages, setCommandValidationMessages] = useState([]);
    
    useEffect(() => {
        
        
        if (errorCodes.length > 0) {
            /* if we are returning an error code it is because we are expecting to handle this with specific logic
            so we won't show an end user message. this is why we should never use error codes unless we have that
            logic in place.
             */
            return;
        }
            
        if (errors.length === 0) {
            setViewState(viewStates.hidden);
            return;
        }
        
        if (errorMessages.some(x => x === defaultErrorMessage)) {
            setViewState(viewStates.hasDefaultMessage);
            return;
        }
        setViewState(viewStates.hasCustomMessage);
    }, [errors, defaultErrorMessage]);

    const reset = useCallback(() => {
        setCustomerMessage("");
        setCustomerName("");
        setCustomerEmail("");
        clearErrors();
    }, [clearErrors]);

    switch (viewState) {
        case viewStates.hidden:
            return null;
        case viewStates.hasDefaultMessage:
            return (<ErrorModal onClose={reset}>
                <>
                    <SpaceBottom>
                        <TalkBadge>
                            <p>Hi, it's Alan here. Sorry, it appears something has gone wrong!</p>
                        </TalkBadge>
                    </SpaceBottom>
                    <SpaceBottom>
                        <TalkBadge>
                            <p>You may want to try again, or,&nbsp;
                                <LinkButton underline={false} onClick={() => {
                                    setViewState(viewStates.sendEmail);
                                }}>click here</LinkButton> to
                                request I investigate. I will be able to look
                                up {(isAuthenticated ? "your details and" : "")} the error.</p>
                        </TalkBadge>
                    </SpaceBottom>
                    {errorMessages.length > 1 ?
                        <StatefulPanel title={`x${errorMessages.length}`}>
                            {errorMessages.map((e, i) => <p key={i}>[{i + 1}] {e}<br/></p>)}
                        </StatefulPanel> : null}
                </>
            </ErrorModal>);
        case viewStates.hasCustomMessage:
            return (
                <ErrorModal onClose={reset}>
                    <>
                        {errorMessages.map((e, i) => <TalkBadge key={i}><p>{
                            e.startsWith('$') ? translate(e) : e }<br/></p></TalkBadge>)}
                    </>
                </ErrorModal>
            );
        case viewStates.sendEmail:
            return (
                <ErrorModal
                    onClose={reset}
                    onButtonCloseAsync={async () => {
                        const command = {
                            C117_Email: !!customerEmail ? customerEmail : "im@mycomputer.com",
                            C117_Fullname: !!customerName ? customerName : "not provided",
                            C117_Topic: {selectedKeys: ["tech-support"]},
                            C117_Message: `Customer requested to investigate error in message(s) ${messageIds.toString()} Additional Info: ${customerMessage}`
                        };
                        const commandValidationErrors = await validateCommand(command, "C117v1_ContactUs");
                        
                        if (commandValidationErrors) {
                            setCommandValidationMessages(commandValidationErrors.filter(x => x !== ""));
                            
                            return false;
                        } else {
                            sendC117(command);
                            reset();
                        }
                    }}
                    closeButtonText={"Send"}
                >
                    <>
                        <ValidationNotification errorMsg={commandValidationMessages.length ? commandValidationMessages.map((m,i) => <p key={i}>{m}</p>) : undefined} />
                        <SpaceTopAndBottom>
                            <Input placeholder={"Email"} size={SIZE.compact}
                                   onChange={(e) => setCustomerEmail(e.target.value)} value={customerEmail}/>
                            
                        </SpaceTopAndBottom>
                        <Input placeholder={"Name"} size={SIZE.compact}
                               onChange={(e) => setCustomerName(e.target.value)} value={customerName}/>
                        
                        <SpaceTopAndBottom>
                            <TalkBadge>Is there anything specific about the problem you think I should know? If so,
                                please type it below. Then press Send.</TalkBadge>
                        </SpaceTopAndBottom>
                        <Textarea placeholder={"Please Investigate"} size={SIZE.compact}
                                  onChange={(e) => setCustomerMessage(e.target.value)} value={customerMessage}/>
                    </>
                </ErrorModal>
            );
    }
}