﻿import * as React from 'react';
import {useEffect, useState} from 'react';
import * as Soap from '@soap/modules'
import {
    AggregateView,
    CenterSpinner,
    EntityMenu,
    SecondaryActionMenuButton, translate,
    useEvent,
    useLazyQuery
} from '@soap/modules'
import {CenteredCard, SlimCard} from "../z40-uicomp/centered-card";
import {Frame} from "../z40-uicomp/frame";
import {
    HeadingLarge,
    HeadingMedium,
    HeadingSmall,
    LabelMedium,
    ParagraphMedium,
    ParagraphSmall,
    ParagraphXSmall
} from "baseui/typography";
import {useStyletron} from "baseui";
import {Centered, SpaceBottom, SpaceTopAndBottom} from "../z40-uicomp/layout";
import {LineItem} from "../z40-uicomp/lineItem";
import {Cell, Grid} from "baseui/layout-grid";
import {KIND, Notification} from 'baseui/notification';
import {EditBillingDetails} from "../z50-domaincomp/drawers/edit-billing-details";
import {ProgressBar} from "baseui/progress-bar";
import {EditBillingCurrency} from "../z50-domaincomp/drawers/edit-billing-currency";
import {CenteredColumn} from "../z40-uicomp/centered-column";
import {DialogModal} from "../z40-uicomp/dialog-modal";
import {LinkButton} from "../z40-uicomp/buttons";
import {DialogListItem} from "../z40-uicomp/dialog-list-item";
import {Roles} from "../hooks/useEnumerations";


export function MyProfile() {

    const [getProfile, e138GotProfile] = useLazyQuery("C166v1_GetUserProfile", {});


    
    useEvent({
        eventName: "E145v1_BillingDetailsUpdated",
        onEventReceived: (e, v) => {
            getProfile();
        }
    }, [getProfile]);

    useEvent({
        eventName: "E188v1_BillingCurrencyUpdated",
        onEventReceived: (e, v) => {
            getProfile();
        }
    }, [getProfile]);

    //* styling 
    const [css, theme] = useStyletron();

    
    
    if (e138GotProfile) {

        const profileView = {
            FirstName: e138GotProfile.e138_FirstName,
            LastName: e138GotProfile.e138_LastName,
            Email: e138GotProfile.e138_Email,
            CurrentPlan: e138GotProfile.e138_ActivePlan,
            BillingCountry: e138GotProfile.e138_BillingCountry,
            BillingCurrency: e138GotProfile.e138_BillingCurrency,
            BillingPostcode: e138GotProfile.e138_BillingPostcode,
            BillingTaxMode: e138GotProfile.e138_BillingTaxMode,
            QueuedPlan: e138GotProfile.e138_QueuedPlan,
            AuthenticationProvider: e138GotProfile.e138_Provider.e138_AuthenticationProvider,
            UpdateUrl: e138GotProfile.e138_UpdatePaymentDetailsTransactionId,
            IsPastDue: e138GotProfile.e138_AccountPastDue,
            Invoices: e138GotProfile.e138_Invoices,
            IsOnPaidPlan: e138GotProfile.e138_QueuedPlan.e138_PlanKey2 !== 'free',
            HasJoinedOrganisation: e138GotProfile.e138_IsOnAPlanWithSubAccounts,
            TenantName: e138GotProfile.e138_TenantName,
            OrgRoleKey: e138GotProfile.e138_OrgRoleKey
        };

        const pastDue = profileView.IsPastDue;

        const hasBillingCountry = !!e138GotProfile.e138_BillingCountry;
        const hasBillingPostcode = !!e138GotProfile.e138_BillingPostcode;

        return (<Frame>

            <CenteredCard maxWidth={800}
                          title={<Centered><HeadingLarge>My Profile</HeadingLarge></Centered>}>

                {profileView.HasJoinedOrganisation ?
                <SpaceTopAndBottom>
                    <SlimCard>
                        
                            <Centered>
                                <ParagraphMedium>
                                Your account is a member of {profileView.TenantName}.
                                </ParagraphMedium>
                            </Centered><Centered>
                            <LinkButton onClick={() => location.href = '#/my-org'}><ParagraphMedium> View Organisation
                                Details</ParagraphMedium></LinkButton>
                        </Centered>
                        
                    </SlimCard>
                </SpaceTopAndBottom>
                    : null }


                {pastDue ? <Notification kind={KIND.negative} overrides={{
                    Body: {style: {width: 'auto'}},
                }}> {profileView.IsOnPaidPlan ?
                    "Your account is Past Due. We we're not able to charge your registered Payment Method. Please update your billing details." :
                    "There was a problem renewing your account credit. Please raise a support ticket in order to resolve this issue."}

                </Notification> : null}


                {(e138GotProfile?.e138_Provider.e138_IsExternal === false) ?
                    <>
                        <SpaceBottom>
                            <LineItem>
                                <Grid gridMargins={0} gridGaps={10} gridGutters={0}>
                                    <Cell span={[1, 2, 3]}>Email</Cell>
                                    <Cell span={[3, 6, 9]}><LabelMedium>{profileView.Email}</LabelMedium></Cell>
                                    {hasBillingCountry ?
                                        [<Cell key={"EDBAAFDB-F480-48E5-AA39-2DBDC78DCF2D"} span={[1, 2, 3]}>Billing
                                            Country</Cell>,
                                            <Cell key={"CC183A48-C292-4CB5-AE71-C56118345C18"}
                                                  span={[3, 6, 9]}>{profileView.BillingCountry}</Cell>
                                        ] : null}
                                    {hasBillingPostcode ?
                                        [<Cell key={"B97235AF-4644-4A8F-8BFF-DFDEBD76EE8F"} span={[1, 2, 3]}>Billing
                                            Post/Zip Code</Cell>,
                                            <Cell key={"69D59D7D-B735-4F30-98D6-84FF240F83C6"}
                                                  span={[3, 6, 9]}>{profileView.BillingPostcode}</Cell>
                                        ] : null}
                                </Grid>
                            </LineItem>

                            <Soap.AutoForm
                                query={{
                                    $type: "C165v1_GetC164FormData"
                                }}
                                submitText={"Update Contact Details"}
                                afterSubmit={() => {
                                    window.location.reload();
                                }}
                            />

                        </SpaceBottom>
                        <PlanView profileView={profileView}/>
                    </>
                    :
                    <>

                        <SpaceBottom>
                            <LineItem>
                                <Grid gridMargins={0} gridGaps={10} gridGutters={0}>
                                    <Cell span={[1, 2, 3]}>Email</Cell>
                                    <Cell span={[3, 6, 9]}>{profileView.Email}</Cell>
                                    <Cell span={[1, 2, 3]}>First Name</Cell>
                                    <Cell span={[3, 6, 9]}>{profileView.FirstName}</Cell>
                                    <Cell span={[1, 2, 3]}>Last Name</Cell>
                                    <Cell span={[3, 6, 9]}>{profileView.LastName}</Cell>
                                    <Cell span={[2, 2, 3]}>Authentication Provider</Cell>
                                    <Cell span={[2, 6, 9]}>{profileView.AuthenticationProvider}</Cell>
                                    {hasBillingCountry ?
                                        [<Cell key={"EDBAAFDB-F480-48E5-AA39-2DBDC78DCF2D"} span={[1, 2, 3]}>Billing
                                            Country</Cell>,
                                            <Cell key={"CC183A48-C292-4CB5-AE71-C56118345C18"}
                                                  span={[3, 6, 9]}>{profileView.BillingCountry}</Cell>
                                        ] : null}
                                    {hasBillingPostcode ?
                                        [<Cell key={"B97235AF-4644-4A8F-8BFF-DFDEBD76EE8F"} span={[1, 2, 3]}>Billing
                                            Post/Zip Code</Cell>,
                                            <Cell key={"69D59D7D-B735-4F30-98D6-84FF240F83C6"}
                                                  span={[3, 6, 9]}>{profileView.BillingPostcode}</Cell>
                                        ] : null}
                                </Grid>

                            </LineItem>

                            <SpaceTopAndBottom>
                                <ParagraphSmall className={css({})}>If you wish to edit your profile details please goto
                                    your {e138GotProfile.e138_Provider.e138_AuthenticationProvider} account to change
                                    them.<br/>
                                    They will be updated on your next login.</ParagraphSmall>
                            </SpaceTopAndBottom>
                        </SpaceBottom>

                        <PlanView profileView={profileView}/>
                    </>}

            </CenteredCard>
        </Frame>);
    } else {
        return <CenterSpinner/>;
    }
}

function PlanView({profileView}) {


    //* styling 
    const [css, theme] = useStyletron();


    const rootActions = [];
    const rootIndividualActions = [() => <SecondaryActionMenuButton onClick={() => location.assign('/#/pricing')}>Change
        Plan</SecondaryActionMenuButton>];

    if (profileView.UpdateUrl) {
        rootActions.push(() => <SecondaryActionMenuButton className={css({
            backgroundColor: "white",
            width: '100%'
        })} onClick={() => {
            Paddle.Checkout.open({
                transactionId: profileView.UpdateUrl,
                allowLogout: false
            });
        }}>Update Payment Method</SecondaryActionMenuButton>);
    }

    if (profileView.IsOnPaidPlan) {
        rootActions.push(() => <EditBillingDetails taxMode={profileView.BillingTaxMode}/>);
        rootActions.push(() => <EditBillingCurrency/>);
        rootIndividualActions.push(() => <SecondaryActionMenuButton
            onClick={() => location.href = "#/my-profile/invoices"}
        >View Invoices</SecondaryActionMenuButton>);


    }

    let hiddenFields = ["e138_PlanKey", "e138_AssessmentsLimit", "e138_StartDate", "e138_TagsLimit", "e138_AssessmentsTakenLimit", "e138_CandidatesLimit", "e138_AssessmentsLimit", "e138_QuestionsLimit", "e138_SubAccountsLimit"];
    let hiddenFields2 = ["e138_PlanKey2"];

    return (<>

        <SpaceBottom><HeadingMedium>My Plan</HeadingMedium> </SpaceBottom>

        <AggregateView
            hiddenFields={hiddenFields}
            aggregate={profileView.CurrentPlan} displayMode={false} entityMenus={{
            "root-individual": new EntityMenu(null, rootIndividualActions),
            "root": rootActions.length > 0 ? new EntityMenu(null, rootActions) : undefined,
        }}
            propertyRenderer={{
                "e138_Assessments": (value, entity) => <Graph used={value} total={entity.e138_AssessmentsLimit}/>,
                "e138_Candidates": (value, entity) => <Graph used={value} total={entity.e138_CandidatesLimit}/>,
                "e138_Questions": (value, entity) => <Graph used={value} total={entity.e138_QuestionsLimit}/>,
                "e138_AssessmentsTaken": (value, entity) => <Graph used={value}
                                                                   total={entity.e138_AssessmentsTakenLimit}/>,
                "e138_Tags": (value, entity) => <><Graph used={value} total={entity.e138_TagsLimit}/></>,
                "e138_SubAccounts": (value, entity) => <Graph used={value} total={entity.e138_SubAccountsLimit}/>,
            }}
            labelRenderer={{
                "e138_Tags": (value) => <>{value} <sup>&dagger;</sup></>,
                "e138_SubAccounts": (value) => <>SubAccounts
                    <AdvertiseOrg hasOrganisation={profileView.HasJoinedOrganisation} isAdmin={profileView.OrgRoleKey === Roles.OrgAdmin}/>
                </>
            }}
            title={<HeadingSmall>This Month</HeadingSmall>}/>

        <AggregateView
            labelRenderer={{
                "e138_TotalTags": (value) => <>{value} <sup>&dagger;</sup></>
            }}
            hiddenFields={hiddenFields2} aggregate={profileView.QueuedPlan} displayMode={false}
            title={<HeadingSmall>Next Month</HeadingSmall>}/>

        <ParagraphXSmall><br/><sup>&dagger;</sup> Tag Limit can be increased upon request</ParagraphXSmall>
    </>);
}

function Graph({total, used}) {
    return (
        <ProgressBar
            value={(used + total === 0) ? 0 : ((used / total) * 100)}
            getProgressLabel={value =>
                `${used} out of ${total} used`
            }
            showLabel
        />
    )
}

function AdvertiseOrg({hasOrganisation, isAdmin}) {

    const [isOpen, setIsOpen] = useState(false);

    if (hasOrganisation) {
        if (isAdmin) {
            return (
                <>
                    <br/>
                    <LinkButton underline={true} hover={true} onClick={() => location.href = "#/my-org"}>Assign
                        SubAccounts</LinkButton>
                </>
            );
        } else return null;
    } else {
        return (
            <>
                <br/>
                <LinkButton underline={true} hover={true} onClick={() => setIsOpen(true)}>Upgrade to an
                    Organisation</LinkButton>
                {isOpen ?
                    <DialogModal title={"Upgrade to an Organisation Account"} close={() => setIsOpen(false)}>
                        <CenteredColumn>
                            <p>By upgrading to an organisation account you can create individual user accounts all of
                                which
                                have
                                access the same data.</p><br/>
                            <p>The only action required to enable this feature is to <strong>purchase a plan with
                                sub-accounts</strong>; either the
                                Extra Plan or a Custom Plan.</p><br/>
                            <p>Once you have purchased one of these plans return here, to begin assigning your available
                                sub-accounts to other users.</p><br/>

                            <p>Important Points:</p>
                                <ul>
                                <DialogListItem>All users of an organisation share the same credit. This help keep costs to a
                                    minimum and
                                    is one of
                                    our favourite features.</DialogListItem>
                                <DialogListItem>Once you purchase a plan with SubAccounts you will not be able to change to any plan which does not have at least 1 SubAccount.</DialogListItem>
                            </ul>
                        </CenteredColumn>
                    </DialogModal> : null}

            </>
        );
    }
}