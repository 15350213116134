﻿import {CenteredCard} from "../../z40-uicomp/centered-card";
import {ProgressBar} from "baseui/progress-bar";
import {SpaceTopAndBottom} from "../../z40-uicomp/layout";
import {HeadingMedium, HeadingSmall} from "baseui/typography";
import {AutoForm, headerKeys, setHeader, translate, useDomainEvent} from "@soap/modules";
import React from "react";


export function StepFreeTextOptions({statefulProcessId}) {

    const trigger = useDomainEvent({
        eventName: "freetext-options-defined",
        conversationId: "create-question"
    });

    const backTrigger = useDomainEvent({
        eventName: "step-back",
        conversationId: "create-question"
    });


    return (<CenteredCard  title={<HeadingMedium>Create Question</HeadingMedium>} showRobot={true}>
            <ProgressBar value={60}/>
            <SpaceTopAndBottom>
                <HeadingSmall>Set Free Text Question Options</HeadingSmall>
            </SpaceTopAndBottom>
            <AutoForm
                query={{
                    $type: "C145v1_GetC144FormData",
                    "C145_StatefulProcessId": statefulProcessId}}
                afterCancel={()=> {
                    backTrigger({
                        stepNumber:3
                    });
                }}
                cancelText={"Back"}
                afterSubmit={() => {
                    trigger();
                }}
                submitText={translate("Next")}
            />
        </CenteredCard>
    );
}