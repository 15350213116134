﻿import React, {useCallback, useEffect, useRef, useState} from "react";
import {useAuth, uuidv4} from "@soap/modules";
import Compressor from 'compressorjs'
import {useStyletron} from "baseui";
import QuickPinchZoom, {make3dTransformValue} from "react-quick-pinch-zoom";
import {ParagraphXSmall} from "baseui/typography";
import {Centered} from "../../z40-uicomp/layout";


export function ImageBox({enrichedBlob, caption}) {

    const [css, theme] = useStyletron();

    const imgRef = useRef();
    const onUpdate = useCallback(({x, y, scale}) => {
        const {current: img} = imgRef;

        if (img) {
            const value = make3dTransformValue({x, y, scale});

            img.style.setProperty("transform", value);
        }
    }, []);

    const [imageKey, setImageKey] = useState(uuidv4());

    return (
        <div>
            {enrichedBlob ?
                <>
                    <Centered>
                        <div style={{display: "flex"}}><ParagraphXSmall>Use mouse-wheel or pinch to
                            zoom</ParagraphXSmall>&nbsp;
                            <a onClick={() => setImageKey(uuidv4())} style={{
                                textDecoration: "underline",
                                cursor: "pointer"
                            }}>
                                <ParagraphXSmall>reset</ParagraphXSmall></a>
                        </div>
                    </Centered>
                    <QuickPinchZoom key={imageKey} shouldInterceptWheel={() => false} onUpdate={onUpdate}
                                    draggableUnZoomed={false}>
                        <img
                            ref={imgRef}
                            src={enrichedBlob.objectUrl}
                            style={{margin: "2px", width: "100%"}}
                            alt={enrichedBlob.name}
                        />
                    </QuickPinchZoom>
                    {caption}
                </> : null}
        </div>

    );
}


export function useImageFromBlobMeta(blobMeta, sasUrl = null, dimensions = {maxWidth: 1280, maxHeight: 1024}) {
    
    const [enrichedBlob, setEnrichedBlob] = useState(null);
    const {
        idToken,
        authReady
    } = useAuth("take-assessment");

    useEffect(() => {
        (async function GetBlobFromBackend() {

            if (authReady && blobMeta?.id) {

                const id = blobMeta.id;
                const name = blobMeta.name;
                
                if (sasUrl) {
                    
                    const blobResponse = await fetch(sasUrl);
                    const blob = await blobResponse.blob();
                    const enrichedBlob = await prepareBlob(blob);
                    setEnrichedBlob(enrichedBlob);
                    
                } else {
                    const endpoint = `${globalThis.Soap.vars.functionAppRoot}/GetBlob`;
                    const url = `${endpoint}?id=${encodeURI(id)}&it=${idToken}`;
                    let response = await fetch(url);
                    const blob = await response.blob();
                    const enrichedBlob = await prepareBlob(blob);
                    setEnrichedBlob(enrichedBlob);
                }
                
                async function prepareBlob(blob) {
                    const blobInfo = {
                        id: id,
                        name: name,
                        blob: blob
                    };
                    return await enrichBlob(blobInfo);
                }
            }
        })();
    }, [authReady, blobMeta]) //* run only once

    const enrichBlob = useCallback(async (blobInfo) => {

            function resizeTo(blob, {maxHeight, maxWidth}) {
                return new Promise((resolve, reject) => {
                    new Compressor(blob, {
                        checkOrientation: false,
                        maxHeight: maxHeight,
                        maxWidth: maxWidth,
                        success: resolve,
                        error: reject
                    });
                });
            }

            switch (blobInfo.blob.type) {
                case "image/png":
                case "image/jpeg":
                case "image/jpg":
                case "image/jfif":

                    blobInfo.thumb = URL.createObjectURL(await resizeTo(blobInfo.blob, {
                        maxWidth: 250,
                        maxHeight: 300
                    }));

                    const resizedBlob = await resizeTo(blobInfo.blob, {
                        maxWidth: dimensions.maxWidth,
                        maxHeight: dimensions.maxHeight
                    });

                    const objectUrl = URL.createObjectURL(resizedBlob);

                    const img = new Image();
                    img.src = objectUrl;

                    await new Promise((resolve) => {
                        img.onload = resolve;
                    });

                    blobInfo.height = img.height;
                    blobInfo.width = img.width;
                    blobInfo.objectUrl = objectUrl;
                    blobInfo.isImage = true;
                    break;

                default:
                    blobInfo.isImage = false;
                    blobInfo.objectUrl = URL.createObjectURL(blobInfo.blob);
                    break;
            }

            blobInfo.sizeInKb = Math.round(blobInfo.blob.size / 1000) + " kb";
            delete blobInfo.blob;
            return blobInfo;
        }, []
    );

    return enrichedBlob ?? null;
}