﻿import {ActionModal, translate} from "@soap/modules";
import React from "react";
import {CenteredCard} from "../../z40-uicomp/centered-card";
import {CenteredColumn} from "../../z40-uicomp/centered-column";
import {DialogListItem} from "../../z40-uicomp/dialog-list-item";
import {CheckedListItem} from "../../z40-uicomp/checked-list-item";

export function ArchiveCandidate(props) {
    const {candidateId, candidateName} = props;
    return (
        <ActionModal menuText={translate("Archive")} modalHeader={translate("Archive Candidate")} auth={true}
                     command={{
                         $type: 'C163v1_ArchiveCandidate',
                         C163_CandidateId: candidateId
                     }}>
            <p>{`${translate("This action cannot be undone")}.`} <br/> {translate("Please confirm you want to archive the candidate")} <strong>{candidateName}</strong>?</p>
            <br/>
            <CenteredColumn>
                <ul>
                    <DialogListItem>{translate("Archived candidates")}
                        <ul>
                            <CheckedListItem>{translate("can be viewed using the Include Archived switch")}</CheckedListItem>
                            <CheckedListItem>{translate("cannot be edited")}</CheckedListItem>
                        </ul>
                    </DialogListItem>
                    <DialogListItem>{translate("Shared Assessment links are revoked for Archived Candidates")}</DialogListItem>
                    <DialogListItem>{translate("Assessment Results will remain visible for Archived candidates")}</DialogListItem>
                    <DialogListItem>{translate("Historical Data such as Archived Items are counted against your plan limits")}</DialogListItem>
                    <DialogListItem>{translate("If you wish to remove a candidate altogether, use Delete instead")}</DialogListItem>
                </ul>
            </CenteredColumn>
        </ActionModal>
    );
}