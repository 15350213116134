﻿import {Select, SIZE} from "baseui/select";
import {translate} from "@soap/modules";
import * as React from "react";

export function TagSelect({options, value, onChange}) {
    
    return (<Select
        size={SIZE.compact}
        value={value}
        options={options}
        multi
        placeholder={translate("Filter By Tag")}

        onChange={(params) => onChange(params.value)}
        overrides={{
            SelectArrow: {
                props: {
                    overrides: {
                        Svg: {
                            style: ({ $theme }) => ({
                                cursor: "pointer"
                            })
                        }
                    }
                }
            },
            ClearIcon: {
                props: {
                    overrides: {
                        Svg: {
                            style: ({ $theme }) => ({
                                cursor: "pointer"
                            })
                        }
                    }
                }
            }
        }}
    />);
}