export default {
    "soap-No": "No",
    "soap-Yes": "Yes",
    "soap-item": "item",
    "soap-items": "items",
    "soap-Form Submitted Successfully": "Form Submitted Successfully",
    "soap-Cancel": "Cancel",
    "soap-Submit": "Submit",
    "soap-Unique Identifier (e.g. ac769a1f-e681-4bc4-8098-45e0b4d923cf)": "Unique Identifier (e.g. ac769a1f-e681-4bc4-8098-45e0b4d923cf)",
    "soap-Processing... hang tight.": "Processing... hang tight.",
    "soap-File is too big.": "File is too big.",
    "soap-Maximum size": "Maximum size",
    "soap-Error loading": "Error loading",
    "soap-File is invalid.": "File is invalid.",
    "soap-DeactivatedUser": "Your account has been de-activated. Login is disabled."
};