﻿import React, {useState} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {ChevronDown, Grab} from "baseui/icon";
import {Panel, StatelessAccordion} from "baseui/accordion";
import {Button, KIND, SIZE as BUTTON_SIZE} from "baseui/button";
import {useStyletron} from "baseui";
import {useLazyCommand} from "@soap/modules";
import {SpaceTopAndBottom} from "../../../z40-uicomp/layout";

export function EditQuestionsReorderMode({assessment, setAssessment}) {

    const clonedSections = JSON.parse(JSON.stringify(assessment.Sections));
    
    const [reorderTrigger] = useLazyCommand("C198v1_ReorderAssessmentItems");
    
    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        // change background colour if dragging

        display: "flex",
        alignItems: "center",
        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = (isDraggingOver) => ({
        // change background colour if dragging
    });

    const [sections, setSections] = useState(clonedSections);


    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const sourceIndex = result.source.index;
        const destIndex = result.destination.index;
        if (result.type === "droppableItem") {
            setSections(reorder(sections, sourceIndex, destIndex));
        } else if (result.type === "droppableSubItem") {
            
            const itemSubItemMap = sections.reduce((acc, section) => {
                acc[section.SectionId] = section.Questions;
                return acc;
            }, {});

            const sourceParentId = result.source.droppableId;
            const destParentId = result.destination.droppableId;
            

            const sourceSubItems = itemSubItemMap[sourceParentId];
            const destSubItems = itemSubItemMap[destParentId];

            let newSections = [...sections];

            /* In this case subItems are reOrdered inside same Parent */
            
            if (sourceParentId === destParentId) {
                const reorderedSubItems = reorder(
                    sourceSubItems,
                    sourceIndex,
                    destIndex
                );
                newSections = newSections.map((section) => {
                    if (section.SectionId === sourceParentId) {
                        section.Questions = reorderedSubItems;
                    }
                    return section;
                });
                setSections(newSections);
            } else { //* different Parent
                
                let newSourceSubItems = [...sourceSubItems];
                const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);

                let newDestSubItems = [...destSubItems];
                newDestSubItems.splice(destIndex, 0, draggedItem);
                newSections = newSections.map((item) => {
                    if (item.SectionId === sourceParentId) {
                        item.Questions = newSourceSubItems;
                    } else if (item.SectionId === destParentId) {
                        item.Questions = newDestSubItems;
                    }
                    return item;
                });
                setSections(newSections);
            }
        }

        const orderedArrays = sections.map(s => ({
            id: s.SectionId,
            questions: s.Questions.map(q => q.QuestionId)
        }));

        const cloned = JSON.parse(JSON.stringify(assessment));
        cloned.Sections = sections;
        setAssessment(cloned);
        
        reorderTrigger({
            C198_AssessmentId: assessment.AssessmentId,
            C198_OrderedAssessmentItemArrays: JSON.stringify(orderedArrays) 
        });
        
    };

    
    return (
        <>
            
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" type="droppableItem">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {sections.map((item, index) => (
                                <Draggable key={item.SectionId} draggableId={item.SectionId} index={index}>
                                    {(provided, snapshot) => (
                                        <>
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >

                                                <div
                                                    {...provided.dragHandleProps}
                                                >
                                                    <Grab size={24}/>
                                                </div>
                                                <Section section={item} parentKey={item.SectionId}/>
                                            </div>
                                            {provided.placeholder}
                                        </>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );

}

function Section({section, parentKey}) {

    const [keys, setKeys] = React.useState([]);

    return (
        <StatelessAccordion expanded={keys} key={parentKey}>
            <Panel
                onClick={(e) => {
                    if (keys["0"]) {
                        setKeys([]);
                    } else {
                        setKeys(["0"]);
                    }
                }}
                overrides={{
                    Header: {
                        style: ({$theme}) => ({
                            justifyContent: "flex-end",
                            flexDirection: "row-reverse"
                        })
                    },
                    ToggleIcon: {
                        component: () => (
                            <>
                                <Button kind={KIND.tertiary} size={BUTTON_SIZE.compact}>
                                    <ChevronDown/>
                                </Button>
                            </>
                        )
                    }
                }}
                title={
                    <div
                        key={section.SectionId}
                        data-id={section.SectionId}
                        style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "baseline"
                        }}
                    >
                        <div>{section.Title}</div>
                        <div style={{marginLeft: "auto"}}>Time Limit {section.TimeLimit}</div>
                        <div style={{marginLeft: "16px"}}>Total Points: {section.Points2}</div>
                    </div>
                }
            >
                <QuestionList questions={section.Questions} parentKey={section.SectionId}/>
            </Panel>
        </StatelessAccordion>
    );
}

function QuestionList({questions, parentKey}) {

    const [css, theme] = useStyletron();
    
    const getItemStyle = (isDragging, draggableStyle) => ({
        // change background colour if dragging

        display: "flex",
        alignItems: "center",
        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = (isDraggingOver) => ({
        // change background colour if dragging
    });

    return (
        <Droppable droppableId={parentKey} type={`droppableSubItem`}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={getListStyle(snapshot.isDraggingOver)}
                >
                    {questions.length === 0 ? "< Drag Items Here >" : null}
                    {questions.map((item, index) => (
                        <Draggable key={item.QuestionId} draggableId={item.QuestionId} index={index}>
                            {(provided, snapshot) => (
                                <>
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                    >

                                        <div
                                            {...provided.dragHandleProps}
                                        >
                                            <Grab size={24}/>
                                        </div>

                                        <div
                                            key={item.QuestionId}
                                            data-id={item.QuestionId}
                                            style={{
                                                display: "flex",
                                                alignItems: "baseline"
                                            }}
                                        >
                                            <div className={css({margin:"12px"})}>{item.Title}  </div>
                                            <div style={{marginLeft: "auto"}}>Time Limit: {item.TimeLimit2}</div>
                                            <div className={css({margin:"12px"})}>Points: {item.Points3}</div>
                                        </div>
                                    </div>
                                    {provided.placeholder}
                                </>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
}