import {useCallback} from "react";
import {componentViewMode} from "../z50-domaincomp/constants/component-view-mode";
import {useQueryString} from "./useQueryString";
import {appQueryParams} from "./appQueryParams";

export function useLink() {
    const queryString = useQueryString();
    return useCallback((segments, options = {}, queryParams) => {
        let host = '';
        let params = '#/' + segments.join('/');
        let query = '';
        if (queryParams) {
            query += queryParams.join('&');
        }
        if (options) {
            if (options.host) {
                host = (options.host.endsWith('/') ? options.host.slice(0, -1) : options.host);
            }
            if (options.componentViewMode !== componentViewMode.Website) {
                query = appendCVMOtoQuery(query, options.componentViewMode);
            } else {
                const inheritedCVMO = queryString.search(appQueryParams.componentViewMode)
                if (inheritedCVMO) {
                    query = appendCVMOtoQuery(query, inheritedCVMO);
                }
            }
        }
        params = host + query + params;

        return params;

        function appendCVMOtoQuery(query, CVMO) {
            const param = `${appQueryParams.componentViewMode}=${CVMO}`;
            return query ? query + `&${param}` : query + `?${param}`;
        }

    }, [queryString]);
}