﻿import {ActionModal, translate} from "@soap/modules";
import React from "react";
import {CenteredColumn} from "../../z40-uicomp/centered-column";
import {DialogListItem} from "../../z40-uicomp/dialog-list-item";
import {CheckedListItem} from "../../z40-uicomp/checked-list-item";


export function GrantAdmin(props) {
    const {tenantUserId, tenantUser} = props;
    return (
        <ActionModal menuText={translate("Make Admin")} modalHeader={translate("Make User An Admin")} auth={true}
                     command={{
                         $type: 'C244v1_GrantOrgAdminRole',
                         C244_UserId: tenantUserId
                     }}>

            <p>{translate("Please confirm you want to give Admin rights to")} <strong> {tenantUser}</strong>?</p>
            <br/>
            <CenteredColumn>
                <ul>
                    <DialogListItem>{translate("Admin Rights, allow a user to")}
                        <ul>
                            <CheckedListItem>{translate("activate/deactivate any user, including other admins")}</CheckedListItem>
                            <CheckedListItem>{translate("change the subscription")}</CheckedListItem>
                            <CheckedListItem>{translate("close the account")}</CheckedListItem>
                        </ul>
                    </DialogListItem>
                </ul>
            </CenteredColumn>
        </ActionModal>

    );
}
