import React from 'react';
import {useQuery} from '../hooks/useQuery';
import {CenterSpinner} from "./CenterSpinner";

export function JsonView(props) {

    const {query, sendQuery} = props;

    const [dataViewEvent, refresh] = useQuery({query, sendQuery});

    if (dataViewEvent) {
        return (<pre>{JSON.stringify(dataViewEvent, undefined, 2)}</pre>);
    } else if (sendQuery) {
        return (<CenterSpinner/>);
    } else {
        return null;
    }
}
