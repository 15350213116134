﻿import {ActionDrawer, AutoForm, bus, translate, useDomainEvent} from "@soap/modules";
import React, {useEffect, useRef, useState} from "react";
import {ParagraphSmall} from "baseui/typography";
import {Modal, ModalBody, ModalButton, ModalFooter, ModalHeader} from "baseui/modal";
import {KIND, SIZE} from "baseui/button";


export function MarkQuestion(props) {
    const {assessmentResultId, questionId} = props;
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [submitApproved, setSubmitApproved] = useState(false);
    
    const triggerForm = useDomainEvent({
        eventName: "force-autoform-submit",
        conversationId: questionId
    });
    
    useEffect(() => {
        if (submitApproved) {
            triggerForm();
        }
    }, [submitApproved]);
    
    return (
        <ActionDrawer title={translate("Mark Question")} auth={true}>
            <AutoForm
                backgroundSubmitKey={questionId}
                beforeSubmit={() => {
                    setModalIsOpen(true);
                    return submitApproved;
                }}
                query={{
                    $type: "C221v1_GetC220FormData",
                    C221_AssessmentResultId: assessmentResultId,
                    C221_QuestionId: questionId
                }}
                submitText={"Save"}
                hiddenFields={["C220_AssessmentResultId", "C220_QuestionId"]}
                afterSubmit={() => bus.closeAllDialogs()}
            />
            <Modal onClose={() => {
                //close via corner x
                setModalIsOpen(false);
            }} isOpen={modalIsOpen}>
                <ModalHeader>Confirm</ModalHeader>
                <ModalBody>
                    <ParagraphSmall>{translate("Once you mark this question it cannot be changed")}.</ParagraphSmall>
                    <ParagraphSmall>{translate("Continue")}?</ParagraphSmall>
                </ModalBody>
                <ModalFooter>
                    <ModalButton kind={KIND.secondary} size={SIZE.compact} onClick={
                        () => {
                            setModalIsOpen(false);
                        }
                    }>
                        {translate("No")}
                    </ModalButton>
                    <ModalButton kind={KIND.secondary} size={SIZE.compact} onClick={
                        () => {
                            setModalIsOpen(false);
                            setSubmitApproved(true);
                        }
                    }>
                        {translate('Yes')}
                    </ModalButton>
                </ModalFooter>
            </Modal>
        </ActionDrawer>
    );
}

