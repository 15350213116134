import bus from '../soap/bus';
import commandHandler from '../soap/command-handler';
import {useIsConfigLoaded} from "./systemStateHooks";
import {useCallback, useEffect, useState} from "react";
import {useAuth} from "./useAuth";
import {toTypeName} from "../soap/messages";
import config from "../soap/config";

export function useLazyCommand(commandSchema, initialCommand) {
    
    const hookName = "useLazyCommand";
    const configLoaded = useIsConfigLoaded(hookName);
    const { authReady, refresh, authEnabled } = useAuth(hookName);
    const [conversationIds, setConversationIds] = useState([]);
    const [initialQueuedCommand, setInitialQueuedCommand] = useState();
    
    if (config.debug.hooks) {
        const timeString = new Date().getTime().toString();
        console.warn(`status of ${hookName} at render at ${timeString} `,
            JSON.stringify(
                {
                    configLoaded,
                    authEnabled,
                    authReady,
                    initialCommand,
                    initialQueuedCommand
                }));
    }
    
    const sendCommand = useCallback((commandProperties) => {

        if (config.debug.hooks) {
            const timeString = new Date().getTime().toString();
            console.warn(`status of ${hookName} at sendCommand callback at ${timeString} `,
                JSON.stringify(
                    {
                        configLoaded,
                        authEnabled,
                        authReady,
                        initialCommand,
                        initialQueuedCommand
                    }));
        }
        
        let command = commandProperties ?? initialCommand ?? initialQueuedCommand;
        command = {...command, $type: commandSchema};
        
        if (configLoaded && authReady) {

            //* convert from class short name to assembly qualified short name
            command.$type = toTypeName(command.$type);

            if (!command.headers) {
                command.headers = [];
            }

            const conversationId = commandHandler.handle(command, () => null, 0, refresh);

            setConversationIds(oldArray => [...oldArray, conversationId]);

            return conversationId;
        }

    }, [configLoaded, authReady, commandSchema, initialCommand, initialQueuedCommand]);
    
    useEffect(() => {
            return function cleanup() {
                conversationIds.forEach(id => {
                    bus.closeConversation(id);
                })
            };
        }, []);

    //* send initialCommand after system is ready
    useEffect(() => {

        if (config.debug.hooks) {
            const timeString = new Date().getTime().toString();
            console.warn(`status of ${hookName} at useEffect at ${timeString} `,
                JSON.stringify(
                    {
                        configLoaded,
                        authEnabled,
                        authReady,
                        initialCommand,
                        initialQueuedCommand
                    }));
        }
        
        if (configLoaded && authReady && (initialCommand || initialQueuedCommand)) {
            sendCommand();
            setInitialQueuedCommand(undefined);
        }
    }, [configLoaded, authReady])
    
    const queueCommand = useCallback((commandProperties = {}) => {
        /* this it catch are any triggers fired BEFORE authReady is true.
        the alternative to doing this would be return null for the trigger
        until authReady is true but that does require checking the trigger
        at every call. authReady is not true all at once for every component 
        and that is why this can happen. 
        we may find out that if we use a different state management
        pattern, something built more for react rather than useDomainEvent maybe the 
        updates to authReady would occur in BOTH the component concerned 
        and it's instance of this hook, before the code in the useEffect block
        in this hook runs, but that requires some real testing and 
        I'm not sure it would so queueing it for a short time is probably
        best since it doesn't change the hook API, beware though that we 
        only queue a single command so if you call the callback multiple
        times before authReady only the last call will execute. don't think
        you would execute the same query twice so not a strange behaviour */
        if (config.debug.hooks) {
            const timeString = new Date().getTime().toString();
            console.warn(`queueing command until authReady in ${hookName} at ${timeString}`)
        }
        setInitialQueuedCommand(commandProperties);
    }, []);
    
    return [authReady ? sendCommand : queueCommand, conversationIds];
    
}
