﻿import {AutoForm, bus, translate, useDomainEvent, useEvent, uuidv4} from "@soap/modules";
import React, {useState} from "react";
import {CenteredCard} from "../../z40-uicomp/centered-card";
import {EditAssessmentTopNav} from "../../z50-domaincomp/drawers/edit-assessment-menu";


export function EditAssessmentTitle({assessmentId, assessmentTitle,selectedItemId}) {

    const completeEdit = useDomainEvent({
        eventName: "edit-complete",
        conversationId: "edit-assessment"
    });

    const [autoFormKey, setAutoFormKey] = useState(uuidv4());

    useEvent({
        eventName: "E149v1_AssessmentUpdated",
        onEventReceived:(e) => {
            setAutoFormKey(uuidv4())
        }
    })
    
    return (
        <CenteredCard title={"Edit Assessment"} onBack={() => location.href = "#/assessments/assessment-library"} maxWidth={600}>
            <EditAssessmentTopNav assessmentId={assessmentId} assessmentTitle={assessmentTitle} selectedItemId={selectedItemId} />
            <AutoForm 
                query={{
                    $type: "C182v1_GetC181FormData",
                    C182_AssessmentId: assessmentId
                }}

                submitText={translate("Save")}
                hiddenFields={["C181_AssessmentId"]}
                key={autoFormKey}
            /></CenteredCard>
    );
}

