﻿import postal from 'postal';
import React, {useCallback, useEffect} from "react";
import config from "../soap/config";
import bus from "../soap/bus";
import {optional, types, validateArgs} from "../soap/util";
import {useIsConfigLoaded} from "./systemStateHooks";

export function useDomainEvent(args) {

    const hookName = "UseDomainEvent hook";
    const configLoaded = useIsConfigLoaded(hookName);
    const {conversationId, onEventReceived, eventName, channel = bus.channels.domainEvents } = args;
    
    validateArgs(
        [{eventName}, types.string],
        [{channel}, types.string, optional],
        [{conversationId}, types.string, optional],
        [{onEventReceived}, types.function, optional]
    );

    /* define dependencies; included by default in the depArray so callers don't have to pass them
    we don't allow changing on eventName
     */
    const depArray = [conversationId, channel];
    
    /* we don't want to call bus.subscribe every render, but we do want the latest handler so store it in a Ref
    that can be accessed from the closure which does subscribe */
    const callback = React.useRef(onEventReceived);
    callback.current = onEventReceived;
    
    useEffect(() => {
        if (callback.current) { //* when a callback is not provided, we assume the user is just using the hook to get the trigger
            
            //TODO some subscriptions could occur before configLoaded, in this case no logging will
            //occur. We can queue these log requests and publish them once the config was loaded with
            //a timestamp and note to say they occurred earlier.
            const sub = bus.subscribe(channel, eventName, (event) => callback.current(event), conversationId);

            return function cleanup() {
                if (config.logger) {
                    if (channel !== "ui" && channel !== "internal") {
                        config.logger.log(`UNSUBSCRIBED to channel:${sub.channel}, topic:${sub.topic}`);
                    }
                    if (!!sub) postal.unsubscribe(sub);
                }
            };
        }
    }, depArray);
    
    const trigger = useCallback((msg, cId) => bus.publish(channel, eventName, msg ?? {}, cId ?? conversationId), depArray);
    return trigger;
}

