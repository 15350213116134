﻿import {ActionDrawer, AutoForm, bus, translate} from "@soap/modules";
import React from "react";
import {TalkBadge} from "../../z40-uicomp/talk-badge";
import {MaxWidth} from "../../z40-uicomp/layout";


export function EditBillingCurrency({}) {
    return (
        <ActionDrawer title={translate("Update Billing Currency")} auth={true}>
            <MaxWidth maxWidth={600}>
            <TalkBadge>
                <p>Updating your Billing Currency will cause any upgrades you make to your plan to take effect at the renewal date.
                    Don't worry, you can always change your currency back if you need to make immediate changes to your plan in the meantime.</p>
                <br/>
                <p>The new price will be displayed on your profile page when you press Update.
                The rate of exchange used will be the current mid-market rate and will not fluctuate once set.</p>
            </TalkBadge>
            <AutoForm
                query={{
                    $type: "C233v1_GetC232FormData"
                }}
                submitText={translate("Update")}
                afterSubmit={() => bus.closeAllDialogs()}
            />
            </MaxWidth>
        </ActionDrawer>
    );
}

