﻿import {CenteredCard} from "../../z40-uicomp/centered-card";
import {ProgressBar} from "baseui/progress-bar";
import {HeadingMedium, HeadingSmall} from "baseui/typography";
import {AutoForm, headerKeys, setHeader, translate} from "@soap/modules";
import React from "react";
import {useParams} from "react-router-dom";
import {useReferrer} from "../../hooks/useReferrer";


export function StepCreateQuestion({statefulProcessId}) {
    
    
    const {referrerId} = useParams();
    const {pop} = useReferrer();
    
    return (<CenteredCard onBack={() => {
            
            if (referrerId) {
                const referrer = pop(referrerId);
                if (referrer) {
                    return location.href = location.origin + referrer;
                }
            }
            return location.href = "#/questions/question-library";
            
        }} title={<HeadingMedium>Create Question</HeadingMedium>} showRobot={true}>
            <ProgressBar value={0}/>
            <AutoForm
                query={{
                    $type: "C128v1_GetC127FormData",
                    "C128_StatefulProcessId": statefulProcessId}}
                submitText={translate("Next")}
            />
        </CenteredCard>
    );
}