﻿import React from "react";
import {AutoForm, bus, translate, useEvent} from "@soap/modules";
import {Frame} from "../z40-uicomp/frame";
import {CenteredCard} from "../z40-uicomp/centered-card";

export function CreateAssessment(props) {

    useEvent({
        eventName: "E147v1_AssessmentAdded",
        onEventReceived: (e, v) => {
            location.href = '#/assessments/assessment-library';
        }
    });
    
    return <Frame>
        <CenteredCard title={"Create Assessment"} onBack={() => {
            location.href = '#/assessments/assessment-library'}}>
            <AutoForm
                query={{
                    $type: "C179v1_GetC178FormData"
                }}
                submitText={translate("Create")}
                afterSubmit={() => bus.closeAllDialogs()}
            />
        </CenteredCard>
    </Frame>;
}


