import {Frame} from "../z40-uicomp/frame";
import {CenteredCard} from "../z40-uicomp/centered-card";
import {translate} from "@soap/modules";
import * as React from "react";
import {EnzuzoContent} from "../z40-uicomp/enzuzo-content";
import {Centered, SpaceBottom, SpaceTopAndBottom} from "../z40-uicomp/layout";
import logo from "../z30-images/logo-xl.png";
import {Accordion, Panel} from "baseui/accordion";
import {useStyletron} from "baseui";
import {ChevronDown, Plus} from "baseui/icon";
import {ParagraphMedium} from "baseui/typography";

function PanelTitle({children}) {
    const [css, theme] = useStyletron();
    return (<span className={css({
        fontFamily:"Lato",
        fontSize:"20px"
    })}>{children}</span>);
}

function PanelChildren({text}) {
    const newText = text.split('\n').map(s => <SpaceBottom override={"9px"}><ParagraphMedium>{s}</ParagraphMedium></SpaceBottom>);
    return newText;
}

export function TermsAndConditions() {
    const [css, theme] = useStyletron();
    return (
        <Frame>
            <CenteredCard showRobot={false} maxWidth={800}> 
                <SpaceTopAndBottom>
                    <Centered>
                        <img src={logo} width={200} />
                    </Centered>
                </SpaceTopAndBottom>
                <Accordion
                    accordion = {true}
                    overrides={{
                        
                        PanelContainer: {
                            style: ({ $theme }) => ({
                                maxWidth:"700px",
                                marginLeft:"20px",
                                marginRight:"20px",
                                borderTop: '0px lightgrey solid',
                                borderLeft: '1px #e5e7eb solid',
                                borderRight: '1px #e5e7eb solid',
                                borderBottom: '2px #e5e7eb solid',
                            })
                        }
                    }}
                >
                    <Panel title={<PanelTitle>1. Introduction</PanelTitle>}>
                        <PanelChildren text={`                                               
                        Our aim is to keep this Agreement as readable as possible, but in some cases for legal reasons, some of the language is required "legalese".
                        `}></PanelChildren>
                    </Panel>
                    
                    <Panel title={<PanelTitle>2. Your Acceptance of this Agreement</PanelTitle>}>
                        <PanelChildren text={`
                        These terms of service are entered into by and between You and Futuretank Ltd, d/b/a ATest ("Company," "we," "our," or "us"). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively "Terms of Service"), govern your access to and use of atest.me, including any content, functionality, and services offered on or through atest.me (the "Website").

                        Please read the Terms of Service carefully before you start to use the Website.

                        By using the Website [or by clicking to accept or agree to the Terms of Service when this option is made available to you], you accept and agree to be bound and abide by these Terms of Service and our Privacy Policy, incorporated herein by reference. If you do not want to agree to these Terms of Service, you must not access or use the Website.

                        BY ACCESSING AND USING THIS WEBSITE, YOU:

                        ACCEPT AND AGREE TO BE BOUND AND COMPLY WITH THESE TERMS OF SERVICE;
                        YOU REPRESENT AND WARRANT THAT YOU ARE THE LEGAL AGE OF MAJORITY UNDER APPLICABLE LAW TO FORM A BINDING CONTRACT WITH US; AND,
                        YOU AGREE IF YOU ACCESS THE WEBSITE FROM A JURISDICTION WHERE IT IS NOT PERMITTED, YOU DO SO AT YOUR OWN RISK.
                        `}></PanelChildren>
                    </Panel>
                    
                    <Panel title={<PanelTitle>3. Changes to Terms of Service</PanelTitle>}>
                        <PanelChildren text={`
                        These terms were last updated on Feb 4 2024.
                        
                        We may revise and update these Terms of Service from time to time in our sole discretion. All changes are effective immediately when we post them and apply to all access to and use of the Website thereafter.

                        Continuing to use the Website following the posting of revised Terms of Service means that you accept and agree to the changes.                        
                        
                        If we make material changes to these terms, or how we handle your data, we’ll provide you with reasonable advance notice and the opportunity to review the changes, except (1) when we launch a new service or feature, or (2) in urgent situations, such as preventing ongoing abuse or responding to legal requirements. 
                        
                        If you don’t agree to the new terms you should stop using the service. You may also should submit a request to remove your content using the form in the privacy policy.                       
                    `}></PanelChildren>
                    </Panel>
                    
                    <Panel title={<PanelTitle>4. Your Responsibilities</PanelTitle>}>
                    <PanelChildren text={`                  
                        You are required to ensure that all persons who access the Website are aware of this Agreement and comply with it. It is a condition of your use of the Website that all the information you provide on the Website is correct, current, and complete.
    
                        YOU ARE SOLELY AND ENTIRELY RESPONSIBLE FOR YOUR USE OF THE WEBSITE AND YOUR COMPUTER, INTERNET AND DATA SECURITY.                   
                    `}></PanelChildren>
                    </Panel>
                    
                    <Panel title={<PanelTitle>5. Prohibited Activities</PanelTitle>}>
                        <PanelChildren text={`                  
                        You may use the Website only for lawful purposes and in accordance with these Terms of Service. You agree not to use the Website:
    
                        In any way that violates any applicable federal, state, local or international law or regulation (including, without limitation, any laws regarding the exports of data software to and from the U.S. or other countries).
                        For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information or otherwise.
                        To send, knowingly receive, upload, download, use, or re-use any material that does not comply with the Submission Standards set out in these Terms of Service.
                        To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail," "chain letter," "spam," or any other similar solicitation.
                        To impersonate or attempt to impersonate the Company, a Company employee, another user, or any other persona or entity (including, without limitation, by using email addresses associated with any of the foregoing).
                        To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the website, or which as determined by us, may harm the Company or users of the website, or expose them to liability.
                        Additionally, you agree not to:
    
                        Use the Website in any manner that could disable, overburden, damage, or impair the site or interfere with any other party's use of the Website, including their ability to engage in real-time activities through the Website.
                        Use any robot, spider, or other automatic device, to engage in monitoring or copying any of the material on the Website.
                        Use any manual process to monitor or copy any of the material on the Website, or for any other purpose not expressly authorized in these Terms of Service, without our prior written consent, unless the content is your own.
                        Use any device, software, or routine that interferes with the proper working of the Website.
                        Introduce any viruses, Trojan horses, or other material that is malicious or technologically harmful.
                        Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Website, the server on which the Website is stored, or any server, computer, or database connected to the Website.
                        Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.
                        Otherwise attempt to interfere with the proper working of the Website.                   
                    `}></PanelChildren>
                    </Panel>
                    
                    <Panel title={<PanelTitle>6. Intellectual Property Rights</PanelTitle>}>
                        <PanelChildren text={`                  
                        The Website and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by the Company, its licensors, content-creators, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
    
                        You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Website, except as follows:                        
                        When the content is owned and was upload by you, through your account, or by someone with an account in your organisation.                                                
                        If we provide social media features with certain content, you may take such actions as are enabled by such features.                        
                        Your computer may temporarily store copies of such material in RAM incidental to your accessing and viewing those materials.
                        You may store files that are automatically cached by your Web browser for display enhancement purposes.
                        You may print or download one copy of a reasonable number of pages of the Website for your own personal, non-commercial use and not for further reproduction, publication or distribution.
                        
                        You must not:                        
                        Modify copies of any materials from this site.
                        Delete or alter any of the copyright, trademark, or other proprietary rights notices from copies of materials from this site.
                        You must not access or use for any commercial purposes any part of the website or any services or materials available through the Website.
    
                        If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Website in breach of the Terms of Service, your right to use the Website will stop immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title, or interest in or to the Website or any content on the Website is transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the Website not expressly permitted by these Terms of Service is a breach of these Terms of Service and may violate copyright, trademark, and other laws.                 
                    `}></PanelChildren>
                    </Panel>
                    
                    <Panel title={<PanelTitle>7. User Submissions and Submission Standards</PanelTitle>}>
                        <PanelChildren text={`                  
                        The Website may provide you with the opportunity to create, submit, post, display, transmit, public, distribute, or broadcast content and materials to us or in the Website, including but not limited to text, writings, video, audio, photographs, graphics, comments, ratings, reviews, feedback, or personal information or other material (collectively, "Content"). You are responsible for your use of the Website and for any content you provide, including compliance with applicable laws, rules, and regulations.
    
                        All User Submissions must comply with the Submission Standards and Prohibited Activities set out in these Terms of Service.
    
                        Any User Submissions you post to the Website will be considered non-confidential and non-proprietary. By submitting, posting, or displaying content on or through the Website, you grant us a worldwide, non-exclusive, royalty-free license to use, copy, reproduce, process, disclose, adapt, modify, publish, transmit, display and distribute such Content in accordance with the services provided on this site. The use and distribution may occur in any media format and through any media channels.
    
                        You represent and warrant that:
    
                        You own or control all rights in and to the User Submissions and have the right to grant the license granted above to us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns.
                        All of your User Submissions comply with these Terms of Service.
                        We do not assert any ownership over your Content. You retain full ownership of all of your Content and any intellectual property rights or other proprietary rights associated with your Content. We are not liable for any statement or representations in your Content provided by you in any area in the Website. You are solely responsible for your Content related to the Website and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Content. We are not responsible or liable to any third party for the content or accuracy of any User Submissions posted by you or any other user of the Website. User Submissions are not endorsed by us and do not necessarily represent our opinions or the view of any of our affiliates or partners. We do not assume liability for any User Submission or for any claims, liabilities, or losses resulting from any review.
    
                        We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any Content; (2) to recategorize any Content to place them in more appropriate locations in the Website; and (3) to prescreen or delete any Content at any time and for any reason, without notice. We have no obligation to monitor your Content. Any use of the Website in violation of these Terms of Service may result in, among other things, termination or suspension of your right to use the Website.
    
                        These Submission Standards apply to any and all User Submissions. User Submissions must in their entirety comply with all the applicable federal, state, local, and international laws and regulations. Without limiting the foregoing, User Submissions must not:
    
                        Contain any material that is defamatory, obscene, indecent, abusive, offensive, misleading, harassing, violent, hateful, inflammatory, or otherwise objectionable.
                        Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age.
                        Infringe any patent, trademark, trade secret, copyright, or other intellectual property or other rights of any other person.
                        Violate the legal rights of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these terms of service and our Privacy Policy.
                        Be likely to deceive any person.
                        Promote any illegal activity, or advocate, promote, or assist in any unlawful act.
                        Impersonate any person, or misrepresent your identity or affiliation with any person or organization.
                        Involve commercial activities or sales, such as contests, sweepstakes, and other sales promotions, barter, or advertising.
                        Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.                 
                    `}></PanelChildren>
                    </Panel>
                    
                    <Panel title={<PanelTitle>8. Our Rights</PanelTitle>}>
                        <PanelChildren text={`                  
                        We have the right, without provision of notice to:
    
                        Remove or refuse to post any User Submission for any or no reason in our sole discretion;
                        Take any action with respect to any User Submission that we deem necessary or appropriate in our sole discretion, including if we believe that such User Submission violates the Terms of Service, including the Submission Standards, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the Website or the public, or could create liability for the Company;
                        Take appropriate legal action, including, without limitation, referral to or cooperation with law enforcement or regulatory authorities, or notifying the harmed party of any illegal or unauthorized use of the Website; and
                        Terminate or suspend your access to all or part of the Website where we believe, any violation of these Terms of Service has occurred.                   
                        YOU WAIVE AND HOLD HARMLESS COMPANY AND ITS PARENT, SUBSIDIARIES, AFFILIATES, AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, AND SUCCESSORS FROM ANY AND ALL CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE COMPANY AND ANY OF THE FOREGOING PARTIES RELATING TO ANY, INVESTIGATIONS BY EITHER THE COMPANY OR BY LAW ENFORCEMENT AUTHORITIES.                                                                                                             
                    `}></PanelChildren>
                    </Panel>
                    
                    <Panel title={<PanelTitle>9. Third-Party Links and Content</PanelTitle>}>
                        <PanelChildren text={`                                  
                        For your convenience, this Website may provide links or pointers to third-party sites or third-party content. We make no representations about any other websites or third-party content that may be accessed from this Website. If you choose to access any such sites, you do so at your own risk. We have no control over the third-party content or any such third-party sites and accept no responsibility for such sites or for any loss or damage that may arise from your use of them. You are subject to any terms and conditions of such third-party sites.                                                                                                             
                    `}></PanelChildren>
                    </Panel>
                    
                    <Panel title={<PanelTitle>10. Integration and Social Media Features</PanelTitle>}>
                        <PanelChildren text={`                                                                          
                        This Website may provide certain integration and social media features that enable you to:
    
                        Link from your own or certain third-party websites to certain content on this Website.
                        Send emails or other communications with certain content, or links to certain content, on this Website.
                        Cause limited portions of content on this Website to be displayed or appear to be displayed on your own or certain third-party websites.
                        You may use these features solely as they are provided by us and solely with respect to the content they are displayed with. Subject to the foregoing, you must not:
    
                        Establish a link from any website that is not owned by you.
                        Cause the Website or portions of it to be displayed on, or appear to be displayed by, any other site, for example, framing, deep linking, or in-line linking.
                        Link to any part of the Website other than the homepage.
                        Otherwise take any action with respect to the materials on this Website that is inconsistent with any other provision of these Terms of Use.
                        The Website from which you are linking, or on which you make certain content accessible, must comply in all respects with the Submission Standards set out in these Terms of Service.
    
                        You agree to cooperate with us in causing any unauthorized framing or linking immediately to stop.
    
                        We reserve the right to withdraw linking permission without notice.

                        We may disable all or any social media features and any links at any time without notice in our discretion.                                                                                                             
                    `}></PanelChildren>
                    </Panel>
                    
                    <Panel title={<PanelTitle>11. Pricing, Payment and Fees</PanelTitle>}>
                        <PanelChildren text={`
                        Company reserves the right to adjust the pricing for our services, products, or any components thereof in any manner and at any time as we may determine in our sole and absolute discretion.
                                                                  
                        Changing your billing address can affect the tax we need to charge you on your subscription. If you are changing from:
                         a TAX-INCLUDED REGION to a TAX INCLUDED REGION  the final price should remain the same after tax, 
                         a TAX-INCLUDED REGION to a TAX NOT INCLUDED REGION  the final price may vary depending on the new tax rate.
                         a TAX NOT INCLUDED REGION to a TAX NOT INCLUDED REGION  the final price may vary depending on the new tax rate.
                         a TAX NOT INCLUDED REGION to a TAX INCLUDED REGION  the final price may vary depending on the new tax rate.
                        
                        Changing your billing currency will result in a currency exchange rate being applied to the current price of your subscription at the time of making the change. 
                        This will remain the rate of exchange applied to your subscription unless you initiate a further change.
                         
                        Excepting where the change in price is due to the above actions taken by the user to change address or currency,
                        We will communicate any changes in pricing affecting your most recent subscription plan to you via the email associated with your account at least 30 days in advance. Please bear in mind that we cannot guarantee that all emails sent will be delivered. 
                     
                        Except as otherwise expressly provided for in these Terms and Conditions, any price changes to your service will take effect upon your renewal date following the expiration of the 30 days notice period beginning when we email you about the changes.             
                      
                        YOUR CONTINUED USE OF THE COMPANY'S SERVICES AFTER THE NOTICE PERIOD WILL BE DEEMED ACCEPTANCE OF THE PRICE CHANGES.
                                                                                                                                                                      
                        If you are required to purchase or pay a fee to access our services. We accept PayPal, Visa, Mastercard, and Google Pay for all purchases. We allow payment in a number of currencies depending on your location or as available on the site at time of purchase depending on your location.
                         
                        However, Company does not guarantee the availability of any payment method and currency combination at any moment and Company may add, remove or suspend any payment method temporarily or permanently at Company's sole discretion. 
                        You agree to provide current, complete, and accurate purchase and account information for all purchases made via the Website and to promptly update account and payment information, including email address, payment method, and payment card expiration date, in order to complete your purchases and contact you as needed. Sales tax will be added to the price of purchases as deemed required by us. 
                        We may change prices at any time but we will give you 30 days notice by email if we intend to do so. 
                        
                        You agree to pay all charges or fees at the prices then in effect for your purchases, and you authorize us to charge your chosen payment provider for any such amounts upon making your purchase. 
                        If you authorize PayPal or Google Pay for future purchases, this Website will store an identification code linked to your PayPal account, which will authorize this Website to automatically process future payments or recurring installments of past purchases. PayPal’s authorization can be revoked at any time, either by contacting us or by changing the user settings offered by PayPal.
    
                        If you do not keep up payments on your subscription, after a grace period of at least 14 days, we reserve the right to cancel your subscription and downgrade you to the free plan or remove your data altogether. 
    
                        If your purchase is subject to recurring charges, you must keep a valid payment method on file with Company to pay for all incurred and recurring fees. 
                        Company will charge applicable fees to any valid payment method that you have provided and you will be invoiced automatically as outlined in the order. 
                        You authorize such payment of recurring fees without requiring your prior approval for each recurring charge, until you notify us of your cancellation, or the Company terminates in writing in accordance with these Terms of Service, or until the recurring contract ends, and any and all outstanding fees and charges have been paid in full.
    
                        We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or received payment. We also reserve the right to refuse any order placed through the Website.                                                                                                            
                    `}></PanelChildren>
                    </Panel>
                    
                    <Panel title={<PanelTitle>12. Cancellation</PanelTitle>}>
                        <PanelChildren text={`                                                                                                       
                        You can cancel your subscription at any time by logging into your account and downgrading to the Free Plan or contacting us using the contact information provided below. Your cancellation will take effect at the end of the current billing period.
                        If you wish for us to remove your data entirely, you can submit a request to do so from our Privacy Policy.
    
                        Subscription purchases are non-refundable, have no monetary value (for example, they are not a cash account or equivalent), and are purchases of only a non-exclusive, revocable, non-assignable and non-transferable right to use the subscription.
    
                        You may not transfer, sell, purchase, barter, or trade your subscriptions or attempt or offer to do so. Any attempted transfer will be null and void. Except as required by applicable law, we are not responsible for any refunds or credits in connection with any modified, suspended or terminated subscriptions.                                                                                                            
                        `}></PanelChildren>
                    </Panel>
                    
                    <Panel title={<PanelTitle>13. Disclaimers, Liability and Indemnification</PanelTitle>}>
                        <PanelChildren text={`                                                                                                                                                   
                        YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY GOODS, DIGITAL PRODUCTS, SERVICES, INFORMATION OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY GOODS, SERVICES, DIGITAL PRODUCTS, INFORMATION OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
    
                        YOU ACKNOWLEDGE AND AGREE THAT COMPANY OR ITS RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS MAKE NO WARRANTY, REPRESENTATION, OR ENDORSEMENT WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, SUITABILITY, ACCURACY, CURRENCY, OR AVAILABILITY OF THE WEBSITE OR ITS CONTENTS OR THAT ANY GOODS, SERVICES, DIGITAL PRODUCTS, INFORMATION OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE OR CONTENT ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR DESTRUCTIVE CODE.
    
                        How We Limit Our Liability to You
    
                        EXCEPT WHERE SUCH EXCLUSIONS ARE PROHIBITED BY LAW, IN NO EVENT SHALL THE COMPANY NOR ITS PARENT, SUBSIDIARIES, AFFILIATES OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THESE TERMS OF SERVICE, GOODS, DIGITAL PRODUCTS, AND/OR INFORMATION WHETHER ARISING OUT OF OR RELATED TO BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, EXCEED THE TOTAL AMOUNT PAID TO THE COMPANY BY YOU PURSUANT TO THESE TERMS OF SERVICE IN THE TWELVE (12) MONTHS BEFORE THE CLAIM WAS MADE. THE FOREGOING LIMITATIONS SHALL APPLY EVEN IF THE COMPANY’S REMEDIES UNDER THESE TERMS OF SERVICE FAIL OF THEIR ESSENTIAL PURPOSE.
    
                        Indemnification
    
                        To the maximum extent permitted by applicable law, you agree to defend, indemnify, and hold harmless Company, its parent, subsidiaries, affiliates, and their respective directors, officers, employees, agents, service providers, contractors, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your breach of these Terms of Service or your use of the Website including, but not limited to, third-party sites and content, any use of the Website's content and services other than as expressly authorized in these Terms of Service or any use of any goods, digital products and information purchased from this Website.                                                                                     
                        `}></PanelChildren>
                    </Panel>
                    
                    <Panel title={<PanelTitle>
                        14. Dispute Resolution
                    </PanelTitle>}>
                        <PanelChildren text={`                                                                                                       
                        At Company’s sole discretion, it may require you to submit any disputes arising from these Terms of Service or use of the Website, including disputes arising from or concerning their interpretation, violation, invalidity, non-performance, or termination, to final and binding arbitration under the Rules of Arbitration of the American Arbitration Association applying United Kingdom law. (If multiple jurisdictions, under applicable laws).

                        ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN 1 YEAR(S) AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.                                                                              
                        `}></PanelChildren>
                    </Panel>
                    
                    <Panel title={<PanelTitle>
                        15. Privacy Policy
                    </PanelTitle>}>
                        <PanelChildren text={`                                                                                                       
                        Your provision of personal information through the Website is governed by our privacy policy located at https://atest.me/#/privacy-policy (the "Privacy Policy").                                                                          
                        `}></PanelChildren>
                    </Panel>
                    
                    <Panel title={<PanelTitle>
                        16. Governing Law
                    </PanelTitle>}>
                        <PanelChildren text={`                                                                                                       
                        The Website and these Terms of Service will be governed by and construed in accordance with the laws of United Kingdom and any applicable federal laws applicable therein, without giving effect to any choice or conflict of law provision, principle, or rule and notwithstanding your domicile, residence, or physical location. Any action or proceeding arising out of or relating to this Website and/or under these Terms of Service will be instituted in the courts of the Country of United Kingdom, and each party irrevocably submits to the exclusive jurisdiction of such courts in any such action or proceeding. You waive any and all objections to the exercise of jurisdiction over you by such courts and to the venue of such courts.
    
                        If you are a citizen of any European Union country or Switzerland, Norway or Iceland, the governing law and forum shall be the laws and courts of your usual place of residence.
    
                        The parties agree that the United Nations Convention on Contracts for the International Sale of Goods will not govern these Terms of Service or the rights and obligations of the parties under these Terms of Service.
                        `}></PanelChildren>
                    </Panel>
                    
                    <Panel title={<PanelTitle>
                        17. Severability
                    </PanelTitle>}>
                        <PanelChildren text={`                                                                                                       
                        If any provision of these Terms of Service is illegal or unenforceable under applicable law, the remainder of the provision will be amended to achieve as closely as possible the effect of the original term and all other provisions of these Terms of Service will continue in full force and effect.                                                                                                           
                        `}></PanelChildren>
                    </Panel>
                    
                    <Panel title={<PanelTitle>
                        18. Entire Terms of Service
                    </PanelTitle>}>
                    <PanelChildren text={`                                                                                                       
                        These Terms of Service constitute the entire and only Terms of Service between the parties in relation to its subject matter and replaces and extinguishes all prior or simultaneous Terms of Services, undertakings, arrangements, understandings or statements of any nature made by the parties or any of them whether oral or written (and, if written, whether or not in draft form) with respect to such subject matter. Each of the parties acknowledges that they are not relying on any statements, warranties or representations given or made by any of them in relation to the subject matter of these Terms of Service, save those expressly set out in these Terms of Service, and that they shall have no rights or remedies with respect to such subject matter otherwise than under these Terms of Service save to the extent that they arise out of the fraud or fraudulent misrepresentation of another party. No variation of these Terms of Service shall be effective unless it is in writing and signed by or on behalf of Company.                                                         
                        `}></PanelChildren>
                    </Panel>

                    <Panel title={<PanelTitle>
                        19. Waiver
                    </PanelTitle>}>
                        <PanelChildren text={`                                                                                                       
                          No failure to exercise, and no delay in exercising, on the part of either party, any right or any power hereunder shall operate as a waiver thereof, nor shall any single or partial exercise of any right or power hereunder preclude further exercise of that or any other right hereunder.                                                                          
                        `}></PanelChildren>
                    </Panel>

                    <Panel title={<PanelTitle>
                        20. Notices
                    </PanelTitle>}>
                        <PanelChildren text={`                                                                                                       
                        We may provide any notice to you under these Terms of Service by: (i) sending a message to the email address associated with your account; or (ii) by posting to the Website. Notices sent by email will be effective when we send the email and notices we provide by posting will be effective upon posting. It is your responsibility to keep your email address current.

                        To give us notice under these Terms of Service, you must contact us as follows: (i) by emailing admin@futuretank.co.uk (ii) we will respond within 10 working days confirming receipt (iii) Notices will be effective immediately once a reply confirming receipt is  an authorized representative of Company. Notices provided by overnight courier or registered or certified mail will be effective once confirmation has been provided to evidence the receipt of the notice.                                                                                                            
                        `}></PanelChildren>
                    </Panel>

                    <Panel title={<PanelTitle>
                        21. Contacting Us
                    </PanelTitle>}>
                        <PanelChildren text={`                                                                                                       
                        This Website is operated by ATest , Bartle House, Oxford Court, Manchester, M2 3WQ, Greater Manchester, GB.
                        
                        All communication with us should be directed to one of the following email addresses:
                        Data Protection Officer: dpo@atest.me
                        Billing Queries: billing@atest.me
                        Support Queries: support@atest.me
                        Legal Queries and Official Notices: admin@futuretank.co.uk
                        
                        Should you become aware of misuse of the website including libelous or defamatory conduct, you must report it to the Company. All reports of misuse and other feedback, comments, requests for technical support, and other communications relating to the Website should be directed to dpo@atest.me.                                                                                        
                        `}></PanelChildren>
                    </Panel>
                </Accordion>
            </CenteredCard>
        </Frame>
    );
}