﻿import {ActionDrawer, bus, SecondaryActionMenuButton, translate, useDomainEvent} from "@soap/modules";
import React, {useState} from "react";
import {Button, KIND, SIZE} from "baseui/button";
import {SpaceAround} from "../../z40-uicomp/layout";
import {Textarea} from "baseui/textarea";
import {Checkbox, LABEL_PLACEMENT} from "baseui/checkbox";
import {EditIcon} from "../../z20-icons/icons";


export function EditMultipleChoiceAnswer({answer, isCorrect, disableIsCorrect = false}) {

    const trigger = useDomainEvent({
        eventName: "answer-edited",
        conversationId: "edit-question"
    });
    
    const [checked, setChecked] = useState(isCorrect);
    const [value, setValue] = React.useState(answer);

    return (
        <ActionDrawer menuText={translate("Edit")} drawerHeader={translate("Edit Answer")} auth={true}>
            <SpaceAround >
                <Textarea  value={value}
                               onChange={e => setValue(e.target.value)}
                               />
            </SpaceAround>
            <SpaceAround>
                <Checkbox
                    disabled={disableIsCorrect}
                    checked={checked}
                    onChange={e => setChecked(e.target.checked)}
                    labelPlacement={LABEL_PLACEMENT.right}
                >
                    Is Correct
                </Checkbox>
            </SpaceAround>
            <SpaceAround>
                <SecondaryActionMenuButton
                        onClick={() => { 
                            trigger({
                                text: value,
                                isCorrect: checked,
                                oldText: answer
                                });
                            setValue(undefined);
                            setChecked(false);
                            bus.closeAllDialogs();   
                        }}>{translate("Save")}</SecondaryActionMenuButton>
            </SpaceAround>
        </ActionDrawer>
    );
}

