﻿import React, {useState} from "react";
import {getHeader, headerKeys, translate, useDomainEvent, useEvent} from "@soap/modules";
import {StepReview} from "./step-review";
import {StepChooseQuestionType} from "./step-choose-question-type";
import {StepCreateQuestion} from "./step-create-question";
import {StepDefineValue} from "./step-define-value";
import {StepTags} from "./step-tags";
import {Frame} from "../../z40-uicomp/frame";
import {questionTypes} from "../../z50-domaincomp/question-types";

export function CreateQuestion(props) {

    const [viewState, setViewState] = useState("step-1");
    const [statefulProcessId, setStatefulProcessId] = useState();
    const [questionType, setQuestionType] = useState();

    useEvent({
        eventName: "E116v1_CreateQuestionTypeSelected",
        onEventReceived: (event) => {
            const type = Object.entries(questionTypes).map(p => p[1]).find(q => q.key === event.e116_QuestionTypeKey);
            if (!type) throw "Could not find Question Type.";
            setQuestionType(type);
            setViewState("step-3");
        }
    });

    useDomainEvent({
        eventName: "value-defined",
        conversationId: "create-question",
        onEventReceived: (event) => {
            setViewState("step-4");
        }
    }, []);

    useDomainEvent({
        eventName: "tags-defined",
        conversationId: "create-question",
        onEventReceived: (event) => {
            setViewState("step-6");
        }
    }, []);

    useDomainEvent({
        eventName: "options-defined",
        conversationId: "create-question",
        onEventReceived: (event) => {
            setViewState("step-5");
        }
    }, []);

    useDomainEvent({
        eventName: "boolean-options-defined",
        conversationId: "create-question",
        onEventReceived: (event) => {
            setViewState("step-5");
        }
    }, []);

    useDomainEvent({
        eventName: "freetext-options-defined",
        conversationId: "create-question",
        onEventReceived: (event) => {
            setViewState("step-5");
        }
    }, []);

    useDomainEvent({
        eventName: "step-back",
        conversationId: "create-question",
        onEventReceived: (event) => {
            setViewState("step-" + event.stepNumber);
        }
    }, []);


    useEvent({
        eventName: "E112v1_CreateQuestionFirstStepCompleted",
        onEventReceived: (event) => {
            setStatefulProcessId(getHeader(event, headerKeys.statefulProcessId));
            setViewState("step-2");
        }
    });

    let question;
    switch (viewState) {
        case "step-1":
            question = <StepCreateQuestion statefulProcessId={statefulProcessId} />;
            break;
        case "step-2":
            question = <StepChooseQuestionType statefulProcessId={statefulProcessId} />;
            break;
        case "step-3":
            question = <StepDefineValue allowPartialPoints={questionType.allowPartialPoints} statefulProcessId={statefulProcessId} />;
            break;
        case "step-4":
            question = <questionType.customAddStep statefulProcessId={statefulProcessId} questionTypeKey={questionType.key} />
            break;
        case "step-5":
            question = <StepTags statefulProcessId={statefulProcessId} />;
            break;
        case "step-6":
            question = <StepReview statefulProcessId={statefulProcessId} questionTypeKey={questionType.key} />;
            break;
    };
    
    return <Frame>{question}</Frame>;
}



