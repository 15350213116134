﻿import {ActionDrawer, bus, SecondaryActionMenuButton, translate, useDomainEvent} from "@soap/modules";
import React, {useState} from "react";
import {Button, KIND, SIZE} from "baseui/button";
import {SpaceAround} from "../../z40-uicomp/layout";
import {Textarea} from "baseui/textarea";
import {Checkbox, LABEL_PLACEMENT} from "baseui/checkbox";
import {AddIcon} from "../../z20-icons/icons";


export function AddMultipleChoiceAnswer({isCorrectDefault = false, disableIsCorrect = false}) {

    const trigger = useDomainEvent({
        eventName: "answer-added",
        conversationId: "create-question"
    });
    
    const [checked, setChecked] = useState(isCorrectDefault);
    const [value, setValue] = React.useState();

    return (
        <ActionDrawer drawerHeader={translate("Add Answer")} menuText={<><AddIcon />{translate("Add Answer")}</>} auth={true}>
            <SpaceAround > 
                <Textarea  value={value}
                               onChange={e => setValue(e.target.value)}
                               />
            </SpaceAround>
            <SpaceAround>
                <Checkbox
                    disabled={disableIsCorrect}
                    checked={checked}
                    onChange={e => setChecked(e.target.checked)}
                    labelPlacement={LABEL_PLACEMENT.right}
                >
                    Is Correct
                </Checkbox>
            </SpaceAround>
            <SpaceAround>
                <SecondaryActionMenuButton
                        onClick={() => { 
                            trigger({
                                text: value,
                                isCorrect: checked
                                });
                            setValue(undefined);
                            setChecked(false);
                            bus.closeAllDialogs();   
                        }}>{translate("Save")}</SecondaryActionMenuButton>
            </SpaceAround>
        </ActionDrawer>
    );
}

