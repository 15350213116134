﻿import {ActionModal, translate} from "@soap/modules";
import React from "react";
import {CenteredColumn} from "../../z40-uicomp/centered-column";
import {DialogListItem} from "../../z40-uicomp/dialog-list-item";
import {CheckedListItem} from "../../z40-uicomp/checked-list-item";


export function ArchiveQuestion(props) {
    const {questionId, questionName} = props;
    return (
        <ActionModal menuText={translate("Archive")} modalHeader={translate("Archive Question")} auth={true}
                     command={{
                         $type: 'C138v1_ArchiveQuestion',
                         C138_QuestionId: questionId
                     }}>
            <p>{`${translate("This action cannot be undone")}.`} <br/> {translate("Please confirm you want to archive the Question")} <strong>{questionName}</strong>?</p>
            <br/>
            <CenteredColumn>
                <ul>
                    <DialogListItem>{translate("Archived Questions")}
                        <ul>
                            <CheckedListItem>{translate("can be viewed using the Include Archived switch")}</CheckedListItem>
                            <CheckedListItem>{translate("can no longer be edited")}</CheckedListItem>
                            <CheckedListItem>{translate("will remain part of any Assessments until removed")}</CheckedListItem>
                        </ul>
                    </DialogListItem>
                    <DialogListItem>{translate("Historical Data such as Archived Questions are counted against your plan limits")}</DialogListItem>
                    <DialogListItem>{translate("If you wish to remove the Question from all Assessments, use Delete instead")}</DialogListItem>
                </ul>
            </CenteredColumn>
        </ActionModal>
    );
}
