﻿import backIcon from "../z20-icons/back-icon.svg";
import React from "react";
import {ParagraphSmall} from "baseui/typography";
import {useStyletron} from "baseui";


export function StyledBackLinkWithTitle({onPress, title}) {
    return (<BackLinkWithTitle onPress={onPress}
                               title={<ParagraphSmall style={{paddingTop: "4px"}}>{title}</ParagraphSmall>}/>);
}

export function BackLinkWithTitle({title, onPress}) {

    const [css, theme] = useStyletron();

    return (<div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        }}>

            <button onClick={onPress} style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                alignItems: "center",
                display: "flex",
                marginRight: theme.sizing.scale300
            }}>
                <img src={backIcon} height={"32px"} width={"32px"} style={{marginRight: "8px"}}/>
                {title}
            </button>
            
        </div>
    );
}


