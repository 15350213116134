import * as React from "react";
import {useEffect, useState} from "react";
import {FileUploader} from "baseui/file-uploader";
import {ListItem, ListItemLabel} from "baseui/list";
import {Button, SIZE as BUTTON_SIZE } from "baseui/button";
import Delete from "baseui/icon/delete";
import Download from "baseui/icon/arrow-down"
import {StyledLink} from "baseui/link";
import {Modal, ModalBody, ModalHeader, ROLE, SIZE} from "baseui/modal";
import {useStyletron} from "baseui";
import {uuidv4} from "../soap/util";
import Compressor from 'compressorjs'
import {LabelMedium, ParagraphLarge, ParagraphMedium} from "baseui/typography";
import config from "../soap/config";
import {useAuth} from "../hooks/useAuth.js"
import {translate} from "../i18n";


function resizeTo(blob, {maxHeight, maxWidth}) {
    return new Promise((resolve, reject) => {
        new Compressor(blob, {
            checkOrientation: false,
            maxHeight: maxHeight,
            maxWidth: maxWidth,
            success: resolve,
            error: reject
        });
    });
}

async function uploadBlobToBackend(blobId, blob, idToken) {

    const endpoint = `${config.vars.functionAppRoot}/AddBlob`;


    //* shouldn't need use effect as page should be loaded
    await fetch(`${endpoint}?id=${blobId}&it=${idToken}`, {
        method: "post",
        //we don’t set Content-Type header manually, because a Blob object has a built-in type for Blob objects that type becomes the value of Content-Type.
        body: blob
    });


}

async function objectUrlToBlob(objectUrl) {
    let blob = await fetch(objectUrl).then(r => r.blob());
    return blob;
}

export function FileUpload(props) {

    const {onChange, onBlur, error, acceptedTypes, value, disabled, maxBytes} = props;
    
    const [progressMessage, setProgressMessage] = useState('');
    const dimensions = props.dimensions ?? {};
    dimensions.maxWidth ??= 1024;
    dimensions.maxHeight ??= 768;
    const winHeight = window.screen.height - 50;
    const winWidth = window.screen.width - 50;
    if (winHeight < dimensions.maxHeight) dimensions.maxHeight = winHeight;
    if (winWidth < dimensions.maxWidth) dimensions.maxWidth = winWidth;

    const [isOpen, setIsOpen] = useState(false);
    const [css] = useStyletron();

    const {
        idToken,
        authReady
    } = useAuth("FileUpload");

    //* run to get the blob state after first render is complete
    useEffect(() => {
        (async function GetBlobFromBackend() {
            if (value !== null && value.objectUrl === undefined && authReady) {
                setProgressMessage(`Loading...`);
                const endpoint = `${config.vars.functionAppRoot}/GetBlob`;
                const url = `${endpoint}?id=${encodeURI(value.id)}&it=${idToken}`;
                if (config.debug.blobs) {
                    console.warn("Fetching Blob at: " + url);
                }
                let response = await fetch(url);
                const blob = await response.blob();
                const blobInfo = {
                    id: value.id,
                    name: value.name,
                    blob: blob
                };

                const enrichedBlob = await enrichBlobInfo(blobInfo);

                onChange(enrichedBlob);  //* forces react hook form to save value in controller
                setProgressMessage('');
            }
        })();
    }, [authReady])

    const maxFileSizeLabel = maxBytes >= 1000000 ? `${(maxBytes / 1000000).toFixed(1)} MB` : `${(maxBytes / 1000).toFixed(1)} KB` 
    
    return (
        <div>
            <FileUploader
                accept={acceptedTypes}
                disabled={disabled}
                maxSize={maxBytes}
                onBlur={onBlur}
                multiple={false}
                progressMessage={progressMessage}
                onDrop={async (acceptedFiles, rejectedFiles) => {
                    setProgressMessage(translate(`soap-Processing... hang tight.`));
                    if (rejectedFiles.length) {
                        if (rejectedFiles[0].size > maxBytes) {
                            setProgressMessage(
                                <>
                                    <ParagraphMedium>{`${translate("soap-File is too big.")} ${translate("soap-Maximum size")} ${maxFileSizeLabel}`}</ParagraphMedium>
                                    <Button size={BUTTON_SIZE.mini}  onClick={() => setProgressMessage('')}>OK</Button>
                                </>
                            );
                            return;
                        } else {
                            setProgressMessage(
                            <>
                                <ParagraphMedium>{`${translate("soap-File is invalid.")} ${translate("soap-Error loading")} ${rejectedFiles[0].name}`}</ParagraphMedium>
                                <Button size={BUTTON_SIZE.mini}  onClick={() => setProgressMessage('')}>OK</Button>
                            </>
                            );
                            return;
                        }
                    }
                    const blob = await getBlobFromDisk(acceptedFiles[0]);
                    const enrichedBlob = await enrichBlobInfo(blob);
                    enrichedBlob.id = uuidv4();
                    const blobToUpload = await objectUrlToBlob(enrichedBlob.objectUrl);
                    await uploadBlobToBackend(enrichedBlob.id, blobToUpload, idToken);
                    onChange(enrichedBlob);
                    setProgressMessage('');
                }}
                overrides={{
                    FileDragAndDrop: {
                        style: (props) => ({
                            borderLeftColor: error
                                ? props.$theme.colors.borderNegative
                                : props.$theme.colors.primaryA,
                            borderRightColor: error
                                ? props.$theme.colors.borderNegative
                                : props.$theme.colors.primaryA,
                            borderTopColor: error
                                ? props.$theme.colors.borderNegative
                                : props.$theme.colors.primaryA,
                            borderBottomColor: error
                                ? props.$theme.colors.borderNegative
                                : props.$theme.colors.primaryA,
                            backgroundColor: error
                                ? props.$theme.colors.backgroundLightNegative
                                : props.$theme.colors.backgroundStateDisabled
                        })
                    }
                }}
            />
            {renderUploadedItem()}
        </div>
    );


    async function getBlobFromDisk(file) {

        const blobInfo = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);

            reader.onload = () => {
                const blobInfo = {
                    name: file.name,
                    id: uuidv4(),
                    blob: new Blob([reader.result], {type: file.type})
                };
                resolve(blobInfo);
            };
        });

        return blobInfo;
    }

    async function enrichBlobInfo(blobInfo) {

        function getNewDimensions(origWidth, maxWidth, origHeight, maxHeight) {

            const newDimensions = {
                height: origHeight,
                width: origWidth
            };

            if (newDimensions.height > maxHeight) {
                const ratio = maxHeight / newDimensions.height;
                newDimensions.height = newDimensions.height * ratio;
                newDimensions.width = newDimensions.width * ratio;
            }

            if (newDimensions.width > maxWidth) {
                const ratio = maxWidth / newDimensions.width;
                newDimensions.height = newDimensions.height * ratio;
                newDimensions.width = newDimensions.width * ratio;
            }

            return newDimensions;
        }

        switch (blobInfo.blob.type) {
            case "image/png":
            case "image/jpeg":
            case "image/jpg":
            case "image/jfif":
            case "image/webp":

                blobInfo.thumb = URL.createObjectURL(await resizeTo(blobInfo.blob, {
                    maxWidth: 100,
                    maxHeight: 100
                }));

                const objectUrl = URL.createObjectURL(blobInfo.blob);


                const img = new Image();
                img.src = objectUrl;
                await new Promise((resolve) => {
                    img.onload = resolve;
                });
                const resizedDimensions = getNewDimensions(img.width, dimensions.maxWidth, img.height, dimensions.maxHeight);

                blobInfo.rawHeight = img.height;
                blobInfo.rawWidth = img.width;
                blobInfo.height = resizedDimensions.height;
                blobInfo.width = resizedDimensions.width;
                blobInfo.objectUrl = objectUrl;
                blobInfo.isImage = true;
                break;

            default:
                blobInfo.isImage = false;
                blobInfo.objectUrl = URL.createObjectURL(blobInfo.blob);
                break;
        }

        blobInfo.sizeInKb = Math.round(blobInfo.blob.size / 1000) + " kb";
        delete blobInfo.blob;
        return blobInfo;
    }


    function renderUploadedItem() {

        if (progressMessage) return;  //* show nothing while preparing value

        if (value && value.objectUrl !== undefined) { //* could be null on empty new form

            if (disabled) {
                return (<LabelMedium>Uploaded {value.name}</LabelMedium>);
            }

            let thumb, fullSize, file;
            if (value.isImage) {
                thumb = <img src={value.thumb} alt={value.name}/>;
                fullSize = (
                    <span>
          <StyledLink
              onClick={() => setIsOpen(true)}
              className={css({
                  cursor: "pointer"
              })}
          >
            {value.rawWidth}x{value.rawHeight}
          </StyledLink>
          <Modal
              onClose={() => setIsOpen(false)}
              isOpen={isOpen}
              animate
              autoFocus
              size={SIZE.auto}
              role={ROLE.dialog}
          >
            <ModalHeader>{value.name}</ModalHeader>
            <ModalBody>
                {value.rawHeight > value.height || value.rawWidth > value.width ?
                    <p>This image has been resized to better fit the screen. <br/>The actual dimensions
                        are {value.rawWidth}x{value.rawHeight}.</p> : null}
                <img src={value.objectUrl} width={value.width} height={value.height} alt={value.name}/>
            </ModalBody>
          </Modal>
        </span>
                );
            } else {
                file = value.objectUrl ? <StyledLink
                    href={value.objectUrl}
                    download={value.name}
                >
                    <Download size={20}/>
                </StyledLink> : null;
            }

            return (
                <ListItem
                    overrides={{
                        Root: {style: {height: "100px", marginTop: "10px"}}
                    }}
                    endEnhancer={() => (
                        <Button
                            shape="round"
                            size="compact"
                            kind="secondary"
                            onClick={() => {
                                onChange(null);
                            }}
                        >
                            <Delete/>
                        </Button>
                    )}
                >
                    {thumb}
                    <ListItemLabel>
                        <span style={{
                            wordBreak: "break-all"
                        }}>
                            {value.name}<br/>
                            ({value.sizeInKb})&nbsp;{fullSize}
                            {file}
                            </span>
                    </ListItemLabel>
                </ListItem>
            );
        }
    }

}
