﻿import {useStyletron} from "baseui";
import {SpaceTopAndBottom} from "./layout";
import {PrimaryActionMenuButton} from "@soap/modules";
import * as React from "react";

export function ActionButton({onClick, text, disabled}) {
    const [css, theme] = useStyletron();

    return (
        <div className={css({
            justifyContent: 'center',
            display: 'flex'
        })}>
            <SpaceTopAndBottom>
                <PrimaryActionMenuButton disabled={disabled} onClick={onClick}>{text}</PrimaryActionMenuButton>
            </SpaceTopAndBottom>
        </div>

    );
}