import {ServiceBusClient} from '@azure/service-bus';
import {ApplicationInsights} from '@microsoft/applicationinsights-web'
import eventHandler from "./event-handler";
import {getHeader, optional, setHeader, types, validateArgs} from './util';
import {getListOfRegisteredMessages, headerKeys, registerMessageTypes} from './messages';
import {BlobServiceClient} from "@azure/storage-blob";
import _ from "lodash";
import * as signalR from '@microsoft/signalr';
import {HubConnectionState} from '@microsoft/signalr';
import soapVars from '@soap/vars';

if (!globalThis.Soap) { //* this should only be created once the first time the module loads
    console.log("initialising global config");    
    /* globalThis.Soap holds a react-free store of core parts of the system which can be
     used not only by react components but plain JS modules as well, including this one
     for things like setting up the connections to azure for signalR and serviceBus
     */
    
    globalThis.Soap = {
        beforeSendCommand: undefined,
        configIsInitialised : false, 
        authReady: false,
        onLoadedCallbacks: [],
        debug: {
            caching: false,
            configState: false,
            auth: false,
            isMounted: false,
            renders: false,
            classDeclarations: false,
            logFormDetail: false,
            busMessageContent: false,
            hooks: false,
            blobs: false
        },
        showSignup: false
    };
} else {
    /* If the entire library is loaded a second time then it will initialise a second
    instance of the config module, which will in turn try to reset the single Soap global object.
    This would cause errors for sure. However, we should never load the library twice anyways,
    it's not designed for that type of scenario, and if that is happening there is problem
    with the architecture.
     */
    throw "Soap config module being loaded twice. It may be the result of referencing one of the @soap/modules source files directly" +
    "which depends on the config module";
}

let queuedLogMessages = [];
const proxyTrap = {
    get(target, prop, receiver) {
        if (globalThis.Soap.configIsInitialised) {
            return globalThis.Soap[prop];
        } else if (prop === 'logger') {
            return {
                log: (m) => {
                    if (globalThis.Soap.debug.configState) {
                        const timeStamp = new Date().getTime().toString();
                        console.warn(`Queueing msg ${m} at ${timeStamp}`)
                    }
                    queuedLogMessages.push(m);
                }
            }
        }
        if (prop in globalThis.Soap) {
            return globalThis.Soap[prop];
        }
        throw `Attempting to access a config object property or method ${prop} that does not exist. 
        If the name is correct, use the useIsConfigLoaded(callerName) hook to wait for the config to fully initialise.`;
    }
};

const configProxy = new Proxy({}, proxyTrap);
/* When parcel supports top-level awaits you could say `export default await loadConfig()` 
    But being able to set globalThis.Soap properties before configLoaded is true is quite useful.
    This approach enriches the config, and doesn't delay any code which doesn't need the full config.
     */
export default configProxy;

/* this IIFE makes the awaitable's in the body work correctly.
When the module is loaded the module level code outside this IIFE runs right past this and returns the proxy
without the code in the IIFE having completed.
 */
(async function () {
        await loadConfig();
        globalThis.Soap.configIsInitialised = true;
        /* I had note here that configIsInitialised=true should be set before the callbacks are called on next line.
        I don't know if that's still relevant. I know it had to do with the fact that 
        the Proxy trap has already closed over globalThis.Soap and so the useConfigIsLoaded hooks will read that value
        before and when the callbacks are called and I remember that causing an error if it was set afterwards in some scenario.
        I can't see the need today, but I don't have time to test thoroughly and I see no need to change the order
        in this function, but it's worth documenting that for posterity. The general pattern I have been
        using to sync globals and react state elsewhere such as in useAuthReady follows the same approach of updating
        the global object first and then sending notifications to components of the change so they can update their local
        state and trigger whatever reactions need to occur. 
         */
        globalThis.Soap.onLoadedCallbacks.forEach(c => c()); //* this line triggers useIsConfigLoaded to change state
}());

async function loadConfig() {

    let _sendMode = "httpdirect";
    let _auth0, _sessionConnections;
    const isTest = process.env.NODE_ENV === 'test';
    const _logger = createLogger();
    
    {
        if (!isTest) {
            const configState = await loadConfigState();
            _auth0 = configState.auth0;
            _sessionConnections = configState.sessionConnections;
        }
        
        const postInitConfig = {

            get vars() {
                return vars();
            },

            get logger() {
                return _logger;
            },

            get auth0() {
                return _auth0;
            },

            get sendMode() {
                return _sendMode;
            },

            set sendMode(value) {
                _sendMode = value;
            },
            
            send(message) {
                sendMessage(message);
            }
        };
        
        //enrich the globalThis.Soap store with the post Init Data
        Object.assign(globalThis.Soap, postInitConfig);

        if (globalThis.Soap.debug.configState) {
            const timeStamp = new Date().getTime().toString();
            console.warn(`Logging ${queuedLogMessages.length} queued messages at ${timeStamp}`)
        }
        queuedLogMessages.forEach(m => {
            _logger.log(m)
        });
    }

    function vars() {

        const functionAppRoot = soapVars.FUNCTIONAPP_ROOT;
        functionAppRoot || _logger.log("FUNCTIONAPP_ROOT not defined.");
        const appInsightsKey = soapVars.APPINSIGHTS_KEY;
        appInsightsKey || _logger.log("APPINSIGHTS_KEY not defined.");
        const serviceBusConnectionString = soapVars.SERVICEBUS_CONN;
        serviceBusConnectionString || _logger.log("SERVICEBUS_CONN not defined.");
        const blobStorageUri = soapVars.BLOBSTORAGE_URI;
        blobStorageUri || _logger.log("BLOBSTORAGE_URI not defined.");
        const envPartitionKey = soapVars.ENVIRONMENT_PARTITION_KEY;
        envPartitionKey || _logger.log("ENVIRONMENT_PARTITION_KEY not defined.")
         
        
        return {
            functionAppRoot,
            appInsightsKey,
            serviceBusConnectionString,
            blobStorageUri,
            envPartitionKey,
            signalRHubConnectionId: _sessionConnections?.hubConnection.connectionId,
        };
    }

    function getSasUrl(message) {

        const sasToken = getHeader(message, headerKeys.sasStorageToken);
        let sasUrl = sasToken;
        if (!sasUrl.startsWith('http')) {
            sasUrl = vars().blobStorageUri + sasToken;
        }
        
        _logger.log("attaching to " + sasUrl);
        return sasUrl;
    }

    function sendMessage(msg) {

        if (!_sessionConnections || !_sessionConnections.hubConnection) {
            console.error(`trying to send msg ${msg.$type} before connections initiated. message will be discarded`);
        }

        /* sending has to be async, but our calling interface may not be.
        however at this point, allowing this code to run async from here on is fine 
        since nothing will depend directly on anything inside sendMessageAsync its
        fire and forget */
        (async function () {

            console.log("HubConnection state at sendMessage is " + _sessionConnections.hubConnection.state + ". connectionId is " + _sessionConnections.hubConnection.connectionId);
            if (_sessionConnections.hubConnection.state === HubConnectionState.Disconnected ||
                !_sessionConnections.hubConnection.connectionId) {
                console.warn('hubConnection unavailable at time of sending message. trying to restart...')
                try {
                    await _sessionConnections.hubConnection.start();
                    console.log('hubConnection re-started. sending message.');
                    await sendMessageAsync(msg);
                } catch (err) {
                    console.error("SignalR Connection Error", err);
                }
            } else {
                await sendMessageAsync(msg);
            }
            
        }());

        async function sendMessageAsync(typedMessage) {

            try {
                if (_sendMode.toLowerCase() == "httpdirect") {
                    await sendByHttp(typedMessage);
                } else if (_sendMode.toLowerCase() == "servicebus") {
                    await sendByBus(typedMessage);
                } else if (_sendMode.toLowerCase() == "signalr") {
                    await sendBySignalR(typedMessage);
                }

            } catch (e) {
                _logger.log(e);
            }

            async function sendBySignalR(message) {

                _logger.log(`Sending message ${getHeader(message, headerKeys.schema)}\r\nid/conversation ${getHeader(message, headerKeys.messageId)}`, message);

                //* signalR doesn't require us to use blob storage
                _.remove(message.headers, h => h.key == headerKeys.blobId);
                _.remove(message.headers, h => h.key == headerKeys.sasStorageToken);

                setHeader(message, headerKeys.sessionId, _sessionConnections.hubConnection.connectionId);

                try {
                    await _sessionConnections.hubConnection.send("ReceiveMessageSignalR", JSON.stringify(message), getHeader(message, headerKeys.messageId), message.$type);
                } catch (err) {
                    console.error(err);
                }

                _logger.log(`Sent message ${getHeader(message, headerKeys.messageId)} by websocket, connectionId ${_sessionConnections.hubConnection.connectionId}`);

            }

            async function sendByBus(message) {
                const queue = getHeader(message, headerKeys.queueName);
                const sender = _sessionConnections.serviceBusClient.createSender(queue);

                _logger.log(`Sending message ${getHeader(message, headerKeys.schema)}\r\nid/conversation ${getHeader(message, headerKeys.messageId)}`, message);

                setHeader(message, headerKeys.sessionId, _sessionConnections.hubConnection.connectionId);

                if (_.find(message.headers, h => h.key === headerKeys.blobId)) {

                    const messageBlob = new Blob([JSON.stringify(message)]);
                    await uploadMessageToBlobStorage(message, messageBlob);
                    clearDownMessageProperties(message);
                }

                await sender.sendMessages({
                    body: message,
                    messageId: getHeader(message, headerKeys.messageId),
                    subject: message.$type,
                    sessionId: _sessionConnections.hubConnection.connectionId
                });

                _logger.log(`Sent message ${getHeader(message, headerKeys.messageId)} to queue ${queue}`);

                await sender.close();
            }

            async function sendByHttp(message) {

                _logger.log(`Sending message ${getHeader(message, headerKeys.schema)}\r\nid/conversation ${getHeader(message, headerKeys.messageId)}`, message);

                //* http doesn't require us to use blob storage
                _.remove(message.headers, h => h.key == headerKeys.blobId);
                _.remove(message.headers, h => h.key == headerKeys.sasStorageToken);

                setHeader(message, headerKeys.sessionId, _sessionConnections.hubConnection.connectionId);

                const endpoint = encodeURI(`${vars().functionAppRoot}/ReceiveMessageHttp?id=${getHeader(message, headerKeys.messageId)}&type=${message.$type}`);

                await fetch(endpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(message),
                })

                _logger.log(`Sent message ${getHeader(message, headerKeys.messageId)} to endpoint ${endpoint}`);
            }

            function clearDownMessageProperties(message) {
                for (const property in message) {
                    switch (property) {
                        case '$type': //* leave it there for deserialisation
                        case 'headers': //* leave it there for downloading blob
                        case 'validate': //* you added this
                        case 'types': //* you added this
                        case 'constructor': //js default
                            break;
                        default:
                            delete message[property];
                    }
                }
            }

            async function uploadMessageToBlobStorage(message, blob) {

                const blobId = getHeader(message, headerKeys.blobId);
                const sasUrl = getSasUrl(message);
                const blobServiceClient = new BlobServiceClient(sasUrl);
                const containerClient = blobServiceClient.getContainerClient("large-messages");
                const blockBlobClient = containerClient.getBlockBlobClient(blobId);
                const typeClass = "AssemblyQualifiedName";
                const typeString = message.$type;
                const options = {metadata: {typeClass, typeString}};
                const uploadBlobResponse = await blockBlobClient.uploadData(blob, options);
                _logger.log(`Upload block blob ${blobId} successfully`, uploadBlobResponse.requestId);

            }
        }
    }

    function createLogger() {

        const l = {
            appInsights: null,
            enabled: true,
            showStackTrace: false
        };
        l.log = (logMsg, logObject, important) => {
            if (l.enabled === true) {
                if (typeof logMsg === types.object) logMsg = logMsg.toString();
                if (typeof logObject === types.object) logObject = JSON.parse(JSON.stringify(logObject, null, 2)); //* clone it to protect from mutation 

                validateArgs(
                    [{msg: logMsg}, types.string],
                    [{important}, types.boolean, optional]
                );

                const stackTrace = (l.showStackTrace) ?
                    new Error().stack.substring(5) : null;
                if (logObject === undefined) {
                    !!important ? console.warn(logMsg) : console.log(logMsg);
                } else {
                    !!important ? console.warn(logMsg, logObject, stackTrace) : console.log(logMsg, logObject, stackTrace)
                }

                if (!!important && !isTest) { //* log to appinsights

                    if (!l.appInsights) {
                        l.appInsights = new ApplicationInsights({
                            config: {
                                instrumentationKey: vars().appInsightsKey
                            }
                        });
                        l.appInsights.loadAppInsights();
                    }
                    l.appInsights.trackTrace({message: logMsg});
                }
            }
        };
        return l;
    }

    async function loadConfigState() {

        //* load all config state, when this is done the system is ready
        {
            const results = await Promise.all([registerMessageTypesFromApi(), createSessionConnections(receiveMessage)]);
            return {
                auth0: results[0],
                sessionConnections: results[1]
            }
        }

        async function receiveMessage(processor) {

            const functionAppRoot = vars().functionAppRoot;

            const hubConnection = new signalR.HubConnectionBuilder()
                .withUrl(functionAppRoot)
                .withAutomaticReconnect([0,1000,2000,2000,2000,4000,6000,10000])
                .configureLogging(signalR.LogLevel.Information)
                .build();

            hubConnection.on('eventReceived', async message => {
                const messageObj = JSON.parse(message.substring(3)); //don't let signalr do the serialising or it will use the wrong JSON settings
                await processor(messageObj);
            });

            hubConnection.onreconnecting(err => console.warn("SignalR Reconnecting", err));
            hubConnection.onreconnected(connectionId => {
                console.warn("SignalR Reconnected. New Session Id: " + connectionId)
            });

            hubConnection.onclose(async err => {
                const seconds = 2;
                console.warn(`SignalR connection closing, will try to restart in ${seconds} seconds`, err);
                await sleep(seconds * 1000);
                await tryStart();  // Restart connection after 2 seconds.
            });

            await tryStart();

            const endpoint = `${functionAppRoot}/AddToGroup?connectionId=${encodeURIComponent(hubConnection.connectionId)}`;

            //* don't wait it will finish before first response
            await fetch(endpoint); //this will get us messages matched to our environment partition key

            return hubConnection;

            async function tryStart() {
                try {
                    //* could already be reconnected e.g. if someone tried to send a message during the reconnect sleep
                    console.log("HubConnection state at tryStart is " + hubConnection.state + ". connectionId is " + hubConnection.connectionId);
                    if (hubConnection.state === HubConnectionState.Disconnected ||
                    !hubConnection.connectionId) {
                        await hubConnection.start();
                    }
                } catch (err) {
                    console.error("SignalR Connection Error", err);
                }
            }

            function sleep(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            }
        }

        async function registerMessageTypesFromApi() {

            const endpoint = `${vars().functionAppRoot}/GetJsonSchema`;
            let auth0Info;

            const jsonArrayOfMessages = await fetch(endpoint)
                .then(response => {

                    const auth0Enabled = (response.headers.get('Idaam-Enabled') == 'true');
                    if (auth0Enabled) {
                        auth0Info = {
                            uiAppId: response.headers.get('Auth0-UI-Application-ClientId'),
                            audience: response.headers.get('Auth0-UI-Api-ClientId'),
                            tenantDomain: response.headers.get('Auth0-Tenant-Domain'),
                            redirectUri: response.headers.get('Auth0-Redirect-Uri'),
                            isAuthenticated: false,
                            accessToken: null,
                            identityToken: null,
                            userName: null
                        };
                    }

                    return response.json();
                });

            registerMessageTypes(jsonArrayOfMessages);
            _logger.log(`Schema built for ${jsonArrayOfMessages.length} messages:`, getListOfRegisteredMessages());

            return auth0Info;
        }

        async function createSessionConnections(receiveEventFunction) {

            const serviceBusClient = new ServiceBusClient(vars().serviceBusConnectionString); 
            /* Timeout occurs at the default of [5 minutes] of inactivity on the AMQP connection.
            In normal circumstance the client will hold a lock on the session until the timeout occurs.
            If the user refreshes the page then you will get a new session Id.
            Certainly the old one should die off with the serviceBusClient after the connection timeout. 
            since there are no lockRenewals on session or send calls on that client and probably dies as soon as refresh occurs.
            It may be also be that the connection and session are killed as soon as the WSS connection is lost but I wasn't able to verify that 
            */

            const hubConnection = await receiveEventFunction(processMessage);

            return {
                hubConnection,
                serviceBusClient
            };

            async function processMessage(message) {

                let anonymousEvent = message;

                try {
                    _logger.log(`Received message ${getHeader(message, headerKeys.messageId)}`, anonymousEvent);

                    if (_.find(message.headers, h => h.key === headerKeys.blobId)) {
                        //* make the swap

                        anonymousEvent = await downloadMessageBlob(anonymousEvent);

                    }
                    eventHandler.handle(anonymousEvent);
                } catch (err) {
                    _logger.log(`>>>>> Error handling event message ${message.messageId}, ${err + " stacktrace:" + err.stack}`);
                }
            }

            async function downloadMessageBlob(anonymousEvent) {

                const blobId = getHeader(anonymousEvent, headerKeys.blobId);
                const sasUrl = getSasUrl(anonymousEvent); 
                if (sasUrl.startsWith('http')) {
                    const blobResponse =  await fetch(sasUrl);
                    const blobText = await blobResponse.text();
                    const blobbedMessage = JSON.parse(blobText);
                    return blobbedMessage;
                }
                
                const blobServiceClient = new BlobServiceClient(sasUrl);
                
                const containerClient = blobServiceClient.getContainerClient("large-messages");
                const blobClient = containerClient.getBlobClient(blobId);
                // Get blob content from position 0 to the end
                // In browsers, get downloaded data by accessing downloadBlockBlobResponse.blobBody
                const downloadBlockBlobResponse = await blobClient.download();
                const downloaded = await blobToString(await downloadBlockBlobResponse.blobBody);
                const blobbedMessage = JSON.parse(downloaded);
                await containerClient.deleteBlob(blobId);
                return blobbedMessage;

                //a helper method used to convert a browser Blob into string.
                async function blobToString(blob) {

                    const fileReader = new FileReader();
                    return new Promise((resolve, reject) => {
                        fileReader.onloadend = (ev) => {
                            resolve(ev.target.result);
                        };
                        fileReader.onerror = reject;
                        fileReader.readAsText(blob);
                    });
                }

            }
        }
    }
}

