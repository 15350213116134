import {Cell, Grid} from "baseui/layout-grid";
import {HeadingMedium} from "baseui/typography";
import React, {useState} from "react";
import {Button, KIND, SIZE} from "baseui/button";
import bus from "../soap/bus";
import {ArrayRenderer} from "./ArrayRenderer";
import {CenterSpinner} from "./CenterSpinner";
import {optional, types, uuidv4, validateArgs} from "../soap/util";
import {AggregateListSecondaryActionsMenu} from "./ActionMenu";
import {useStyletron} from "baseui";
import {EntityMenu} from "./EntityMenu";



export function AggregateView(props) {
    
    const { expandedFieldsFirstObjectOnly = [], aggregate, ...rest } = props;
    const newProps = { expandedFieldsFirstObjectOnly : ["root", ...expandedFieldsFirstObjectOnly], aggregates : (!!aggregate ? [aggregate] : undefined), ...rest, dataType: "object" };
    return AggregateList(newProps);
}

export function AggregateList(props) {

    const [css, theme] = useStyletron();
    
    const {
        title,
        entityMenus,
        aggregates,
        propertyRenderer,
        labelRenderer,
        hiddenFields = [],
        expandedFields = [],
        expandedFieldsFirstObjectOnly = [],
        headerColumns = [],
        refreshFunction,
        backFunction,
        conversationId,
        dataType,
        rowColour,
        displayMode,
        displayOptions
    } = props;

    validateArgs(
        [{entityMenus}, types.object, optional],
        [{propertyRenderer}, types.object, optional],
        [{labelRenderer}, types.object, optional],
        [{hiddenFields}, [types.string], optional],
        [{headerColumns}, [types.string], optional],
        [{expandedFields}, [types.string], optional],
        [{expandedFieldsFirstObjectOnly}, [types.string], optional],
        [{refreshFunction}, types.function, optional],
        [{backFunction}, types.function, optional],
        [{rowColour}, types.string, optional],
        [{conversationId}, types.guid, optional]
    );
    
    function getEntity() {
        return !!aggregates && dataType === "object" ? aggregates[0] : undefined;
    }
    
    function getActions(title, entityMenus, backFunction, refreshFunction) 
        {
            //* backFunction and refreshFunction are to be deprecated
            
            let result = [];
            let hasTitle = undefined;
            
            if (title) {
                hasTitle = true;
                result.push((typeof title === typeof "") ? <div key={uuidv4()}><HeadingMedium className={css({
                    marginBlockStart: '0em',
                    marginBlockEnd: '0em',
                    marginRight:theme.sizing.scale600
                })}>{title}</HeadingMedium></div> : <div key={uuidv4()} className={css({
                    marginRight:theme.sizing.scale600
                })}>{title}</div>); 
            }
            
            if (backFunction) {
                result.push(<div key={uuidv4()} className={css({
                    marginRight:theme.sizing.scale600
                })}><Button kind={KIND.tertiary} size={SIZE.compact} onClick={() => backFunction()}>Back</Button></div>);
            }
            
            if (entityMenus && entityMenus["root-individual"]?.actions) {
                result.push(...entityMenus["root-individual"].actions.map(a => <div key={uuidv4()} className={css({
                    marginRight:theme.sizing.scale600
                })}>{a()}</div>));
            }
            
            if (refreshFunction) {
                if (!entityMenus) {
                    entityMenus = {};
                } 
                if (!entityMenus["root"]) {
                    entityMenus["root"] = new EntityMenu(null, [])
                }
                //* add it to the menu
                entityMenus["root"].actions.push(() => <Button style={{
                    width:"100%" //* fill menu
                }} kind={KIND.tertiary} size={SIZE.compact} onClick={() => {
                    bus.closeAllDialogs();
                    refreshFunction();
                }}>Refresh</Button>);
            }
            
            if (entityMenus && entityMenus["root"]?.actions) {
                result.push(<div key={uuidv4()}><AggregateListSecondaryActionsMenu propertyKey={"root"} entityMenus={entityMenus} entity={getEntity()}/></div>);
            }
            
            const rightAlignAtIndex = hasTitle ? 1 : 0;
            result[rightAlignAtIndex] = (<div key={uuidv4()} className={css({
                marginLeft:"auto"
            })}>{result[rightAlignAtIndex]}</div>);
            
            return (
                <div className={css({
                    display:'flex',
                    flexWrap: 'wrap',
                    paddingTop: theme.sizing.scale600,
                    paddingBottom: theme.sizing.scale700
                })}>
                    {result}
                </div>
            );
    }

    return (<>
        <Grid gridMaxWidth={1200} 
              gridGutters={[2,6,12]}
              gridMargins={theme.sizing.scale500}>
            <Cell span={12}>
                {getActions(title, entityMenus, backFunction, refreshFunction)}
            </Cell>
            <Cell span={12}>
                {aggregates ?
                        <ArrayRenderer entityMenus={entityMenus}
                                       arrayOfObjects={aggregates}
                                       hiddenFields={hiddenFields}
                                       headerColumns={headerColumns}
                                       conversationId={conversationId}
                                       expandedFields={expandedFields}
                                       expandedFieldsFirstObjectOnly={expandedFieldsFirstObjectOnly}
                                       propertyRenderer={propertyRenderer}
                                       labelRenderer={labelRenderer}
                                       propertyKey={"root-Items"}
                                       
                                       dataType={dataType}
                                       rowColour={rowColour}
                                       displayMode={displayMode}
                                       displayOptions={displayOptions}
                        />
                    :
                    <CenterSpinner/>}
            </Cell>
        </Grid></>
    );
}