﻿import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {
    CenterSpinner,
    headerKeys,
    PrimaryActionMenuButton,
    toTypeName,
    translate,
    useApiErrors,
    useAuth,
    useCommandValidation,
    useDomainEvent,
    useEvent,
    useLazyCommand,
    useTokenRefreshRequired,
    uuidv4,
    ValidationNotification
} from '@soap/modules'
import {CenteredCard} from "../z40-uicomp/centered-card";
import {Frame} from "../z40-uicomp/frame";
import {LabelLarge, LabelMedium, LabelSmall, ParagraphMedium} from "baseui/typography";
import {useParams} from "react-router-dom";
import {Centered, SpaceAround, SpaceBottom, SpaceTop, SpaceTopAndBottom} from "../z40-uicomp/layout";
import {Cell, Grid} from "baseui/layout-grid";
import {useStyletron} from "baseui";
import {Table} from "baseui/table-semantic";
import {Input} from "baseui/input";
import {Select} from "baseui/select";
import {TalkBadge} from "../z40-uicomp/talk-badge";
import {useDebounce} from "../hooks/useDebounce";
import ErrorModal from "../z40-uicomp/error-modal";

function useCommandValidationWithLogging() {
    const {validateCommand} = useCommandValidation();
    return {
        validateCommand: async (command, schema) => {
            console.log(`validating ${schema ?? command.$type}`, command);
            return await validateCommand(command, schema);
        }
    };
}

export function Order() {

    const {validateCommand} = useCommandValidationWithLogging();

    const toMoney = function (price, currency) {

        if (price === 0) return "Free";
        const amount = Number.isInteger(price) ? price : price.toFixed(2);
        return amount + " " + currency;
    }

    let {id: statefulProcessIdAkaOrderId} = useParams();
    statefulProcessIdAkaOrderId = window.atob(statefulProcessIdAkaOrderId);


    //* styling 
    const [css, theme] = useStyletron();


    const viewStates = {
        WaitingForOrderData: 0,
        OrderDataReceivedWaitingForUserAction: 1,
        PaymentFailed: 2,
        PaymentAcceptedButNotConfirmed: 3,
        PaymentConfirmed: 4,
        WaitingForCheckout: 5,
        PendingCurrencyChangePreventedOrder: 6,
        TryingToUpgradeToExistingPlan: 7,
        PaymentNotRequired: 8
    };

    const [viewState, setViewState] = useState(viewStates.WaitingForOrderData);

    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');

    const [postcode, setPostcode] = React.useState('');
    const debouncedPostcode = useDebounce(postcode, 500);

    const [countryCode, setCountryCode] = React.useState();
    const [countries, setCountries] = React.useState([]);
    const [currencyCode, setCurrencyCode] = React.useState();
    const [currencies, setCurrencies] = React.useState([]);

    const [orderSummaryData, setOrderSummaryData] = useState();
    const [orderCompletedData, setOrderCompletedData] = useState();
    const [confirmOrderConversationId, setConfirmOrderConversationId] = useState();

    //* show if error
    const [notificationMessage, setNotificationMessage] = useState(undefined);

    const [disabled, setDisabled] = useState(true);

    //* check a valid plan was selected and create an account on the free plan if one doesn't yet exist,  returns E140
    const [continueOrderTrigger, conversationIds] = useLazyCommand('C169v1_ContinueOrderAfterLogin', {
        headers: [{key: headerKeys.statefulProcessId, value: statefulProcessIdAkaOrderId}]
    });

    const {refresh} = useAuth("order.js");

    useEffect(() => {
        if (viewState == viewStates.OrderDataReceivedWaitingForUserAction && postcode !== orderSummaryData.billingPostcode) {
            //* in the US this can change the tax you pay e.g. Texas vs California

            sendConfirmCommand(true, currencyCode, countryCode, debouncedPostcode, firstName, lastName);
        }
    }, [debouncedPostcode]);

    useDomainEvent({
        eventName: "checkout-completed",
        onEventReceived: () => {
            Paddle.Checkout.close();
            setViewState(viewStates.PaymentAcceptedButNotConfirmed);
        }
    });

    useDomainEvent({
        eventName: "checkout-closed",
        onEventReceived: () => {
            setViewState(viewStates.PaymentFailed);
            setNotificationMessage(<>
                <LabelMedium>Transaction has been Cancelled.</LabelMedium>
                {(orderSummaryData.newPlanKey !== "free" && orderSummaryData.HasNotCompletedTenantSetup == true)
                    ? <LabelMedium>You have been placed on the Free Plan.</LabelMedium>
                    : null}
                If you wish to try again,<br/> please return to the <a href={"#/pricing"}>Pricing Page</a>
            </>);
        }
    });

    useEvent({
        eventName: "E186v1_GotTransactionIdForCheckout",
        conversationId: confirmOrderConversationId,
        onEventReceived: (e186) => {

            //* use customer details from backend not frontend to ensure any post-processing and validation has been done

            let customer;
            if (e186.e186_ExistingCustomerId) {
                customer = {
                    id: e186.e186_ExistingCustomerId
                };
            } else {
                customer = {
                    email: e186.e186_NewCustomerDetails.e186_Email,
                    address: {
                        postalCode: e186.e186_NewCustomerDetails.e186_BillingPostcode,
                        countryCode: e186.e186_NewCustomerDetails.e186_BillingCountry
                    }
                };
            }

            const checkoutSettings = {
                settings: {
                    allowLogout: false
                    ,
                },
                transactionId: e186.e186_TransactionId,
                customer: customer
            };
            console.warn(checkoutSettings);
            Paddle.Checkout.open(checkoutSettings);

            setViewState(viewStates.WaitingForCheckout);
        }
    });


    useTokenRefreshRequired(useCallback((idToken, accessToken) => {
        if (viewState === viewStates.OrderDataReceivedWaitingForUserAction || viewState === viewStates.WaitingForOrderData) {

            /* continueOrderTrigger is sent, it fails because autoAccountCreationHandler is run and this throws error 
            because token needs refreshing after account creation
             */
            console.log("token refresh due to new account creation.");
            (async function () {
                {
                    await refresh({ignoreCache: true});
                    continueOrderTrigger(); //* try again
                }
            })();
        }
    }, [refresh]));

    const renderTagLimit = useCallback((t) => {
        if (t === 999) {
            return "Unlimited";
        } else {
            return t;
        }
    }, []);

    const {errors, clearErrors} = useApiErrors((newError, errors) => {

        const errorCodes = {
            pendingCurrencyChange: "601d463e-2558-4a3e-a402-236114bf1709",
            tryingToUpgradeToExistingPlan: "9862dde9-ddcc-475c-bcbf-bcf688a0c82c"
        };

        setConfirmOrderConversationId(undefined); //clear ongoing conversation
        setDisabled(true); //disable actions

        if (newError.errorCodes?.includes(errorCodes.pendingCurrencyChange)) {
            setViewState(viewStates.PendingCurrencyChangePreventedOrder);
            //* notification msg would be visible after closing error modal
            setNotificationMessage(<>
                <LabelMedium>Transaction has been cancelled.</LabelMedium>
                If you wish to update your billing details<br/> please visit the <a href={"#/my-profile"}>My
                Profile</a> page.
            </>);
        } else if (newError.errorCodes?.includes(errorCodes.tryingToUpgradeToExistingPlan)) {
            setViewState(viewStates.TryingToUpgradeToExistingPlan);
        }
    });

    useEvent({
        eventName: "E140v1_GotOrderDetailsForConfirmation",
        onEventReceived: (e140) => {

            const threeColumns = ['Item', 'Current Plan', 'New Plan'];
            const twoColumns = ['Item', 'Amount'];
            const planChosenIsFreePlan = e140.e140_NewPlan.e140_PlanKey === 'free';
            const currentPlanIsFreePlan = e140.e140_QueuedPlan.e140_PlanKey === 'free';
            const taxNotIncluded = "Tax not included";
            const brandNewUser = (e140.e140_AccountSetupNeverCompleted === true && planChosenIsFreePlan);
            const showCurrentPlan = !brandNewUser;
            const newPlanTaxMode = planChosenIsFreePlan ? "" : (e140.e140_NewPlan.e140_PlanTaxMode === "external" ? taxNotIncluded : "");
            const currentPlanTaxMode = currentPlanIsFreePlan ? "" : (e140.e140_QueuedPlan?.e140_PlanTaxMode === "external" ? taxNotIncluded : "");
            const data = {
                billingPostcode: e140.e140_BillingPostcode ?? '',
                checkoutNotRequired: e140.e140_NewPlan.e140_BillingCodes.includes('checkout-not-required'),
                billingCodes: e140.e140_NewPlan.e140_BillingCodes,
                profileEmail: e140.e140_ProfileEmail,
                newPlan: e140.e140_NewPlan.e140_PlanName,
                newPlanKey: e140.e140_NewPlan.e140_PlanKey,
                newPlanPrice: e140.e140_NewPlan.e140_PlanPrice,
                newPlanCurrency: e140.e140_NewPlan.e140_PlanCurrency,
                currentPlan: e140.e140_QueuedPlan?.e140_PlanName,
                currentPlanPrice: e140.e140_QueuedPlan?.e140_PlanPrice,
                currentPlanCurrency: e140.e140_QueuedPlan?.e140_PlanCurrency,
                currentPlanKey: e140.e140_QueuedPlan?.e140_PlanKey,
                tableColumns: showCurrentPlan ? threeColumns : twoColumns,
                tableData: showCurrentPlan ?
                    [
                        ["Candidate Shares", e140.e140_QueuedPlan.e140_PlanCredit.e140_CandidateShares, e140.e140_NewPlan.e140_PlanCredit.e140_CandidateShares],
                        ["Candidates", e140.e140_QueuedPlan.e140_PlanCredit.e140_Candidates, e140.e140_NewPlan.e140_PlanCredit.e140_Candidates],
                        ["Questions", e140.e140_QueuedPlan.e140_PlanCredit.e140_Questions, e140.e140_NewPlan.e140_PlanCredit.e140_Questions],
                        ["Assessments", e140.e140_QueuedPlan.e140_PlanCredit.e140_Assessments, e140.e140_NewPlan.e140_PlanCredit.e140_Assessments],
                        ["Sub Accounts", e140.e140_QueuedPlan.e140_PlanCredit.e140_SubAccounts, e140.e140_NewPlan.e140_PlanCredit.e140_SubAccounts],
                        ["Tag Limit", renderTagLimit(e140.e140_QueuedPlan.e140_PlanCredit.e140_TagLimit), renderTagLimit(e140.e140_NewPlan.e140_PlanCredit.e140_TagLimit)],
                        ["Price", <LabelLarge
                            key={uuidv4()}>{toMoney(e140.e140_QueuedPlan.e140_PlanPrice, e140.e140_QueuedPlan.e140_PlanCurrency)}
                            <LabelSmall>{currentPlanTaxMode}</LabelSmall></LabelLarge>,
                            <LabelLarge
                                key={uuidv4()}>{toMoney(e140.e140_NewPlan.e140_PlanPrice, e140.e140_NewPlan.e140_PlanCurrency)}
                                <LabelSmall>{newPlanTaxMode}</LabelSmall></LabelLarge>],
                    ]
                    : [
                        ["Candidate Shares", e140.e140_NewPlan.e140_PlanCredit.e140_CandidateShares],
                        ["Candidates", e140.e140_NewPlan.e140_PlanCredit.e140_Candidates],
                        ["Questions", e140.e140_NewPlan.e140_PlanCredit.e140_Questions],
                        ["Assessments", e140.e140_NewPlan.e140_PlanCredit.e140_Assessments],
                        ["Sub Accounts", e140.e140_NewPlan.e140_PlanCredit.e140_SubAccounts],
                        ["Tag Limit", renderTagLimit(e140.e140_NewPlan.e140_PlanCredit.e140_TagLimit)],
                        ["Price", <LabelLarge
                            key={uuidv4()}>{toMoney(e140.e140_NewPlan.e140_PlanPrice, e140.e140_NewPlan.e140_PlanCurrency)}
                            <LabelSmall>{newPlanTaxMode}</LabelSmall></LabelLarge>],
                    ]
            };

            setOrderSummaryData(data);
            setFirstName(e140.e140_ProfileFirstName);
            setLastName(e140.e140_ProfileLastName ?? '');
            setPostcode(e140.e140_BillingPostcode ?? '');
            setCountryCode(e140.e140_BillingCountry.selectedKeys[0]);
            setCountries(e140.e140_BillingCountry.allEnumerations);
            setCurrencyCode(e140.e140_BillingCurrency.selectedKeys[0]);
            setCurrencies(e140.e140_BillingCurrency.allEnumerations);
            setViewState(viewStates.OrderDataReceivedWaitingForUserAction);

            setConfirmOrderConversationId(undefined);
            setDisabled(false);
        }
    });


    useEvent({
        eventName: "E143v1_OrderCompleted",
        conversationId: confirmOrderConversationId,
        onEventReceived: (e143) => {

            const columnsPlanOnly = ['Item', 'Amount'];
            const newPlanData = [
                ["Candidate Shares", e143.e143_Credit.e143_CandidateShares],
                ["Candidates", e143.e143_Credit.e143_Candidates],
                ["Questions", e143.e143_Credit.e143_Questions],
                ["Assessments", e143.e143_Credit.e143_Assessments],
                ["Sub Accounts", e143.e143_Credit.e143_SubAccounts],
                ["Tag Limit", e143.e143_Credit.e143_TagLimit]
            ];
            const data = {
                planKey: e143.e143_PlanKey,
                name: e143.e143_PlanName,
                tableColumns: columnsPlanOnly,
                tableData: newPlanData
            };

            setOrderCompletedData(data);
            setViewState(viewStates.PaymentConfirmed);
        }
    });

    async function sendConfirmCommand(refreshOnly, currencyCode, countryCode, postcode, firstName, lastName) {

        const confirmOrderCommand = {
            $type: toTypeName("C171v1_ConfirmOrder"),
            headers: [{
                key: headerKeys.statefulProcessId,
                value: statefulProcessIdAkaOrderId
            }],
            C171_FirstName: firstName,
            C171_LastName: lastName,
            C171_BillingPostcode: postcode,
            C171_BillingCountryCode: countryCode,
            C171_BillingCurrencyCode: currencyCode,
            C171_AllowChangeOfBillingDetails: orderSummaryData.billingCodes.includes('allow-change-of-billing-details'),
            C171_RefreshOnly: refreshOnly
        };
        const messages = await validateCommand(confirmOrderCommand);

        if (messages) {
            setNotificationMessage(messages.map((m, i) => <p key={i}>{m}</p>));
        } else {
            setNotificationMessage(undefined);
            const conversationId = confirmOrderTrigger(confirmOrderCommand);  //* still send this as it still returns e143

            if (orderSummaryData.checkoutNotRequired && !refreshOnly) {
                setViewState(viewStates.PaymentNotRequired);
            } else { //wait for transactionId
                setConfirmOrderConversationId(conversationId); //this will be used to disable the checkout button while processing
                setDisabled(true);
            }
        }
    }

    const [cancelTrigger] = useLazyCommand('C168v1_CancelOrder');
    const [confirmOrderTrigger] = useLazyCommand('C171v1_ConfirmOrder');


    switch (viewState) {
        case viewStates.PaymentAcceptedButNotConfirmed:
        case viewStates.PaymentNotRequired:
            return (<Frame>
                <CenteredCard
                    title={<div style={{
                        width: "100%"
                    }}>
                        {orderSummaryData?.newPlanKey !== 'free' ?
                            <><Centered>Processing your payment</Centered>
                                <Centered>and updating your account.</Centered></> :
                            <><Centered>Updating your account.</Centered></>}

                        <SpaceTop>
                            <SpaceTopAndBottom>
                                <Centered><ParagraphMedium>This could take up to 90
                                    seconds.</ParagraphMedium></Centered>
                            </SpaceTopAndBottom>
                            <ParagraphMedium><Centered centerText={true}>You can wait for confirmation or you can
                                continue using the app
                                and your credit will be added in the next few minutes.</Centered></ParagraphMedium>
                        </SpaceTop>
                        <SpaceTop>
                            <CenterSpinner/>
                        </SpaceTop>
                    </div>} showRobot={false}>
                </CenteredCard>
            </Frame>);
        case viewStates.PaymentFailed:
            return (
                <Frame><CenteredCard><ValidationNotification errorMsg={notificationMessage}/></CenteredCard></Frame>);
        case viewStates.PendingCurrencyChangePreventedOrder:
            return (
                <Frame>
                    <ErrorModal onClose={() => setViewState(viewStates.PaymentFailed)}>
                        <>
                            <TalkBadge><p>{translate("$CannotChangeSubscriptionWithPendingCurrencyChange")}<br/></p>
                            </TalkBadge>
                        </>
                    </ErrorModal>
                </Frame>);
        case viewStates.TryingToUpgradeToExistingPlan:
            return (
                <Frame>
                    <ErrorModal onClose={() => {
                        location.href = "#/pricing";
                    }}>
                        <>
                            <TalkBadge><p>{translate("$CannotUpgradeToExistingPlan")}<br/></p></TalkBadge>
                        </>
                    </ErrorModal>
                </Frame>
            );
        case viewStates.PaymentConfirmed:
            const itemId = uuidv4();
            sessionStorage.setItem(itemId, JSON.stringify(orderCompletedData));
            sessionStorage.removeItem("oc-loaded-once");
            location.href = '#/order-complete/' + itemId;
            return <Frame/>;
        case viewStates.WaitingForOrderData:
            return (<Frame><CenteredCard><CenterSpinner/></CenteredCard></Frame>);
        case viewStates.WaitingForCheckout:
            return (<Frame>
                <CenteredCard
                    title={<div style={{
                        width: "100%",
                        textAlign: "center"
                    }}>{`You chose, ${orderSummaryData.newPlan}`}</div>}>
                </CenteredCard>
            </Frame>);
        case viewStates.OrderDataReceivedWaitingForUserAction:
            let billingInfo = null;

            if (orderSummaryData.billingCodes.includes('bill-immediately')) {
                billingInfo = (<>
                    <TalkBadge showRobot={true}>This change in plans will take effect immediately.
                        You will be credited for any unused days remaining on the old plan and billed for any
                        outstanding balance on the new plan immediately.</TalkBadge>
                </>);
            } else if (orderSummaryData.billingCodes.includes("revert-to-free")) {
                billingInfo = (<><SpaceBottom> <LabelLarge>Billing</LabelLarge></SpaceBottom>
                    <TalkBadge showRobot={true}>Reverting to the Freebie will not cause the loss of any of your
                        data.</TalkBadge>
                    You will retain access to the credit in your old plan until your next renewal date at which time
                    your credit will be downgraded.
                    While your data is retained, you may lose access to some features if reverting to the free plan
                    causes you to exceed free plan limits.
                </>);
            } else if (orderSummaryData.billingCodes.includes('bill-at-renewal')) {
                billingInfo = (<>
                    <TalkBadge showRobot={true}>Your new plan will begin on your renewal date.</TalkBadge>
                </>);
            }

            const confirmButtonText = orderSummaryData.checkoutNotRequired ? "Confirm" : (disabled ?
                <CenterSpinner/> : "Proceed To Payment");

            return (
                <Frame>
                    <CenteredCard
                        title={<div style={{
                            width: "100%",
                            textAlign: "center"
                        }}>{`You chose, ${orderSummaryData.newPlan}`}</div>}>
                        <SpaceAround>
                            <Grid gridMargins={0} gridGaps={5}>
                                <Cell span={[1, 3, 5]}>
                                    <div className={css({paddingTop: theme.sizing.scale200})}>First Name</div>
                                </Cell>
                                <Cell span={[3, 5, 7]}><Input value={firstName}
                                                              onChange={e => setFirstName(e.target.value)}/></Cell>
                                <Cell span={[1, 3, 5]}>
                                    <div className={css({paddingTop: theme.sizing.scale200})}>Last Name</div>
                                </Cell>
                                <Cell span={[3, 5, 7]}><Input value={lastName}
                                                              onChange={e => setLastName(e.target.value)}/></Cell>
                                {orderSummaryData.billingCodes.includes('allow-change-of-billing-details') ? [
                                        //* CURRENCY
                                        <Cell key={0} span={12}><TalkBadge
                                            showRobot={true}>{orderSummaryData.checkoutNotRequired ? "Currency changes will not take effect until the new renewal date" : "Purchases can be made in any currency."} Please
                                            ensure the Billing Address matches the one registered with your payment
                                            provider. {
                                            }</TalkBadge></Cell>,
                                        <Cell key={1} span={[1, 3, 5]}>
                                            <div className={css({paddingTop: theme.sizing.scale200})}>Billing Currency</div>
                                        </Cell>,
                                        <Cell key={2} span={[3, 5, 7]}><Select
                                            options={currencies}
                                            backspaceRemoves={false}
                                            clearable={false}
                                            deleteRemoves={false}
                                            escapeClearsValue={false}
                                            required={true}
                                            searchable={false}
                                            value={[{key: currencyCode}]}
                                            labelKey="value"
                                            valueKey="key"
                                            disabled={disabled}
                                            onChange={params => {
                                                const currencyCode = params?.value[0]?.key;
                                                setCurrencyCode(currencyCode);
                                                sendConfirmCommand(true, currencyCode, countryCode, postcode, firstName, lastName);
                                            }}
                                        /></Cell>,

                                        //* BILLING POSTCODE
                                        <Cell key={3} span={[1, 3, 5]}>
                                            <div className={css({paddingTop: theme.sizing.scale200})}>Billing Zip/Post
                                                Code
                                            </div>
                                        </Cell>,
                                        <Cell key={4} span={[3, 5, 7]}><Input value={postcode} required={true}
                                                                              disabled={disabled}
                                                                              onChange={e => setPostcode(e.target.value)}/></Cell>,
                                        //* BILLING COUNTRY
                                        <Cell key={5} span={[1, 3, 5]}>
                                            <div className={css({paddingTop: theme.sizing.scale200})}>Billing Country</div>
                                        </Cell>,
                                        <Cell key={6} span={[3, 5, 7]}><Select
                                            disabled={disabled}
                                            options={countries}
                                            backspaceRemoves={false}
                                            clearable={false}
                                            deleteRemoves={false}
                                            escapeClearsValue={false}
                                            required={true}
                                            searchable={false}
                                            value={[{key: countryCode}]}
                                            labelKey="value"
                                            valueKey="key"
                                            onChange={params => {
                                                const countryCode = params?.value[0]?.key;
                                                setCountryCode(countryCode);
                                                sendConfirmCommand(true, currencyCode, countryCode, postcode, firstName, lastName);
                                            }}
                                        /></Cell>]
                                    : null}
                                <Cell span={12}>
                                    <SpaceTop override={theme.sizing.scale600}>
                                        <LabelLarge>Plan Details</LabelLarge>
                                    </SpaceTop>
                                </Cell>
                                <Cell span={12}>
                                    <ValidationNotification errorMsg={notificationMessage}/>
                                </Cell>
                                <Cell span={12}>
                                    <Table columns={orderSummaryData.tableColumns} data={orderSummaryData.tableData}/>
                                </Cell>
                                <Cell span={12}>
                                    <SpaceBottom>
                                        {billingInfo}
                                    </SpaceBottom>
                                    <SpaceAround>
                                        <div className={css({textAlign: 'end'})}>
                                            <PrimaryActionMenuButton className={css({backgroundColor: 'grey'})}
                                                                     onClick={() => {
                                                                         cancelTrigger({
                                                                             headers: [{
                                                                                 key: headerKeys.statefulProcessId,
                                                                                 value: statefulProcessIdAkaOrderId
                                                                             }]
                                                                         });
                                                                         location.assign(location.origin + '/#/pricing');
                                                                     }}>Back</PrimaryActionMenuButton>
                                            <PrimaryActionMenuButton style={{marginLeft: "20px"}}
                                                                     onClick={() => sendConfirmCommand(false, currencyCode, countryCode, postcode, firstName, lastName)}
                                                                     disabled={disabled}
                                            >
                                                {confirmButtonText}</PrimaryActionMenuButton>
                                        </div>
                                    </SpaceAround>
                                </Cell>
                            </Grid>
                        </SpaceAround>
                    </CenteredCard>
                </Frame>
            );


    }
}