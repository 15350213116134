import * as React from "react";
import config from "../soap/config";

/*  useIsMounted usage should be:  const x = useIsMounted() and then with the expression (x.current === true)
    You cannot capture .current it must be read every time.
    It's useful for avoiding calling setState or other effects after a component is already unloaded.
    This might happen for example on the completion of an async function. 
 */
export const useIsMounted = (callerId) => {
    
    const isMounted = React.useRef(false);
    
    React.useEffect(() => {
        
        if (config.debug.isMounted) {
            console.warn(`tell "${callerId ?? "unknown"}" that the component is mounted`);  
        }
        isMounted.current = true;
        
        return () => {
            //* called when component that uses the hook is unloaded
            if (config.debug.isMounted) {
                console.warn(`tell "${callerId ?? "unknown"}" that the component has been unmounted`);
            }
            isMounted.current = false;
        };
    }, []); //* runs only on mount and dismount due to empty array making the effect destructor a component destructor
    return isMounted;
};


export const useIsConfigLoaded = (callerId) => {

    /* always set default state to underlying store state, otherwise hooks initialised after the configIsLoaded would not get the updated state
    since any callback registered would never fire.
     */
    const [isLoaded, setIsLoaded] = React.useState(globalThis.Soap.configIsInitialised);
    const [callbackAdded, setCallbackAdded] = React.useState(false);
    const isMounted = useIsMounted(callerId);

    if (config.debug.configState) {
        console.warn(`useIsConfigLoaded in ["${callerId ?? "unknown"}"] at render`, JSON.stringify({
            isLoaded,
            callbackAdded,
            isMounted: isMounted.current
        }));
    }
    
    if (!callbackAdded && !isLoaded) {
        /* Not sure if there is any benefit to this block being in useEffect, I don't think so.
         */
        if (config.debug.configState) {
            console.warn(`Adding config onLoaded callback  ["${callerId ?? "unknown"}"]`);
        }
        /* because config.js is a normal js module, we can't use any react based notifications to capture
        changes to it. passing a callback is a simple way to subscribe to the change that doesn't involve react */
        globalThis.Soap.onLoadedCallbacks.push(configLoadedCallback);
        setCallbackAdded(true); //* no need to register more than one callback
    }
    
    return isLoaded;

    function configLoadedCallback() {
        if (config.debug.configState) {
            console.warn(`onLoaded callback started, checking isMounted ["${callerId ?? "unknown"}"]`, isMounted.current);  
        } 
        if (isMounted.current === true) {
            if (config.debug.configState) {
                console.warn(`setting configLoaded to true, should cause re-render ["${callerId ?? "unknown"}"]`);  
            } 
            setIsLoaded(true);
        }
    }
};
