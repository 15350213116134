﻿import {useStyletron} from "baseui";
import {useMediaQuery} from "react-responsive";


export const BaseWebBreakPoints = {
    First: 319,
    Second: 599,
    Third: 1135,
}

export function SpaceBottom({children, override}) {

    const [css, theme] = useStyletron();

    return (<div className={css({
        paddingBottom: override ?? theme.sizing.scale600
    })}>{children}
    </div>);
}

export function SpaceTop({children, override}) {
    
    const [css, theme] = useStyletron();

    return (<div className={css({
        paddingTop: override ?? theme.sizing.scale600
    })}>{children}
    </div>);
}

export function RightAlign({children}) {
    
    const [css, theme] = useStyletron();

    return (<div className={css({
        display: "flex",
        justifyContent: "flex-end"
    })}>{children}
    </div>);
}

export function SpaceTopAndBottom({children, override}) {

    const [css, theme] = useStyletron();

    return (<div className={css({
        paddingTop: override ?? theme.sizing.scale600,
        paddingBottom: override ?? theme.sizing.scale600
    })}>{children}
    </div>);
}


export function DesktopOnly({children}) {

    const [css, theme] = useStyletron();
    
    const isDesktop= useMediaQuery({query: `(min-width: ${BaseWebBreakPoints.Third}px)`})

    return (isDesktop ? <>{children}</> : null);
}


export function MobileOnly({children}) {

    const [css, theme] = useStyletron();
    
    const isMobile = useMediaQuery({query: `(max-width: ${BaseWebBreakPoints.Third}px)`})

    return (isMobile ? <>{children}</> : null);
}


export function MinWidthIfNotMobile({children, minWidth = 400}) {

    const [css, theme] = useStyletron();
    
    const isNotMobile = useMediaQuery({query: `(min-width: ${BaseWebBreakPoints.Second}px)`})

    return (<div className={css({
        minWidth: isNotMobile ? `${minWidth}px` : undefined
    })}>{children}
    </div>);
}


export function FullWidth({children}) {

    const [css, theme] = useStyletron();

    return (<div 
                 className={css({
                     width:'100%'
                 })}>{children}
    </div>);
}

export function MaxWidth({children, maxWidth = 0}) {

    const [css, theme] = useStyletron();

    return (<div  
     className={css({
        maxWidth: `${maxWidth}px`
    })}>{children}
    </div>);
}

export function MinWidth({children, minWidth = 0}) {

    const [css, theme] = useStyletron();

    return (<div
        className={css({
            minWidth: `${minWidth}px`
        })}>{children}
    </div>);
}



export function SpaceAround(props) {
    const {children, override} = props;

    return (<SpaceLeftAndRight override={override}><SpaceTopAndBottom override={override}>{children}</SpaceTopAndBottom></SpaceLeftAndRight>);
}

export function SpaceLeft( {children, override}) {

    const [css, theme] = useStyletron();

    return (<div className={css({
        paddingLeft: override ?? theme.sizing.scale600
    })}>{children}</div>);
}

export function SpaceRight( {children, override}) {

    const [css, theme] = useStyletron();

    return (<div className={css({
        paddingRight: override ?? theme.sizing.scale600
    })}>{children}</div>);
}

export function SpaceLeftAndRight({children, override}) {

    const [css, theme] = useStyletron();

    return (<div className={css({
        paddingLeft: override ?? theme.sizing.scale600,
        paddingRight: override ?? theme.sizing.scale600
    })}>{children}</div>);
}

export function Centered({children, centerText}) {
    
    const [css, theme] = useStyletron();
    
    return (
        <div className={css({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: centerText ? "center" : undefined
        })}
        >{children}</div>
    );
}

export function HScroll({children}) {

    const [css, theme] = useStyletron();

    return (
        <div className={css({
            overflowX:"auto"
        })}
        >{children}</div>
    );
}