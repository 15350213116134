﻿import {Input, SIZE, StatefulInput} from "baseui/input";
import {useState} from "react";
import {useCommandValidation} from "@soap/modules";


export function InlineEditInput({initialValue, buildCommand, afterCommandValidation, type, min, max}) {
    const {validateCommand} = useCommandValidation();
    const [error, setError] = useState('');
    const [value, setValue] = useState(!initialValue ? '' : initialValue);
    return (<><Input
        type={type}
        min={min}
        max={max}
        onChange={(e) => {
            setValue(e.target.value);
            setError('');
        }}
        onBlur={async (e) => {
            const command = buildCommand(e.target.value);
            if (!!command) {
                const messages = await validateCommand(command);
                if (!messages) {
                    setError('');
                    afterCommandValidation(command, value);
                } else if (messages.length > 0) {
                    setError(messages.join());
                }
               
            }
        }}
        error={error.length > 0}
        value={value}
        size={SIZE.compact}
        overrides={{
            Root: {
                style: ({$theme}) => ({maxWidth: "100px"})
            },
            Input: {
                style: ({ $theme }) => ({
                    backgroundColor: 'none'
                })
            }
        }}/>{error ? <>&nbsp;{error}</>: null}</>);
}
