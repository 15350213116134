import { initPlasmicLoader } from "@plasmicapp/loader-react";
import soapVars from "../soap-vars";

function getVersion() {
    const epk = soapVars.ENVIRONMENT_PARTITION_KEY;
    if (epk === "REL" || epk === "LIVE" || epk === "VNEXT") {
        return epk;
    } else {
        return undefined;
    }
}

function getPreview() {
    const epk = soapVars.ENVIRONMENT_PARTITION_KEY;
    if (epk === "REL" || epk === "LIVE" || epk === "VNEXT") {
        return false;
    } else {
        return true;
    }
}

export const PLASMIC = initPlasmicLoader({
    projects: [
        {
            id: "6U1vysuQHwywURGGrvxyxH",  // ID of a project you are using
            token: "BPSbcX41g6Hur2b9G3iQaD2A4kytMsvyad7FjB5jxmLoObaKzUl3JUOAQsNTJNciA3vvWfAqGw24m6EZaQ",  // API token for that project
            version: getVersion()
        }
    ],
    // Fetches the latest revisions, whether or not they are published!
    preview: getPreview()
})