﻿    import {useStyletron} from "baseui";
import {SpaceTopAndBottom} from "./layout";
import {LabelSmall} from "baseui/typography";
import {SIZE, StyledDivider} from "baseui/divider";
import * as React from "react";

export function LineItem({children, noDivider}) {
    const [css, theme] = useStyletron();

    return (
        <div>
            <SpaceTopAndBottom override={"10px"}>
                <LabelSmall>
                    {children}
                </LabelSmall>
            </SpaceTopAndBottom>
            {noDivider ? null : <StyledDivider $size={SIZE.cell}/>}

        </div>
    );
}