﻿import {ActionDrawer, bus, CenterSpinner, config, PrimaryActionMenuButton, useAuth, useLazyQuery, translate} from "@soap/modules";
import React, {useEffect, useState} from "react";
import {Centered, SpaceTopAndBottom} from "../../z40-uicomp/layout";
import {CenteredColumn} from "../../z40-uicomp/centered-column";

function Content({candidateId}) {
    
    const viewStates = {
        loading: 1,
        showLink: 2
    };

    const [trigger, result] = useLazyQuery('C230v1_GetCandidateDataExport', {
        C230_CandidateId: candidateId
    });
    const {idToken} = useAuth();

    useEffect(() => {
        if (result) {
            setViewState(viewStates.showLink);
        }
    }, [result]);

    const [viewState, setViewState] = useState(viewStates.loading);

    switch (viewState) {
        case viewStates.loading:
            return <CenterSpinner/>;
        case viewStates.showLink:
            const blobId = result.e184_BlobId;
            const fileName = result.e184_Filename;
            const url = `${config.vars.functionAppRoot}/GetBlob?id=${blobId}&filename=${fileName}&it=${idToken}`;
            return (<Centered>
                <SpaceTopAndBottom>
                    <a href={url}>{fileName}</a>
                </SpaceTopAndBottom>
            </Centered>);
    }
}

export function DownloadCandidateData({candidateId}) {
    //* whole issue with loading when the menu loads is that the trigger must be inside the actiondrawer
    return (
        <ActionDrawer menuText={translate("Export Data")} drawerHeader={translate("Export This Candidate's Data")} auth={true}>
            <p>This is a GDPR-compliant export of all the data in our system for this candidate.</p>
            <SpaceTopAndBottom>
                <Content candidateId={candidateId}/>
            </SpaceTopAndBottom>
            <PrimaryActionMenuButton onClick={() => bus.closeAllDialogs()}>Close</PrimaryActionMenuButton>
        </ActionDrawer>
    );
}

