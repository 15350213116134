import {useEffect, useMemo} from "react";
import {componentViewMode} from "../z50-domaincomp/constants/component-view-mode";
import {appQueryParams} from "./appQueryParams";
import {optional, types, validateArgs} from "@soap/modules";

export function useQueryString() {
    const location = window.location;
    const search = (id) => {
        return new URLSearchParams(location.search).get(id);
    };
    
    return useMemo(() => {
        const viewMode = search(appQueryParams.componentViewMode);
        return ({
            search,
            componentViewMode: viewMode ? Number(viewMode) : componentViewMode.Website
        });
    }, [location]);
}

export function useQueryResponse(queryResponse, responseAction) {

    validateArgs(
        [{queryResponse}, types.object, optional],
        [{responseAction}, types.function],
    );
    
    useEffect(() => {
        if (queryResponse) {
            responseAction(queryResponse);
        }
    }, [queryResponse]);
}

