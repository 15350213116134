import {PLACEMENT, StatefulPopover} from "baseui/popover";
import {Button, KIND, SIZE} from "baseui/button";
import React, {useRef} from 'react';
import bus from "../soap/bus";
import {optional, types, uuidv4, validateArgs} from "../soap/util";
import {useIsMounted} from "../hooks/systemStateHooks";


export function AggregateListSecondaryActionsMenu(props) {

    const {propertyKey, entityMenus, entity} = props;

    validateArgs(
        [{propertyKey}, types.string],
        [{entityMenus}, types.object, optional],
        [{entity}, types.object, optional]
    );

    if (entityMenus && entityMenus[propertyKey]?.actions) {
        const actions = entityMenus[propertyKey]?.actions;

        if (actions.some(action => !!action(entity))) {
            return (<SecondaryActionMenuItem>
                {
                    //* array of actiondrawers (i.e. buttons with attached associated drawers)
                    actions.map(action => <div key={uuidv4()}>{action(entity) ?? null}</div>)
                }
            </SecondaryActionMenuItem>);    
        } else {
            return null;
        }
    } else {
        return null;
    }
}

export const PrimaryActionMenuButton = (props) => <Button kind={KIND.primary} size={SIZE.compact} {...props} />;

export function PrimaryActionMenuItem(props) {
    const {buttonText = "\u2630"} = props;
    return (
        <ActionMenuItem children={props.children}
                        button={<Button kind={KIND.primary} size={SIZE.compact}>{buttonText}</Button>}/>
    );
}

export const SecondaryActionMenuButton = (props) => <Button kind={KIND.secondary} size={SIZE.compact} {...props} />;

export function SecondaryActionMenuItem(props) {
    const {buttonText = "\uFE19"} = props;
    return (
        <ActionMenuItem children={props.children}
                        button={<Button kind={KIND.secondary} size={SIZE.compact}>{buttonText}</Button>}/>
    );
}


export const ActionMenuItem = (props) => {

    const {children, button} = props;
    const sub = useRef();
    const isMounted = useIsMounted("ActionMenuItem");
    return (
        children ?
            <StatefulPopover
                dismissOnClickOutside={true}
                placement={PLACEMENT.right}
                overrides={{
                    Body: {
                        style: ({$theme}) => ({
                            boxShadow: 'none',
                            border: "1px solid darkgrey"
                        }),

                    },
                    Inner: {
                        style: ({$theme}) => ({
                            backgroundColor: 'white'
                        })
                    }
                }}
                content={({close}) => {

                    //* unsub from previous render's sub
                    if (!!sub.current) {
                        sub.current.unsubscribe();
                    }

                    //* the close method pass into content is different on every render so we need to re-capture it
                    sub.current = bus.onCloseAllDialogs(() => {                         
                            //* runs async sometimes it's unmounted
                            if (isMounted.current === true) {
                                close();
                            }
                            
                            //* clear previous sub
                            sub.current.unsubscribe();
                        }
                    );

                    return <div>{children}</div>
                }}
            >
                {button}
            </StatefulPopover> : null
    );
}
