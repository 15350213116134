﻿import {ActionModal, translate} from "@soap/modules";
import React from "react";
import {CenteredColumn} from "../../z40-uicomp/centered-column";
import {DialogListItem} from "../../z40-uicomp/dialog-list-item";
import {CheckedListItem} from "../../z40-uicomp/checked-list-item";


export function DeleteQuestion(props) {
    const {questionId, questionName} = props;
    return (
        <ActionModal menuText={translate("Delete")} modalHeader={translate("Delete Question")} auth={true}
                     command={{
                         $type: 'C228v1_DeleteQuestion',
                         C228_QuestionId: questionId
                     }}>
            <p>{`${translate("This action cannot be undone")}.`}</p>
            <p>{translate("Please confirm you want to delete the Question")} <strong>{questionName}</strong>?</p>
            <br/>
            <CenteredColumn>
                <ul>
                    <DialogListItem>{translate("Deleted Questions")}
                        <ul>
                            <CheckedListItem>{translate("are removed from any Assessments where they are referenced")}</CheckedListItem>
                            <CheckedListItem>{translate("are not removed from Assessment Results")}</CheckedListItem>
                        </ul>
                    </DialogListItem>
                    <DialogListItem>{translate("If you wish only to hide the Question, use Archive instead")}</DialogListItem>
                </ul>
            </CenteredColumn>
        </ActionModal>

    );
}
