﻿import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {
    CenterSpinner,
    translate,
    useApiErrors,
    useAuth,
    useEvent,
    useLazyCommand,
    useLogin,
    useTokenRefreshRequired,
    ValidationNotification
} from '@soap/modules'
import {CenteredCard} from "../z40-uicomp/centered-card";
import {Frame} from "../z40-uicomp/frame";
import {Centered, SpaceTop} from "../z40-uicomp/layout";
import {useStyletron} from "baseui";
import {useParams} from "react-router-dom";
import {refreshTokens} from "../modules/jwt-helpers";


export function AcceptInvitation2({}) {

    //* styling 
    const [css, theme] = useStyletron();

    const {invitationId} = useParams();

    const viewStates = {
        loading: 0,
        creatingAccount: 1, 
        invitationIdNotValid: 2
    };

    
    const [acceptInvitationTrigger] = useLazyCommand("C238v1_AcceptInvitation");

    const [viewState, setViewState] = useState(invitationId ? viewStates.loading : viewStates.invitationIdNotValid);

    const {isAuthenticated, authReady, refresh, accessToken} = useAuth("accept-invitation2");

    useEffect(() => {

            if (authReady) {
                console.warn(1, "auth ready");
                if (!invitationId) {
                    setViewState(viewStates.invitationIdNotValid);
                } else if (viewState === viewStates.loading) {
                    console.warn(3, "starting signup");
                    setViewState(viewStates.creatingAccount);
                    acceptInvitationTrigger({
                        C238_InvitationId: invitationId
                    }); 
                }
            }
        }
        , [authReady, isAuthenticated, invitationId, acceptInvitationTrigger]);
    
    useEvent({
        eventName: "E191v1_AccountCreatedFromInvitation",
        onEventReceived: (e, v) => {
            (async function() {
                //* C238 adds roles and token needs refreshing
                console.warn("refreshing tokens", accessToken);
                await refresh({ignoreCache:true});
                console.warn("refreshing tokens", accessToken);
                location.href = "#/my-profile";    
            }());
        }
    }, [refresh, accessToken]);

    useApiErrors((newError, errors) => {
        setViewState(viewStates.invitationIdNotValid);
    })

    switch (viewState) {
        case viewStates.loading:
            return (<Frame><CenterSpinner/></Frame>);
        case viewStates.creatingAccount:
            return (<Frame>
                <CenteredCard
                    title={<div style={{
                        width: "100%",
                        paddingLeft: "33px"
                    }}><Centered>Preparing your account</Centered>
                        <Centered>Please wait ...</Centered>
                        <SpaceTop>
                            <CenterSpinner/>
                        </SpaceTop>
                    </div>} showRobot={false}>
                </CenteredCard>
            </Frame>);
        case viewStates.invitationIdNotValid:
            return (<Frame>
                <CenteredCard>
                    <ValidationNotification errorMsg={translate("$linkInvalid")}/>
                </CenteredCard>
            </Frame>);
    }

}