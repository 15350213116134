import {useStyletron} from "baseui";
import {Button, KIND, SIZE} from "baseui/button";
import React from "react";

export const LinkButton = ({children, hover=false, colour, hoverColour, underline = true, onClick, $style}) => {
    const [css, theme] = useStyletron();
    return (<button className={css({
        ...$style,
        background: 'none',
        color: colour ?? theme.colors.contentPrimary,
        border: 'none',
        cursor: 'pointer',
        alignItems: "center",
        ":hover": hover ? {
            textDecoration: underline ? 'none' : undefined,    
            color: underline ? 'none' : hoverColour ?? theme.colors.contentSecondary,
        } : undefined,
        textDecoration: underline ? 'underline' : undefined,
    })} onClick={onClick}>{children}</button>);
}

export const ButtonPrimary = ({children, size, $style, ...props}) => {
    const [css, theme] = useStyletron();
    const style = {

        ...$style
    };
    return (
        <Button {...props}
                size={size ?? SIZE.default}
                kind={KIND.primary}
                style={style} //cant figure out why doesnt apply if you use styletron css()
        >
            {children}
        </Button>
    );
};

export const ButtonSecondary = ({children, size, $style, ...props}) => {
    const [css, theme] = useStyletron();
    const style = {
        borderRadius: theme.borders.radius300,
        ...$style
    };
    return (
        <Button {...props}
                size={size ?? SIZE.compact}
                kind={KIND.secondary}
                style={style} //cant figure out why doesnt apply if you use styletron css()
        >
            {children}
        </Button>
    );
};

export const ButtonTertiary = ({children, size, $style, ...props}) => {
    const [css, theme] = useStyletron();
    const style = {
        borderRadius: theme.borders.radius300,
        ...$style
    };
    return (
        <Button {...props}
                size={size ?? SIZE.mini}
                kind={KIND.tertiary}
                style={style} //cant figure out why doesnt apply if you use styletron css()
        >
            {children}
        </Button>
    );
};

