import postal from 'postal';
import React, {useEffect} from 'react';
import bus from '../soap/bus';
import {optional, types, validateArgs} from "../soap/util";
import config from "../soap/config";
import {toTypeName} from "../soap/messages";
import {useIsConfigLoaded} from "./systemStateHooks";
import {useAuth} from "./useAuth.js";


export function useEvent(
    args
) {
    //* check args
    let { eventName, onEventReceived, conversationId, channel = bus.channels.events } = args;
    
    validateArgs(
        [{eventName}, types.string],
        [{channel}, types.string],
        [{conversationId}, types.string, optional],
        [{onEventReceived}, types.function]
    );

    //* get additional state
    const hookName = "useEvent";
    const configLoaded = useIsConfigLoaded(hookName);
    const { authReady } = useAuth(hookName);
    
    /* we don't want to call bus.subscribe every render, but we do want the latest handler so store it in a Ref
    that can be accessed from the closure which subscribes */
    const callback = React.useRef(onEventReceived);
    callback.current = onEventReceived;
    
    //* define dependencies; include configLoaded and authReady by default in the depArray so callers don't have to pass them
    const depArray = [conversationId, channel, configLoaded, authReady];
    
    useEffect(() => {
        
        if (configLoaded && authReady) {
            
            /* 
            This waits for authReady because other hooks (e.g. useLazyQuery) which use this hook wait for them.
            If you wait only for configLoaded, you will subscribe temporarily to a version of onEventReceived where authReady is false.            
            That means any code in it which depends on authReady to be true to execute will not run as expected,
            why bother registering and then quickly disposing such unreliable callbacks.            
             */
            
            /* .split is because channel topic doesn't use assembly name, still worth running through toTypeName
            to verify schema and to allow use of short message names */
            const topic = toTypeName(eventName).split(',')[0]
            
            const sub = bus.subscribe(channel, topic, (event) => callback.current(event), conversationId);
            
            return function cleanup() {
                config.logger.log(`UNSUBSCRIBED to channel:${sub.channel}, topic:${sub.topic}`);
                if (!!sub) postal.unsubscribe(sub);
            };
        } 
    }, depArray);
}
