import {useCallback} from "react";
import {uuidv4} from "@soap/modules";

export function useReferrer() {
    
    const push = useCallback((referrer) => {
        const rid = uuidv4();
        sessionStorage.setItem("rfr|" + rid, referrer);
        return rid;
    },[]);
    
    const pop = useCallback((referrerId) => {
        try {
            const referrer = sessionStorage.getItem("rfr|" + referrerId);
            sessionStorage.removeItem(referrerId);
            return referrer;
        } catch {
            return undefined;
        }
    },[]);
    
    return {
        push,
        pop
    };
}