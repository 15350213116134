﻿import {ActionModal, translate} from "@soap/modules";
import React from "react";

export function RemoveAssessmentSection(props) {
    const {assessmentId, sectionId, sectionTitle} = props;
    
    return (
        <ActionModal title={translate("Remove") + " " + translate("Section")} auth={true}
                     command={{
                         $type: 'C191v1_RemoveAssessmentSection',
                         C191_AssessmentId: assessmentId,
                         C191_SectionId: sectionId
                     }}>
            <p>{`Please confirm you want to remove the section "${sectionTitle}" ?`}</p>
            <br/>
            <p>{translate("This will not remove the section from any previously shared assessments.")}</p>
        </ActionModal>
    );
}
