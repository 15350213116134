﻿import {ActionModal, translate} from "@soap/modules";
import React from "react";
import {CenteredColumn} from "../../z40-uicomp/centered-column";
import {DialogListItem} from "../../z40-uicomp/dialog-list-item";
import {CheckedListItem} from "../../z40-uicomp/checked-list-item";


export function RevokeInvite(props) {
    
    const {inviteId, email} = props;
    console.log(inviteId)
    return (
        <ActionModal menuText={translate("Revoke")} modalHeader={translate("Revoke User Invitation")} auth={true}
                     command={{
                         $type: 'C249v1_RevokeOrgUserInvitation',
                         C249_OrgUserInvitationId: inviteId
                     }}>
            <p>{translate("Please confirm you want to revoke this invitation")} <strong> {email}</strong>?</p>
        </ActionModal>

    );
}
