﻿import {CenteredCard} from "../../z40-uicomp/centered-card";
import {ProgressBar} from "baseui/progress-bar";
import {SpaceAround, SpaceBottom, SpaceRight, SpaceTop, SpaceTopAndBottom} from "../../z40-uicomp/layout";
import {HeadingMedium, HeadingSmall} from "baseui/typography";
import React from "react";

import {
    AggregateView,
    headerKeys,
    PrimaryActionMenuButton,
    setHeader,
    toTypeName,
    useDomainEvent, useEvent, useLazyCommand, useLazyQuery,
    ValidationNotification
} from "@soap/modules";
import {Button, KIND, SIZE} from "baseui/button";
import {useStyletron} from "baseui";
import {useQueryString} from "../../hooks/useQueryString";
import {useParams} from "react-router-dom";
import {useReferrer} from "../../hooks/useReferrer";


export function StepReview({statefulProcessId, questionTypeKey}) {

    const {referrer} = useQueryString();
    
    const backTrigger = useDomainEvent({
        eventName: "step-back",
        conversationId: "create-question"
    });

    const [css, theme] = useStyletron();
    
    const [c136Trigger] = useLazyCommand("C136v1_CreateQuestionReviewStep");

    const {referrerId} = useParams();
    const {pop} = useReferrer();
    
    useEvent({
        eventName: "E109v1_QuestionCreated",
        onEventReceived: (e, v) => {
            
            if (referrerId) {
                const referrer = pop(referrerId);
                if (referrer) {
                    return location.href = location.origin + referrer;
                }
            }
            return location.href = "#/questions/question-library";
        }
    });

    const [c137Trigger, e117] = useLazyQuery("C137v1_GetQuestionForReview", {
        C137_StatefulProcessId: statefulProcessId
    });
    
    let hiddenFields = undefined;
    
    switch (questionTypeKey) {
        case "multiple-choice":
        case "single-selection":
        case "specific-string":
            hiddenFields = ["e117_TrueLabel", "e117_FalseLabel", "e117_TrueOrFalse", "e117_CharacterLimit"];
            break;
        case "boolean":
            hiddenFields = ["e117_Options", "e117_CharacterLimit"];
            break;
        case "freetext":
            hiddenFields = ["e117_TrueLabel", "e117_FalseLabel", "e117_TrueOrFalse", "e117_Options"];
            break;
    }
    
    return (<CenteredCard  title={<HeadingMedium>Create Question</HeadingMedium>} showRobot={true}>
            <ProgressBar value={100}/>
            <SpaceAround>
                <SpaceBottom>
                    <HeadingSmall>Review Question</HeadingSmall>
                </SpaceBottom>
                <SpaceBottom> 
                    <AggregateView
                        expandedFields={["e117_Tags", "e117_Options"]}
                        headerColumns={["e117_OptionValue", "e117_IsCorrect"]}
                        labelRenderer={{
                            "e117_OptionValue": (v) => "Option",
                            "e117_IsCorrect": (v) => "T/F"
                        }}
                        aggregate={e117}
                        hiddenFields={hiddenFields}
                        displayMode={true}
                    />
                </SpaceBottom>
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}>
                        <SpaceRight override={"10px"}>
                            <PrimaryActionMenuButton
                                type="button"
                                onClick={() => backTrigger({stepNumber: 5})}>
                                Back
                            </PrimaryActionMenuButton>
                        </SpaceRight>
                        <PrimaryActionMenuButton
                            type="button"
                            onClick={() => {
                                // build command here
                                const command = {
                                    $type: toTypeName("C136v1_CreateQuestionReviewStep"),
                                    headers: [],
                                };
                                setHeader(command, headerKeys.statefulProcessId, statefulProcessId);
                                c136Trigger(command);    
                            }}>
                            Finish
                        </PrimaryActionMenuButton>
                    </div>
            </SpaceAround>
        </CenteredCard>
    );
}