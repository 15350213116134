﻿import {ActionModal, translate} from "@soap/modules";
import React from "react";
import {CenteredColumn} from "../../z40-uicomp/centered-column";


import {CheckedListItem} from "../../z40-uicomp/checked-list-item";
import {DialogListItem} from "../../z40-uicomp/dialog-list-item";

export function DeleteCandidate(props) {
    const {candidateId, candidateName} = props;
    return (
        <ActionModal menuText={translate("Delete")} modalHeader={translate("Delete Candidate")} auth={true}
                     command={{
                         $type: 'C229v1_DeleteCandidate',
                         C229_CandidateId: candidateId
                     }}>
            <p>{`${translate("This action cannot be undone")}.`}</p>
            <p>{translate("Please confirm you want to delete the candidate")} <strong>{candidateName}</strong>?</p>
            <p>{translate("This is a GDPR-compliant operation which will permanently destroy all data on file for this Candidate.")}</p>
            <br/>
            <CenteredColumn>
                <ul>
                    <DialogListItem>{translate("Deleting a Candidate will also delete")}
                        <ul>
                            <CheckedListItem>{translate("their Shared Assessment links")}</CheckedListItem>
                            <CheckedListItem>{translate("their Assessment Results")}</CheckedListItem>
                        </ul>
                    </DialogListItem>
                    <DialogListItem>{translate("If you wish to retain any of this candidates data, use Archive instead")}</DialogListItem>
                </ul>
            </CenteredColumn>
        </ActionModal>
    );
}

