﻿import {ActionModal, AutoForm, bus, translate} from "@soap/modules";
import React from "react";

export function RemoveTag(props) {
    const {tagId, tagName} = props;
    return (
        <ActionModal title={translate("Remove") + " " + translate("Tag")} auth={true}
                     command={{
                         $type: 'C122v1_RemoveTag',
                         C122_TagId: tagId
                     }}>
            <p>{`Please confirm you want to remove the tag "${tagName}" ?`}</p>
            <br/>
            <p>{translate("This will remove it from any associated items.")}</p>
        </ActionModal>
    );
}

