﻿import {AutoForm, headerKeys, PrimaryActionMenuButton, setHeader, translate, useDomainEvent} from "@soap/modules";
import {CenteredCard} from "../../z40-uicomp/centered-card";
import {ProgressBar} from "baseui/progress-bar";
import {SpaceAround, SpaceBottom, SpaceTop} from "../../z40-uicomp/layout";
import {HeadingMedium, HeadingSmall} from "baseui/typography";
import React from "react";

import {questionTypes} from "../../z50-domaincomp/question-types";

export function StepChooseQuestionType({statefulProcessId}) {

    const questionTypeSelectedTrigger = useDomainEvent({
        eventName: "question-type-selected",
        conversationId: "create-question"
    });
    const backTrigger = useDomainEvent({
        eventName: "step-back",
        conversationId: "create-question"
    });
    
    return (<CenteredCard  title={<HeadingMedium>Create Question</HeadingMedium>} showRobot={true}>
            <ProgressBar value={20}/>
            <SpaceAround>
                <SpaceBottom>
                    <HeadingSmall>Choose a Question Type</HeadingSmall>
                </SpaceBottom>
                <AutoForm
                    query={{
                        $type: "C135v1_GetC134FormData",
                        "C135_StatefulProcessId": statefulProcessId
                    }}
                    afterCancel={()=> {
                        backTrigger({
                            stepNumber:1
                        });
                    }}
                    cancelText={"Back"}
                    submitText={translate("Next")}
                />
            </SpaceAround>
        </CenteredCard>
    );
}