﻿import {useStyletron} from "baseui";
import React, {useCallback, useMemo} from "react";
import {timingModeKeys} from "../../z50-domaincomp/constants/timing-mode-keys";
import {SpaceBottom, SpaceTopAndBottom} from "../../z40-uicomp/layout";
import {HeadingMedium, HeadingSmall, LabelMedium, ParagraphMedium} from "baseui/typography";
import {ForwardLinkWithTitle, StyledForwardLinkWithTitle} from "../../z40-uicomp/forward-link-with-title";
import {viewStates} from "./view-states";
import {useFormatTimerString} from "../../hooks/useFormatTimerString";

export function Welcome({e170, setViewState, hasInstructions}) {
    
    const [css, theme] = useStyletron();
    const timingPhrase = useMemo(() => {
        if (e170) {
            const key = e170.e170_TimingModeKey;
            const phrases = {
                [timingModeKeys.PerSection]: "Each section is timed individually.",
                [timingModeKeys.PerQuestion]: "Each question is timed individually."
            };
            return phrases[key] || "";
        }
    }, [e170]);
    
    const {fromSeconds} = useFormatTimerString();
    const sectionCount = e170.e170_NumberOfSections;
    const questionCount = e170?.e170_NumberOfQuestions;
    const Paragraph = ({children}) => <SpaceBottom><ParagraphMedium>{children}</ParagraphMedium></SpaceBottom>;

    return (
        <>
            <SpaceTopAndBottom>
                    <HeadingSmall>{e170.e170_AssessmentTitle}</HeadingSmall><br/>
                <Paragraph>You are about to take an assessment. </Paragraph>

                <Paragraph>The assessment is made up of {sectionCount} section{sectionCount === 1 ? "" : "s"} with a total of {questionCount} question{questionCount === 1 ? "" : "s"}.</Paragraph>
                
                <Paragraph>This assessment is {timingModeKeys.NotTimed === e170.e170_TimingModeKey ? "not" : ""} timed. {e170.e170_TimingModeKey !== timingModeKeys.NotTimed ?
                        `You will have a maximum of ${fromSeconds(e170.e170_TimeLimit)} to complete the assessment.` : null} {timingPhrase}</Paragraph>
                
                {hasInstructions ?
                    <Paragraph>Further instructions have been provided by the assessment author on the following
                        page.</Paragraph> : null}
                <Paragraph>
                    Please ensure you have a stable internet connection as you will not be able to restart the
                    assessment if your connection fails.
                    Additionally, we recommend you complete the assessment using a mouse with a laptop or PC rather than
                    a tablet or mobile device for the most efficient user experience.
                </Paragraph>
            </SpaceTopAndBottom>
            <div className={css({
                display: "flex",
                justifyContent: "flex-end"
            })}>
                <StyledForwardLinkWithTitle title={"Continue"} onPress={() => {
                    setViewState(hasInstructions ? viewStates.showInstructions : viewStates.confirmCandidateDetails);
                }}/>
            </div>

        </>
    );
}