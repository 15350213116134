﻿import {ActionModal, translate} from "@soap/modules";
import * as React from "react";
import {sharedAssessmentTypes} from "../constants/shared-assessment-types";

export function ResendAssessmentLink({sharedAssessmentId, linkId}) {
    
    const title = translate("Invitation");

    return (
        <ActionModal title={translate("Resend") + " " + translate("Shared Assessment")} auth={true}
                     command={{
                         $type: 'C234v1_ResendAssessment',
                         C234_SharedAssessmentId: sharedAssessmentId,
                         C234_SharedAssessmentLinkId: linkId
                     }}>
            <p>{`Please confirm you want to resend this ${title}?`}</p>
        </ActionModal>
    );
}