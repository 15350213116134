﻿import {StepDefineOptions} from "../z60-pagecomp/create-question/step-define-options";
import {StepBooleanOptions} from "../z60-pagecomp/create-question/step-boolean-options";
import {StepFreeTextOptions} from "../z60-pagecomp/create-question/step-freetext-options";
import {EditQuestionDefineOptions} from "../z60-pagecomp/edit-question/edit-question-define-options";
import {EditQuestionBooleanOptions} from "../z60-pagecomp/edit-question/edit-question-boolean-options";
import {EditQuestionFreeTextOptions} from "../z60-pagecomp/edit-question/edit-question-free-text-options";
import {translate} from "@soap/modules";

export const questionTypes = {
    //TODO the strings below need translating. they cant use the translate function because its not loaded yet
    MultipleChoice: newQuestionType("multiple-choice", "Multiple Choice", true, false, StepDefineOptions, EditQuestionDefineOptions),
    Boolean: newQuestionType("boolean", "True/False", false, false, StepBooleanOptions, EditQuestionBooleanOptions),
    FreeText: newQuestionType("freetext", "Free Text", true, true, StepFreeTextOptions, EditQuestionFreeTextOptions),
    SingleSelection: newQuestionType("single-selection", "Select from a List", false, false, StepDefineOptions, EditQuestionDefineOptions),
    SpecificString: newQuestionType("specific-string", "Specific Value(s)", false, false, StepDefineOptions, EditQuestionDefineOptions),
};

function newQuestionType(key, label, allowPartialPoints, requiresManualMarking, customAddStep, customEditStep) {
    return Object.freeze({
        key: key,
        label: label,
        allowPartialPoints: allowPartialPoints,
        requiresManualMarking: requiresManualMarking,
        customAddStep: customAddStep,
        customEditStep: customEditStep
    });
}