import {useEffect, useState, useCallback } from 'react';
import bus from '../soap/bus';
import commandHandler from '../soap/command-handler';
import {useIsConfigLoaded} from "./systemStateHooks";
import {useAuth} from "./useAuth";
import config from "../soap/config";
import {toTypeName} from "../soap/messages";

export function useQuery({query, sendQuery = true, acceptableStalenessFactorInSeconds = 0}) {
        
    const hookName = "useQuery";
    const [queryResult, setQueryResult] = useState();
    const configLoaded = useIsConfigLoaded(hookName);
    const { authReady, authEnabled, refresh } = useAuth(hookName);
    const [queryRefreshIndex, setQueryRefreshIndex] = useState(0);
    
    useEffect(() => {

        if (config.debug.hooks) {
            const timeString = new Date().getTime().toString();
            console.warn(`status of ${hookName} at useQuery at ${timeString} `,
                JSON.stringify(
                    {
                        configLoaded,
                        authEnabled,
                        authReady,
                        sendQuery
                    }));
        }
        
        const onResponse = data => setQueryResult(data);
        
        if (configLoaded && authReady && sendQuery) {

            //convert from class short name to assembly qualified short name
            query.$type = toTypeName(query.$type); 
        
            if (!query.headers) {
                query.headers = [];
            }
            
            
            const conversationId = commandHandler.handle(
                    query,
                    onResponse,
                    acceptableStalenessFactorInSeconds,
                    refresh
                );
            
            
            if (conversationId) { //conversation id can be null if using a cached result
                
                return function cleanup() {
                    /*              
                    ConversationId is used to terminate the default subscription when CloseConversation is called
                    useEffect's cleanup behaviour will terminate each previous subscription when a new command is sent. In this case if you resend
                    using the same hook before the first command's response has been processed you won't process the first command's result.                
                     */

                    if (config.debug.hooks) console.warn(
                        `${hookName} destructor ran because one of dependencies changed or the component was ` +
                        "unmounted resulting in termination of any outstanding conversation.");

                    //* close conversation when component is unloaded or a new command is sent    
                    bus.closeConversation(conversationId);
                };
                
            }
            
        }
        /* we need the latest refresh but we don't want to trigger a resend of the command if it's already been sent.
        I think react will get the latest version of any dependent objects used in the useEffect closure even if
        they are not in the dependencies list it just won't get them until something that is in the list changes.
        Otherwise, i would have to store refresh in state and use a second useEffect to update it
        */
    }, [sendQuery, configLoaded, authReady, queryRefreshIndex, refresh]);
    
    const refreshQuery = useCallback(() => {
        setQueryRefreshIndex(index => index + 1);
        setQueryResult(null);
    }, []);
    
    return [queryResult, refreshQuery];
}