import {useCallback, useEffect, useState} from 'react';
import bus from '../soap/bus';
import commandHandler from '../soap/command-handler';
import {useIsConfigLoaded} from "./systemStateHooks";
import {useAuth} from "./useAuth";
import config from "../soap/config";
import {headerKeys, toTypeName} from "../soap/messages";
import {getHeader} from "../soap/util";

export function useLazyQuery(querySchema, initialQuery) {
    
    const hookName = "useLazyQuery";
    const configLoaded = useIsConfigLoaded(hookName);
    const { authReady, refresh, authEnabled } = useAuth(hookName);
    const [initialQueuedQuery, setInitialInitialQueuedQuery] = useState();
    const [queryResult, setQueryResult] = useState();

    if (config.debug.hooks) {
        const timeString = new Date().getTime().toString();
        console.warn(`status of ${hookName} at render at ${timeString}`,
            JSON.stringify(
                {
                    configLoaded,
                    authEnabled,
                    authReady,
                    initialQuery,
                    initialQueuedQuery,
                }));
    }
    
    const sendQuery = useCallback((commandProperties, acceptableStalenessFactorInSeconds = 0) => {

        if (config.debug.hooks) {
            const timeString = new Date().getTime().toString();
            console.warn(`status of ${hookName} at sendQuery callback at ${timeString}`,
                JSON.stringify(
                    {
                        configLoaded,
                        authEnabled,
                        authReady,
                        initialQuery,
                        initialQueuedQuery,
                    }));
        }
        
        
        //* initialQueuedQuery is only applicable when there is no initialQuery, otherwise it's ignored
        let command = commandProperties ?? initialQuery ?? initialQueuedQuery; 
        command = {...command, $type: querySchema};
        
        const onResponse = (event) => {
            const conversationId = getHeader(event, headerKeys.commandConversationId);
            bus.closeConversation(conversationId);
            setQueryResult(event);
        }

        if (configLoaded && authReady) {

            //convert from class short name to assembly qualified short name
            command.$type = toTypeName(command.$type);

            if (!command.headers) {
                command.headers = [];
            }

            const conversationId = commandHandler.handle(
                command,
                onResponse,
                acceptableStalenessFactorInSeconds,
                refresh
            );
            
            return conversationId;
        } else {
            /*  trying to send query before config or auth variables set to true
            this can happen e.g. if the trigger is called somewhere on each render.
            
            Ignoring these calls should not be a problem. configLoaded has to be true
            for commandHandler and toTypeName to work. technically you could call the trigger
            before authReady is true but I have never seem a legitimate case for that.
             */
        }

    }, [configLoaded, authReady, querySchema, initialQuery, initialQueuedQuery]);  
    
    //* send initialQuery after system is ready
    useEffect(() => {

        if (config.debug.hooks) {
            const timeString = new Date().getTime().toString();
            console.warn(`status of ${hookName} at useEffect at ${timeString}`,
                JSON.stringify(
                    {
                        configLoaded,
                        authEnabled,
                        authReady,
                        initialQuery,
                        initialQueuedQuery,
                    }));
        }
        
        if (configLoaded && authReady && (initialQuery || initialQueuedQuery)) 
            /* once everything has finished loading if there is an initialQuery provided, or if the user called the trigger before things we're fully 
            loaded then instruct the callback to send the appropriate message accordingly */  
        {
            sendQuery();
            setInitialInitialQueuedQuery(undefined);
        }
    }, [configLoaded, authReady]);

    const queueQuery = useCallback((commandProperties = {}) => {
        //* SEE LONG NOTE in useLazyCommand
        if (config.debug.hooks) {
            const timeString = new Date().getTime().toString();
            console.warn(`queueing query until authReady in ${hookName} at ${timeString}`)
        }
        
        setInitialInitialQueuedQuery(commandProperties);
    }, []);

    //* return trigger and result proxy for alternative calls
    return [authReady ? sendQuery : queueQuery, queryResult];
    
}