﻿import {PrimaryActionMenuButton, translate, useAuth, useDomainEvent, useLogin} from "@soap/modules";
import {useStyletron} from "baseui";
import React, {useState} from "react";
import {AppNavBar} from "baseui/app-nav-bar";
import {Overflow} from "baseui/icon";
import LogoAtWhite from "/z30-images/logo-at-white.png"

export function NavBar() {

    const {
        isAuthenticated,
        authEnabled,
        authReady,
    } = useAuth()

    return (authReady && authEnabled) ?
        (isAuthenticated ? <GetAuthenticatedNavBar/> : <GetNavBar/>)
        : <AppNavBar/>; //spacing
}

function LoginControls() {

    const maintenance = window.ATest.maintenance===true;
    const {
        logIn,
        logOut
    } = useLogin();

    const {
        authEnabled,
        authReady,
        isAuthenticated
    } = useAuth();
    return (
        <div style={{
            height: "100%",
            display: "flex",
            alignItems: "center"
        }}>
            <PrimaryActionMenuButton
                $style={{
                    paddingTop: "1px",
                    paddingBottom: "1px"
                }}
                disabled={maintenance}
                onClick={() => {
                        if (authReady && authEnabled) {
                            if (isAuthenticated) {
                                logOut();
                            } else {
                                logIn();
                            }
                        }
                }}> <img src={LogoAtWhite} height={35}/> {isAuthenticated ? "Logout" : "Log In"}
            </PrimaryActionMenuButton>
        </div>
    );
}


function GetAuthenticatedNavBar({version}) {
    /* WARNING CHANGING THIS FILE REQUIRES REMOVING PARCELCACHE and DIST, hot-reload AND refresh are not enough, no idea why */


    const [css, theme] = useStyletron();
    const [activeItem, setActiveItem] = useState();

    const triggerNavbarClicked = useDomainEvent({
        eventName: "navbar-clicked"
    });

    const {status} = useLogin();

    function extractString(str) {
        if (!str) return "";
        const regex = /(.+?)(?=@)|(.+)/; // Regex to capture string before @ or whole string if @ is not present
        const match = str.match(regex);
        if (match) {
            return match[1] || match[2]; // Return the part before @ if it exists, otherwise return the whole string
        } else {
            return ""; // Return empty string if no match is found
        }
    }
    
    return (
        <AppNavBar
            title={<LoginControls/>}
            username={extractString(status.userName)}
            userItems={[
                {icon: Overflow, label: translate("My Profile"), id: "my-profile", url: '#/my-profile'},
            ]}
            onUserItemSelect={item => {
                triggerNavbarClicked({
                    item
                });
                location.href = item.url;

            }}
            mainItems={[
                {
                    label: translate("Home"),
                    id: 'home',
                    url: '#/',
                    active: ('home' === activeItem),
                    level: 1
                },
                {
                    label: translate("Assessments"),
                    id: 'assessments',
                    active: ('assessments' === activeItem),
                    url: '#/assessments/assessment-library',
                    level: 1
                },
                {
                    label: translate("Questions"),
                    id: 'questions',
                    active: ('questions' === activeItem),
                    url: '#/questions/question-library',
                    level: 1
                },
                {
                    label: translate("Candidates"),
                    id: 'candidates',
                    active: ('candidates' === activeItem),
                    url: '#/candidates/candidate-library',
                    level: 1
                },
                {
                    label: translate("Our Plans"),
                    id: 'upgrade',
                    url: '#/pricing',
                    active: ('upgrade' === activeItem),
                    level: 1
                },
                {
                    label: translate("Help"),
                    id: 'help',
                    url: '#/help',
                    active: ('help' === activeItem),
                    level: 1
                }
            ]}
            onMainItemSelect={item => {
                triggerNavbarClicked({
                    item
                });
                location.href = item.url;
                setActiveItem(item.id);
            }}

            overrides={{

                SecondaryMenuContainer: {
                    style: ({$theme}) => ({
                        backgroundColor: '#eeeeee',
                        justifyContent: 'center',
                        borderRadius: "100px",
                        marginBottom: "10px !important"
                    })
                }
            }}
        />
    );
}


function GetNavBar({version}) {
    /* WARNING CHANGING THIS FILE REQUIRES REMOVING PARCELCACHE and DIST, hot-reload AND refresh are not enough, no idea why */


    const [css, theme] = useStyletron();
    const [activeItem, setActiveItem] = useState();


    const triggerNavbarClicked = useDomainEvent({
        eventName: "navbar-clicked"
    });

    return (
        <AppNavBar
            title={<LoginControls/>}

            mainItems={[
                {label: translate("Home"), id: 'home', url: '#/', active: ('home' === activeItem), level: 1},
                {
                    label: translate("Features"),
                    id: 'features',
                    url: '#/features',
                    active: ('features' === activeItem),
                    level: 1
                },
                {
                    label: translate("Mission"),
                    id: 'mission',
                    url: '#/mission',
                    active: ('mission' === activeItem),
                    level: 1
                },
                {
                    label: translate("Our Plans"),
                    id: 'pricing',
                    url: '#/pricing',
                    active: ('pricing' === activeItem),
                    level: 1
                },
                {label: translate("Help"), id: 'help', url: '#/help', active: ('help' === activeItem), level: 1}
            ]}
            onMainItemSelect={item => {
                triggerNavbarClicked({
                    item
                });
                setActiveItem(item.id);
                location.href = item.url;
            }}
            overrides={{
                SecondaryMenuContainer: {
                    style: ({$theme}) => ({
                        backgroundColor: '#eeeeee',
                        justifyContent: 'center',
                        borderRadius: "100px",
                        marginBottom: "10px !important"
                    })
                }
            }}
        />
    );
}