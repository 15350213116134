import {withStyle} from "baseui";
import React from 'react';
import {Spinner} from "baseui/spinner";

export const CenterSpinner = withStyle(Spinner, {
  marginLeft: "auto",
  marginRight:"auto",
  marginTop:"10px",
  marginBottom:"10px"
});


