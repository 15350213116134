﻿import React, {useCallback, useEffect, useState} from "react";
import {StatefulSelect} from "baseui/select";
import {Radio, StatefulRadioGroup} from "baseui/radio";
import {LABEL_PLACEMENT, StatefulCheckbox} from "baseui/checkbox";
import {CenteredColumn} from "../../z40-uicomp/centered-column";
import {Centered, FullWidth, RightAlign, SpaceTop, SpaceTopAndBottom} from "../../z40-uicomp/layout";
import {
    DisplayXSmall,
    HeadingMedium,
    HeadingSmall,
    LabelLarge,
    ParagraphMedium,
    ParagraphSmall
} from "baseui/typography";
import {ImageBox, useImageFromBlobMeta} from "./image-from-blob-meta";
import {StyledDivider} from "baseui/divider";
import {CenterSpinner, PrimaryActionMenuButton, useLazyCommand} from "@soap/modules";
import {StatefulTextarea} from "baseui/textarea";
import {StatefulInput} from "baseui/input";
import {useStyletron} from "baseui";
import {timingModeKeys, timingModes} from "../../z50-domaincomp/constants/timing-mode-keys";
import {useFormatTimerString} from "../../hooks/useFormatTimerString";


function RadioAnswer({kvPairs, setAnswer}) {
    return <StatefulRadioGroup name="stateful"
                               onChange={e => {
                                   const value = e.currentTarget.value;
                                   setAnswer(value);
                               }}
    >
        {kvPairs.map(x => <Radio key={x.key} value={x.key}>{x.value}</Radio>)}
    </StatefulRadioGroup>;
}

function CheckboxAnswer({kvPairs, setAnswer}) {
    const [css, theme] = useStyletron();
    const [checkedKeys, setCheckedKeys] = useState([]);

    useEffect(() => {
        setAnswer(checkedKeys.join('|'));
    }, [checkedKeys]);

    return (<>
        <div>

            <ParagraphSmall className={css({
                marginBottom: "6px"
            })}>(tick all that apply)</ParagraphSmall>

            {kvPairs.map(x =>
                <StatefulCheckbox
                    key={x.key}
                    onChange={e => {
                        const key = x.key;
                        const add = e.target.checked;
                        if (add === true && !checkedKeys.includes(key)) {
                            setCheckedKeys([key, ...checkedKeys]);
                        } else {
                            setCheckedKeys(checkedKeys.filter(k => k !== key));
                        }
                    }}
                    labelPlacement={LABEL_PLACEMENT.right}
                >
                    {x.value}
                </StatefulCheckbox>)}
        </div>
    </>);
}

function TextAreaAnswer({setAnswer, maxLength}) {
    return <StatefulTextarea
        onChange={e => setAnswer(e.target.value)}
        placeholder="Answer Here"
        maxLength={maxLength}
    />;
}

function TextBoxAnswer({setAnswer, maxLength}) {
    return <StatefulInput
        onChange={e => setAnswer(e.target.value)}
        placeholder="Answer Here"
        maxLength={maxLength}
    />;
}

function SelectAnswer({kvPairs, setAnswer}) {
    return <StatefulSelect
        options={kvPairs}
        labelKey="value"
        valueKey="key"
        clearable={false}

        onChange={params => {
            const value = params.value[0].key;
            setAnswer(value);
        }}
        overrides={{
            IconsContainer: {
                style: ({$theme}) => ({
                    cursor: "pointer"
                })
            }
        }}
    />
}

function TimingHeader({timingModeKey, questionNumber, questionCount, timeLeft, sectionTitle}) {

    const {fromMilliseconds} = useFormatTimerString();
        
    switch (timingModeKey) {
        case timingModeKeys.NotTimed:
            return (<>
                <ParagraphMedium>Question {questionNumber} of {questionCount}</ParagraphMedium>
            </>);
        case timingModeKeys.PerSection:
            return (<>
                <ParagraphMedium> Time Remaining
                    in {timingModes[timingModeKey].replace('Per ', '')} {sectionTitle} </ParagraphMedium>
                <Centered><DisplayXSmall>{fromMilliseconds(timeLeft)}</DisplayXSmall></Centered>
                <ParagraphMedium>Question {questionNumber} of {questionCount}</ParagraphMedium>
            </>);
        case timingModeKeys.PerQuestion:
            return (<>

                <ParagraphMedium> Time Remaining
                    for this Question</ParagraphMedium>
                <Centered><HeadingSmall>{fromMilliseconds(timeLeft)}</HeadingSmall></Centered>
            </>);
        case timingModeKeys.PerAssessment:
            return (<>
                <ParagraphMedium> Time Remaining
                    in Assessment </ParagraphMedium>
                <Centered><HeadingMedium>{fromMilliseconds(timeLeft)}</HeadingMedium></Centered>
                <ParagraphMedium>Question {questionNumber} of {questionCount}</ParagraphMedium>
            </>);
    }
}

function QuestionForm({questionType, maxLength, setAnswer, kvPairs, questionId}) {
    const useRadio = ["boolean"];
    const useSelect = ["single-selection"];
    const useTextArea = ["freetext"];
    const useTextBox = ["specific-string"];
    const useCheckBoxes = ["multiple-choice"];
    
    let ctrl;
    if (useRadio.includes(questionType)) {
        ctrl = (<RadioAnswer kvPairs={kvPairs} key={questionId} setAnswer={setAnswer}/>);
    }
    if (useSelect.includes(questionType)) {
        ctrl = (<SelectAnswer kvPairs={kvPairs} key={questionId} setAnswer={setAnswer}/>);
    }
    if (useTextArea.includes(questionType)) {
        ctrl = (<TextAreaAnswer setAnswer={setAnswer} key={questionId} maxLength={maxLength}/>);
    }
    if (useTextBox.includes(questionType)) {
        ctrl = (<TextBoxAnswer setAnswer={setAnswer} maxLength={maxLength}/>);
    }
    if (useCheckBoxes.includes(questionType)) {
        ctrl = (<CheckboxAnswer kvPairs={kvPairs} setAnswer={setAnswer}/>);
    }
    return ctrl;
}

export function AnswerQuestion({e173, timeLeft, timerControls, dimensions = {maxWidth: 400, maxHeight: 768}}) {

    const viewStates = {
        loading: 1,
        showQuestion: 2
    };

    const [viewState, setViewState] = useState(viewStates.loading);
    
    const {start, pause, resume, reset} = timerControls;
    
    const [sendAnswer] = useLazyCommand('C215v1_SendAnswer');
    const [handleExpiredTimer] = useLazyCommand('C216v1_HandleAssessmentTimerExpired');
    
    const [blobMeta, setBlobMeta] = useState();
    const [answer, setAnswer] = useState();
    
    const enrichedBlob = useImageFromBlobMeta(blobMeta, e173?.e173_NextQuestionMedia?.e173_SasUrl);
    
    useEffect(() => {
        if (timeLeft === 0 && e173 && viewState === viewStates.showQuestion) {
            const {
                e173_NextQuestionId: questionId,
                e173_AssessmentResultId: assessmentResultId
            } = e173;
            handleExpiredTimer({
                C216_AssessmentResultId: assessmentResultId,
                C216_QuestionId: questionId
            });
        }
    }, [timeLeft])
    
    useEffect(() => {
        if (e173 && viewState === viewStates.loading) {
            
            const {
                e173_TimeInSeconds: timeInSeconds,
                e173_TimingModeKey: timingModeKey,
                e173_IsFirstQuestionInSection: firstQuestionInSection,
            } = e173;

            const questionHasImage = e173.e173_NextQuestionMedia?.e173_BlobMeta;

            if (questionHasImage && !enrichedBlob) {
                setBlobMeta(questionHasImage);
                return;
            }

            const isTimed = timingModeKey !== timingModeKeys.NotTimed;
            const shouldStartTimer = (timingModeKey === "per-question" ||
                (timingModeKey === "per-section" && firstQuestionInSection));
            const shouldResumeTimer = (timingModeKey === "per-section" && !firstQuestionInSection);
                
            if (isTimed) {
                if (shouldStartTimer) {
                    start(timeInSeconds * 1000);
                }
                if (shouldResumeTimer)
                {
                    resume();
                }
            }
            
            setViewState(viewStates.showQuestion);
        }
    }, [e173, enrichedBlob])

    switch (viewState) {
        case viewStates.loading:
            return <CenterSpinner/>;
        case viewStates.showQuestion:

            const {
                e173_NextQuestionPossibleAnswers: kvPairs,
                e173_NextQuestionId: questionId,
                e173_NextQuestionTypeKey: questionType,
                e173_QuestionNumber: questionNumber,
                e173_QuestionCount: questionCount,
                e173_SectionTitle: sectionTitle,
                e173_AssessmentResultId: assessmentResultId,
                e173_TimingModeKey: timingModeKey,
                e173_IsLastQuestionInTest: isLastQuestion,
                e173_MaxLength: maxLength
            } = e173;

            const caption = e173.e173_NextQuestionMedia?.e173_Caption;
            let questionText = e173.e173_NextQuestionText;
            if (!questionText.endsWith("?")) questionText += " ?";
            let buttonText = isLastQuestion ? "Finish" : "Continue";

            return (
                    <>
                    <CenteredColumn> 
                        <Centered>
                            <TimingHeader timingModeKey={timingModeKey} questionNumber={questionNumber} questionCount={questionCount} timeLeft={timeLeft} sectionTitle={sectionTitle} />
                            <SpaceTop>
                                <Centered>
                                    <ImageBox key={questionId} enrichedBlob={enrichedBlob} 
                                              caption={<Centered><ParagraphSmall>{caption}</ParagraphSmall></Centered>}/>
                                    <SpaceTopAndBottom override={"25px"}>
                                        <HeadingSmall>{questionText}</HeadingSmall>
                                    </SpaceTopAndBottom>
                                </Centered>
                            </SpaceTop>
                            <StyledDivider $style={{width: "100%"}}/>
                            <FullWidth>
                                <SpaceTopAndBottom>
                                    <Centered>
                                        <QuestionForm questionId={questionId} questionType={questionType} maxLength={maxLength} kvPairs={kvPairs} setAnswer={setAnswer} />
                                    </Centered>
                                </SpaceTopAndBottom>
                            </FullWidth>
                        </Centered>
                    </CenteredColumn>
                    <RightAlign>
                        <PrimaryActionMenuButton
                            disabled={!answer}
                            onClick={() => {
                                if (timingModeKey === "per-question") {
                                    pause();  //maybe provides a minor visual benefit 
                                }
                                sendAnswer({
                                    C215_AssessmentResultId: assessmentResultId,
                                    C215_QuestionId: questionId,
                                    C215_AnswerAsString: answer,
                                }); 
                            }}>{buttonText}</PrimaryActionMenuButton>
                    </RightAlign>
                </>
            );
    }
}