﻿import * as React from 'react';
import {useEffect, useState} from 'react';
import {CenterSpinner, translate, useAuth, useLogin, ValidationNotification} from '@soap/modules'
import {CenteredCard} from "../z40-uicomp/centered-card";
import {Frame} from "../z40-uicomp/frame";
import {useStyletron} from "baseui";
import {useParams} from "react-router-dom";


export function AcceptInvitation({}) {

    //* styling 
    const [css, theme] = useStyletron();

    const {invitationId} = useParams();

    const viewStates = {
        loading: 0,
        invalidId: 1
    };

    const [viewState, setViewState] = useState(viewStates.loading);

    const {
        signUp,
        logOut
    } = useLogin();

    const {isAuthenticated, authReady} = useAuth("accept-invitation");

    useEffect(() => {
        
        if (authReady) {
            console.warn(1, "auth ready");
            if (isAuthenticated) {
                console.warn(2, "authenticated already");
                logOut(location.href);
            } else {
                if (!invitationId) {
                    setViewState(viewStates.invalidId);
                } else {
                    console.warn(3, "starting signup");
                    signUp(location.href.replace('accept-invite', 'accept-invite2'), {
                        
                    });
                }    
            }
        }

    }, [authReady, isAuthenticated, invitationId, logOut, signUp]);

    switch (viewState) {
        case viewStates.loading:
            return <CenterSpinner/>;
        case viewStates.invalidId:
            return (<Frame>
                <CenteredCard>
                    <ValidationNotification errorMsg={translate("$linkInvalid")}/>
                </CenteredCard>
            </Frame>);
    }
}