﻿import * as React from 'react';
import {useStyletron} from "baseui";
import {CenteredColumn} from "../z40-uicomp/centered-column";
import {Frame} from "../z40-uicomp/frame";

import {AssessmentTable} from "../z50-domaincomp/assessment-table";
import {Card} from "baseui/card";

export function AssessmentLibrary() {

    const [css, theme] = useStyletron();
    
    return (<Frame>
        <CenteredColumn>
            <AssessmentTable/>
        </CenteredColumn>
    </Frame>);
}