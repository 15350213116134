﻿import * as React from 'react';
import {useEffect, useState} from 'react';
import {CenterSpinner, useAuth, ValidationNotification} from '@soap/modules'
import {CenteredCard} from "../z40-uicomp/centered-card";
import {Frame} from "../z40-uicomp/frame";
import {LabelMedium, ParagraphMedium} from "baseui/typography";
import {Centered, SpaceAround, SpaceBottom} from "../z40-uicomp/layout";
import {Cell, Grid} from "baseui/layout-grid";
import {useStyletron} from "baseui";
import {Table} from "baseui/table-semantic";
import {TalkBadge} from "../z40-uicomp/talk-badge";
import {LinkButton} from "../z40-uicomp/buttons";
import {useParams} from "react-router-dom";

export function OrderConfirmation() {

    //* styling 
    const [css, theme] = useStyletron();
    const [planData, setPlanData] = useState();
    const viewStates = {
        loading: 1,
        loaded: 3,
        failed: 4
    };

    const [viewState, setViewState] = useState(viewStates.loading);
    const {id} = useParams();
    const {refresh} = useAuth("order-confirmation");


    useEffect(() => {

        // declare the data fetching function
        const refreshToGetNewPermissions = async () => {
            await refresh({ignoreCache: true});
            setViewState(viewStates.loaded);
        };

        const loadedOnce = sessionStorage.getItem("oc-loaded-once");
        if (viewState === viewStates.loading) {
            if (!loadedOnce) {
                sessionStorage.setItem("oc-loaded-once", "true");
                refreshToGetNewPermissions()
                    // make sure to catch any error
                    .catch(console.error);
            } else {
                setViewState(viewStates.loaded);
            }
        }

        if (viewState === viewStates.loaded) {
            const planJSON = sessionStorage.getItem(id);
            if (!planJSON) {
                setViewState(viewStates.failed);
            }

            if (planJSON) {
                const plan = JSON.parse(planJSON);
                //* convert plan to planData making any adjustments along the way
                const row = plan.tableData.find(x => x[0].startsWith("Tag Limit"));
                const cloned = JSON.parse(JSON.stringify(plan));
                if (row && row[1] === 999) {
                    cloned.tableData.find(x => x[0].startsWith("Tag Limit"))[1] = "Unlimited";
                    setPlanData(cloned);

                } else {
                    setPlanData(cloned);
                }
            }
        }

    }, [refresh, viewState]);

    switch (viewState) {
        case viewStates.failed:
            return (
                <Frame>
                    <CenteredCard>
                        <SpaceAround>
                            <ValidationNotification
                                errorMsg={"An error has occurred confirming your order. Please check your profile or contact support"}/>
                        </SpaceAround>
                    </CenteredCard>
                </Frame>
            );
        case viewStates.loading:
            //* refresh token to pickup any new paid features e.g. organisations
            return <Frame><CenterSpinner/></Frame>;
        case viewStates.loaded:
            return (
                <Frame>
                    <CenteredCard
                        title={<div style={{
                            width: "100%",
                            paddingLeft: "33px"
                        }}>
                            <Centered>Thank You</Centered></div>} showRobot={false}>
                        <SpaceAround>
                            {planData ?
                                <Grid gridMargins={0} gridGaps={5}>
                                    <Cell span={12}>
                                        <><SpaceBottom>
                                            <Centered><LabelMedium>You are now
                                                on {planData.name}!</LabelMedium></Centered></SpaceBottom>
                                        </>
                                        <SpaceBottom>
                                            <TalkBadge showRobot={true}>
                                                <ParagraphMedium> 
                                                    What Next? <br/>
                                                    Are you a new user?
                                                    <li><LinkButton
                                                        onClick={() => location.href = '#/questions/question-library/add-question'}>Create
                                                        a Question</LinkButton></li>
                                                    <li>Then <LinkButton
                                                        onClick={() => location.href = '#/assessments/assessment-library/create-assessment'}>Create
                                                        an Assessment</LinkButton></li>
                                                    <li>Finally <LinkButton
                                                        onClick={() => location.href = '#/assessments/assessment-library/share-assessment'}>Share the Assessment</LinkButton></li>
                                                </ParagraphMedium>

                                            </TalkBadge>
                                        </SpaceBottom>
                                        <Table columns={planData.tableColumns} data={planData.tableData}/>
                                    </Cell>
                                </Grid> : null}
                        </SpaceAround>
                    </CenteredCard>
                </Frame>
            );
    }
}