import {Frame} from "../z40-uicomp/frame";
import {CenteredCard} from "../z40-uicomp/centered-card";
import * as React from "react";
import {EnzuzoContent} from "../z40-uicomp/enzuzo-content";
import logo from "../z30-images/logo-xl.png";
import {Centered, SpaceTopAndBottom} from "../z40-uicomp/layout";
export function PrivacyPolicy() {


    return (
        <Frame>
            <CenteredCard maxWidth={800} showRobot={false}>
                <SpaceTopAndBottom>
                <Centered>
                <img src={logo} width={200} />
                    
                </Centered>
                </SpaceTopAndBottom>
                <EnzuzoContent enzuzoUrl={"https://app.enzuzo.com/scripts/privacy/e7528b5e-956f-11ee-bc02-2f5e817acc12"} />
            </CenteredCard>
        </Frame>
    );
}