﻿import forwardIcon from "../z20-icons/fwd-icon.svg";
import React from "react";
import {ParagraphSmall} from "baseui/typography";


export function StyledForwardLinkWithTitle({onPress, title}) {
    return (<ForwardLinkWithTitle onPress={onPress} title={<ParagraphSmall style={{paddingTop:"4px"}}>{title}</ParagraphSmall>} />);
}

export function ForwardLinkWithTitle({title, onPress}) {
    return (<div style={{
            display: "flex",
            alignItems: "center"
        }}>
            <button onClick={onPress} style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                alignItems: "center",
                display: "flex"
            }}>
                <img src={forwardIcon} height={"32px"} width={"32px"} style={{marginRight: "8px"}}/>
                {title}
            </button>

        </div>
    );
}


