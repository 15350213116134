﻿import {Button, KIND, SIZE} from "baseui/button";
import React from "react";
import {ActionMenuItem} from "./ActionMenu";
import {optional, types, uuidv4, validateArgs} from "../soap/util";

export const ViewLinkButton = (props) => <Button kind={KIND.tertiary} size={SIZE.compact} {...props} />;

export function ViewLink(props) {
    const {buttonText = "\uD83D\uDC41"} = props;
    return (
        <ActionMenuItem children={props.children} button={<Button kind={KIND.secondary} size={SIZE.compact} overrides={{
            BaseButton: {
                style: {
                    fontSize: "x-large",
                    paddingLeft: "5px",
                    paddingRight: "5px"
                }
            }
        }}>{buttonText}</Button>} overrides={{
            Body: {
                style: {
                    borderWidth: "1px"
                }
            }
        }}/>
    );
}

export function CreateViewButtonForAggregateList(propertyKey, entityMenus, entity) {

    validateArgs(
        [{propertyKey}, types.string],
        [{entityMenus}, types.object, optional],
        [{entity}, types.object, optional]
    );

    if (entityMenus && entityMenus[propertyKey]?.viewAction) {  //* 1. setting null for a non-array viewaction will catch here and render nothing
        const viewAction = entityMenus[propertyKey]?.viewAction;

        if (viewAction instanceof Array) {
            /* when it's an array we should be able to test the resolution to see if it returns null/undefined
            , and in this case skip it, because it will need to return a menu item not the result */ 
            if (viewAction.some(action => !!action(entity))) {
                return (<ViewLink>
                    {viewAction.map((action,i) => <div style={{
                        display:"grid"
                    }} key={i}>{action(entity)}</div>)}
                </ViewLink>);    
            } else {
                return null;
            }
        } else { //* 1. that is why we don't need to test it here
            return (<Button kind={KIND.tertiary} size={SIZE.compact} overrides={{
                BaseButton: {
                    style: {
                        fontSize: "x-large",
                        paddingLeft: "5px",
                        paddingRight: "5px"
                    }
                }
            }}
                            onClick={() => viewAction(entity)}>{"\uD83D\uDC41"}</Button>);
        }
    } else {
        return null;
    }
}