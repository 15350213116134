﻿import * as React from 'react';
import {useStyletron} from "baseui";

import {
    AggregateList,
    EntityMenu,
    SecondaryActionMenuButton,
    translate,
    types,
    useEvent,
    useLazyQuery,
    validateArgs
} from "@soap/modules";
import {AddTag} from "./drawers/add-tag";
import {RemoveTag} from "./modals/remove-tag";
import {EditTag} from "./drawers/edit-tag";
import {StatefulPanel} from "baseui/accordion";
import {Link} from "baseui/link/styled-components";
import {KIND} from "baseui/button";
import {BackLinkWithTitle, StyledBackLinkWithTitle} from "../z40-uicomp/back-link-with-title";
import {CenteredCard} from "../z40-uicomp/centered-card";


export function TagTable({tagTypeKey, tagTypeName}) {

    validateArgs(
        [{tagTypeKey}, types.string],
        [{tagTypeName}, types.string],
    );

    const [css, theme] = useStyletron();

    const [c119Trigger, e103] = useLazyQuery("C119v1_GetTags", {
        C119_TagTypes: {
            SelectedKeys: [tagTypeKey]
        }
    });

    useEvent({
        eventName: "E105v1_TagAdded",
        onEventReceived: (e, v) => {
            c119Trigger();
        }
    }, [c119Trigger]);

    useEvent({
        eventName: "E108v1_TagUpdated",
        onEventReceived: (e, v) => {
            c119Trigger();
        }
    },[c119Trigger]);

    useEvent({
        eventName: "E106v1_TagRemoved",
        onEventReceived: (e, v) => {
            c119Trigger();
        }
    },[c119Trigger]);

    return (
        <CenteredCard title={tagTypeName + " Tags"} onBack={() => location.href = `#/${tagTypeName}s/${tagTypeName}-library`} maxWidth={600}>
            <AggregateList
                aggregates={e103?.e103_Tags}
                headerColumns={["e103_Title"]}
                hiddenFields={["e103_LongId"]}
                rowColour={theme.colors.accent}
                entityMenus={{
                    
                    "root-individual": new EntityMenu(null, [
                        () => <AddTag tagTypeKey={tagTypeKey}/>,
                       ]),
                    "root-Items": new EntityMenu(null, [
                        (entity) => <RemoveTag tagName={entity.e103_Title} tagId={entity.e103_LongId} />,
                        (entity) => <EditTag  tagId={entity.e103_LongId} />
                    ]),
                }}
            />
        </CenteredCard>
    );
}