﻿import {ActionModal, translate} from "@soap/modules";
import React from "react";
import {DialogListItem} from "../../z40-uicomp/dialog-list-item";
import {CheckedListItem} from "../../z40-uicomp/checked-list-item";
import {CenteredColumn} from "../../z40-uicomp/centered-column";

export function DeleteAssessment(props) {
    const {assessmentId, assessmentTitle} = props;
    return (
        <ActionModal menuText={translate("Delete")} modalHeader={translate("Delete Assessment")} auth={true}
                     command={{
                         $type: 'C223v1_DeleteAssessment',
                         C223_AssessmentId: assessmentId
                     }}>
            <p>{`${translate("This action cannot be undone")}.`}</p>
            <p>{translate("Please confirm you want to Delete this assessment")} <strong>{assessmentTitle}</strong>?</p>
            <br/>
            <CenteredColumn>
                <DialogListItem>
                    {translate("Deleting an Assessment will")}
                    <ul>
                        <CheckedListItem>
                            {translate("remove all its Assessment Results permanently, including from a Candidates' Assessment history")}
                        </CheckedListItem>
                        <CheckedListItem>
                            {translate("remove all its Shares")}
                        </CheckedListItem>
                    </ul>
                </DialogListItem>
                <DialogListItem>
                    {translate("If you only wish to hide the Assessment from this list, use Archive instead")}
                </DialogListItem>
            </CenteredColumn>
        </ActionModal>
    );
}