﻿import {useStyletron} from "baseui";
import {Centered, SpaceTopAndBottom} from "../../z40-uicomp/layout";
import {HeadingMedium, HeadingSmall, ParagraphLarge, ParagraphMedium} from "baseui/typography";
import React, {useCallback, useEffect, useState} from "react";
import {CenterSpinner} from "@soap/modules";
import {useFormatTimerString} from "../../hooks/useFormatTimerString";

export function ViewSummary({e174, title}) {

    const [css, theme] = useStyletron();

    const viewStates = {
        loading: 1,
        loaded: 2
    };

    const [viewState, setViewState] = useState(viewStates.loading);

    const {fromSeconds} = useFormatTimerString();
    
    useEffect(() => {
        if (e174 && viewState === viewStates.loading) setViewState(viewStates.loaded);
    }, [e174]);

    switch (viewState) {
        case viewStates.loading:
            return <CenterSpinner/>;
        case viewStates.loaded:
            return (
                <>
                        <SpaceTopAndBottom>
                            <HeadingSmall>Thank you for completing the assessment</HeadingSmall><br/>
                            <Centered><HeadingSmall>{title}.</HeadingSmall></Centered>
                        </SpaceTopAndBottom>
                        <SpaceTopAndBottom>
                            <Centered>
                                <ParagraphLarge>Your results have been recorded.</ParagraphLarge><br/>
                                <ParagraphLarge>You
                                    completed {e174.e174_CompletedQuestions} of {e174.e174_TotalQuestions} questions
                                    in {fromSeconds(e174.e174_TotalCompletionTimeInSeconds)}.</ParagraphLarge><br/>
                                <ParagraphLarge>You may now close this browser window.</ParagraphLarge></Centered>
                        </SpaceTopAndBottom>
                </>
            );
    }
    
}