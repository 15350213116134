import React, {useEffect, useState} from "react";
import {useAuth} from "../hooks/useAuth";
import bus from "../soap/bus";
import {Button, KIND, SIZE} from "baseui/button";
import {optional, types, validateArgs} from "../soap/util";
import {DialogDrawer} from "./DialogDrawer";

export function ActionDrawer(props) {

    const {title, auth, children, viewHeight} = props;
    let {menuText, drawerHeader} = props;
    const {requireAuth} = useAuth("ActionDrawer");
    const [showDrawer, setShowDrawer] = useState(false);
    menuText = menuText ?? title;
    drawerHeader = drawerHeader ?? title;

    validateArgs(
        [{viewHeight}, types.number, optional],
        [{auth}, types.boolean, optional],
    );

    useEffect(() => {
        //* register to unmount the drawer if your children publish closeAllDialogs
        const sub = bus.onCloseAllDialogs(() => {
            setShowDrawer(false); //* unmounts children due to ternary below
        });
        return function cleanup() {
            sub.unsubscribe(); //* unregister on unmount
        }
    }, []); //* run on first render only

    
    return menuText ? (<>
        <Button 
            style={{width:"100%"}} //* fill menu width 
            size={SIZE.compact} kind={KIND.tertiary} onClick={() => {
            if (auth) {
                requireAuth(() => {
                    setShowDrawer(true);
                })
            } else {
                setShowDrawer(true);
            }
        }} children={menuText} />
        {showDrawer ? <DialogDrawer auth={auth} title={drawerHeader} viewHeight={viewHeight}>{children}</DialogDrawer> : null}
    </>) : null;
}



  