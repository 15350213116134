import {useCallback, useEffect, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import config from "../soap/config";
import {useIsConfigLoaded, useIsMounted} from "./systemStateHooks";
import {types, uuidv4, validateArgs} from "../soap/util";
import {useDomainEvent} from "./useDomainEvent";

/* TODO if you can use React Portal together with Context to avoid having
sending all these domain events outside of react in useAuthReady and useTokenUpdates
it might have a significant impact on performance   
 */


const addToDefaultGroupsAsync = (functionAppRoot, connectionId, idToken) => {
    if (config.debug.auth) {
        console.warn("adding to default groups", functionAppRoot, connectionId, idToken);
    }
    const endpoint = `${functionAppRoot}/AddToGroup?connectionId=${encodeURIComponent(connectionId)}&it=${idToken}`;
    //* don't wait
    fetch(endpoint);
}

const useAuthReady = (callerName) => {
    
    //* start with the correct value if ask for it
    const [localCache, updateLocalCache] = useState(globalThis.Soap.authReady);

    /* the function returned by publishChange is a domain event trigger which
     causes itself (the instance that triggered the event) and every other instance 
     with this hook to update as well
     */
    const updateAuthReady = useDomainEvent({
        eventName: "update authReady",
        channel: "internal",
        onEventReceived: e => {
            if (config.debug.auth) {
                const timeStamp = new Date().getTime().toString();
                console.warn(`received authReady update in instance owned by ${callerName} at ${timeStamp}`);
            }
            updateLocalCache(e.value);
    }});
    
    /* when a caller "sets the state" it will first update the global store
    and then notify everyone of the change, there will of course be a delay
    and the instance that made the change is not guaranteed to receive the update first
    */
    const updateStoreAndPublishChange = useCallback(() => {
        if (config.debug.auth) {
            const timeStamp = new Date().getTime().toString();
            console.warn(`triggering authReady update to true in instance owned by ${callerName} at ${timeStamp}`);
        }
        globalThis.Soap.authReady = true; //* update store
        updateAuthReady({value: true});
    }, [updateAuthReady]);
    
    return [localCache, updateStoreAndPublishChange];
}

const useTokenUpdates = (callerName) => {
    
    //* local reactive variable
    const [lastConversationId, setLastConversationId] = useState(null);
    
    const sendTokensUpdated = useDomainEvent({
        eventName: "tokens-updated",
        channel: "internal",
        onEventReceived: e => {
            const conversationId = e.conversationId;
            if (config.debug.auth) {
                const timeStamp = new Date().getTime().toString();
                console.warn(`received tokens-updated in instance owned by ${callerName} at ${timeStamp} with id ${conversationId}`);
            }
            //* force reactive update
            setLastConversationId(conversationId);
        }});
    
    return {
        sendTokensUpdated,
        tokenConversationId: lastConversationId
    };
};

/* Note: this hook does work outside Auth0Provider HOC, But the 
isLoading, isAuthenticated variables returned by the Auth0 hook will not update
after logging in, however that won't affect any of the variables we return
but it will be noticeable in the status log messages.
 */
export const useAuth = (callerName) => {

    const [authReady, setAuthReady] = useAuthReady(callerName);
    
    //* we are counting on if tokenConversationId changes that the new token values will be returned from this hook
    const {tokenConversationId, sendTokensUpdated} = useTokenUpdates(callerName);

    const {
        isLoading,
        isAuthenticated,
        getIdTokenClaims,
        getAccessTokenSilently,
        user,
        loginWithRedirect
    } = useAuth0();

    const configLoaded = useIsConfigLoaded(`UseAuth in ${callerName}`);
    const isMounted = useIsMounted(`UseAuth in ${callerName}`);
    //* there could a theoretical delay between raw config object and configLoaded so never rely on config object without configLoaded
    const authEnabledInConfig = configLoaded && !!config.auth0;

    
    const getTokens = useCallback(async (ready, ignoreCache) => {
        
        if (!authEnabledInConfig) {
            throw "You cannot access tokens unless authEnabled is true."
        }
        if (!ready) {
            throw "You cannot access tokens unless authReady is true."
        }
        if (!isAuthenticated) {
            throw "You cannot access tokens unless isAuthenticated is true."
        }
        
        if (config.auth0.tokenMode === "forceful") {
            //* if they are changed, that comes by calling setTokenForcefully
            return {
                tokensChanged: false,
                idToken: config.auth0.identityToken,
                accessToken: config.auth0.accessToken
            };
        }
        
        if (config.debug.auth) {
            console.warn(`checking for new tokens [ignoreCache: ${ignoreCache}] in useAuth instance owned by ${callerName}`);
        }
        
        const claims = await getIdTokenClaims();
        const id_token = claims.__raw;
        const access_token = await getAccessTokenSilently({
            cacheMode: ignoreCache === true ? "off" : "on"
        });
        const tokensChanged =
            config.auth0.identityToken !== id_token ||
            config.auth0.accessToken !== access_token;
        //* the change and the check need to stay together for concurrency sake
        if (tokensChanged) {
            const beforeId = config.auth0.identityToken;
            const beforeAccess = config.auth0.accessToken;
            config.auth0.identityToken = id_token;
            config.auth0.accessToken = access_token;
            if (config.debug.auth) {
                console.warn(`new tokens found: in useAuth instance owned by ${callerName}`, JSON.stringify({
                    idToken: beforeId,
                    id_token,
                    accessToken: beforeAccess,
                    access_token,
                }, null, 2));
                /* This update to the user's profile on the backend, which I thought was designed to change
                 access and id token in the same backend request, seems to trigger two separate token changes 
                 on the frontend. The first time refresh is called (e.g. in useRefreshRequired) the access token changes, then
                 the next time (e.g. when useQuery) is called (say in the parent of useRefreshRequired) the id token changes to pickup
                 the name change. Why they are not both updating on the first request to auth0 for new tokens I don't know 
                 as the backend change happen at the same time before the first request. It doesn't seem
                 an issue as both tokens are updated before the first query is resent after a token refresh is required
                 and its only the access token that really matters anyway.
                 */
            }
        }
        
        return {
            tokensChanged,
            idToken: id_token,
            accessToken: access_token
        };
            
    }, [configLoaded, authEnabledInConfig, isAuthenticated]);

    const setTokensForceFully = useCallback((identityToken, accessToken, userName) => {
        if (config.debug.auth) {
            console.warn(`setTokensForcefully called in instance owned by ${callerName}`, JSON.stringify({
                configLoaded,
                authEnabledInConfig,
                authReady,
            }, null, 2));
        }
        //* use immediate not local state
        if (configLoaded && !config.authReady) { 
            if (authEnabledInConfig) {
                config.auth0.isAuthenticated = true;
                config.auth0.tokenMode = "forceful";
                config.auth0.accessToken = accessToken;
                config.auth0.identityToken = identityToken;
                config.auth0.userName = userName;
                if (config.debug.auth) {
                    console.warn(`setting ready at callsite 3 in instance owned by ${callerName}`);
                }
                setAuthReady();
                //* because you could setTokensForcefully after the initial load, in which case it's like a refresh
                sendTokensUpdated({
                    conversationId: uuidv4()
                })
            } else {
                throw `cannot set tokens forcefully if authEnabled is false in instance owned by ${callerName}`;
            }
        }
        if (config.debug.auth) {
            console.warn(`end setTokensForcefully in instance owned by ${callerName}`, JSON.stringify({
                configLoaded,
                authEnabledInConfig,
                readyLocal: authReady,
                readyImmediate: config.authReady
            }, null, 2));
        }
    }, [configLoaded, authEnabledInConfig]);

    const refreshTokens = useCallback(async ({ignoreCache = false, conversationId = uuidv4()} = {}) => {
        if (config.debug.auth) {
            console.warn(`refresh tokens function called in instance owned by ${callerName}`, JSON.stringify({
                authEnabledInConfig,
                ignoreCache,
                conversationId,
                readyLocal: authReady,
                readyImmediate: config.authReady,
                idToken:  config.auth0.identityToken,
                accessToken: config.auth0.accessToken
            }, null, 2));
        }
        
        if (!authEnabledInConfig) throw `Refreshing tokens requires authEnabled to be true in instance owned by ${callerName}`;
        if (!authReady) throw `Refreshing tokens requires authReady to be true in instance owned by ${callerName}`;
        
        if (isAuthenticated) {
            //* since refresh is called by every command, even when not authenticated you cannot have a guard for that
            const {tokensChanged, idToken} = await getTokens(authReady, ignoreCache);

            if (tokensChanged) {
                
                if (config.debug.auth) {
                    console.warn(`tokens changed in instance owned by ${callerName}, triggering global update`);
                }
                //* force a reactive re-render in all instances based on the new config values
                sendTokensUpdated({
                    conversationId
                });

                addToDefaultGroupsAsync(config.vars.functionAppRoot, config.vars.signalRHubConnectionId, idToken);
            }
        }
        
    }, [configLoaded, authReady, getTokens, authEnabledInConfig, useTokenUpdates]);

    const requireAuth = useCallback((onAuthenticated) => {
        validateArgs([{onAuthenticated}, types.function]);
        if (authReady) {
            if (authEnabledInConfig) {
                if (isAuthenticated) {
                    onAuthenticated();
                } else {
                    //see auth0provider in app.jsx for where returnTo is used
                    loginWithRedirect({appState: {returnTo: window.location.href}});
                }
            } else { //* auth is disabled
                onAuthenticated();
            }
        }
    }, [authReady, authEnabledInConfig, isAuthenticated, loginWithRedirect]);

    if (config.debug.auth) {
        const timeStamp = new Date().getTime().toString();
        console.warn("status of useAuth owned by " + callerName + " at render " + timeStamp,
            JSON.stringify(
                {
                    configLoaded,
                    isLoadingAuth0: isLoading,
                    isAuthenticatedAuth0: isAuthenticated,
                    isAuthenticatedHook: authEnabledInConfig && authReady ? config.auth0.isAuthenticated : null,
                    isAuthenticatedStore: configLoaded ? config.auth0.isAuthenticated : null,
                    authEnabledInConfig,
                    readyLocal : authReady,
                    readyImmediate: config.authReady
                }));
    }
    useEffect(() => {
            (async () => {
                
                if (configLoaded) {
                    if (authEnabledInConfig) {
                        //* once the Login component finishes processing correctly it will set isLoading to false 
                        if (!isLoading && config.auth0.tokenMode !== "forceful") {
                            //* catch that one moment in one instance between auth0 reporting authenticated and soap not updated
                            if (isAuthenticated) {
                                if (!config.auth0.isAuthenticated) {
                                    //* make sure config.auth0.isAuthenticated = true; is always called immediately after the check to prevent re-entry 
                                    config.auth0.isAuthenticated = true;
                                    if (config.debug.auth) {
                                        console.warn(`fetching tokens at callsite 1 in instance owned by ${callerName}`);
                                    }
                                    const tokens = await getTokens(true, false);
                                    if (isMounted.current === true) {
                                        config.auth0.identityToken = tokens.idToken;
                                        config.auth0.accessToken = tokens.accessToken;
                                        config.auth0.userName = user.sub
                                        if (config.debug.auth) {
                                            console.warn(`setting ready at callsite 1 in instance owned by ${callerName}`, {
                                                idToken: tokens.idToken,
                                                accessToken: tokens.accessToken
                                            });
                                        }
                                        addToDefaultGroupsAsync(config.vars.functionAppRoot, config.vars.signalRHubConnectionId, tokens.idToken);
                                        setAuthReady();
                                    } else {
                                        //* reset if this instance is not mounted when the reply comes
                                        config.auth0.isAuthenticated = false;
                                    }
                                } else {
                                    /* do nothing, we are waiting for another instance to complete the previous statement block
                                    and set the tokens */ 
                                }
                            } else {
                                setAuthReady();
                                /* if we reach here, it should only be if there was an error in Auth0 authenticating user   
                                or if the authIsEnabled but no one is logged in                              
                                */
                            }
                        }
                    } else {
                        /* This would be where config is loaded but there is no auth0 object meaning auth is disabled, 
                        but for components waiting on a determination of whether auth is enabled or not, its now "ready".                     
                        Checking config.authReady keeps us from repeatedly calling setAuthReady() when all instances have not updated;                                                                          
                        */
                        if (!config.authReady) {
                            if (config.debug.auth) {
                                console.warn("setting ready at instance 2");
                            }
                            if (isMounted.current === true) {
                                setAuthReady();
                            }
                        }
                    }
                }
            })();
        }, [isLoading, isAuthenticated, configLoaded, authReady, authEnabledInConfig, getTokens]
    );
    
        return {
        /* functions are not reactive, so you need to be sure to return variables and not getters
        these should be updated everytime the hook renders and the hook should render when it receives
        an update to tokensRefreshedAt from any instance which triggers an update. We don't pass the tokens around
        but we pick them up directly from the global store.  
         */
        idToken : (authEnabledInConfig && authReady ? config.auth0.identityToken : null),
        accessToken : (authEnabledInConfig && authReady ? config.auth0.accessToken : null),
        authEnabled: configLoaded ? authEnabledInConfig : null,
        /* use the localCache version of authReady here not the global version to make sure you never tell a caller something you haven't fully processed yourself
        also wait for configLoaded as the change to authReady may come before configIsLoaded if the change is coming from
        another instance of the hook
         */
        authReady: configLoaded ? authReady : false,  
        isAuthenticated: (authEnabledInConfig && authReady ? config.auth0.isAuthenticated : null),
        setTokensForceFully,
        requireAuth,
        refresh: refreshTokens,
        tokenConversationId
    };
}