import {useStyletron} from "baseui";
import robot from "../z30-images/robot-s.png";
import {translate, types, validateArgs} from "@soap/modules";
import * as React from "react";
import {useEffect, useState} from "react";
import {Row} from "./row";


export function DisappearingTalkBadge({children, centerText, disappearAfterMilliseconds = 0, showRobot = false}) {

    validateArgs(
        [{disappearAfterMilliseconds}, types.number]
    );

    const viewStates = {
        hidden: 0,
        visible: 1
    };
    const [viewState, setViewState] = useState(viewStates.hidden);

    useEffect(() => {
        if (disappearAfterMilliseconds) {
            setTimeout(() => {
                setViewState(viewStates.hidden);
            }, disappearAfterMilliseconds);
        }
    }, [disappearAfterMilliseconds]);

    switch (viewStates) {
        case viewStates.visible:
            return <TalkBadge centerText={centerText} showRobot={showRobot}>{children}</TalkBadge>;
        case viewStates.hidden:
            return null;
    }
}

export function TalkBadge({children, centerText, showRobot = false}) {

    const [css, theme] = useStyletron();

    return (
        <div style={{
            borderRadius: '8px',
            textAlign: centerText ? "center" : undefined,
            padding: theme.sizing.scale300,
            backgroundColor: theme.colors.accent50,
            color: theme.colors.accent600
        }}>
            <Row>
                {showRobot ? <img src={robot} width={40} height={40} alt={translate("ATest Robot")}/> : null}
                <>
                    {children}
                </>
            </Row>

        </div>
    );


}