import {translate} from "@soap/modules";
import addIcon from "./add-icon.svg";
import viewIcon from "./view-icon.svg";
import editIcon from "./edit-icon.svg";
import * as React from "react";
import {useStyletron} from "baseui";

export function ViewIcon({$style}) {
    
    const [css, theme] = useStyletron();
    
    return (
        <img className={css({
            marginTop: "2px",
            marginRight: "1px",
            height: "10px",
            ...$style
        })} alt={translate("View")} src={viewIcon} />
    );
}

export function EditIcon({$style}) {

    const [css, theme] = useStyletron();

    return (
        <img className={css({
            marginTop: "2px",
            marginRight: "3px",
            height: "12px",
            ...$style
        })} alt={translate("Edit")} src={editIcon} />
    );
}



export function AddIcon({$style}) {

    const [css, theme] = useStyletron();

    return (
        <img className={css({
            marginTop: "2px",
            marginRight: "2px",
            height: "10px",
            ...$style
        })} alt={translate("Add")} src={addIcon} />
    );
}