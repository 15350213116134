﻿import {useStyletron} from "baseui";
import DOMPurify from "dompurify";
import {SpaceAround, SpaceTop} from "../../z40-uicomp/layout";
import {BackLinkWithTitle} from "../../z40-uicomp/back-link-with-title";
import {LabelMedium} from "baseui/typography";
import {viewStates} from "./view-states";
import {StyledForwardLinkWithTitle} from "../../z40-uicomp/forward-link-with-title";
import React from "react";

export function Instruction({html, setViewState}) {
    const [css, theme] = useStyletron();

    const purifiedContent = DOMPurify.sanitize(html, {USE_PROFILES: {html: true}});
    return (<>
            <SpaceTop>
                <BackLinkWithTitle title={<LabelMedium>Back</LabelMedium>} onPress={() => {
                    setViewState(viewStates.welcome);
                }}/>
            </SpaceTop>
            <SpaceAround override={theme.sizing.scale1200}>
                <div dangerouslySetInnerHTML={{__html: purifiedContent}}/>
            </SpaceAround>
            <div className={css({
                display: "flex",
                justifyContent: "flex-end"
            })}>
                <StyledForwardLinkWithTitle title={"Continue"} onPress={() => {
                    setViewState(viewStates.confirmCandidateDetails);
                }}/></div>
        </>
    )
}