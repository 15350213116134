﻿import {ActionDrawer, AutoForm, bus, translate} from "@soap/modules";
import React from "react";
import {MinWidthIfNotMobile} from "../../z40-uicomp/layout";


export function EditCandidate({candidateId}) {

    return (
        <ActionDrawer menuText={translate("Edit")} drawerHeader={translate("Candidate")} auth={true}>
            <MinWidthIfNotMobile>
                
                <AutoForm
                    query={{
                        $type: "C162v1_GetC161FormData",
                        C162_CandidateId: candidateId
                    }}
                    submitText={translate("Save")}
                    hiddenFields={["C161_CandidateId"]}
                    afterSubmit={() => bus.closeAllDialogs()}
                />
                </MinWidthIfNotMobile>
        </ActionDrawer>
    );
}

