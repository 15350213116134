﻿import {ActionDrawer, AutoForm, bus, translate} from "@soap/modules";
import React from "react";
import {AddIcon} from "../../z20-icons/icons";


export function AddTag(props) {
    const {tagTypeKey} = props;
    return (
        <ActionDrawer drawerHeader={<>{translate("Add")} {translate("Tag")}</>} title={<><AddIcon />{translate("Add")} {translate("Tag")}</>} auth={true}>
            <AutoForm
                query={{
                    $type: "C121v1_GetC120FormData",
                    C121_TagTypeKey: tagTypeKey
                }}
                submitText={translate("Add")}
                hiddenFields={["C120_TagTypeKey"]}
                afterSubmit={() => bus.closeAllDialogs()}
            />
        </ActionDrawer>
    );
}

