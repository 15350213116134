﻿import {ActionModal, translate} from "@soap/modules";
import React from "react";

export function RemoveAssessmentQuestion(props) {
    const {assessmentId, questionId, questionTitle} = props;
    
    return (<ActionModal title={translate("Remove") + " " + translate("Question")} auth={true}
                         command={{
                             $type: 'C194v1_RemoveQuestionFromAssessment',
                             C194_AssessmentId: assessmentId,
                             C194_QuestionId: questionId
                         }}>
            <p>{`Please confirm you want to remove the question "${questionTitle}" ?`}</p>
            <br/>
            <p>{translate("This will not remove the question from any previously shared assessments.")}</p>
        </ActionModal>

    );
}
