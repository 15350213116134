﻿import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import {useStyletron} from "baseui";
import {
    AggregateList,
    EntityMenu,
    SecondaryActionMenuButton,
    translate,
    useDomainEvent,
    useEvent,
    useLazyQuery,
    uuidv4
} from "@soap/modules";
import {KIND} from "baseui/button";
import {TagSelect} from "./tag-select";
import {LabelMedium} from "baseui/typography";
import {ArchiveAssessment} from "./modals/archive-assessment";
import {EditAssessmentMenu} from "./drawers/edit-assessment-menu";
import {EditAssessmentTitle} from "../z60-pagecomp/edit-assessment/edit-assessment-title";
import {EditAssessmentInstructions} from "../z60-pagecomp/edit-assessment/edit-assessment-instructions";
import {EditAssessmentTags} from "../z60-pagecomp/edit-assessment/edit-assessment-tags";
import {EditAssessmentQuestions} from "../z60-pagecomp/edit-assessment/edit-assessment-questions";
import {SoapPaginator, useSoapPaginator} from "./soap-paginator";
import {LinkButton} from "../z40-uicomp/buttons";
import {Row} from "../z40-uicomp/row";
import {Badge, COLOR, HIERARCHY} from "baseui/badge";
import {GeneratePreviewLink} from "./drawers/generate-preview-link";
import {IncludeArchivedCheckbox} from "../z40-uicomp/include-archived-checkbox";
import {DeleteAssessment} from "./modals/delete-assessment";
import {Tag, VARIANT} from "baseui/tag";
import {KIND as TAGKIND} from "baseui/tag/constants";

import {useDeviceType} from "../hooks/useDeviceType";
import {HScroll} from "../z40-uicomp/layout";
import {AddIcon, EditIcon, ViewIcon} from "../z20-icons/icons";
import {useParams} from "react-router-dom";
import * as path from "path";


export function AssessmentTable(props) {
    
    const [css, theme] = useStyletron();
    const {assessmentId, action} = useParams();

    const pageSize = 5;

    const {
        fetchPage,
        currentPageRecords: e148,
        recordCount,
        resetRecordSet,
        currentPage
    } = useSoapPaginator("E148v1_GotAssessments", "C180v1_GetAssessments", pageSize, {});
    
    const viewStates = {
        assessmentTable: "assessment-table",
        editTitle: "edit-title",
        editInstructions: "edit-instructions",
        editTags: "edit-tags",
        editQuestions: "edit-questions"
    };
    
    const getInitialState = useCallback(() => {
        
        if (assessmentId && action) {
                return [action, assessmentId];
        }
        
        return [viewStates.assessmentTable];
        
    }, []);  
    
    const [viewState, setViewState] = React.useState(getInitialState());

    const [refreshTags, e103] = useLazyQuery("C119v1_GetTags", {
        C119_TagTypes: {
            SelectedKeys: ['assessment']
        }
    });
                                                                                                 
    useEvent({
        eventName: "E150v1_AssessmentArchived",
        onEventReceived: (e, v) => {
            fetchPage();
        }
    }, [fetchPage]);

    useEvent({
        eventName: "E181v1_AssessmentDeleted",
        onEventReceived: (e, v) => {
            fetchPage();
        }
    }, [fetchPage]);
    
    useEvent({
        eventName: "E108v1_TagUpdated",
        onEventReceived: (e, v) => {
            fetchPage();
            refreshTags();
        }
    }, [fetchPage, refreshTags]);

    useEvent({
        eventName: "E106v1_TagRemoved",
        onEventReceived: (e, v) => {
            fetchPage();
            refreshTags();
        }
    }, [fetchPage, refreshTags]);
    
    useEvent({
        eventName: "E105v1_TagAdded",
        onEventReceived: (e, v) => {
            refreshTags();
        }
    }, [refreshTags]);

    const [includeArchived, setIncludeArchived] = useState(false);
    
    /* start tags */
    
    const [selectedTags, setSelectedTags] = useState([]);
    const [tableKey, setTableKey] = useState(uuidv4());

    const selectOptions = useMemo(() => e103?.e103_Tags.map(x => ({
        label: x.e103_Title,
        id: x.e103_LongId
    })) ?? [], [e103]);

    const onOptionsChange = useCallback((selectedOptions) => {
        //* trigger always seems to run first even if its placed after set statements.
        if (selectedOptions.length) {
            resetRecordSet({
                C180_PipeSeparatedTagIds: selectedOptions.map(t => t.id).join('|'),
                C180_IncludeArchived: includeArchived
            });
        } else {
            resetRecordSet();
        }
        setSelectedTags(selectedOptions);
        setTableKey(uuidv4()); //get rid of any previous state (e.g. expanded rows)
    }, []);

    /* end tags */

    
    function getAssessmentTitle(id) {
        const title = e148?.e148_Assessments.find(x => x.e148_AssessmentId === id).e148_Title;
        return title;
    }
    
    function gotoShares(id) {
        const url = "#/assessments/" + id + "/shares";
        location.href = url;
    }

    function gotoResults(id) {
        const url = "#/assessments/" + id + "/results";
        location.href = url;
    }

    function gotoQuestions(assessmentId) {
        location.href = `#/assessments/assessment-library/${assessmentId}/edit-questions`
    }
    
    const actionsArray = useMemo(() => {
        const archiveAllowed = ["Needs Sharing", "Shared", "Needs Questions"];
        const editAllowed = ["Needs Sharing", "Shared", "Needs Questions"];
        const shareAllowed = ["Needs Sharing", "Shared"];
        
        return [
            
            (entity) =>  editAllowed.includes(entity.e148_Status) ? <EditAssessmentMenu assessmentId={entity.e148_AssessmentId} /> : null,
            (entity) =>  shareAllowed.includes(entity.e148_Status) ? <SecondaryActionMenuButton style={{width:'100%'}} kind={KIND.tertiary}
                                                                                           onClick={() => {
                                                                   return location.href = "#/assessments/assessment-library/share/" + entity.e148_AssessmentId;
                                                                                           }}>{translate("Share")}</SecondaryActionMenuButton> : null,
            (entity) =>  archiveAllowed.includes(entity.e148_Status) ? <ArchiveAssessment assessmentTitle={entity.e148_Title}
                                                                                          assessmentId={entity.e148_AssessmentId}/> : null,
            (entity) =>   <DeleteAssessment assessmentTitle={entity.e148_Title} assessmentId={entity.e148_AssessmentId}/>,
        ];
    },[]);
    
    const viewActionsArray = useMemo(() => {
        const previewAllowed = ["Needs Sharing", "Shared"];
        const resultsAllowed = ["Archived", "Shared"];
        const sharesAllowed = ["Archived", "Shared"];

        return [
            (entity) => sharesAllowed.includes(entity.e148_Status) ? <SecondaryActionMenuButton style={{width:'100%'}} kind={KIND.tertiary}
                                                   onClick={() => location.href = "#/assessments/" + entity.e148_AssessmentId + "/shares"}>{translate("Shares")}</SecondaryActionMenuButton> : null,
            (entity) => resultsAllowed.includes(entity.e148_Status) ? <SecondaryActionMenuButton style={{width:'100%'}} kind={KIND.tertiary} 
                                                     onClick={() => location.href = "#/assessments/" + entity.e148_AssessmentId + "/results"}>{translate("Results")}</SecondaryActionMenuButton> : null, 
            (entity) => previewAllowed.includes(entity.e148_Status) ? <GeneratePreviewLink assessmentId={entity.e148_AssessmentId} /> : null
        ]
    }, []);
    
    const {isDesktop} = useDeviceType();
    const headerColumns = useMemo(() => {
        const c = ["e148_Title", "e148_Status"];
        if (isDesktop) {
            c.push("e148_TagsApplied");
        }
        return c;
    },[isDesktop]);


    switch (viewState[0]) {
        case viewStates.assessmentTable:
        default:
            
            return (<><AggregateList
                key={tableKey}
                title={translate("Assessments")}
                aggregates={e148?.e148_Assessments}
                rowColour={theme.colors.accent}
                headerColumns={headerColumns}
                hiddenFields={["e148_SortableTitle", "e148_AssessmentId"]}
                propertyRenderer={{
                    "e148_Status": (value, entity) => {
                        if (value === "Needs Sharing")
                            return  (<LinkButton hover={true} underline={true} onClick={() => { location.href = "#/assessments/assessment-library/share/" + entity.e148_AssessmentId}}>{translate(value)}</LinkButton>)
                        if (value === "Needs Questions")
                            return (<LinkButton underline={true} hover={true} onClick={() => gotoQuestions(entity.e148_AssessmentId) }>{translate(value)}</LinkButton>)
                        return value;
                    }, 
                    "e148_Questions": (value, entity) => <Row><LabelMedium>{value}</LabelMedium> / <LinkButton onClick={() => gotoQuestions(entity.e148_AssessmentId)}>{`${translate("View")} ${translate("Questions")}`}</LinkButton></Row>,
                    "e148_TimesShared": (value, entity) =><Row><LabelMedium>{value}</LabelMedium> /  <LinkButton onClick={() => gotoShares(entity.e148_AssessmentId)}>{`${translate("View")} ${translate("Shares")}`}</LinkButton></Row>,
                    "e148_TimesTaken": (value, entity) => <Row><LabelMedium>{value}</LabelMedium> /  <LinkButton onClick={() => gotoResults(entity.e148_AssessmentId)}>{`${translate("View")} ${translate("Results")}`}</LinkButton> </Row>,
                    "e148_TagsApplied": (value) => value.map(i =>  <Tag closeable={false}
                                                                        variant={VARIANT.solid}
                                                                        kind={TAGKIND.neutral}
                                                                        key={i}>{i}</Tag>),
                }}
                entityMenus={{
                    "root-individual": new EntityMenu(null, [
                        () => <TagSelect options={selectOptions} value={selectedTags} onChange={onOptionsChange}/>,
                        () => <IncludeArchivedCheckbox archived={includeArchived} onChange={(includeArchived) => {
                            resetRecordSet({
                                C180_PipeSeparatedTagIds: selectedTags.map(t => t.id).join('|'),
                                C180_IncludeArchived: includeArchived
                            });
                            setIncludeArchived(includeArchived);
                        }} />,
                        () => <SecondaryActionMenuButton kind={KIND.tertiary}
                                                         onClick={() => location.href = "#/assessments/assessment-library/create-assessment"}>
                            <AddIcon />{translate("Create")}</SecondaryActionMenuButton>,
                        () => <SecondaryActionMenuButton kind={KIND.tertiary}
                                                         onClick={() => location.href = "#/assessments/assessment-library/tags"}>
                            <EditIcon />{translate("Tags")}</SecondaryActionMenuButton>,
                        () => <SecondaryActionMenuButton kind={KIND.tertiary} style={{width: '100%'}}
                                                         onClick={() => location.href = "#/assessments/shared"}>
                            <ViewIcon />{translate("Shares")}</SecondaryActionMenuButton>,
                        () => <SecondaryActionMenuButton kind={KIND.tertiary} style={{width: '100%'}}
                                                         onClick={() => location.href = "#/assessments/results"}>
                            <ViewIcon />{translate("Results")}</SecondaryActionMenuButton>,


                    ]),
                    "root": new EntityMenu(null, [

                    ]),
                    "root-Items": new EntityMenu(viewActionsArray, actionsArray),
                }}
            /> <SoapPaginator pageSize={pageSize}
                              page={currentPage}
                              recordCount={recordCount}
                              onPageChanged={(page) => {
                                  fetchPage(page);
                              }}
            /></>)
        case viewStates.editTitle:
            return <EditAssessmentTitle assessmentId={viewState[1]}  assessmentTitle={getAssessmentTitle(viewState[1])} selectedItemId={viewState[0]} />;
        case viewStates.editInstructions:
            return <EditAssessmentInstructions assessmentId={viewState[1]} assessmentTitle={getAssessmentTitle(viewState[1])}  selectedItemId={viewState[0]} />;
        case viewStates.editTags:
            return <EditAssessmentTags assessmentId={viewState[1]} assessmentTitle={getAssessmentTitle(viewState[1])}  selectedItemId={viewState[0]} />;
        case viewStates.editQuestions:
            return <EditAssessmentQuestions assessmentId={viewState[1]} assessmentTitle={getAssessmentTitle(viewState[1])}  selectedItemId={viewState[0]} />;
    }
}


