﻿import React, {useEffect, useMemo, useState} from "react";
import {CenterSpinner, useEvent, useLazyQuery} from "@soap/modules";
import {Frame} from "../../z40-uicomp/frame";
import {useParams} from "react-router-dom";
import {LabelLarge} from "baseui/typography";
import {Centered, SpaceTop} from "../../z40-uicomp/layout";
import {viewStates} from "./view-states";
import {Welcome} from "./welcome";
import {ViewSummary} from "./view-summary";
import {Instruction} from "./instruction";
import {ConfirmCandidateDetails} from "./confirm-candidate-details";
import {AnswerQuestion} from "./answer-question";
import useCountDown from 'react-countdown-hook';
import {SectionLanding} from "./section-landing";
import {CenteredColumn} from "../../z40-uicomp/centered-column";
import {useQueryResponse} from "../../hooks/useQueryString";


export function TakeAssessment() {

    let {sharedAssessmentId, sharedAssessmentLinkId} = useParams();
    if (sharedAssessmentLinkId === "iframe") sharedAssessmentLinkId = null;
    const [activeQuestion, setActiveQuestion] = useState();
    const [activeSection, setActiveSection] = useState();
    const [viewState, setViewState] = useState(viewStates.loading);
    const [completedAssessmentDetails, setCompletedAssessmentDetails] = useState();
    const [timeLeft, timerControls] = useCountDown(0);
    const [, e170] = useLazyQuery("C212v1_GetSharedAssessmentByLinkId", {
        C212_SharedAssessmentLinkId: sharedAssessmentLinkId,
        C212_SharedAssessmentId: sharedAssessmentId
    });
    const hasInstructions = useMemo(() => e170?.e170_Instructions?.trim().length > 0, [e170]);
    
    useQueryResponse(e170, e => {
        if (viewStates.loading) {
            setViewState(viewStates.welcome);
        }
    })

    useEvent({
        eventName: "E173v1_GotQuestionDetails",
        onEventReceived: (e173) => {
            setActiveQuestion(e173);
            setViewState(viewStates.showQuestion);
        }
    })

    useEvent({
        eventName: "E174v1_AssessmentCompleted",
        onEventReceived: (e174) => {
            setCompletedAssessmentDetails(e174);
            setViewState(viewStates.assessmentCompleted);
        }
    })

    useEvent({
        eventName: "E175v1_NewSectionStarted",
        onEventReceived: (e175) => {
            setActiveSection(e175);
            setViewState(viewStates.sectionLanding);
        }
    })

    switch (viewState) {
        case viewStates.loading:
            return <Frame>
                <CenteredColumn>
                    <CenterSpinner/>
                </CenteredColumn>
            </Frame>;
        case viewStates.welcome:
            return <Frame>
                <CenteredColumn maxWidth={600}>
                    <Welcome e170={e170} setViewState={setViewState} hasInstructions={hasInstructions}/>
                </CenteredColumn>
            </Frame>;
        case viewStates.showInstructions:
            return <Frame>
                <CenteredColumn maxWidth={600}>
                    <Centered><LabelLarge>{e170.e170_AssessmentTitle}</LabelLarge></Centered>
                    <Instruction html={e170.e170_Instructions} setViewState={setViewState}/>
                </CenteredColumn>
            </Frame>;
        case viewStates.confirmCandidateDetails:
            return <Frame>
                <CenteredColumn maxWidth={600}>
                    <Centered><LabelLarge>{e170.e170_AssessmentTitle}</LabelLarge></Centered>
                    <ConfirmCandidateDetails candidateId={e170.e170_Candidate?.e170_CandidateId}
                                             sharedAssessmentId={e170.e170_SharedAssessmentId}
                                             linkId={sharedAssessmentLinkId}
                                             setViewState={setViewState}
                                             backState={hasInstructions ? viewStates.showInstructions : viewStates.welcome}/>
                </CenteredColumn>
            </Frame>;
        case viewStates.showQuestion:
            return <Frame>
                <CenteredColumn maxWidth={600}>
                    <Centered><LabelLarge>{e170.e170_AssessmentTitle}</LabelLarge></Centered>
                    {/*key is important on AnswerQuestion or state will be reused from question to question otherwise*/}
                    <AnswerQuestion key={activeQuestion?.e173_NextQuestionId} e173={activeQuestion} timeLeft={timeLeft}
                                    timerControls={timerControls}/>
                </CenteredColumn>
            </Frame>;

        case viewStates.sectionLanding:
            
            return <Frame>
                <CenteredColumn maxWidth={600}>
                    <Centered><LabelLarge>{e170.e170_AssessmentTitle}</LabelLarge></Centered>
                    <SectionLanding key={activeSection?.e175_SectionId} e175={activeSection} timeLeft={timeLeft} timerControls={timerControls} e170={e170} />
                </CenteredColumn>
            </Frame>;

        case viewStates.assessmentCompleted:
            return <Frame>
                <CenteredColumn maxWidth={600}>
                    <Centered>
                        <SpaceTop>
                            <ViewSummary e174={completedAssessmentDetails} title={e170.e170_AssessmentTitle}/>
                        </SpaceTop>
                    </Centered>
                </CenteredColumn>
            </Frame>;
    }
}


