﻿import {ActionDrawer, AutoForm, bus, translate, useDomainEvent} from "@soap/modules";
import React from "react";
import {Menu, StatefulMenu} from "baseui/menu";
import {withStyle} from "baseui";
import {StyledLink} from "baseui/link";
import {HeaderNavigation, StyledNavigationItem, StyledNavigationList} from "baseui/header-navigation";
import {SpaceBottom} from "../../z40-uicomp/layout";



export function EditQuestionTopNav({questionId, questionType, selectedItemId}) {
    
    const ITEMS = getItems(questionType);
    
    const trigger = useDomainEvent({
        eventName: "menu-item-selected",
        conversationId: "edit-question"
    });

    function gotoEditScreen(menuItemId) {
        trigger({
            menuItemId: menuItemId,
            questionId: questionId,
            questionType: questionType
        });
    }

       
    const NavLink = withStyle(StyledLink, ({$theme}) => ({
        cursor: "pointer"
    }));
    
    return (
        <SpaceBottom>
        <HeaderNavigation
            overrides={{
                Root: {
                    style: ({ $theme }) => ({
                        borderBottomWidth:"0px",
                        backgroundColor: "none"
                    })
                }
            }}>
            <StyledNavigationList $style={{
                flexWrap: "wrap"
            }}>
                <StyledNavigationItem>
                    {selectedItemId === ITEMS[0].id ? ITEMS[0].label :
                        <NavLink onClick={() => gotoEditScreen(ITEMS[0].id)}>
                            {ITEMS[0].label}
                        </NavLink>}
                </StyledNavigationItem>
                <StyledNavigationItem>
                    {selectedItemId === ITEMS[1].id ? ITEMS[1].label :
                        <NavLink onClick={() => gotoEditScreen(ITEMS[1].id)}>
                            {ITEMS[1].label}
                        </NavLink>}
                </StyledNavigationItem>
                <StyledNavigationItem>
                    {selectedItemId === ITEMS[2].id ? ITEMS[2].label :
                        <NavLink onClick={() => gotoEditScreen(ITEMS[2].id)}>
                            {ITEMS[2].label}
                        </NavLink>}
                </StyledNavigationItem>
                <StyledNavigationItem>
                    {selectedItemId === ITEMS[3].id ? ITEMS[3].label :
                        <NavLink onClick={() => gotoEditScreen(ITEMS[3].id)}>
                            {ITEMS[3].label}
                        </NavLink>}
                </StyledNavigationItem>
            </StyledNavigationList>
        </HeaderNavigation>
        </SpaceBottom>
    );
}

function getItems(questionType) {
    
    const ITEMS = [
        {label: translate('Question Text'), id: "edit-question"},
        {label: translate('Point Value'), id: "edit-value"},
    ];
    ITEMS.push({label: translate('Options'), id: "edit-question-options-" + questionType.key});
    ITEMS.push({label: translate('Tags'), id: "edit-tags"});
    
    return ITEMS;
}

export function EditQuestionMenu({questionId, questionType}) {
    
    const ITEMS = getItems(questionType);
        
    const trigger = useDomainEvent({
        eventName: "menu-item-selected",
        conversationId: "edit-question"
    });

    return (
        <ActionDrawer drawerHeader={translate("Edit") + " " + translate("Question")} menuText={translate("Edit")} auth={true} >
            <div style={{
                width:"320px"
            }}>
            <StatefulMenu
                onItemSelect={({item}) => {
                    trigger({
                        menuItemId: item.id,
                        questionId: questionId,
                        questionType: questionType
                    });
                }}
                items={ITEMS}
                overrides={{
                    List: {
                        style: {
                            borderRadiusTopLeft: "0px",
                            borderRadiusTopRight: "0px",
                            borderRadiusBottomLeft: "0px",
                            borderRadiusBottomRight: "0px",
                            boxShadow: "0px",
                            textAlign: "center"
                        },
                    },
                    Option: {
                        props: {
                            getItemLabel: item => item.label
                        },
                    },
                }}
            />
            </div>
        </ActionDrawer>
    );
}

