﻿import {SpaceTop, SpaceTopAndBottom} from "../../z40-uicomp/layout";
import {BackLinkWithTitle} from "../../z40-uicomp/back-link-with-title";
import {LabelMedium} from "baseui/typography";
import {AutoForm} from "@soap/modules";
import {viewStates} from "./view-states";
import React from "react";

export function ConfirmCandidateDetails({candidateId, sharedAssessmentId, linkId, setViewState, backState}) {

    return (<>
            <SpaceTop>
                <BackLinkWithTitle title={<LabelMedium>Back</LabelMedium>} onPress={() => {
                    setViewState(backState);
                }}/>
            </SpaceTop>
            <SpaceTopAndBottom>
                <LabelMedium>Confirm Your Details</LabelMedium>
            </SpaceTopAndBottom>
            <AutoForm
                query={{
                    $type: "C214v1_GetC213FormData",
                    C214_CandidateId: candidateId,
                    C214_SharedAssessmentId: sharedAssessmentId,
                    C214_SharedAssessmentLinkId: linkId
                }}
                submitText={"Begin Assessment"}
                hiddenFields={["C213_CandidateId", "C213_SharedAssessmentId", "C213_SharedAssessmentLinkId"]}
                afterSubmit={() => {
                    setViewState(viewStates.showQuestion);
                }}
            /></>
    )
}