import { PlasmicPrepassContext } from "@plasmicapp/query";
import prepass from "@plasmicapp/react-ssr-prepass";
import React from "react";
async function extractPlasmicQueryData(element, onClientComponentRef) {
  const cache = /* @__PURE__ */ new Map();
  try {
    await plasmicPrepass(
      /* @__PURE__ */ React.createElement(PlasmicPrepassContext, { cache }, element),
      onClientComponentRef
    );
  } catch (err) {
    console.warn(`PLASMIC: Error encountered while pre-rendering`, err);
  }
  const queryCache = Object.fromEntries(
    Array.from(cache.entries()).filter(
      ([key, val]) => !key.startsWith("$swr$") && !key.startsWith("$csq$") && val !== void 0
    )
  );
  try {
    return JSON.parse(
      JSON.stringify(
        queryCache,
        (_key, value) => value !== void 0 ? value : null
      )
    );
  } catch {
    return queryCache;
  }
}
async function plasmicPrepass(element, onClientComponentRef) {
  await prepass(element, void 0, onClientComponentRef);
}
export {
  extractPlasmicQueryData,
  plasmicPrepass
};
