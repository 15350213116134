﻿import {useStyletron} from "baseui";
import {FullWidth, SpaceLeftAndRight} from "./layout";
import * as React from "react";

export function Row({children, justification = "flex-start", flexWrap = "nowrap", spacing, expandedContentWidth, alignItems = undefined}) {
    const [css, theme] = useStyletron();

    return (<div className={css({
        display: 'flex',
        flexDirection: "row",
        flexWrap: flexWrap,
        justifyContent: justification,
        alignItems: alignItems
    })}>
        {expandedContentWidth ?
            (Array.isArray(children) ?
                children.map((c, i) =>
                    (<FullWidth>
                        <SpaceLeftAndRight key={i} override={spacing}>{c}</SpaceLeftAndRight>
                    </FullWidth>)) :
                <FullWidth>{children}</FullWidth>)
            :
            (Array.isArray(children) ?
                children.map((c, i) =>
                    (<SpaceLeftAndRight key={i} override={spacing}>{c}</SpaceLeftAndRight>))
                : children)
        }
    </div>);
}