﻿import {AutoForm, headerKeys, setHeader, translate, useDomainEvent, useEvent} from "@soap/modules";
import {CenteredCard} from "../../z40-uicomp/centered-card";
import {ProgressBar} from "baseui/progress-bar";
import {SpaceTopAndBottom} from "../../z40-uicomp/layout";
import {HeadingMedium, HeadingSmall} from "baseui/typography";
import React from "react";


export function StepTags({statefulProcessId}) {

    const trigger = useDomainEvent({
        eventName: "tags-defined",
        conversationId: "create-question"
    });

    useEvent({
        eventName: "E121v1_CreateQuestionTagsSelected",
        onEventReceived: (event) => {
            trigger();
        }
    }, []);

    const backTrigger = useDomainEvent({
        eventName: "step-back",
        conversationId: "create-question"
    });

    return (<CenteredCard  title={<HeadingMedium>Create Question</HeadingMedium>} showRobot={true}>
            <ProgressBar value={80}/>
            <SpaceTopAndBottom>
                <HeadingSmall>Do you want to apply any of your existing tags to this question?</HeadingSmall>
            </SpaceTopAndBottom>
            <AutoForm
                query={{
                    $type: "C141v1_GetC140FormData",
                    "C141_StatefulProcessId": statefulProcessId
                }}
                afterCancel={()=> {
                    backTrigger({
                        stepNumber:4
                    });
                }}
                cancelText={"Back"}
                submitText={translate("Next")}
            />
        </CenteredCard>
    );
}