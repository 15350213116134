﻿import * as React from 'react';
import {useStyletron} from "baseui";
import {CenteredColumn} from "../z40-uicomp/centered-column";
import {Frame} from "../z40-uicomp/frame";
import {SharedAssessmentsTable} from "../z50-domaincomp/shared-assessments-table";
import {useParams} from "react-router-dom";

export function SharedAssessments() {

    const [css, theme] = useStyletron();

    const {assessmentId} = useParams();

    return (<Frame>
        <CenteredColumn>
            <SharedAssessmentsTable assessmentId={assessmentId}/>
        </CenteredColumn>
    </Frame>);
}