﻿export const timingModeKeys = {
    NotTimed: "not-timed",
    PerAssessment: "per-assessment",
    PerSection: "per-section",
    PerQuestion: "per-question"
};

export const timingModes = {
    [timingModeKeys.NotTimed]: "Not Timed",
    [timingModeKeys.PerAssessment]: "Per Assessment",
    [timingModeKeys.PerSection]: "Per Section",
    [timingModeKeys.PerQuestion]: "Per Question"
};

