﻿import {ActionDrawer, translate, useDomainEvent} from "@soap/modules";
import React from "react";
import {StatefulMenu} from "baseui/menu";
import {HeaderNavigation, StyledNavigationItem, StyledNavigationList} from "baseui/header-navigation";
import {StyledLink} from "baseui/link";
import {withStyle} from 'baseui';
import {SpaceBottom} from "../../z40-uicomp/layout";

const ITEMS = [
    {label: 'Title/Description', id: "edit-title"},
    {label: 'Instructions', id: "edit-instructions"},
    {label: 'Questions & Sections', id: "edit-questions"},
    {label: 'Tags', id: "edit-tags"}

];

export function EditAssessmentTopNav({assessmentId, assessmentTitle, selectedItemId}) {

    function gotoEditScreen(menuItemId) {
        location.href = `#/assessments/assessment-library/${assessmentId}/${menuItemId}`
    }

    const NavLink = withStyle(StyledLink, ({$theme}) => ({
        cursor: "pointer"
    }));

    return (
                 <SpaceBottom>
                     <HeaderNavigation
                         overrides={{
                             Root: {
                                 style: ({ $theme }) => ({
                                     borderBottomWidth:"0px",
                                     backgroundColor: "none"
                                 })
                             }
                         }}>
                <StyledNavigationList $style={{
                    flexWrap: "wrap"
                }}>
                    <StyledNavigationItem>
                        {selectedItemId === ITEMS[0].id ? ITEMS[0].label :
                            <NavLink onClick={() => gotoEditScreen(ITEMS[0].id)}>
                                {ITEMS[0].label}
                            </NavLink>}
                    </StyledNavigationItem>
                    <StyledNavigationItem>
                        {selectedItemId === ITEMS[1].id ? ITEMS[1].label :
                            <NavLink onClick={() => gotoEditScreen(ITEMS[1].id)}>
                                {ITEMS[1].label}
                            </NavLink>}
                    </StyledNavigationItem>
                    <StyledNavigationItem>
                        {selectedItemId === ITEMS[2].id ? ITEMS[2].label :
                            <NavLink onClick={() => gotoEditScreen(ITEMS[2].id)}>
                                {ITEMS[2].label}
                            </NavLink>}
                    </StyledNavigationItem>
                    <StyledNavigationItem>
                        {selectedItemId === ITEMS[3].id ? ITEMS[3].label :
                            <NavLink onClick={() => gotoEditScreen(ITEMS[3].id)}>
                                {ITEMS[3].label}
                            </NavLink>}
                    </StyledNavigationItem>
                </StyledNavigationList>
            </HeaderNavigation>
                 </SpaceBottom>
    );
}

export function EditAssessmentMenu({assessmentId, assessmentTitle}) {

    return (
        <ActionDrawer title={"Edit"} auth={true}>
            <div style={{
                width: "320px"
            }}>
                <StatefulMenu
                    onItemSelect={({item}) => {
                        location.href = `#/assessments/assessment-library/${assessmentId}/${item.id}`
                    }}
                    items={ITEMS}
                    overrides={{
                        List: {
                            style: {
                                borderRadiusTopLeft: "0px",
                                borderRadiusTopRight: "0px",
                                borderRadiusBottomLeft: "0px",
                                borderRadiusBottomRight: "0px",
                                boxShadow: "0px",
                                textAlign: "center"
                            },
                        },
                        Option: {
                            props: {
                                getItemLabel: item => item.label
                            },
                        },
                    }}
                />
            </div>
        </ActionDrawer>
    );
}

