﻿import React, {useState} from "react";
import {Frame} from "../../z40-uicomp/frame";
import {CenteredCard} from "../../z40-uicomp/centered-card";
import {SpaceBottom, SpaceTopAndBottom} from "../../z40-uicomp/layout";
import {Card} from "baseui/card";
import {HeadingXSmall} from "baseui/typography";
import {Radio, StatefulRadioGroup} from "baseui/radio";
import {ShareByEmail} from "./share-by-email";
import {ShareByLink} from "./share-by-link";
import {CenterSpinner, useDomainEvent, useEvent, useLazyQuery} from "@soap/modules";
import {ShareByIframe} from "./share-by-iframe";
import {ShareByCandidate} from "./share-by-candidate";
import {ShareByCandidateTag} from "./share-by-candidate-tag";
import {useParams} from "react-router-dom";
import {tagTypeKeys} from "../../z50-domaincomp/constants/tag-type-keys";
import {sharedAssessmentTypes} from "../../z50-domaincomp/constants/shared-assessment-types";
import {CenteredColumn} from "../../z40-uicomp/centered-column";



const viewStates = {
    ChooseType: 0,
    ChoseEmail: 1,
    ChoseLink: 2,
    ChoseCandidate: 3,
    ChoseCandidateTag: 4,
    ChoseIFrame: 5,
    Loading:6
};

function Body() {


    const [c119Trigger, e103] = useLazyQuery("C119v1_GetTags", {
        C119_TagTypes: {
            SelectedKeys: [tagTypeKeys.Candidate]
        }
    });
    const {assessmentId} = useParams();
    const [shareType, setShareType] = useState(null);
    const [viewState, setViewState] = useState(viewStates.Loading);

    useDomainEvent({
        eventName: "back-to-share-assessment",
        conversationId: "share-assessment",
        onEventReceived: (event) => {
            setViewState(viewStates.ChooseType);
        }
    });
    
    useEvent({
        eventName: "E103v1_GotTags",
        onEventReceived:(event) => {
            setViewState(viewStates.ChooseType);
        }
    })
    
    switch (viewState) {
        case viewStates.Loading:
            return (<CenteredCard title={"Share Assessment"} onBack={() => location.href = "#/assessments/assessment-library"}>
                <CenterSpinner />
            </CenteredCard>);
        case viewStates.ChooseType:
            return (
                <CenteredCard title={"Share Assessment"} onBack={() => location.href = "#/assessments/assessment-library"}>
                    <SpaceTopAndBottom>
                        <CenteredColumn>
                            <SpaceBottom>
                                <HeadingXSmall>
                                    Choose how to share the assessment:
                                </HeadingXSmall>
                            </SpaceBottom>
                            <StatefulRadioGroup name="share_type"
                                                initialState={{value: '1'}}
                                                onChange={e => {
                                                    const selection = e.target.value;
                                                    setShareType(selection);
                                                    switch (selection) {
                                                        case sharedAssessmentTypes.ByEmail:
                                                            setViewState(viewStates.ChoseEmail);
                                                            break;
                                                        case sharedAssessmentTypes.ByLink:
                                                            setViewState(viewStates.ChoseLink);
                                                            break;
                                                        case sharedAssessmentTypes.ByCandidate:
                                                            setViewState(viewStates.ChoseCandidate);
                                                            break;
                                                        case sharedAssessmentTypes.ByCandidateTag:
                                                            setViewState(viewStates.ChoseCandidateTag);
                                                            break;
                                                        case sharedAssessmentTypes.ByIFrame:
                                                            setViewState(viewStates.ChoseIFrame);
                                                            break;
                                                    }
                                                }}>
                                <Radio value={sharedAssessmentTypes.ByEmail}>Email</Radio>
                                <Radio value={sharedAssessmentTypes.ByLink}>Link</Radio>
                                <Radio value={sharedAssessmentTypes.ByCandidate}>Candidate</Radio>
                                <Radio disabled={e103?.e103_Tags.length === 0} value={sharedAssessmentTypes.ByCandidateTag}>Candidate Tag</Radio>
                                <Radio value={sharedAssessmentTypes.ByIFrame}>Embed in a Website</Radio>
                            </StatefulRadioGroup>
                        </CenteredColumn>
                    </SpaceTopAndBottom>

                </CenteredCard>
            )

        case viewStates.ChoseLink:
            return <ShareByLink assessmentId={assessmentId} />;
        case viewStates.ChoseEmail:
            return <ShareByEmail  assessmentId={assessmentId} />;
        case viewStates.ChoseIFrame:
            return <ShareByIframe  assessmentId={assessmentId} />;
        case viewStates.ChoseCandidate:
            return <ShareByCandidate  assessmentId={assessmentId} />;
        case viewStates.ChoseCandidateTag:
            return <ShareByCandidateTag  assessmentId={assessmentId} />;

    }
}

export function ShareAssessment() {
    
    return (<Frame>
        <Body/>
    </Frame>);

}


