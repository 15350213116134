﻿import * as React from 'react';
import {useStyletron} from "baseui";
import {AggregateList, EntityMenu, translate, useEvent, useLazyQuery} from "@soap/modules";
import {BackLinkWithTitle} from "../z40-uicomp/back-link-with-title";
import {CenteredCard} from "../z40-uicomp/centered-card";
import {HeadingMedium, LabelSmall} from "baseui/typography";
import {RevokeSharedAssessmentLink} from "./modals/revoke-shared-assessment-link";
import {useEffect, useState} from "react";
import {CancelSharedAssessment} from "./modals/cancel-shared-assessment";
import {SoapPaginator, useSoapPaginator} from "./soap-paginator";
import {Checkbox, LABEL_PLACEMENT, STYLE_TYPE} from "baseui/checkbox";
import {locationIds} from "../location-ids";
import {Centered} from "../z40-uicomp/layout";


export function AssessmentResultsTable({assessmentId}) {

    const [css, theme] = useStyletron();
    
    const pageSize = 10;
    const [needsMarkingOnly, setNeedsMarkingOnly] = useState(false);
    
    const {
        fetchPage,
        currentPageRecords: e176,
        recordCount,
        resetRecordSet,
        currentPage
    } = useSoapPaginator("E176v1_GotAssessmentResults", "C218v1_GetAssessmentResults", pageSize, {
        C218_AssessmentId: assessmentId
    });
    
    
    return (
        <CenteredCard title={e176?.e176_AssessmentTitle ? e176.e176_AssessmentTitle + (` ${translate("Results")}`) : translate("Assessment Results")}
                                                                  onBack={() => location.href = "#/assessments/assessment-library"} maxWidth={800}>
            <Centered>{e176?.e176_ResultsDeleted ? `${translate('This assessment has')} ` + e176?.e176_ResultsDeleted + ` ${translate("deleted results")}.` : ""}</Centered>
            <AggregateList
                
                aggregates={e176?.e176_AssessmentResults}
                displayOptions={{
                    hideItemCountToggle: true
                }}
                
                headerColumns={["e176_Assessment", "e176_Candidate", "e176_DateTaken", "e176_Status", "e176_Score"]}
                hiddenFields={["e176_AssessmentId", "e176_AssessmentResultId", "e176_CompletedAt", "e176_NeedsMarking", "e176_Version"]}
                propertyRenderer={{
                    "e176_Assessment": (value, entity) => `${value} v${entity.e176_Version}`, 
                    "e176_Score": (value, entity) => <span>{entity.e176_NeedsMarking === true || entity.e176_CompletedAt === null ? "---" : value + "%"}</span>
                }}
                entityMenus = {{"root-individual": new EntityMenu (null,[() => <div style={{
                        paddingTop:"10px" //nasty HACK
                    }}><Checkbox
                        overrides={{
                            ToggleTrack: {
                                style: ({ $theme }) => ({ marginTop: "2px" }) //nasty HACK
                            }
                        }}
                        checked={needsMarkingOnly}
                        checkmarkType={STYLE_TYPE.toggle}
                        onChange={e => {
                            const needsMarkingOnly = e.target.checked;
                            resetRecordSet({
                                C218_AssessmentId: assessmentId,
                                C218_NeedsMarkingOnly: needsMarkingOnly
                            });
                            setNeedsMarkingOnly(needsMarkingOnly);

                        }}
                        labelPlacement={LABEL_PLACEMENT.right}
                    >
                        <div><LabelSmall>{translate("Show Only Results That Need Marking")}</LabelSmall></div>
                    </Checkbox></div>]),
                    "root-Items": new EntityMenu( (entity) => {
                        if (entity.e176_CompletedAt !== null) {
                            if (assessmentId) {
                                location.href = `#/assessments/${assessmentId}/results/${entity.e176_AssessmentResultId}/${locationIds.assessmentResultsListFiltered}`;    
                            } else {
                                location.href = `#/assessments/results/${entity.e176_AssessmentResultId}/${locationIds.assessmentResultsListUnfiltered}`;
                            }
                            
                        }
                    })
                }}
            />
            <SoapPaginator pageSize={pageSize}
                           page={currentPage}
                           recordCount={recordCount}
                           onPageChanged={(page) => {
                               fetchPage(page);
                           }}
            />
        </CenteredCard>
    );

}