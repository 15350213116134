﻿import * as React from "react";
import {useCallback, useState} from "react";
import {useEvent, useLazyQuery} from "@soap/modules";
import {CenteredColumn} from "../z40-uicomp/centered-column";
import {Centered, SpaceTopAndBottom} from "../z40-uicomp/layout";
import {Pagination} from "baseui/pagination";

export function useSoapPaginator(eventName, commandName, pageSize, initialCommandProperties = undefined) {

    const [pagesAndTokens, setPagesAndTokens] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [recordCount, setRecordCount] = useState();
    const eventId = eventName.substring(0, 4).toLowerCase();
    const commandId = commandName.substring(0, 4).toLowerCase();
    const pageSizeProperty = commandId + "_PageSize";
    const pageNumberProperty = commandId + "_PageNumber";
    const continuationTokenProperty = commandId + "_ContinuationToken";
    const [lastCommandProperties, setLastCommandProperties] = useState(initialCommandProperties);

    const [getPage, currentPageRecords] = useLazyQuery(commandName, initialCommandProperties ? {
        ...initialCommandProperties,
        [pageSizeProperty]: pageSize,
        [pageNumberProperty]: 1
    } : undefined);


    const fetchPage = useCallback((page = undefined, commandProperties = undefined) => {

               
        commandProperties = commandProperties ?? lastCommandProperties;
        const queryParamsChanged = JSON.stringify(commandProperties) !== JSON.stringify(lastCommandProperties);

        if (queryParamsChanged) {
            //* force (makes sense for UX, but also continuation tokens will be invalid, this will force a clear of the token state)
            page = 1
            setLastCommandProperties(commandProperties);
        }

        if (page) {
            setCurrentPage(page);
        } else {
            page = currentPage;
        }

        const newCommand = {
            ...commandProperties,
            [pageSizeProperty]: pageSize,
            [pageNumberProperty]: page,
            [continuationTokenProperty]: pagesAndTokens[page]
        };

        getPage(newCommand);

    }, [getPage, pageSize, pagesAndTokens, setCurrentPage]);

    useEvent({
        eventName: eventName,
        onEventReceived: (event) => {

            const eventPageNumber = event[eventId + "_PageNumber"];
            const eventContinuationToken = event[eventId + "_ContinuationToken"];
            const eventRecordCount = event[eventId + "_RecordCount"];
            
            if (eventPageNumber === 1) {
                setPagesAndTokens({
                    [2]: eventContinuationToken
                });
            } else {
                const newPagesObject = {
                    ...pagesAndTokens,
                    [eventPageNumber + 1]: eventContinuationToken
                };
                setPagesAndTokens(newPagesObject);
            }

            setRecordCount(eventRecordCount);
        }
    });

    return {
        fetchPage,
        resetRecordSet : (args = {}) => fetchPage(1, args),
        currentPageRecords,
        recordCount,
        currentPage
    };
}

export function SoapPaginator({
                                  pageSize,
                                  recordCount,
                                  page,
                                  onPageChanged
                              }) {

    return recordCount > pageSize ?
        <Centered>
            <SpaceTopAndBottom>
                <Pagination
                    overrides={{
                        Select: {
                            component: () => page
                        }
                    }}
                    numPages={Math.ceil(recordCount / pageSize)}
                    currentPage={page}
                    onPageChange={({nextPage}) => {
                        const pageNotLowerThanOne = Math.max(nextPage, 1);
                        const pageNotMoreThanRecordCount = Math.min(pageNotLowerThanOne, Math.ceil(recordCount / pageSize));
                        onPageChanged(pageNotMoreThanRecordCount);
                    }}
                />
            </SpaceTopAndBottom>
        </Centered> : null;

}