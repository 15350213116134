import _ from 'lodash';
import {md5Hash, types, validateArgs} from './util';
import config from "./config";


let cache = [];

export default {
    addOrReplace: (commandHash, event) => {
        validateArgs([{commandHash}, types.string], [{event}, types.object]);

        /* concurrency risk, prob not possible with 
            default browser STA, unless interrupted by async etc */
        _.remove(cache, i => i.commandHash === commandHash);
        cache.push({
            commandHash,
            event,
            timestamp: new Date().getTime(),
        });
        
        const cacheExpiryInMinutes = 1;
        const cacheExpiryInMilliSeconds = (1000 * 60) * cacheExpiryInMinutes;
        const cacheExpiry = new Date().getTime() - cacheExpiryInMilliSeconds; 
        const removedItems = _.remove(cache, i => i.timestamp < cacheExpiry);
        
        if (config.debug.caching) {
            console.warn(cache.length, cacheExpiry, new Date().getTime());    
        }
        
        if (removedItems.length) {
            console.log(`Removed ${removedItems.length} items from the query cache whose timestamp was over ${cacheExpiryInMinutes} minutes old. ${cache.length} items remaining.`);    
        }
        
    },
    
    clear: () => {cache = [];},

    find: (command, acceptableStalenessFactorInSeconds) => {
        validateArgs(
            [{command}, types.object],
            [{acceptableStalenessFactorInSeconds}, types.number],
        );

        const { headers, ...payload } = command;
        const commandHash = md5Hash(payload);

        let result;
        if (acceptableStalenessFactorInSeconds > 0) {
            
            result = _.find(
                cache,
                i =>
                    i.commandHash === commandHash &&
                    new Date().getTime() - i.timestamp <=
                    acceptableStalenessFactorInSeconds * 1000,
            );
            if (result !== undefined) result = result.event;
        }
        
        return result;
    },
};
