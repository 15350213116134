﻿import React, {useEffect, useState} from "react";
import {Centered, FullWidth, SpaceTopAndBottom} from "../../z40-uicomp/layout";
import {HeadingSmall, ParagraphLarge} from "baseui/typography";
import {AggregateList, CenterSpinner, EntityMenu, useAuth, useEvent, useLazyQuery} from "@soap/modules";
import {DeactivateUser} from "./deactivate-user";
import {ChangeTenantName} from "../drawers/change-tenant-name";
import {SlimCard} from "../../z40-uicomp/centered-card";
import {Row} from "../../z40-uicomp/row";
import {useStyletron} from "baseui";
import {InviteUserToOrg} from "../drawers/invite-user-to-org";
import {Roles} from "../../hooks/useEnumerations";
import {ReactivateUser} from "./reactivate-user";
import {GrantAdmin} from "./grant-admin";
import {RevokeAdmin} from "./revoke-admin";
import {useQueryResponse} from "../../hooks/useQueryString";
import {RevokeInvite} from "./revoke-invite";

export function OrganisationDetails({menuButtonSize}) {

    const [getOrg, gotOrg] = useLazyQuery("C241v1_GetOrganisationDetails", {});
    
    useQueryResponse(gotOrg, (org) => {
        
        if (org.e193_OrgRoleKey === Roles.OrgAdmin) {
            setRequestorIsAdmin(true);   
            getInvites();
        }
    });
    
    const [requestorIsAdmin, setRequestorIsAdmin] = useState(false);
    const [getInvites, gotInvites] = useLazyQuery("C248v1_GetNewUserInvitations");
    const viewStates = {
        loading: 1,
        loaded: 2
    };

    const [viewState, setViewState] = useState(viewStates.loading);
    
    const {logOut} = useAuth('organisation-details');
    
    const [css, theme] = useStyletron();

    useEffect(() => {
        if (gotOrg) {
            setViewState(viewStates.loaded);
        }

    }, [gotOrg]);

    useEvent({
        eventName: "E194v1_OrganisationNameUpdated",
        onEventReceived: () => getOrg()
    })

    useEvent({
        eventName: "E197v1_OrganisationMembersUpdated",
        onEventReceived: () => getOrg()
    })

    useEvent({
        eventName: "E200v1_InvitationRevoked",
        onEventReceived: () => getInvites()
    })

    useEvent({
        eventName: "E201v1_OrgUserInvited",
        onEventReceived: () => getInvites()
    })

    useEvent({
        eventName: "E198v1_AccountClosed",
        onEventReceived: () => {
            logOut(location.origin);
        }
    })

    switch (viewState) {
        case viewStates.loading:
            return <CenterSpinner/>;
        case viewStates.loaded:
            
            let actionsAccounts =[];
            if (requestorIsAdmin) actionsAccounts = [
                () => <ChangeTenantName/>
            ];

            let actionsInvites =[];
            if (requestorIsAdmin) actionsInvites = [
                () => <InviteUserToOrg/>
            ];
            
            return (<FullWidth>
                
                    <SpaceTopAndBottom>
                        <SlimCard>
                            <Centered>
                                <Row alignItems={"center"} flexWrap={"wrap"}>
                                    <div>
                                        <ParagraphLarge>Organisation: <strong>{gotOrg.e193_OrganisationName}</strong></ParagraphLarge>
                                    </div>
                                    <div>
                                        <ParagraphLarge>{gotOrg.e193_ActiveSubAccounts} sub accounts active</ParagraphLarge>
                                        <ParagraphLarge>{gotOrg.e193_AvailableSubAccounts} sub accounts available to assign</ParagraphLarge>
                                        
                                    </div>
                                </Row>
                            </Centered>
                        </SlimCard>
                    </SpaceTopAndBottom>
                <SpaceTopAndBottom>    
                    <AggregateList
                        aggregates={gotOrg.e193_SubAccounts}
                        title={<HeadingSmall>User Accounts</HeadingSmall>}
                        headerColumns={["e193_Name", "e193_Status"]}
                        hiddenFields={["e193_SubAccountId", "e193_IsRequestingUser", "e193_RoleKey", "e193_Role", "e193_IsActive"]}
                        entityMenus={{
                            "root-Items": new EntityMenu(null, [
                                (entity) => !entity.e193_IsRequestingUser && requestorIsAdmin && entity.e193_IsActive ?
                                    <DeactivateUser tenantUserId={entity.e193_SubAccountId}
                                                    tenantUser={entity.e193_Name}/> : null,
                                (entity) => !entity.e193_IsRequestingUser && requestorIsAdmin && !entity.e193_IsActive ?
                                    <ReactivateUser tenantUserId={entity.e193_SubAccountId}
                                                    tenantUser={entity.e193_Name}/> : null,
                                (entity) => !entity.e193_IsRequestingUser && requestorIsAdmin && entity.e193_RoleKey !== Roles.OrgAdmin && entity.e193_IsActive ?
                                    <GrantAdmin tenantUserId={entity.e193_SubAccountId}
                                                    tenantUser={entity.e193_Name}/> : null,
                                (entity) => !entity.e193_IsRequestingUser && requestorIsAdmin && entity.e193_RoleKey === Roles.OrgAdmin && entity.e193_IsActive ?
                                    <RevokeAdmin tenantUserId={entity.e193_SubAccountId}
                                                tenantUser={entity.e193_Name}/> : null
                            ]),
                            "root-individual": new EntityMenu(null, actionsAccounts),
                        }}

                    />
                    </SpaceTopAndBottom>
                    <SpaceTopAndBottom>
                    {requestorIsAdmin ?
                    <AggregateList
                        title={<HeadingSmall>Invitations</HeadingSmall>}
                        aggregates={gotInvites?.e199_Invites}
                        headerColumns={["e199_Email", "e199_SentOn", "e199_SentBy"]}
                        hiddenFields={["e199_InviteId"]}
                        entityMenus={{
                            "root-Items": new EntityMenu(null, [
                                (entity) => 
                                    <RevokeInvite inviteId={entity.e199_InviteId} email={entity.e199_Email} /> 
                            ]),
                            "root-individual": new EntityMenu(null, actionsInvites)
                        }}

                    /> : null}
                    </SpaceTopAndBottom>
                
            </FullWidth>);
    }
}