﻿import {
    ActionDrawer,
    bus,
    CenterSpinner,
    PrimaryActionMenuButton,
    translate,
    useCommandValidation,
    useEvent,
    useLazyCommand,
    useLazyQuery, uuidv4,
    ValidationNotification
} from "@soap/modules";
import {Select, SIZE, TYPE} from "baseui/select";
import React, {useMemo, useRef, useState} from "react";
import {LabelLarge, LabelMedium, ParagraphMedium, ParagraphXSmall} from "baseui/typography";
import {Centered, SpaceTopAndBottom} from "../../z40-uicomp/layout";
import {Input} from "baseui/input";
import {useStyletron} from "baseui";
import {LinkButton} from "../../z40-uicomp/buttons";
import {useReferrer} from "../../hooks/useReferrer";




function AddQuestion({assessmentId, sectionId, existingQuestionIds}) {


    const [css, theme] = useStyletron();
    const [getQuestions, gotQuestions] = useLazyQuery("C192v1_GetQuestionsForAddingToAssessment", {});

    const [addQuestionTrigger] = useLazyCommand("C193v1_AddQuestionToAssessment");

    const {validateCommand} = useCommandValidation();
    const [addQuestionValidationMessages, setAddQuestionValidationMessages] = useState('');

    const viewStates = {
        loading: 1,
        showQuestions: 2
    };

    useEvent({
        eventName: "E158v1_GotQuestionsForAddingToAssessment",
        onEventReceived: (e, v) => {
            setViewState(viewStates.showQuestions);
        }
    }, []);

    const [viewState, setViewState] = useState(viewStates.loading);
    const [selectedQuestions, setSelectedQuestions] = React.useState([]);
    const [selectedQuestionPoints, setSelectedQuestionPoints] = React.useState('');
    const [selectedQuestionTimeLimit, setSelectedQuestionTimeLimit] = React.useState(60);
    const {push} = useReferrer();
    
    const questionEnum = useMemo(() => gotQuestions?.e158_Questions.filter(q => !existingQuestionIds.includes(q.e158_QuestionId)).map(q => ({
        label: q.e158_QuestionTitle,
        id: q.e158_QuestionId,
        text: q.e158_QuestionText,
        points: q.e158_Points
    })), [gotQuestions]);

    switch (viewState) {
        case viewStates.loading:
            return <CenterSpinner/>;
        case viewStates.showQuestions:
            return (
                <>
                    <SpaceTopAndBottom>
                        <Select
                            clearable={false}
                            size={SIZE.default}
                            options={questionEnum}
                            value={selectedQuestions}
                            required
                            type={TYPE.search}
                            placeholder={translate("Select Existing Question")}
                            onChange={params => {
                                setSelectedQuestions(params.value);
                                setSelectedQuestionPoints(params.value[0].points);
                            }}
                        />
                    </SpaceTopAndBottom>
                    {selectedQuestions.length > 0 ?
                        <>
                            <ParagraphMedium>{selectedQuestions[0].text}</ParagraphMedium>
                            <ValidationNotification
                                errorMsg={addQuestionValidationMessages.length ? addQuestionValidationMessages.map((m, i) =>
                                    <p key={i}>{m}</p>) : undefined}/>
                            <SpaceTopAndBottom>
                                <LabelMedium>{translate("Point Value")}</LabelMedium>
                                <Input
                                    value={selectedQuestionPoints}
                                    onChange={(e) => {
                                        setSelectedQuestionPoints(e.target.value);
                                    }}
                                    size={SIZE.compact}
                                    overrides={{
                                        Root: {
                                            style: ({$theme}) => ({maxWidth: "100px"})
                                        }
                                    }}
                                />
                                <ParagraphXSmall>This is the value of the question. It can be changed at any
                                    time.</ParagraphXSmall>
                            </SpaceTopAndBottom>
                            <SpaceTopAndBottom>
                                <LabelMedium>{translate("Time Limit (in seconds)")}</LabelMedium>
                                <Input
                                    value={selectedQuestionTimeLimit}
                                    onChange={(e) => {
                                        setSelectedQuestionTimeLimit(e.target.value);

                                    }}
                                    size={SIZE.compact}
                                    overrides={{
                                        Root: {
                                            style: ({$theme}) => ({maxWidth: "100px"})
                                        }
                                    }}
                                />
                                <ParagraphXSmall>Applicable only if Assessment Timing mode is set to Per
                                    Question</ParagraphXSmall>
                            </SpaceTopAndBottom>
                            <PrimaryActionMenuButton onClick={async () => {

                                const command = {
                                    C193_AssessmentId: assessmentId,
                                    C193_SectionId: sectionId,
                                    C193_QuestionId: selectedQuestions[0].id,
                                    C193_TimeLimit: selectedQuestionTimeLimit,
                                    C193_Points: selectedQuestionPoints,
                                };

                                const commandValidationErrors = await validateCommand(command, "C193v1_AddQuestionToAssessment");

                                if (commandValidationErrors) {
                                    setAddQuestionValidationMessages(commandValidationErrors.filter(x => x !== ""));
                                } else {
                                    addQuestionTrigger(command);
                                    bus.closeAllDialogs();
                                }

                            }}>Add</PrimaryActionMenuButton>
                        </>
                        : null}
                    {selectedQuestions.length === 0 ?
                    <SpaceTopAndBottom>
                        <Centered><LinkButton onClick={() => {
                            const referrerId = push(location.hash);
                            return location.href = `#/questions/question-library/add-question/${referrerId}`;
                        }}>Create A New Question</LinkButton></Centered>
                    </SpaceTopAndBottom> : null}
                </>
            );
    }
}

export function EditAssessmentAddQuestion({assessmentId, sectionId, sectionTitle, existingQuestionIds}) {
    
    return (
        <ActionDrawer title={`${translate("Add Question")}`} auth={true}>
            <>
                <Centered><LabelLarge>Section: {sectionTitle}</LabelLarge></Centered>
                <AddQuestion assessmentId={assessmentId} sectionId={sectionId}
                             existingQuestionIds={existingQuestionIds}/>

            </>
        </ActionDrawer>
    )

}

