import * as React from "react";
import {useEffect, useState} from "react";
import Download from "baseui/icon/arrow-down"
import {StyledLink} from "baseui/link";
import {Modal, ModalBody, ModalHeader, ROLE, SIZE} from "baseui/modal";
import {useStyletron} from "baseui";
import Compressor from 'compressorjs'
import config from "../soap/config";
import {useAuth} from "../hooks/useAuth.js";

export const imageViewResizeModes = {
    literal : 1,
    imgTagDimensions: 2
};

async function objectUrlToBlob(objectUrl) {
    return await fetch(objectUrl).then(r => r.blob());
}

export function FileView({blobMeta}) {
    
    const displayOptions = blobMeta.displayOptions ?? {};
    displayOptions.hideName ??= false;
    displayOptions.hideTextLink ??= false;
    displayOptions.disableInlineHoverLink ??= false;
    displayOptions.hideDimensions ??= false;
    displayOptions.hideSize ??= false;
    displayOptions.isImage ??= false;
    displayOptions.caption ??= "";
    displayOptions.lightBoxDimensions ??= {};
    displayOptions.lightBoxDimensions.maxWidth ??= 1024;
    displayOptions.lightBoxDimensions.maxHeight ??= 768;
    displayOptions.inlineDimensions ??= {};
    displayOptions.inlineDimensions.maxWidth ??= 100;
    displayOptions.inlineDimensions.maxHeight ??= 100;
    displayOptions.imageResizeMode ??= imageViewResizeModes.literal;

    const winHeight = window.screen.height-50;
    const winWidth = window.screen.width-50;
    if (winHeight < displayOptions.inlineDimensions.maxHeight) displayOptions.inlineDimensions.maxHeight = winHeight;
    if (winHeight < displayOptions.lightBoxDimensions.maxHeight) displayOptions.lightBoxDimensions.maxHeight = winHeight;
    if (winWidth < displayOptions.inlineDimensions.maxWidth) displayOptions.inlineDimensions.maxWidth = winWidth;
    if (winWidth < displayOptions.lightBoxDimensions.maxWidth) displayOptions.lightBoxDimensions.maxWidth = winWidth;

    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [enrichedBlob, setEnrichedBlob] = useState(null);     
    const [css] = useStyletron();

    const {
        idToken,
        authReady
    } = useAuth("FileView");

    //* run to get the blob state after first render is complete

    useEffect(() => {
        (async function GetBlobFromBackend() {

            if (blobMeta !== null && blobMeta.objectUrl === undefined && authReady) {
                setIsLoading(true);
                const endpoint = `${config.vars.functionAppRoot}/GetBlob`;

                const url = `${endpoint}?id=${encodeURI(blobMeta.id)}&it=${idToken}`;
                if (config.debug.blobs) {
                    console.warn("Fetching Blob at: " + url);
                }
                let response = await fetch(url);
                const blob = await response.blob();
                const blobInfo = {
                    id: blobMeta.id,
                    name: blobMeta.name,
                    blob: blob
                };

                const enrichedBlob = await enrichBlobInfo(blobInfo, displayOptions);
                
                setEnrichedBlob(enrichedBlob);
                setIsLoading(false);
            }
        })();
    }, [authReady]) //* run only once

    return (
        <div>
            {renderUploadedItem(displayOptions)}
        </div>
    );

    
    async function enrichBlobInfo(blobInfo, displayOptions) {

        function getNewDimensions(origWidth, maxWidth, origHeight, maxHeight) {

            const newDimensions = {
                height: origHeight,
                width: origWidth
            };
            
            if (newDimensions.height > maxHeight) {
                const ratio = maxHeight / newDimensions.height;
                newDimensions.height = newDimensions.height * ratio;
                newDimensions.width = newDimensions.width * ratio;
            }

            if (newDimensions.width > maxWidth) {
                const ratio = maxWidth / newDimensions.width;
                newDimensions.height = newDimensions.height * ratio;
                newDimensions.width = newDimensions.width * ratio;
            }

            return newDimensions;
        }
        
        switch (blobInfo.blob.type) {
            case "image/png":
            case "image/jpeg":
            case "image/jpg":
            case "image/jfif":
            case "image/webp":
            case displayOptions.isImage === true:

                blobInfo.isImage = true;

                //* because image might be smaller than max dimensions we have to load it to figure that out
                const rawImage = new Image();
                const rawImageUrl = URL.createObjectURL(blobInfo.blob);
                rawImage.src = rawImageUrl;
                await new Promise((resolve) => {
                    rawImage.onload = resolve;
                });
                blobInfo.rawWidth = rawImage.width;
                blobInfo.rawHeight = rawImage.height;

                //inline blob
                const inlineBlobDimensions = getNewDimensions(rawImage.width, displayOptions.inlineDimensions.maxWidth, rawImage.height, displayOptions.inlineDimensions.maxHeight);
                const inlineBlob = await resizeTo(blobInfo.blob, inlineBlobDimensions);
                const inlineUrl = URL.createObjectURL(inlineBlob);
                blobInfo.inlineUrl = inlineUrl;
                
                //lightbox blob
                const resizeModeIsImageTag = displayOptions.imageResizeMode === imageViewResizeModes.imgTagDimensions;
                const lightBoxBlobDimensions = getNewDimensions(rawImage.width, displayOptions.lightBoxDimensions.maxWidth, rawImage.height, displayOptions.lightBoxDimensions.maxHeight);
                blobInfo.lightBoxHeight = lightBoxBlobDimensions.height;
                blobInfo.lightBoxWidth = lightBoxBlobDimensions.width;
                const lightBoxBlob = resizeModeIsImageTag ? blobInfo.blob : await resizeTo(blobInfo.blob, lightBoxBlobDimensions);
                const lightBoxUrl = URL.createObjectURL(lightBoxBlob);
                blobInfo.lightBoxUrl = lightBoxUrl;
                
                break;

            default:
                blobInfo.isImage = false;
                blobInfo.objectUrl = URL.createObjectURL(blobInfo.blob);
                break;
        }
        blobInfo.sizeInKb = Math.round(blobInfo.blob.size / 1000) + " KB";
        delete blobInfo.blob;
        return blobInfo;

        function resizeTo(blob, dimensions = {}) {
            dimensions.width ??= 1024;
            dimensions.height ??= 768;
            return new Promise((resolve, reject) => {
                new Compressor(blob, {
                    checkOrientation: false,
                    height: dimensions.height,
                    width: dimensions.width,
                    success: resolve,
                    error: reject
                });
            });
        }
        

    }
    
    function renderUploadedItem(displayOptions) {

        const {
            hideName,
            hideTextLink,
            disableInlineHoverLink,
            hideDimensions,
            hideSize,
            caption,
        } = displayOptions;
        
        
        if (isLoading) return;  //* show nothing while preparing value

        if (enrichedBlob) { //* could be null on empty new form

            let inlineImage, lightBoxImage, file
            
            if (enrichedBlob.isImage) {
                if (disableInlineHoverLink) {
                        inlineImage = (
                            <img src={enrichedBlob.inlineUrl} alt={enrichedBlob.name}/>
                        );
                    } else {
                        inlineImage = (
                            <a
                                onClick={() => setIsOpen(true)}
                                className={css({
                                    cursor: "pointer"
                                })}
                            >
                                <img src={enrichedBlob.inlineUrl} alt={enrichedBlob.name}/> 
                            </a>
                        );    
                    }
                
                lightBoxImage = (
                    <span>
                        { hideDimensions === true ? null :
                           <>{enrichedBlob.lightBoxWidth} x {enrichedBlob.lightBoxHeight}<br/></>
                        }
                        { hideTextLink === true ? null :
                          <StyledLink
                              onClick={() => setIsOpen(true)}
                              className={css({
                                  cursor: "pointer"
                              })}
                          >
                            View Image
                          </StyledLink>
                        }
                      <Modal
                          onClose={() => setIsOpen(false)}
                          isOpen={isOpen}
                          animate
                          autoFocus
                          size={SIZE.auto}
                          role={ROLE.dialog}
                      >
                          { hideName ? null :
                        <ModalHeader>{enrichedBlob.name}</ModalHeader>
                          }
                        <ModalBody>
                                {displayOptions.imageResizeMode === imageViewResizeModes.imgTagDimensions &&
                                    (enrichedBlob.rawHeight > enrichedBlob.lightBoxHeight || enrichedBlob.rawWidth > enrichedBlob.lightBoxWidth) ?
                                    <p>This image has been resized to better fit the screen. <br/>The actual dimensions
                                        are {enrichedBlob.rawWidth}x{enrichedBlob.rawHeight}.</p> : null}
                                <img src={enrichedBlob.lightBoxUrl} height={enrichedBlob.lightBoxHeight} width={enrichedBlob.lightBoxWidth} alt={enrichedBlob.name}/>
                        </ModalBody>
                      </Modal>
                    </span>
                );
            } else {
                file = enrichedBlob.objectUrl ? <StyledLink
                    href={enrichedBlob.objectUrl}
                    download={enrichedBlob.name}
                    style={{
                        verticalAlign: "middle"
                    }}
                >
                    <Download size={20}/>
                </StyledLink> : null;
            }

            return (
                <div>
                    {inlineImage}<br/>
                    {caption ? [caption, <br key={"newline"}/>]: null}
                    {hideName === true ? null : <>{enrichedBlob.name}&nbsp;</>}
                    {hideSize === true ? null : <>({enrichedBlob.sizeInKb})&nbsp;</>}
                    {hideSize === true && hideName === true ? null : <br /> }
                    {hideTextLink === true && disableInlineHoverLink === true ? null : lightBoxImage}
                    {hideTextLink === true ? null : file}
                </div>

            );
        }
    }

}
