﻿import * as React from 'react';
import * as Soap from '@soap/modules'
import {SecondaryActionMenuButton, translate} from '@soap/modules'
import {CenteredCard} from "../z40-uicomp/centered-card";
import {LinkButton} from "../z40-uicomp/buttons";
import {Frame} from "../z40-uicomp/frame";
import {SpaceBottom, SpaceTop, SpaceTopAndBottom} from "../z40-uicomp/layout";
import {HeadingLarge, LabelMedium} from "baseui/typography";
import {CenteredColumn} from "../z40-uicomp/centered-column";


export function Help() {


    const viewStates = {
        showForm: 0,
        showThankYou: 1
    };

    const [viewState, setView] = React.useState(viewStates.showForm);
    
    function Content() {
        switch (viewState) {
            case viewStates.showForm:
                return (<><Soap.AutoForm
                    query={{
                        $type: "C118v1_GetC117FormData"
                    }}
                    submitText={translate("Send")}
                    afterSubmit={() => setView(viewStates.showThankYou)}
                />

                    <SpaceBottom><LabelMedium>
                        ATest<br/>
                        Bartle House,<br/>
                        Oxford Court,<br/>
                        Manchester M23WQ
                    </LabelMedium></SpaceBottom>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2374.549720783961!2d-2.2464724223789987!3d53.476506964975606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bb1ea183342fd%3A0x7f69ec4e969c3e5d!2sBartle%20House%20Oxford%20Court%2C%20Manchester%20M2%203WQ!5e0!3m2!1sen!2suk!4v1702171441517!5m2!1sen!2suk" width="100%" height="350" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </>);
            case viewStates.showThankYou:
                return (<div>{translate("Thank you")}. {translate("We will respond shortly")}.<SpaceTopAndBottom><LinkButton
                    onClick={() => setView(viewStates.showForm)}>{translate("Send Another Message")}</LinkButton></SpaceTopAndBottom>
                </div>);
        }
    }

    return (
        <Frame>
        <CenteredColumn  maxWidth={800} showRobot={false}>
            <SpaceBottom override={"25px"}>
            <HeadingLarge>{translate("Contact Us")}</HeadingLarge>
            </SpaceBottom>
            <Content/>
        </CenteredColumn>
        </Frame>
    );
}