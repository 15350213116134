import {Checkbox, LABEL_PLACEMENT, STYLE_TYPE} from "baseui/checkbox";
import {LabelSmall} from "baseui/typography";
import * as React from "react";
import {translate} from "@soap/modules";

export function IncludeArchivedCheckbox({archived, onChange}) {

    return (
        <div style={{
            paddingTop: "10px" //nasty HACK
        }}><Checkbox
            overrides={{
                ToggleTrack: {
                    style: ({$theme}) => ({marginTop: "2px"}) //nasty HACK
                }
            }}
            checked={archived}
            checkmarkType={STYLE_TYPE.toggle}
            onChange={e => {
                const includeArchived = e.target.checked;
                onChange(includeArchived);
            }}
            labelPlacement={LABEL_PLACEMENT.right}
        >
            <div><LabelSmall>{translate("Include Archived")}</LabelSmall></div>
        </Checkbox></div>
    );
}