﻿import React from "react";
import {AutoForm, bus, translate, useEvent} from "@soap/modules";
import {Frame} from "../z40-uicomp/frame";
import {CenteredCard} from "../z40-uicomp/centered-card";
import {StyledBackLinkWithTitle} from "../z40-uicomp/back-link-with-title";
import {HeadingMedium} from "baseui/typography";

export function AddCandidate({}) {

    useEvent({
        eventName: "E133v1_CandidateAdded",
        onEventReceived: (e, v) => {
            location.href = '#/candidates/candidate-library';
        }
    });

    return (
        <Frame>
            <CenteredCard title={<HeadingMedium>Add Candidate</HeadingMedium>} onBack={() => location.href = '#/candidates/candidate-library'}>
                <AutoForm
                    query={{
                        $type: "C160v1_GetC159FormData"
                    }}
                    submitText={translate("Add")}
                    afterSubmit={() => bus.closeAllDialogs()}
                />
            </CenteredCard>
        </Frame>);
}


