﻿import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import {useStyletron} from "baseui";
import {
    AggregateList,
    EntityMenu,
    SecondaryActionMenuButton,
    translate,
    useDomainEvent,
    useEvent,
    useLazyQuery,
    uuidv4
} from "@soap/modules";
import {KIND} from "baseui/button";
import {ArchiveQuestion} from "./modals/archive-question";
import {EditQuestionMenu} from "./drawers/edit-question-menu";
import {EditQuestionText} from "../z60-pagecomp/edit-question/edit-question-text";
import {EditQuestionValue} from "../z60-pagecomp/edit-question/edit-question-value";
import {questionTypes} from "../z50-domaincomp/question-types";
import {EditQuestionTags} from "../z60-pagecomp/edit-question/edit-question-tags";
import {EditQuestionBooleanOptions} from "../z60-pagecomp/edit-question/edit-question-boolean-options";
import {EditQuestionFreeTextOptions} from "../z60-pagecomp/edit-question/edit-question-free-text-options";
import {EditQuestionDefineOptions} from "../z60-pagecomp/edit-question/edit-question-define-options";
import {TagSelect} from "./tag-select";
import {SoapPaginator, useSoapPaginator} from "./soap-paginator";
import {IncludeArchivedCheckbox} from "../z40-uicomp/include-archived-checkbox";
import {DeleteQuestion} from "./modals/delete-question";
import {Tag, VARIANT} from "baseui/tag";
import {KIND as TAGKIND} from "baseui/tag/constants";
import {useDeviceType} from "../hooks/useDeviceType";
import {AddIcon, EditIcon, ViewIcon} from "../z20-icons/icons";

export function QuestionTable(props) {

    const [css, theme] = useStyletron();
    const pageSize = 5;

    const {
        fetchPage,
        currentPageRecords: e110,
        recordCount,
        resetRecordSet,
        currentPage
    } = useSoapPaginator("E110v1_GotQuestions", "C126v1_GetQuestions", pageSize, {});

    const [viewState, setViewState] = React.useState(["question-table"]);

    const [refreshTags, e103] = useLazyQuery("C119v1_GetTags", {
        C119_TagTypes: {
            SelectedKeys: ['question']
        }
    });
    
    useEvent({
        eventName: "E109v1_QuestionCreated",
        onEventReceived: (e, v) => {
            //* ensure the new question appears at the right place in the table, forcing a reset
            resetRecordSet();
        }
    }, [fetchPage]);

    useEvent({
        eventName: "E118v1_QuestionArchived",
        onEventReceived: (e, v) => {
            //* just refresh the current page
            fetchPage();
        }
    }, [fetchPage]);

    useEvent({
        eventName: "E182v1_QuestionDeleted",
        onEventReceived: (e, v) => {
            //* just refresh the current page
            fetchPage();
        }
    }, [fetchPage]);

    useEvent({
        eventName: "E108v1_TagUpdated",
        onEventReceived: (e, v) => {
            //* not possible from the user's action but perhaps from a broadcast action 
            fetchPage();
            refreshTags();
        }
    }, [fetchPage, refreshTags]);

    useEvent({
        eventName: "E106v1_TagRemoved",
        onEventReceived: (e, v) => {
            //* not possible from the user's action but perhaps from a broadcast action
            fetchPage();
            refreshTags();
        }
    }, [fetchPage, refreshTags]);
    
    useEvent({
        eventName: "E105v1_TagAdded",
        onEventReceived: (e, v) => {
            //* show new item
            refreshTags();
        }
    }, [refreshTags]);
    
    useDomainEvent({
        eventName: "menu-item-selected",
        conversationId: "edit-question",
        onEventReceived: (event) => {
            //* causes render of edit panel rather than grid
            setViewState([event.menuItemId, event.questionId, event.questionType]);
        }
    }, []);

    useDomainEvent({
        eventName: "edit-complete",
        conversationId: "edit-question",
        onEventReceived: (event) => {
            //* this occurs when we return from editing
            setViewState(["question-table"]);
            resetRecordSet();
        }
    }, []);


    const [includeArchived, setIncludeArchived] = useState(false);
    
    /* start tags */

    const [selectedTags, setSelectedTags] = useState([]);
    const [tableKey, setTableKey] = useState(uuidv4());

    const selectOptions = useMemo(() => e103?.e103_Tags.map(x => ({
        label: x.e103_Title,
        id: x.e103_LongId
    })) ?? [], [e103]);

    const onOptionsChange = useCallback((selectedOptions) => {
        //* trigger always seems to run first even if its placed after set statements.
        if (selectedOptions.length) {
            fetchPage(1, {
                C126_PipeSeparatedTagIds: selectedOptions.map(t => t.id).join('|'),
                C126_IncludeArchived: includeArchived
            });
        } else {
            resetRecordSet();
        }
        setSelectedTags(selectedOptions);
        setTableKey(uuidv4()); //get rid of any previous state (e.g. expanded rows)
    }, []);

    /* end tags */

    function getQuestionName(id) {
        const name = e110?.e110_Questions.find(x => x.e110_QuestionId === id).e110_Name;
        return name;
    }

    const actionsArray = useMemo(() => {
        const archiveAllowed = entity => entity.e110_Archived !== true;
        const editAllowed = entity => entity.e110_Archived !== true;

        return [
            (entity) => editAllowed(entity) ? <EditQuestionMenu questionId={entity.e110_QuestionId}
                                                                questionType={Object.values(questionTypes).find(x => x.key === entity.e110_TypeKey)}/> : null,
            (entity) => archiveAllowed(entity) ? <ArchiveQuestion questionName={entity.e110_Name}
                                         questionId={entity.e110_QuestionId}/> : null,
            (entity) => <DeleteQuestion  questionName={entity.e110_Name}
                                         questionId={entity.e110_QuestionId} />
        ];
    },[]);
    
    const {isDesktop} = useDeviceType();
    const headerColumns = useMemo(() => {
        const c = ["e110_Name"];
        if (isDesktop) {
            c.push("e110_TagsApplied");
        }
        return c;
    },[isDesktop]);


    switch (viewState[0]) {
        case "question-table":
        default:
            return (<><AggregateList
                key={tableKey}
                title={translate("Questions")}
                aggregates={e110?.e110_Questions}
                rowColour={theme.colors.accent}
                headerColumns={headerColumns}
                propertyRenderer={{
                    "e110_TagsApplied": (value) => value.map(i =>  <Tag closeable={false}
                                                                        variant={VARIANT.solid}
                                                                        kind={TAGKIND.neutral}
                                                                        key={i}>{i}</Tag>),
                }}
                hiddenFields={["e110_QuestionId", "e110_TypeKey"]}
                entityMenus={{
                    "root-individual": new EntityMenu(null, [
                        () => <TagSelect options={selectOptions} value={selectedTags} onChange={onOptionsChange}/>,
                        () => <IncludeArchivedCheckbox archived={includeArchived} onChange={(includeArchived) => {
                            resetRecordSet({
                                C126_PipeSeparatedTagIds: selectedTags.map(t => t.id).join('|'),
                                C126_IncludeArchived: includeArchived
                            });
                            setIncludeArchived(includeArchived);
                        }} />,
                        () => <SecondaryActionMenuButton kind={KIND.tertiary}
                                                         onClick={() => location.href = "#/questions/question-library/add-question"}>
                                                        <AddIcon/>{translate("Create")}</SecondaryActionMenuButton>,
                        () => (
                            <SecondaryActionMenuButton kind={KIND.tertiary}
                                                       onClick={() => location.href = "#/questions/question-library/tags"}>
                                {<><EditIcon /><>{translate("Tags")}</></>}
                            </SecondaryActionMenuButton>
                        )
                    ]),
                    "root-Items": new EntityMenu(null, actionsArray),
                }}
            /> <SoapPaginator pageSize={pageSize}
                              page={currentPage}
                              recordCount={recordCount}
                              onPageChanged={(page) => {
                                  fetchPage(page);
                              }}
            /></>)
        case "edit-question":
            return <EditQuestionText questionId={viewState[1]} questionName={getQuestionName(viewState[1])}
                                     questionType={viewState[2]} selectedItemId={viewState[0]}/>;
        case "edit-value":
            return <EditQuestionValue questionId={viewState[1]} questionName={getQuestionName(viewState[1])}
                                      questionType={viewState[2]} selectedItemId={viewState[0]}/>;
        case "edit-tags":
            return <EditQuestionTags questionId={viewState[1]} questionName={getQuestionName(viewState[1])}
                                     questionType={viewState[2]} selectedItemId={viewState[0]}/>;
        case "edit-question-options-" + questionTypes.Boolean.key:
            return <EditQuestionBooleanOptions questionId={viewState[1]} questionName={getQuestionName(viewState[1])}
                                               questionType={viewState[2]} selectedItemId={viewState[0]}/>;
        case "edit-question-options-" + questionTypes.FreeText.key:
            return <EditQuestionFreeTextOptions questionId={viewState[1]} questionName={getQuestionName(viewState[1])}
                                                questionType={viewState[2]} selectedItemId={viewState[0]}/>;
        case "edit-question-options-" + questionTypes.MultipleChoice.key:
        case "edit-question-options-" + questionTypes.SingleSelection.key:
        case "edit-question-options-" + questionTypes.SpecificString.key:
            return <EditQuestionDefineOptions questionId={viewState[1]} questionName={getQuestionName(viewState[1])}
                                              questionType={viewState[2]} selectedItemId={viewState[0]}/>;
    }
}