import {useStyletron} from "baseui";
import * as React from "react";

export function FloatingCard({children}) {
    const [css, theme] = useStyletron();
    return (
        //* light gray box
        <div className={css({
            margin: theme.sizing.scale600,
            backgroundColor: theme.colors.accent500,
            boxShadow: "0px 0px 25px #999ea4",
            borderRadius: theme.sizing.scale300,
            width: '250px',


        })}>
            {/*inner white box*/}
            <div className={css({
                padding: '20px',
                borderRadius: theme.sizing.scale300,
                backgroundColor: '#ffffff',
                display: 'flex',
                flexDirection: 'column',
                minHeight: '650px'
            })}>
                {children}
            </div>
        </div>
    );
}