import {useStyletron} from "baseui";
import React from "react";

export function DialogListItem({children}) {
    const [css, theme] = useStyletron();

    return (
        <li
            className={css({
                
                marginTop: theme.sizing.scale200,
            })}
        >{children}</li>
    );
}