﻿import {CenterSpinner, useDomainEvent, useEvent, useLazyQuery, uuidv4} from "@soap/modules";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {CenteredCard} from "../../z40-uicomp/centered-card";
import {useStyletron} from "baseui";
import {EditAssessmentTopNav} from "../../z50-domaincomp/drawers/edit-assessment-menu";
import {Checkbox, LABEL_PLACEMENT, STYLE_TYPE} from "baseui/checkbox";
import {TimingModeOptions} from "./questions/timing-mode-options";
import {EditQuestionList} from "./questions/edit-question-list";
import {EditQuestionsReorderMode} from "./questions/edit-questions-reorder-mode";
import {TalkBadge} from "../../z40-uicomp/talk-badge";
import {Centered, SpaceTopAndBottom} from "../../z40-uicomp/layout";


function AssessmentQuestions({assessmentId}) {

    const [css, theme] = useStyletron();
    const [getAssessment, gotAssessment] = useLazyQuery("C188v1_GetAssessment", {
        C188_AssessmentId: assessmentId
    });

    const viewStates = {
        loadingAssessment: 1,
        showAssessment: 2
    };


    const [viewState, setViewState] = useState(viewStates.loadingAssessment);
    const [assessment, setAssessment] = useState();
    const [reorderMode, setReorderMode] = useState(false);

    useDomainEvent({
        eventName: "timing-mode-changed",
        conversationId: "edit-assessment-questions",
        onEventReceived: (event) => {

            const cloned = JSON.parse(JSON.stringify(assessment));
            cloned.TimingMode = event.newTimingMode;
            setAssessment(cloned);
        }
    }, []);


    useEvent({
        eventName: "E156v1_AssessmentSectionAdded",
        onEventReceived: (event) => {
            const section = {
                SectionId: event.e156_SectionId,
                Title: event.e156_SectionTitle,
                TimeLimit: event.e156_SectionTimeLimit,
                Points: undefined,
                QuestionCount: 0,
                Questions: []
            };

            const cloned = JSON.parse(JSON.stringify(assessment));
            cloned.Sections.push(section);
            calcPoints(cloned);
            setAssessment(cloned);
        }
    }, []);

    
    useEvent({
        eventName: "E159v1_QuestionAddedToAssessment",
        onEventReceived: (event) => {
            const question = {
                QuestionId: event.e159_QuestionId,
                Title: event.e159_QuestionTitle,
                Text: event.e159_QuestionText,
                TimeLimit2: event.e159_TimeLimit,
                Points3: event.e159_Points
            };

            const cloned = JSON.parse(JSON.stringify(assessment));
            cloned.Sections.find(s => s.SectionId === event.e159_SectionId).Questions.push(question);
            calcPoints(cloned);
            setAssessment(cloned);
        }
    }, []);

    useEvent({
        eventName: "E157v1_AssessmentSectionRemoved",
        onEventReceived: (event) => {

            const cloned = JSON.parse(JSON.stringify(assessment));
            cloned.Sections = cloned.Sections.filter(s => s.SectionId !== event.e157_SectionId);
            calcPoints(cloned);
            setAssessment(cloned);
        }
    }, []);

    useEvent({
        eventName: "E149v1_AssessmentUpdated",
        onEventReceived: (event) => {

            const cloned = JSON.parse(JSON.stringify(assessment));
            cloned.Version = event.e149_VersionId;
            setAssessment(cloned);
        }
    }, []);


    useEvent({
        eventName: "E160v1_QuestionRemovedFromAssessment",
        onEventReceived: (event) => {

            const cloned = JSON.parse(JSON.stringify(assessment));
            cloned.Sections.forEach(s => {
                s.Questions = s.Questions.filter(s => s.QuestionId !== event.e160_QuestionId);
            })
            calcPoints(cloned);
            setAssessment(cloned);
        }
    }, []);

    useEffect(() => {

        function convertE154(e154) {
            return {
                ShareCount: e154.e154_ShareCount,
                Points: 0,
                AssessmentId: assessmentId,
                OverallTimeLimit: e154.e154_AssessmentTimeLimitInSeconds,
                TimingMode: e154.e154_TimingModeKey,
                Version: e154.e154_Version,
                Sections: e154.e154_Sections.map(section => ({
                    TimeLimit: section.e154_SectionTimeLimitInSeconds,
                    SectionId: section.e154_SectionId,
                    Points2: section.e154_SectionPoints,
                    Title: section.e154_SectionTitle,
                    Questions: section.e154_Questions.map(question => ({
                        QuestionId: question.e154_QuestionId,
                        Title: question.e154_QuestionTitle,
                        QuestionText: question.e154_QuestionText,
                        TimeLimit2: question.e154_QuestionTimeLimitInSeconds,
                        Points3: question.e154_QuestionPoints
                    }))
                }))
            };
        }
    
        if (gotAssessment) {
            const converted = convertE154(gotAssessment);
            calcPoints(converted);
            setAssessment(converted);
            setViewState(viewStates.showAssessment);
        }

    }, [gotAssessment]);

    switch (viewState) {
        case viewStates.loadingAssessment:
            return <CenterSpinner/>;
        case viewStates.showAssessment:
            return (<div>
                <AssessmentHelpNotifications sectionCount={assessment.Sections.length}
                                             questionCount={assessment.Sections.flatMap(x => x.Questions).length}
                                                shareCount={assessment.ShareCount}
                />
                <Centered>Version Id: {assessment.Version}, Total Points: {assessment.Points}</Centered>
                <TimingModeOptions assessment={assessment}/>
                <SpaceTopAndBottom>
                <Checkbox
                    checked={reorderMode}
                    checkmarkType={STYLE_TYPE.toggle}
                    onChange={e => setReorderMode(Boolean(e.target.checked))}
                    labelPlacement={LABEL_PLACEMENT.right}
                >
                    {reorderMode ? "Turn Off" : "Turn On"} Reordering Mode
                </Checkbox>
                </SpaceTopAndBottom>
                {reorderMode ? <EditQuestionsReorderMode assessment={assessment} setAssessment={setAssessment}/> :
                    <EditQuestionList  assessment={assessment} setAssessment={setAssessment}/>
                }
            </div>);
    }
}

function AssessmentHelpNotifications({sectionCount, questionCount, shareCount}) {

    const show = useMemo(() => {
        return sectionCount === 0 || questionCount === 0 || shareCount === 0;
    }, [sectionCount, questionCount]);

    const textMemo = useMemo(() => {
        if (!sectionCount) {
            return <>Questions on an assessment are grouped into sections. <br/>Start filling out your assessment using the Add Section button below.</>;
        }
        if (!questionCount) {
            return <>Now that you've added a section you can add questions to it. <br/>Select Add Question from
                the <button>{"\uFE19"}</button> menu next to the section you want to populate</>;
        }
        if (!shareCount) {
            return <>When you have added all the questions that you want to the assessment, return to the assessment list to share your assessment with one or more candidates.</>;
        }
    }, [sectionCount, questionCount, shareCount]);

    return (
        show ?
            <SpaceTopAndBottom><TalkBadge showRobot={true}>{textMemo}</TalkBadge></SpaceTopAndBottom> : null
    );
}

export function EditAssessmentQuestions({assessmentId, assessmentTitle, selectedItemId}) {

    const [css, theme] = useStyletron();
    

    return (<CenteredCard onBack={() => location.href = "#/assessments/assessment-library"} title={"Edit Assessment"} maxWidth={800}>
            <Centered>
            <EditAssessmentTopNav assessmentId={assessmentId} assessmentTitle={assessmentTitle}
                                  selectedItemId={selectedItemId}/>
            </Centered>
            <AssessmentQuestions assessmentId={assessmentId}/>
        </CenteredCard>

    );
}

export function calcPoints(assessment) {
    assessment.Points = 0;
    assessment.Sections.forEach(s => {
        s.Points2 = 0;
        s.Questions.forEach(q => {
            s.Points2 += q.Points3;
        });
        assessment.Points += s.Points2;
    });
}