﻿import {AutoForm, useDomainEvent} from "@soap/modules";
import React, {useState} from "react";
import {CenteredCard} from "../../z40-uicomp/centered-card";
import {AssessmentShared} from "./assessment-shared";


export function ShareByEmail({assessmentId}) {

    const viewStates = {
        showForm: 1,
        showConfirmation: 2
    };

    const [viewState, setViewState] = useState(viewStates.showForm);

    const back = useDomainEvent({
        eventName: "back-to-share-assessment",
        conversationId: "share-assessment"
    });

    switch (viewState) {
        case viewStates.showForm:
            return (
                <>
                    <CenteredCard title={"Share Assessment"} onBack={() => back()} maxWidth={800}>
                        <AutoForm
                            query={{
                                $type: "C200v1_GetC199FormData",
                                C200_AssessmentId: assessmentId
                            }}
                            afterSubmit={() => {
                                setViewState(viewStates.showConfirmation);
                            }}
                            hiddenFields={["C199_AssessmentId"]}
                            submitText={"Share"}
                        />
                    </CenteredCard>
                </>
            );
        case viewStates.showConfirmation:
            return <AssessmentShared/>;
    }
}

