import {enableInlineConsole} from "../modules/inline-console";
import {SecondaryActionMenuButton} from "@soap/modules";

export function EnableILC({}) {

    return (
        <>
            <div>
                <SecondaryActionMenuButton onClick={() => enableInlineConsole()}>Enable ILC</SecondaryActionMenuButton>
                <SecondaryActionMenuButton onClick={() => location.href = "#/"}>Home</SecondaryActionMenuButton>
            </div>
        </>);
}