﻿import {CenteredCard} from "../../z40-uicomp/centered-card";
import {AutoForm, useDomainEvent, useEvent, uuidv4} from "@soap/modules";
import React, {useState} from "react";
import {EditQuestionTopNav} from "../../z50-domaincomp/drawers/edit-question-menu";

export function EditQuestionText({questionId, questionType, questionName,selectedItemId}) {

    const completeEdit = useDomainEvent({
        eventName: "edit-complete",
        conversationId: "edit-question"
    });
    
    const [autoFormKey, setAutoFormKey] = useState(uuidv4());

    useEvent({
        eventName: "E125v1_QuestionUpdated",
        onEventReceived:(e) => {
            setAutoFormKey(uuidv4())
        }
    })
    
    return (<CenteredCard onBack={() => completeEdit()} title={"Edit Question"}>
            <EditQuestionTopNav questionId={questionId} questionType={questionType} selectedItemId={selectedItemId} />
            <AutoForm
                title={"Edit the Question Text"}
                key={autoFormKey}
                query={{
                    $type: "C147v1_GetC146FormData",
                    "C147_QuestionId": questionId
                }}
                hiddenFields={["C146_QuestionId"]}
                submitText={"Save"}
            />
        </CenteredCard>
    );
}