﻿import {ActionModal, translate} from "@soap/modules";
import React from "react";
import {DialogListItem} from "../../z40-uicomp/dialog-list-item";
import {CheckedListItem} from "../../z40-uicomp/checked-list-item";
import {CenteredCard} from "../../z40-uicomp/centered-card";
import {CenteredColumn} from "../../z40-uicomp/centered-column";

export function ArchiveAssessment(props) {
    const {assessmentId, assessmentTitle} = props;
    return (
        <ActionModal menuText={translate("Archive")} modalHeader={`${translate("Archive")} ${translate("Assessment")}`} auth={true}
                     command={{
                         $type: 'C183v1_ArchiveAssessment',
                         C183_AssessmentId: assessmentId
                     }}>
            <p>{`${translate("This action cannot be undone")}.`}. <br/>{translate("Please confirm you want to archive this assessment")} <strong>{assessmentTitle}</strong>?</p>
            <br/>
            <CenteredColumn>
                <DialogListItem>
                    {translate("Archived Assessments")}
                    <ul>
                        <CheckedListItem>
                            {translate("can be viewed by using the Include Archived switch")}
                        </CheckedListItem>
                        <CheckedListItem>
                            {translate("cannot be shared and any Shares are cancelled")}
                        </CheckedListItem>
                    </ul>
                </DialogListItem>
                <DialogListItem>
                    {translate("Historical Data such as Archived Items are counted against your plan limits")}
                </DialogListItem>
                <DialogListItem>
                    {translate("Assessment Results for Archived Assessments will still be visible throughout the system, if you wish to remove an Assessment and its results entirely, use Delete")}
                </DialogListItem>
            </CenteredColumn>;

        </ActionModal>
    );
}