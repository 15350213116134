﻿import {useStyletron} from "baseui";
import {SpaceLeftAndRight} from "./layout";
import * as React from "react";

export function CenteredColumn({children, maxWidth, noMargins}) {
    const [css, theme] = useStyletron();

    return (<div className={css({
        width: '100%'
        // container to fill parent rather than defaulting to width of children
    })}>
        <div className={css({
            margin: '0 auto', // center it 
            maxWidth: maxWidth ? `${maxWidth}px` : undefined // restrain the width from filling parent
        })}>
            <SpaceLeftAndRight override={noMargins ? 0 : undefined}>
                {children}
            </SpaceLeftAndRight>
        </div>
    </div>);
}