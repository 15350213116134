﻿import {useLogin, translate, useAuth} from "@soap/modules";
import {ButtonSecondary} from "/z40-uicomp/Buttons";
import {SIZE} from "baseui/button";
import React from "react";
import {useStyletron} from "baseui";

export function SignupContent(props) {
    const {
        isAuthenticated,
        authReady,
        authEnabled
    } = useAuth();
    const [css, theme] = useStyletron();

    
    if (!isAuthenticated && authReady && authEnabled) {
        return (
            <ButtonSecondary size={SIZE.default} onClick={() => location.href ='#/pricing'} $style={{
                backgroundColor: theme.colors.backgroundAccent,
                color: theme.colors.contentOnColor,
                fontWeight: "normal",
                whiteSpace: 'nowrap'
            }}>{translate("Sign Up!")}</ButtonSecondary>
        );
    } else {
        return null;
    }
}