﻿import {ActionModal, translate} from "@soap/modules";
import * as React from "react";
import {sharedAssessmentTypes} from "../constants/shared-assessment-types";




export function RevokeSharedAssessmentLink({sharedAssessmentId, shareTypeKey, linkId}) {
    
    const title = shareTypeKey === sharedAssessmentTypes.ByIFrame ? translate("Link") : translate("Invitation");

    return (
        <ActionModal title={translate("Revoke") + " " + translate("Shared Assessment")} auth={true}
                     command={{
                         $type: 'C210v1_RevokeSharedAssessment',
                         C210_SharedAssessmentId: sharedAssessmentId,
                         C210_LinkId: linkId
                     }}>
            <p>{`Please confirm you want to revoke this ${title}?`}</p>
            <br/>
            <p>{translate("All exposed links will be invalidated and return an error after revoking.")}</p>
        </ActionModal>
    );
}