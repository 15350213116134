﻿import * as React from 'react';
import {useStyletron} from "baseui";

import {
    AggregateList,
    EntityMenu,
    SecondaryActionMenuButton,
    translate,
    types,
    useEvent,
    useLazyQuery,
    validateArgs
} from "@soap/modules";
import {AddTag} from "./drawers/add-tag";
import {RemoveTag} from "./modals/remove-tag";
import {EditTag} from "./drawers/edit-tag";
import {StatefulPanel} from "baseui/accordion";
import {Link} from "baseui/link/styled-components";
import {KIND} from "baseui/button";
import {BackLinkWithTitle, StyledBackLinkWithTitle} from "../z40-uicomp/back-link-with-title";
import {CenteredCard} from "../z40-uicomp/centered-card";
import {useState} from "react";
import {useSoapPaginator} from "./soap-paginator";
import {LinkButton} from "../z40-uicomp/buttons";
import {Centered, SpaceTopAndBottom} from "../z40-uicomp/layout";
import {render} from "react-dom";

export function RecentlyCompletedWidget() {

    const [css, theme] = useStyletron();
    const pageSize = 5;
    const {
        fetchPage,
        currentPageRecords: e176,
        recordCount,
        resetRecordSet,
        currentPage
    } = useSoapPaginator("E176v1_GotAssessmentResults", "C218v1_GetAssessmentResults", pageSize, {
        C218_CompletedOnly: true
    });
    const hiddenFields=["e176_ContinuationToken","e176_PageNumber","e176_RecordCount","e176_AssessmentId","e176_AssessmentResultId","e176_Version","e176_DateTaken","e176_Candidate","e176_Score","e176_NeedsMarking","e176_Status"];
    const renderLink = (value, entity) => <LinkButton onClick={() => location.href = `#/assessments/${entity.e176_AssessmentId}/results/${entity.e176_AssessmentResultId}/1`}>{value}</LinkButton>
    return (
        <SpaceTopAndBottom>
            <CenteredCard showRobot={false} title={translate("Recently Completed")}>
                {e176?.e176_AssessmentResults?.length ?
                <AggregateList
                    aggregates={e176?.e176_AssessmentResults}
                    headerColumns={["e176_Candidate", "e176_Assessment"]}
                    hiddenFields={hiddenFields}
                    propertyRenderer={{
                        e176_Assessment: renderLink
                    }}
                /> : (<Centered>{translate("There are no recent assessments.")}</Centered>)}
            </CenteredCard>
        </SpaceTopAndBottom>
    );
}