﻿import {AutoForm, useDomainEvent} from "@soap/modules";
import React, {useState} from "react";
import {CenteredCard} from "../../z40-uicomp/centered-card";
import {BackLinkWithTitle} from "../../z40-uicomp/back-link-with-title";
import {AssessmentShared} from "./assessment-shared";


export function ShareByCandidate({assessmentId}) {

    const viewStates = {
        showForm: 1,
        showConfirmation: 2
    };

    const [viewState, setViewState] = useState(viewStates.showForm);

    const back = useDomainEvent({
        eventName: "back-to-share-assessment",
        conversationId: "share-assessment"
    });

    switch (viewState) {
        case viewStates.showForm:
            return (
                <>
                    <CenteredCard title={"Share Assessment"} onBack={() => back()}>
                        <AutoForm
                            query={{
                                $type: "C204v1_GetC203FormData",
                                C204_AssessmentId: assessmentId
                            }}
                            afterSubmit={() => {
                                setViewState(viewStates.showConfirmation);
                            }}
                            hiddenFields={["C203_AssessmentId"]}
                            submitText={"Share"}
                        />
                    </CenteredCard>
                </>
            );
        case viewStates.showConfirmation:
            return <AssessmentShared />;
    }
}

