﻿import {ActionDrawer, AutoForm, bus, translate} from "@soap/modules";
import React from "react";
import {AddIcon} from "../../z20-icons/icons";


export function ChangeTenantName(props) {
    const {tenantId} = props;
    return (
        <ActionDrawer  title={<>{translate("Change")} {translate("Organisation Name")}</>} auth={true}>
            <AutoForm
                query={{
                    $type: "C240v1_GetC239FormData"
                }}
                submitText={translate("Save")}
                afterSubmit={() => bus.closeAllDialogs()}
            />
        </ActionDrawer>
    );
}

