import {ListItem} from "baseui/list";
import {Check} from "baseui/icon";
import React from "react";

export function CheckedListItem({children}) {
    return (
        <ListItem
            artwork={() => <Check/>}
            overrides={{
                Root: {
                    style: ({$theme}) => ({
                        borderBottomStyle:"none",
                        borderTopStyle:"none",
                        borderLeftStyle:"none",
                        borderRightStyle:"none",
                        marginTop: $theme.sizing.scale300
                    })
                },
                Content: {
                    style: ({$theme}) => ({
                        minHeight: "0px",
                        borderBottomStyle:"none",
                        borderTopStyle:"none",
                        borderLeftStyle:"none",
                        borderRightStyle:"none",
                    })
                }
            }}
        >{children}</ListItem>
    );
}