import {CenterSpinner, translate, useAuth, useLazyQuery, useTokenRefreshRequired} from "@soap/modules";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useStyletron} from "baseui";
import {Frame, NeedHelp} from "../z40-uicomp/frame";
import {Cell, Grid} from "baseui/layout-grid";
import logo from "../z30-images/logo-xl.png";
import {DisplayXSmall, HeadingLarge, ParagraphLarge} from "baseui/typography";
import {SignupContent} from "../z40-uicomp/signup-content";
import testIcon from "../z30-images/online-survey-icon.png"

import {CenteredColumn} from "../z40-uicomp/centered-column";
import {BaseWebBreakPoints, Centered, DesktopOnly, MobileOnly, SpaceTop, SpaceTopAndBottom} from "../z40-uicomp/layout";
import {Row} from "../z40-uicomp/row";
import {QuoteCard} from "../z50-domaincomp/quote-card";
import {RecentlyCompletedWidget} from "../z50-domaincomp/recently-completed-widget";
import {NeedsMarkingWidget} from "../z50-domaincomp/needs-marking-widget";
import {LinkButton} from "../z40-uicomp/buttons";
import {useMediaQuery} from "react-responsive";
import {CenteredCard} from "../z40-uicomp/centered-card";
import {refreshTokens} from "../modules/jwt-helpers";

export function Home() {
    const {
        authReady,
        isAuthenticated,
        accessToken,
        idToken,
        refresh
    } = useAuth("home.js");

    const viewStates = {
        loadingSystem: 1,
        notAuthenticated: 2,
        loadingProfile: 3,
        showWidgets: 4
    };
    const [viewState, setViewState] = useState(viewStates.loadingSystem);
    const [getProfileTrigger, e138GotProfile] = useLazyQuery("C166v1_GetUserProfile");

    useTokenRefreshRequired(useCallback((idToken, accessToken) => {
        (async () => {
            //* if you login with a new account, without going through the signup page you will trigger autoaccountCreationHandler
            await refresh({ignoreCache:true});
            getProfileTrigger();
        })();
        
    }, [getProfileTrigger]));

    useEffect(() => {

        if (viewState === viewStates.loadingSystem && authReady && isAuthenticated && !e138GotProfile) {
            getProfileTrigger();
            setViewState(viewStates.loadingProfile);
        } else if (viewState === viewStates.loadingSystem && authReady && !isAuthenticated) {
            setViewState(viewStates.notAuthenticated);
        } else if (viewState === viewStates.loadingProfile && e138GotProfile) {
            setViewState(viewStates.showWidgets);
        }
    }, [authReady, e138GotProfile, getProfileTrigger]);

    const [css, theme] = useStyletron();

    const greeting = useMemo(() => {
        const today = new Date()
        const curHr = today.getHours()

        if (curHr < 12) {
            return 'Good morning';
        } else if (curHr < 18) {
            return 'Good afternoon';
        } else {
            return 'Good evening';
        }
    }, []);

    const isMobile = useMediaQuery({query: `(max-width: ${BaseWebBreakPoints.Third}px)`})


    switch (viewState) {
        case viewStates.loadingSystem:
            return <Frame><CenteredColumn><CenterSpinner/></CenteredColumn></Frame>;
        case viewStates.notAuthenticated:
            return (
                <Frame>
                    <div className={css({
                        backgroundColor: theme.colors.primaryB
                    })}>
                        <SpaceTopAndBottom>
                            <SpaceTop>
                    <CenteredColumn>
                        <Grid gridGutters={0}>
                            <Cell span={6}>
                                <div>
                                    
                                    <img alt={translate("ATest Logo")} src={logo} width={'100%'} className={css({
                                        marginBottom: "20px"
                                    })}/>
                                    <DisplayXSmall>{translate("Create")}. {translate("Collaborate")}.<br/>{translate("Customise")}.</DisplayXSmall>
                                    <ParagraphLarge
                                        marginTop={theme.sizing.scale750}>
                                        {translate("$OneLineProductDescription")}
                                    </ParagraphLarge>
                                    <ParagraphLarge marginTop={theme.sizing.scale750}>
                                        <span className={css({
                                            marginRight: theme.sizing.scale300
                                        })}> {translate("Read about")}</span>
                                        <LinkButton $style={{marginRight: theme.sizing.scale300}}
                                                    colour={theme.colors.accent}
                                                    hoverColour={theme.colors.contentPrimary}
                                                    hover={true}
                                                    underline={false}
                                                    onClick={() => location.href = "#/features"}>
                                            <strong>{translate("Our Features")}</strong>
                                        </LinkButton><span className={css({
                                        marginRight: theme.sizing.scale300
                                    })}> | </span>
                                        <LinkButton hover={true}
                                                    underline={false}
                                                    colour={theme.colors.accent}
                                                    hoverColour={theme.colors.contentPrimary}
                                                    onClick={() => location.href = "#/mission"}>
                                            <strong>{translate("Our Mission")}</strong>
                                        </LinkButton>
                                    </ParagraphLarge>
                                    <SpaceTopAndBottom override={theme.sizing.scale900}>
                                        <SignupContent/>
                                    </SpaceTopAndBottom>
                                </div>
                            </Cell>
                            <Cell span={6}>
                                
                                <SpaceTop override={"50px"}>
                                <DesktopOnly>
                                    <Centered>
                                        
                                        <img alt={translate("Assessment Icon")} src={testIcon} width={'60%'}/>
                                        
                                    </Centered>
                                </DesktopOnly>
                                
                                <MobileOnly>

                                    <img alt={translate("Assessment Icon")} src={testIcon} width={'60%'}/>
                                    <SpaceTop override={theme.sizing.scale900}>
                                        <NeedHelp/>
                                    </SpaceTop>
                                </MobileOnly>
                                </SpaceTop>
                                
                            </Cell>
                            
                        </Grid>
                    </CenteredColumn>
                            </SpaceTop>
                        </SpaceTopAndBottom>
                    </div>
                </Frame>);
        case viewStates.loadingProfile:
            return <Frame><CenteredColumn><CenterSpinner/></CenteredColumn></Frame>;

        case viewStates.showWidgets:
            return (
                <Frame>
                    <Centered>
                        <HeadingLarge $style={{
                            overflowWrap: "anywhere"
                        }}>{greeting} {e138GotProfile.e138_FirstName}.
                        </HeadingLarge>
                        <Row flexWrap={"wrap"} expandedContentWidth={isMobile ? true : false}>
                            <RecentlyCompletedWidget/>
                            <Centered>
                            <QuoteCard/>
                            </Centered>
                            <NeedsMarkingWidget/>
                        </Row>
                    </Centered>
                </Frame>
            );
    }

}