import i18next from 'i18next';

const defaultNs = 'translation';

(async function() {
    await i18next.init({
        interpolation: {
            //* react already does escaping
            escapeValue: false
        },
        defaultNs,
        debug: true,
        fallbackLng: 'en'
    });
})();

export const addTranslations = (translations, lng = 'en') => {
    i18next.addResourceBundle(lng, defaultNs, translations);
};

export const translate = key => {
    if (!i18next.exists(key)) {
        if (key.startsWith("soap-")) {
            throw `Translation for key "${key}" doesn't exist. Soap Translations not loaded. for default translations call addTranslations(defaultSoapTranslations) or for custom translations call addTranslation(YOUR_FILE_HERE)`;
        } else {
            throw `Translation for key "${key}" doesn't exist. Check language files or if you have not loaded any translations call addTranslations(YOUR_FILE_HERE).`;
        }
    }
    return i18next.t(key);
}

export default i18next;
