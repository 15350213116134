import {useStyletron} from "baseui";
import {SpaceTopAndBottom} from "../z40-uicomp/layout";
import {HeadingSmall} from "baseui/typography";
import * as React from "react";
import {FloatingCard} from "/z40-uicomp/floatingCard";

export function PricingPane({children, title}) {
    const [css, theme] = useStyletron();


    return (
    <FloatingCard>
                <SpaceTopAndBottom>
                    <div className={css({
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    })}>
                        <HeadingSmall>{title}</HeadingSmall>
                    </div>
                </SpaceTopAndBottom>
                {children}
    </FloatingCard>
    );
}