﻿import {ActionDrawer, AutoForm, bus, translate} from "@soap/modules";
import React from "react";
import {TalkBadge} from "../../z40-uicomp/talk-badge";


export function EditBillingDetails({taxMode}) {
    return (
        <ActionDrawer title={translate("Update Billing Details")} auth={true}>
            {taxMode === "external" ?
            <TalkBadge showRobot={true}>
                 Your subscription is priced without tax applied. If your new billing address has a different tax rate, their may be nominal changes in your final price to reflect the difference in tax. 
                This will be reflected on your next invoice. By proceeding you accept this is the case. 
            </TalkBadge> : null}
            <AutoForm
                query={{
                    $type: "C173v1_GetC172FormData"
                }}
                submitText={translate("Update")}
                afterSubmit={() => bus.closeAllDialogs()}
            />
        </ActionDrawer>
    );
}

