﻿import * as React from 'react';
import {useStyletron} from "baseui";
import {AggregateList, EntityMenu, translate, useEvent, useLazyQuery} from "@soap/modules";
import {CenteredCard} from "../z40-uicomp/centered-card";
import {RevokeSharedAssessmentLink} from "./modals/revoke-shared-assessment-link";
import {useEffect, useState} from "react";
import {CancelSharedAssessment} from "./modals/cancel-shared-assessment";
import {SoapPaginator, useSoapPaginator} from "./soap-paginator";
import {componentViewMode} from "./constants/component-view-mode";
import {sharedAssessmentTypes} from "./constants/shared-assessment-types";
import {Centered} from "../z40-uicomp/layout";
import {useLink} from "../hooks/useLink";
import {ResendAssessmentLink} from "./modals/resend-assessment-link";
import {useSnackbar} from "baseui/snackbar";


export function SharedAssessmentsTable({assessmentId}) {

    const [css, theme] = useStyletron();
    
    const pageSize = 5;

    const {
        fetchPage,
        currentPageRecords: e167,
        recordCount,
        resetRecordSet,
        currentPage
    } = useSoapPaginator("E167v1_GotSharedAssessments", "C209v1_GetSharedAssessments", pageSize, {
        C209_AssessmentId: assessmentId
    });

    const { enqueue: enqueue } = useSnackbar();
    const [sharedAssessments, setSharedAssessments] = useState();
    
    const revokeSharedAssessmentLink = (sharedAssessmentId, linkId) => {
        setSharedAssessments(existingAssessments => {
            const index = existingAssessments.findIndex(x => x.e167_SharedAssessmentId === sharedAssessmentId);
            
            const clonedRow = JSON.parse(JSON.stringify(existingAssessments[index]));
            const link = clonedRow.e167_Links.find(link => link.e167_LinkId === linkId);
            link.e167_Status = translate("Revoked");
            link.e167_Revoked = true;
            return [
                ...existingAssessments.slice(0, index),
                clonedRow,
                ...existingAssessments.slice(index + 1),
            ]
        })
    };
    
    const cancelSharedAssessment = (sharedAssessmentId) => {
        setSharedAssessments(existingAssessments => {
            return existingAssessments.filter(x => x.e167_SharedAssessmentId !== sharedAssessmentId);
        })
    };
    
    useEvent({
        eventName: "E168v1_SharedAssessmentLinkRevoked",
        
        onEventReceived: (e168) => {
            revokeSharedAssessmentLink(e168.e168_SharedAssessmentId, e168.e168_LinkId);      
        }
    });

    useEvent({
        eventName: "E189v1_AssessmentResent",

        onEventReceived: (e189) => {
            enqueue({message:`${e189.e189_AssessmentTitle} Resent`});
        }
    });

    useEvent({
        eventName: "E169v1_SharedAssessmentCancelled",
        onEventReceived: (e169) => {
            cancelSharedAssessment(e169.e169_SharedAssessmentId);
        }
    });
    
    useEffect(() => {
        if (e167) {
            setSharedAssessments(e167.e167_SharedAssessments);
        }
    }, [e167]);
    
    const generateLink = useLink();
        const isFilteredByAssessment = sharedAssessments?.length > 0 && assessmentId;
        
    return (
        <CenteredCard title={isFilteredByAssessment ? sharedAssessments[0].e167_Assessment + " Shares" : "Shared Assessments"}
                                                                  onBack={() => location.href = "#/assessments/assessment-library"} maxWidth={800}>
            {isFilteredByAssessment ?
                <Centered>
                    {e167?.e167_SharesDeleted ? (
                        <>
                            {translate("This assessment has")}
                            {" "}
                            {e167.e167_SharesDeleted}
                            {" "}
                            {translate("deleted shares.")}
                        </>
                    ) : ""}
                </Centered>
                : null
            }


            <AggregateList
                
                aggregates={sharedAssessments}
                displayOptions={{
                    hideItemCountToggle: true
                }}
                headerColumns={["e167_Date", "e167_Assessment", "e167_Method", "e167_Expires", "e167_Identifier", "e167_Status"]}
                hiddenFields={["e167_SharedAssessmentId", "e167_TypeKey", "e167_Revoked", "e167_LinkId", "e167_CanBeResent"]}
                propertyRenderer={{
                    "e167_Link": (assessmentResultId, entity) => {
                        const sharedAssessment = e167.e167_SharedAssessments.find(a => a.e167_Links.some(l => l.e167_Link === assessmentResultId));                         
                        return <span style={{
                            wordBreak: "break-all"
                        }}>{generateLink([sharedAssessment.e167_SharedAssessmentId, assessmentResultId], {
                            host: window.location.origin,
                            componentViewMode: sharedAssessment.e167_TypeKey === sharedAssessmentTypes.ByIFrame ? componentViewMode.IFrame : componentViewMode.Website
                        })}</span>;
                    }
                }}
                entityMenus={{
                    "root-Items": new EntityMenu(null, [
                        (entity) => <CancelSharedAssessment sharedAssessmentId={entity.e167_SharedAssessmentId} assessmentTitle={entity.e167_Assessment} />
                    ]),
                    "e167_Links-Items": new EntityMenu(null, [
                        (entity) => {
                            const sharedAssessment = sharedAssessments.find(x => x.e167_Links.find(l => l.e167_LinkId === entity.e167_LinkId));
                            if (sharedAssessment.e167_TypeKey === sharedAssessmentTypes.ByIFrame) return null;
                            const link = sharedAssessment.e167_Links.find(l => l.e167_LinkId === entity.e167_LinkId);
                            return entity.e167_Revoked !== true ? <RevokeSharedAssessmentLink sharedAssessmentId={sharedAssessment.e167_SharedAssessmentId}
                                                               shareTypeKey={sharedAssessment.e167_TypeKey} linkId={link.e167_LinkId}/> : undefined;
                        },
                        (entity) => {
                            const sharedAssessment = sharedAssessments.find(x => x.e167_Links.find(l => l.e167_LinkId === entity.e167_LinkId));
                            const link = sharedAssessment.e167_Links.find(l => l.e167_LinkId === entity.e167_LinkId);
                            return entity.e167_CanBeResent === true ? <ResendAssessmentLink sharedAssessmentId={sharedAssessment.e167_SharedAssessmentId}
                                                                                                  linkId={link.e167_LinkId}/> : undefined;
                        }
                    ]),
                }}
            />
            <SoapPaginator pageSize={pageSize}
                           page={currentPage}
                           recordCount={recordCount}
                           onPageChanged={(page) => {
                               fetchPage(page);
                           }}
            />
        </CenteredCard>
    );

}