import "normalize.css"
import React, {useCallback, useEffect, useState} from 'react';
import {
    addTranslations,
    App,
    defaultSoapTranslations,
    ProtectedRoute,
    config,
    useAuth,
    useCommand, useDomainEvent, useEvent, useLazyCommand,
    useQuery
} from '@soap/modules';
import {createTheme} from 'baseui';
import en_default from './translations/en-default'
import ReactDOM from "react-dom";
import {Route, Switch, useParams} from "react-router-dom";
import {Help} from "./z60-pagecomp/help";
import {QuestionLibrary} from "./z60-pagecomp/question-library";
import {QuestionTags} from "./z60-pagecomp/question-tags";
import {CreateQuestion} from "./z60-pagecomp/create-question/create-question";
import {CandidateTags} from "./z60-pagecomp/candidate-tags";
import {CandidateLibrary} from "./z60-pagecomp/candidate-library";
import {AddCandidate} from "./z60-pagecomp/add-candidate";
import {Pricing} from "./z60-pagecomp/pricing";
import {MyProfile} from "./z60-pagecomp/my-profile";
import {Order} from "./z60-pagecomp/order";
import {CreateAssessment} from "./z60-pagecomp/create-assessment";
import {AssessmentTags} from "./z60-pagecomp/assessment-tags";
import {AssessmentLibrary} from "./z60-pagecomp/assessment-library";
import {ShareAssessment} from "./z60-pagecomp/share-assessment/share-assessment";
import {SharedAssessments} from "./z60-pagecomp/shared-assessments";
import {TakeAssessment} from "./z60-pagecomp/take-assessment/take-assessment";
import {AssessmentResults} from "./z60-pagecomp/assessment-results";
import {AssessmentResult} from "./z60-pagecomp/assessment-result";
import {Home} from "./z60-pagecomp/home";
import {Features} from "./z60-pagecomp/features";
import {Mission} from "./z60-pagecomp/mission";
import {TermsAndConditions} from "./z60-pagecomp/terms-and-conditions";
import {PrivacyPolicy} from "./z60-pagecomp/privacy-policy";
import {ErrorComponent} from "./z50-domaincomp/error-component";
import {Invoices} from "./z60-pagecomp/invoices";
import {JoinOurTeam} from "./z60-pagecomp/join-our-team";
import {AcceptInvitation} from "./z60-pagecomp/accept-invitation";
import {MyOrg} from "./z60-pagecomp/my-org";
import {AcceptInvitation2} from "./z60-pagecomp/accept-invitation2";
import {EnableILC} from "./z50-domaincomp/enableILC";
import {OrderConfirmation} from "./z60-pagecomp/order-confirmation";

addTranslations(defaultSoapTranslations);
addTranslations(en_default);
//config.debug.auth = true;


window.ATest ??= { maintenance:false };
const localeOverride = undefined;
const countryCodeFromLocale = getCountryCodeFromLocale();
if (localeOverride) {
    console.warn(`The country code is ${localeOverride}.`);
    window.ATest.PaddleCountryCode = localeOverride;
}
else if (countryCodeFromLocale) {
    console.warn(`The country code based on locale is ${countryCodeFromLocale}.`);
    window.ATest.PaddleCountryCode = countryCodeFromLocale;
    
} else {
    console.error("Failed to retrieve the user's country code based on locale.");
}

function Index() {
    
    
    
    const triggerCheckoutCompleted = useDomainEvent({
        eventName: "checkout-completed",
    });

    const triggerCheckoutClosed = useDomainEvent({
        eventName: "checkout-closed"
    });
    
    useLazyCommand('C231v1_GetPaddleData',{});
    useEvent({
        eventName: "E185v1_GotPaddleData",
        onEventReceived:(event) => {
            
            getUserIpAddress()
                .then(ipAddress => {
                    if (ipAddress) {
                        console.log(`The user's IP address is ${ipAddress}.`);
                        getCountryByIp(ipAddress)
                            .then(country => {
                                if (country && !localeOverride) {
                                    console.warn(`Detected Country Code by IP: ${country}.`);
                                    window.ATest.PaddleCountryCode = country;
                                } else {
                                    console.warn("Failed to retrieve country information by IP. Defaulting to browser-locale");
                                }
                            });
                    } else {
                        console.log("Failed to retrieve the user's IP address. Defaulting to browser-locale.");
                    }
                });
            
            Paddle.Setup(  {
                token: event.e185_PaddleClientId,
                eventCallback: function(data) {
                    if (data.name === "checkout.completed") {
                        triggerCheckoutCompleted();
                    }
                    if (data.name === "checkout.closed") {
                        triggerCheckoutClosed();
                    }
                }
            });
            Paddle.Environment.set(event.e185_PaddleEnv);
        }
    });
    
    //#f5f5f5 lighter offwhite
    //#f5fffa mint cream
    /* greens
        accent300: '#afffaa',
        accent400: '#6cff64',
        accent500: '#2aff1d',
        accent600: '#0cd600',
        accent700: '#088f00',
        accent800: 'rgba(12,214,0,0.53)',
     */
    const primitives = {
        accent: "#009900", //style guide green
        accent50: '#16a1ca', //style guide light blue
        accent100: '#00438f', // style guide dark blue
        accent200: '#f0fff0', // honeydew (for text on the style guide green)
        accent300: '#ededed', //style guide off white
        accent400: '#2aff1d',
        accent500: '#999ea4', //silver
        accent600: '#f5f5f5', //lighter offwhite
        primaryB: "#ffffff",
        primaryFontFamily: 'Lato'
     };

    const overrides = {
        colors: {
            contentOnColor: "#ededed", //style guide off-white
            linkText: "#000000",
            linkVisited: "#000000",
            linkHover: "#000000",
            linkActive: "#000000"
        },
    };

    const theme = createTheme(primitives, overrides);
    
    const DEFAULT_ERROR_MESSAGE = "An Error Occurred";

        return (
            
        <App theme={theme} errorComponent={<ErrorComponent defaultErrorMessage={DEFAULT_ERROR_MESSAGE} />} useRefreshTokens={true}>
            <Switch>
                /* routes ordered by specific to general! */
                <Route path="/help" component={Help}/>
                <Route path="/features" component={Features}/>
                <Route path="/mission" component={Mission}/>
                <Route path="/join" component={JoinOurTeam}/>
                <Route path="/pricing" component={Pricing}/>

                <Route path="/terms-and-conditions" component={TermsAndConditions}/>
                <Route path="/privacy-policy" component={PrivacyPolicy}/>
                <Route path="/contact-us" component={Help}/>
                
                <ProtectedRoute path="/questions/question-library/add-question/:referrerId" component={CreateQuestion}/>
                <ProtectedRoute path="/questions/question-library/add-question" component={CreateQuestion}/>
                <ProtectedRoute path="/questions/question-library/tags" component={QuestionTags}/>
                <ProtectedRoute path="/questions/question-library" component={QuestionLibrary}/>

                <ProtectedRoute path="/candidates/candidate-library/add-candidate" component={AddCandidate}/>
                <ProtectedRoute path="/candidates/candidate-library/tags" component={CandidateTags}/>
                <ProtectedRoute path="/candidates/candidate-library" component={CandidateLibrary}/>

                <ProtectedRoute path="/assessments/assessment-library/create-assessment" component={CreateAssessment}/>
                <ProtectedRoute path="/assessments/assessment-library/share/:assessmentId" component={ShareAssessment}/>
                <ProtectedRoute path="/assessments/assessment-library/tags" component={AssessmentTags}/>
                <ProtectedRoute path="/assessments/assessment-library/:assessmentId/:action" component={AssessmentLibrary}/>
                <ProtectedRoute path="/assessments/assessment-library" component={AssessmentLibrary}/>
                
                <ProtectedRoute path="/assessments/:assessmentId/shares" component={SharedAssessments}/>
                <ProtectedRoute path="/assessments/shared" component={SharedAssessments}/>

                <ProtectedRoute path="/assessments/:assessmentId/results/:assessmentResultId/:fromLocationId"
                                component={AssessmentResult}/>
                <ProtectedRoute path="/assessments/results/:assessmentResultId/:fromLocationId"
                                component={AssessmentResult}/>

                <ProtectedRoute path="/assessments/:assessmentId/results" component={AssessmentResults}/>

                <ProtectedRoute path="/assessments/results" component={AssessmentResults}/>

                <ProtectedRoute path="/my-profile/invoices" component={Invoices}/>
                <ProtectedRoute path="/my-profile" component={MyProfile}/>
                <ProtectedRoute path="/my-org" component={MyOrg}/>
                
                <ProtectedRoute path="/order/:id" component={Order}/>
                <ProtectedRoute path="/order-complete/:id" component={OrderConfirmation}/>
                
                <Route path="/accept-invite/:invitationId" component={AcceptInvitation} />
                <Route path="/accept-invite2/:invitationId" component={AcceptInvitation2} />
                <Route path="/:sharedAssessmentId/:sharedAssessmentLinkId" component={TakeAssessment}/>
                <Route path="/:sharedAssessmentId/iframe/" component={TakeAssessment}/>
                <Route path="/enableILC" component={EnableILC} />
                <Route path="/">
                    {/*must be last, routes ordered by specific to general*/}
                    <Home/>
                </Route>
            </Switch>
        </App>
            
    );
}

ReactDOM.render(<Index/>, document.getElementById('content'));

async function getCountryByIp(ipAddress) {
    const apiUrl = `https://api.country.is/${ipAddress}`;

    try {
        const response = await fetch(apiUrl);
        const data = await response.json();

        if (response.ok) {
            const country = data.country;
            return country;
        } else {
            console.error(`Error fetching country: ${data.error}`);
            return null;
        }
    } catch (error) {
        console.error(`Error fetching country: ${error}`);
        return null;
    }
}
function getCountryCodeFromLocale() {
    // Get the user's browser locale
    const userLocale = navigator.language || navigator.userLanguage;
    // Extract the country code from the locale (assuming it follows the format 'en-US' or 'en_US')
    const countryCode = userLocale.split(/[-_]/)[1];
    return countryCode;
}

// Function to get the user's IP address using ipify
async function getUserIpAddress() {
    try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();

        if (response.ok) {
            const ipAddress = data.ip;
            return ipAddress;
        } else {
            console.error(`Error: ${data.error}`);
            return null;
        }
    } catch (error) {
        console.error(`An error occurred: ${error}`);
        return null;
    }
}

    