import {Frame} from "../z40-uicomp/frame";
import * as React from "react";
import {LabelMedium} from "baseui/typography";
import {AggregateList, EntityMenu, SecondaryActionMenuButton, useLazyQuery} from "@soap/modules";
import {CenteredColumn} from "../z40-uicomp/centered-column";
import {CenteredCard} from "../z40-uicomp/centered-card";

export function Invoices() {

    const [getProfile, e138GotProfile] = useLazyQuery("C166v1_GetUserProfile",{
        C166_IncludeInvoices: true
    });

    return (
        <Frame>
            <CenteredCard onBack={() => location.href="#/my-profile"} title={<LabelMedium>Invoices for ATest Monthly Subscription</LabelMedium>}>
                <AggregateList
                               aggregates={e138GotProfile?.e138_Invoices} 
                               headerColumns={["e138_Date", "e138_Status", "e138_PdfUrl"]}
                               hiddenFields={["e138_InvoiceTitle", "e138_PdfUrl"]}
                               propertyRenderer={{
                                   "e138_PdfUrl":(value, parent) => value ? <a href={value}>Download PDF</a> : "Invoice Unavailable"
                               }}
                               labelRenderer={{
                                   "e138_PdfUrl":(value, parent)=> ""
                               }}
                />
            </CenteredCard>
        </Frame>
    );
}
