﻿import * as React from 'react';
import {TagTable} from "../z50-domaincomp/tag-table";
import {useStyletron} from "baseui";
import {CenteredColumn} from "../z40-uicomp/centered-column";
import {Frame} from "../z40-uicomp/frame";
import {tagTypeKeys} from "../z50-domaincomp/constants/tag-type-keys";


export function QuestionTags() {

    const [css, theme] = useStyletron();
    
    return (
        <Frame>
        <CenteredColumn>
            <TagTable tagTypeKey={tagTypeKeys.Question} tagTypeName="Question" />
        </CenteredColumn>
        </Frame>
    );
}