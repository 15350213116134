import React from "react";
import {Button} from "baseui/button";
import {useLogin} from '../hooks/useLogin'

export function Login() {
    const {
        signUp,
        logIn,
        logOut,
        status
    } = useLogin();

    return (
        status.isAuthenticated ?
            <Button onClick={() => logOut()}>Log Out</Button> :
            status.isReady && status.isEnabled ?
                <Button onClick={() => logIn()}>Log In</Button> :
                null);
}

    



