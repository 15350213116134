﻿import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {AggregateView, CenterSpinner, EntityMenu, FileView, useEvent, useLazyQuery} from '@soap/modules'
import {CenteredCard} from "../z40-uicomp/centered-card";
import {Frame} from "../z40-uicomp/frame";
import {HeadingLarge, HeadingMedium, HeadingSmall, LabelMedium} from "baseui/typography";
import {Centered, SpaceTopAndBottom} from "../z40-uicomp/layout";
import {useStyletron} from "baseui";
import {useParams} from "react-router-dom";
import {Badge, COLOR} from "baseui/badge";
import {MarkQuestion} from "../z50-domaincomp/drawers/mark-question";
import {locationIds} from "../location-ids";

export function AssessmentResult() {

    const {assessmentResultId, assessmentId, fromLocationId} = useParams();

    //* styling 
    const [css, theme] = useStyletron();

    const viewStates = {
        loading: 1,
        showResults: 2
    };

    const [viewState, setViewState] = useState(viewStates.loading);

    const [getAssessmentResult, gotAssessmentResult] = useLazyQuery("C219v1_GetAssessmentResult", {
        C219_AssessmentResultId: assessmentResultId
    });
    
    const [e177, setE177] = useState(gotAssessmentResult);

    useEffect(() => {
        if (gotAssessmentResult) {
            setViewState(viewStates.showResults);
            setE177(gotAssessmentResult);
        }
    }, [gotAssessmentResult]);

    useEvent({
        eventName: "E179v1_QuestionMarked",
        onEventReceived: (event) => {
            const cloned = JSON.parse(JSON.stringify(e177));
            cloned.e177_CandidateScoreAsPercentage = event.e179_AssessmentScore;
            const question = cloned.e177_Questions.find(q => q.e177_QuestionId === event.e179_QuestionId);
            question.e177_PointsGiven = event.e179_QuestionScore;
            setE177(cloned);
        }
    }, []);
    
    switch (viewState) {
        case viewStates.loading:
            return <Frame><CenterSpinner/></Frame>;
        case viewStates.showResults:
            return (
                <Frame>
                    <CenteredCard title={<HeadingMedium>{e177.e177_AssessmentTitle}</HeadingMedium>}
                                  onBack={() => {
                                      
                                      switch (Number(fromLocationId)) {
                                          case locationIds.assessmentResultsListFiltered:
                                              location.href = `#/assessments/${assessmentId}/results/`;
                                              break;
                                          case locationIds.assessmentResultsListUnfiltered:
                                              
                                              location.href = `#/assessments/results/`;
                                              break;
                                          case locationIds.homePage:
                                              location.href = "/";
                                              break;
                                          case locationIds.candidateList:
                                              location.href = "#/candidates/candidate-library";
                                              break;
                                      }
                                      
                                  }}
                                  maxWidth={800}>
                        <Centered>
                            <SpaceTopAndBottom>
                                <HeadingLarge>{e177.e177_CandidateScoreAsPercentage != null ? "Score:" + e177.e177_CandidateScoreAsPercentage + "%" : "Score: n/a"}</HeadingLarge>
                                <LabelMedium>{e177.e177_CandidateScoreAsPercentage != null ? "" : <Badge color={COLOR.warning}
                                                                                                 content={"Needs Manual Marking"}></Badge>}</LabelMedium>
                            </SpaceTopAndBottom>
                        </Centered>
                        <>
                            <SpaceTopAndBottom>
                                <HeadingMedium>Questions</HeadingMedium>
                            </SpaceTopAndBottom>
                            {e177.e177_Questions.map((q, i) => <Question key={i} index={i} assessmentResultId={e177.e177_AssessmentResultId} q={q} />)}
                        </>
                    </CenteredCard>
                </Frame>
            );
    }
}

function Question({q, index, assessmentResultId}) {
    
    if (!q.e177_Image) {
        delete q["e177_Image"];
    }
    if (q.e177_RequiresManualMarking) {
        delete q["e177_CorrectAnswer"];
    }
    const propertyRenderer = {
        "e177_PointsGiven": (value, parent) => {

            return (parent.e177_RequiresManualMarking && (value === null || value === undefined)) ?
                <Badge color={COLOR.warning} content={"Needs Marking"}></Badge> : value;
        },
        "e177_Image": (value, parent) => {
            
            return (value !== null && value !== undefined) ? <FileView blobMeta={value} /> : null;
        }
    };

    const actions = q.e177_RequiresManualMarking && (q.e177_PointsGiven == null || q.e177_PointsGiven === undefined) ?
        [
            () => <MarkQuestion assessmentResultId={assessmentResultId}
                                questionId={q.e177_QuestionId}/>
        ]
        : [];

    return (<AggregateView
        title={<HeadingSmall>{`${index + 1}. ${q.e177_QuestionText}`}</HeadingSmall>}
        entityMenus={{
            "root": new EntityMenu(null, actions)
        }}
        hiddenFields={["e177_QuestionText", "e177_QuestionId", "e177_PartialPointsPermitted", "e177_MediaCaption", "e177_RequiresManualMarking", "e177_ImageCaption"]}
        displayMode={true}
        propertyRenderer={propertyRenderer}
        aggregate={q}/>);
}
