﻿import * as React from 'react';
import {Frame} from "../z40-uicomp/frame";
import {CenteredColumn} from "../z40-uicomp/centered-column";
import {OrganisationDetails} from "../z50-domaincomp/modals/organisation-details";


export function MyOrg() {

    return (
        <Frame>
            <CenteredColumn maxWidth={800} showRobot={false}>
                
                <OrganisationDetails/>
            </CenteredColumn>
        </Frame>
    );
}