﻿import {AutoForm, headerKeys, setHeader, translate, useDomainEvent} from "@soap/modules";
import {CenteredCard} from "../../z40-uicomp/centered-card";
import {ProgressBar} from "baseui/progress-bar";
import {SpaceTopAndBottom} from "../../z40-uicomp/layout";
import {HeadingMedium, HeadingSmall} from "baseui/typography";
import React from "react";


export function StepDefineValue({allowPartialPoints, statefulProcessId}) {

    const trigger = useDomainEvent({
        eventName: "value-defined",
        conversationId: "create-question"
    });

    const backTrigger = useDomainEvent({
        eventName: "step-back",
        conversationId: "create-question"
    });

    return (<CenteredCard  title={<HeadingMedium>Create Question</HeadingMedium>} showRobot={true}>
            <ProgressBar value={40}/>
            <SpaceTopAndBottom>
                <HeadingSmall>Set a default value for this Question</HeadingSmall>
            </SpaceTopAndBottom>
            <AutoForm
                query={{
                    $type: "C132v1_GetC131FormData",
                    "C132_StatefulProcessId": statefulProcessId
                }}
                hiddenFields={allowPartialPoints ? [] : ["C131_AllowPartialPoints"]}
                afterCancel={()=> {
                    backTrigger({
                        stepNumber:2
                    });
                }}
                cancelText={"Back"}
                afterSubmit={() => {
                    trigger();
                }}
                submitText={translate("Next")}
            />
        </CenteredCard>
    );
}