import React, {useEffect} from 'react';
import {Client as Styletron} from 'styletron-engine-atomic';
import {Provider as StyletronProvider} from 'styletron-react';
import {BaseProvider, LocaleProvider} from 'baseui';
import {PLACEMENT as PLACEMENT_TOASTER, ToasterContainer} from 'baseui/toast';
import {DURATION, PLACEMENT as PLACEMENT_SNACKBAR, SnackbarProvider} from 'baseui/snackbar';
import {Auth0Provider} from "@auth0/auth0-react";
import config from "../soap/config";
import {useIsConfigLoaded} from "../hooks/systemStateHooks";
import {HashRouter as Router} from "react-router-dom";
import {CenterSpinner} from "./CenterSpinner";
import {useLogin} from "../hooks/useLogin";
import {toaster} from "baseui/toast";
import {useApiErrors} from "../hooks/useApiErrors";

const defaultLocaleOverride = {
    fileuploader: {
        dropFilesToUpload: "Drop a file here, or ",
        browseFiles: "Browse for a file"
    }
};

const engine = new Styletron();

export function FullyInitialiseAuth0() {
    /* The useLogin hook must be called inside the Auth0Provider HOC and finish loading in order for certain functions like ProtectedRoutes to work.
    Because nothing inside the ProtectedRoute will render until it's true you need to have called it from a control in the tree which is inside of Auth0Provider but not inside of the component in the ProtectedRoute.
    That is a small window, and the called will not know anything about this requirement. Therefore this dummy control (used below) is the resolution. 
     */
    const {} = useLogin();
    return null;
}

export function App({children, localeOverride, errorComponent = null, theme, useRefreshTokens}) {

    useApiErrors ((newError, errors) => {
            if (!errorComponent) {
                toaster.negative(newError.errorMessage);
            }
    });

    const configLoaded = useIsConfigLoaded("app.jsx");

    useEffect(() => {
        if (config.debug.renders) console.warn("app.jsx rendered");
    });

    const locale = localeOverride ? {...defaultLocaleOverride, ...(localeOverride)} : defaultLocaleOverride;

    return (<LocaleProvider locale={locale}>
        <StyletronProvider value={engine}>
            <BaseProvider theme={theme}> 
                <ToasterContainer autoHideDuration={8000} placement={PLACEMENT_TOASTER.topRight}>
                    <SnackbarProvider defaultDuration={DURATION.short} placement={PLACEMENT_SNACKBAR.bottom}>
                        <Router>
                            {getContent()}
                        </Router>
                    </SnackbarProvider>
                </ToasterContainer>
            </BaseProvider>
        </StyletronProvider>
    </LocaleProvider>);

    function getContent() {
        if (!configLoaded) {
            return <CenterSpinner/>;
        } else {
            if (config.auth0) {
                return (<Auth0Provider
                    domain={config.auth0.tenantDomain}
                    clientId={config.auth0.uiAppId}
                    authorizationParams={{
                        useRefreshTokens: useRefreshTokens,
                        cacheLocation: useRefreshTokens ? "localstorage" : "memory",
                        audience: config.auth0.audience
                    }}
                >
                    {errorComponent}
                    <FullyInitialiseAuth0/>
                    {children}
                </Auth0Provider>);

            } else {
                return (<>
                    {errorComponent}
                    {children}
                </>);
            }
        }
    }
}


/* this code would be useful 
after login for changing where the back button goes but HashRouter doesn't 
support history object and using the Azure CDN which would allow us to
get away from HashRouter and Use Router would be a problem because its
not available for test and the delay in publishing would make dev impossible
It's not that important a feature to spend any more time on it but worth
noting for future options as this is how the sample code works.

to use it you would import these two constants into the main page
and set the onRedirectCallback={onRedirectCallback} attr of the Auth0provider
and history={history} attr of the Router object and
import {createBrowserHistory} from "history";
 */
