﻿import {
    AggregateList, config,
    EntityMenu, getHeader, headerKeys,
    PrimaryActionMenuButton, setHeader, toTypeName,
    useDomainEvent, useEvent,
    useLazyCommand, useLazyQuery, uuidv4,
    ValidationNotification
} from "@soap/modules";
import React, {createRef, forwardRef, useEffect, useRef, useState} from "react";
import {CenteredCard} from "../../z40-uicomp/centered-card";
import {ProgressBar} from "baseui/progress-bar";
import {SpaceAround, SpaceRight, SpaceTop} from "../../z40-uicomp/layout";
import {AddMultipleChoiceAnswer} from "../../z50-domaincomp/drawers/add-multiple-choice-answer";
import {Button, KIND, SIZE} from "baseui/button";

import {HeadingMedium} from "baseui/typography";
import {EditMultipleChoiceAnswer} from "../../z50-domaincomp/drawers/edit-multiple-choice-answer";



async function Validate(command) {
    
    const validationEndpoint = `${config.vars.functionAppRoot}/ValidateMessage?type=${encodeURIComponent(command.$type)}`;

    let response = await fetch(validationEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(command) // body data type must match "Content-Type" header
    });
    const msg = await response.text();

    if (msg) {
        let messages = msg.split('\r\n');
        messages = messages.map(m => (<p key={uuidv4()}>{m}</p>));
        return messages;
    } else {
        return undefined;
    }

}

export function StepDefineOptions({statefulProcessId, questionTypeKey}) {

    const [c133Trigger] = useLazyCommand("C133v1_CreateMultipleChoiceQuestionAddOptions");

    const optionsDefinedTrigger = useDomainEvent({
        eventName: "options-defined",
        conversationId: "create-question",
    });

    useDomainEvent({
        eventName: "answer-added",
        conversationId: "create-question",
        onEventReceived: (e, ev) => {
            const {text, isCorrect} = e;
            const newEl = {Value: text, "Is Correct": isCorrect};
            setOptions(oldArray => [...oldArray, newEl]);
        }
    });

    const [c139Trigger, e119] = useLazyQuery("C139v1_GetC133FormData", {
        $type: "C139v1_GetC133FormData",
        C139_StatefulProcessId: statefulProcessId
    });
    
    useEffect(() => {
        if (e119) {
            setOptions(e119.e119_Options.map(o => ({
                "Is Correct": o.e119_IsCorrect,
                "Value": o.e119_OptionText
            })));
        }
    }, [e119]);
    
    const answerRemovedTrigger = useDomainEvent({
        eventName: "answer-removed",
        conversationId: "create-question",
        onEventReceived: (e, ev) => {
            const {text} = e;
            setOptions(oldArray => {
                return oldArray.filter(i => i.Value !== text);
            });
        }
    });

    useDomainEvent({
        eventName: "answer-edited",
        conversationId: "edit-question",
        onEventReceived: (e, ev) => {
            const {oldText, text, isCorrect} = e;
            const newEl = {Value: text, "Is Correct": isCorrect};
            setOptions(oldArray => {
                const index = oldArray.findIndex(i => i.Value === oldText);
                const newArray = [...oldArray];
                newArray[index] = newEl;
                return newArray;
            });
        }
    });

    const backTrigger = useDomainEvent({
        eventName: "step-back",
        conversationId: "create-question"
    });

    const [options, setOptions] = useState([]);
    const [validationNotification, setValidationNotification] = useState();
    const submitButtonRef = useRef(null);
     
    const instruction  = ({
        "single-selection": <p>Define all possible answers, only one can be correct.</p>,
        "multiple-choice": <p>Define all possible answers, at least one must be false.</p>,
        "specific-string": <><p>Define all possible correct answers.</p><p>Answers are case-insensitive when marking results.</p></>,
    })[questionTypeKey];


    return (<CenteredCard  title={<HeadingMedium>Create Question</HeadingMedium>} showRobot={true}>
            <ProgressBar value={60}/>
            <SpaceAround>
                {instruction}
                <AggregateList
                    title={"Answers"}
                    aggregates={options}
                    headerColumns={["Value", "Is Correct"]}
                    hiddenFields={["e110_LongId"]}
                    entityMenus={{
                        "root-individual": new EntityMenu(null, [
                            () => <AddMultipleChoiceAnswer isCorrectDefault={questionTypeKey === "specific-string"} 
                                                           disableIsCorrect={questionTypeKey === "specific-string"} />
                        ]),
                        "root-Items": new EntityMenu(null, [
                            (entity) => <Button kind={KIND.secondary} size={SIZE.compact}
                                                onClick={() => answerRemovedTrigger({text: entity.Value})}>Remove</Button>,
                            (entity) => <EditMultipleChoiceAnswer answer={entity.Value}
                                                                  disableIsCorrect={questionTypeKey === "specific-string"}
                                                                  isCorrect={entity["Is Correct"]} />
                        ]),
                    }}
                />
                <SpaceTop>
                    <ValidationNotification errorMsg={validationNotification} />
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}>
                        <SpaceRight override={"10px"}>
                            <PrimaryActionMenuButton
                                type="button"
                                onClick={() => backTrigger({stepNumber: 3})}>
                                Back
                            </PrimaryActionMenuButton>
                        </SpaceRight>
                        <Button kind={KIND.primary} size={SIZE.compact} ref={submitButtonRef} onClick={async () => {
                            // build command here
                            const command = {
                                $type: toTypeName("C133v1_CreateMultipleChoiceQuestionAddOptions"),
                                headers: [],
                                C133_QuestionTypeKey: questionTypeKey,
                                C133_MultipleChoiceOptions: {
                                    allEnumerations: options.map(o => ({key: o.Value, value: o.Value})),
                                    selectedKeys: options.filter(o => o["Is Correct"] === true).map(o => o.Value)
                                }
                            };

                            setHeader(command, headerKeys.statefulProcessId, statefulProcessId);
                            
                            const messages = await Validate(command);
                            if (messages) {
                                setValidationNotification([...messages]);
                                submitButtonRef.current.scrollIntoView(false);
                            } else {
                                // send command
                                c133Trigger(command);
                                // move to next stage
                                optionsDefinedTrigger();
                            }
                        }}>Next</Button>
                    </div>
                </SpaceTop>
            </SpaceAround>
        </CenteredCard>
    );

}