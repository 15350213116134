﻿import {ActionDrawer, AutoForm, bus, translate} from "@soap/modules";
import React from "react";


export function EditTag(props) {
    const {tagId} = props;
    return (
        <ActionDrawer title={translate("Edit") + " " + translate("Tag")} auth={true}>
            <AutoForm
                query={{
                    $type: "C124v1_GetC123FormData",
                    C124_TagId: tagId
                }}
                submitText={translate("Save")}
                hiddenFields={["C123_TagId"]}
                afterSubmit={() => bus.closeAllDialogs()}
            />
        </ActionDrawer>
    );
}

