import {useCallback, useState} from "react";
import {useEvent} from "./useEvent";

export function useApiErrors(onErrorReceived) {

    const [errors, setErrors] = useState([]);

    const clear = useCallback(() => {
        setErrors([]);
    }, []);
    
    useEvent({
        
        eventName: "Soap.Interfaces.Messages.E001v1_MessageFailed, Soap.Interfaces.Messages",
        onEventReceived: (event, envelope) => {
            
            const refreshRequiredGuid = "4d96bfef-adc5-4818-89f2-9147c6d75e47";
            
            if (event.e001_ErrorCodes?.includes(refreshRequiredGuid)) {
                return;
            }

            const errorMessage = event["e001_ErrorMessage"];
            const messageId = event["e001_MessageId"];
            const errorCodes = event["e001_ErrorCodes"];
            const newError =  {messageId, errorMessage, errorCodes};
            
            if (onErrorReceived) {
                onErrorReceived(newError, errors);
            }
            setErrors([...errors, newError]);
        }
    });
    
    return {errors, clearErrors: clear};
}