﻿import {useStyletron} from "baseui";
import {Centered, RightAlign, SpaceBottom, SpaceTopAndBottom} from "../../z40-uicomp/layout";
import {HeadingSmall, HeadingXSmall, ParagraphLarge, ParagraphMedium} from "baseui/typography";
import React, {useEffect, useState} from "react";
import {CenterSpinner, useLazyCommand} from "@soap/modules";
import {StyledForwardLinkWithTitle} from "../../z40-uicomp/forward-link-with-title";
import {useFormatTimerString} from "../../hooks/useFormatTimerString";

export function SectionLanding({e175, timeLeft, timerControls, e170}) {

    const [css, theme] = useStyletron();

    const viewStates = {
        loading: 1,
        loaded: 2
    };

    const [viewState, setViewState] = useState(viewStates.loading);

    const [startSection] = useLazyCommand("C217v1_StartSection");

    useEffect(() => {
        if (e175 && viewState === viewStates.loading) {
            setViewState(viewStates.loaded);

            //e170 always loaded from parent
            if (e170.e170_TimingModeKey === "per-assessment" &&
                e175.e175_SectionNumber === 1) {

                const timeInSeconds = e170.e170_TimeLimit;
                
                timerControls.start(timeInSeconds * 1000);
            }
        }
    }, [e175, e170]);

    const {fromSeconds, fromMilliseconds} = useFormatTimerString();

    switch (viewState) {
        case viewStates.loading:
            return <CenterSpinner/>;
        case viewStates.loaded:
            return (
                <>
                    {e170.e170_TimingModeKey === "per-assessment" ?
                        <SpaceBottom>
                            <Centered><ParagraphMedium> Time Remaining
                                in Assessment {fromMilliseconds(timeLeft)}</ParagraphMedium>
                            </Centered>
                        </SpaceBottom> : null}
                    <SpaceTopAndBottom>
                        <HeadingXSmall>Section {e175.e175_SectionNumber} of {e175.e175_SectionCount}</HeadingXSmall><br/>
                        <HeadingSmall>{e175.e175_SectionTitle}</HeadingSmall>
                    </SpaceTopAndBottom>

                    <SpaceBottom>
                        <ParagraphLarge>This section contains {e175.e175_QuestionCount} questions.</ParagraphLarge>
                    </SpaceBottom>

                    {e175.e175_AssessmentTimingModeKey === "per-section" ?
                        <SpaceBottom>
                            <ParagraphLarge>This section is timed. You will have up
                                to {fromSeconds(e175.e175_TimeInSeconds)} to complete it.</ParagraphLarge>
                        </SpaceBottom> : null}

                    {e175.e175_AssessmentTimingModeKey === "per-question" ?
                        <SpaceBottom>
                            <ParagraphLarge>The questions in this section are individually timed.</ParagraphLarge>
                        </SpaceBottom> : null}

                    <SpaceTopAndBottom>
                        <RightAlign>
                            <StyledForwardLinkWithTitle title={"Continue"} onPress={() => {
                                startSection({
                                    C217_SectionId: e175.e175_SectionId,
                                    C217_AssessmentResultId: e175.e175_AssessmentResultId
                                });
                            }}/>
                        </RightAlign>
                    </SpaceTopAndBottom>
                </>
            );
    }

}