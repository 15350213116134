import * as React from 'react';
import {Button} from 'baseui/button';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
} from 'baseui/modal';
import {useEffect} from "react";
import {Badge, COLOR} from "baseui/badge";
import {RightAlign} from "./layout";
import badRobot from "/z30-images/robot-alert.png"
import {translate, useLazyCommand} from "@soap/modules";

export default function ErrorModal({children, closeButtonText = "Dismiss", onButtonCloseAsync, onClose=() => {}}) {

    const [isOpen, setIsOpen] = React.useState(true);

    useEffect(() => {
        if (children) {
            setIsOpen(true);
        }
    }, [children])

    function closeWithButton() {
        (async function() {
            if (onButtonCloseAsync) {
                const result = await onButtonCloseAsync();
                if (result === false) return;
            }
            onClose();
            setIsOpen(false);
        })();
    }
    
    function closeWithX() {
        onClose();
        setIsOpen(false);
    }
  
    return (
        <React.Fragment>
            <Modal onClose={closeWithX} isOpen={isOpen} autoFocus={false}>
                <ModalHeader>{translate(`$ErrorModalHeader`)}<RightAlign><img src={badRobot}
                                                                   height={"50px"}/></RightAlign></ModalHeader>
                <ModalBody>
                    {children}
                </ModalBody>
                <ModalFooter>
                    <ModalButton onClick={closeWithButton}>{translate(closeButtonText)}</ModalButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}