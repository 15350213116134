﻿import {useStyletron} from "baseui";
import {BEHAVIOR, Cell, Grid} from "baseui/layout-grid";
import {ParagraphMedium, ParagraphXSmall} from "baseui/typography";
import React, {useMemo, useState} from "react";
import {NavBar} from "./navBar";
import {PrimaryActionMenuButton, translate} from "@soap/modules";
import {StyledLink} from "baseui/link";
import {componentViewMode} from "../z50-domaincomp/constants/component-view-mode";
import {useQueryString} from "../hooks/useQueryString";
import {useLocation} from "react-router-dom";
import {Row} from "./row";
import alan from "/z30-images/robot-s.png"
import {LinkButton} from "./buttons";
import {SocialIcon} from "react-social-icons";
import {DesktopOnly, HScroll, MobileOnly, SpaceTop} from "./layout";

export function Frame({children}) {

    const queryString = useQueryString();
    const viewMode = useMemo(() => queryString.componentViewMode, [queryString]);
const [css, theme] = useStyletron();
    switch (viewMode) {
        case componentViewMode.IFrame:
            return <>{children}</>;
        case componentViewMode.Website:
            return (<>
                <div style={{
                    minHeight: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    
                }}>
                    <div style={{
                        margin: "0px",
                    }}>
                        <Grid
                            behavior={BEHAVIOR.fluid}
                            gridGutters={0}
                            gridMargins={0}
                        >
                            <Cell span={12}>
                                <Header/>
                            </Cell>
                            <Cell span={12}>
                                <HScroll>
                                {children}
                                </HScroll>
                            </Cell>
                        </Grid>
                    </div>
                    <div style={{
                        marginTop: "auto",
                        
                    }}>
                        <Footer/>
                    </div>
                </div>
            </>);
    }
}

function Footer() {
    const [css, theme] = useStyletron();
    const [version, setVersion] = useState('');

    if (!version) {
        (async function () {
            const response = await fetch(process.env.FUNCTIONAPP_ROOT + '/GetVersion');
            let versionText = (await response.text());
            versionText = versionText.substring(0, versionText.indexOf('.') + 3);
            setVersion(versionText);
        }());
    }

    return (
        <div className={css({
            marginTop: '50px',
            paddingTop: '50px',
            paddingBottom: '50px',
            background: theme.colors.accent
        })}>
            <Grid>
                <Cell span={8}>
                    <div className={css({
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                    })}>
                        <Row>
                            <ParagraphMedium
                            >
                                <LinkButton
                                    $style={{marginRight: theme.sizing.scale900}}
                                    colour={theme.colors.accent200}
                                    hoverColour={theme.colors.accent200}
                                    hover={true}
                                    underline={false}
                                    onClick={() => location.href = "#/privacy-policy"}>
                                    {translate("Privacy Policy")}</LinkButton>
                                <LinkButton
                                    $style={{marginRight: theme.sizing.scale900}}
                                    hover={true}
                                    underline={false}
                                    colour={theme.colors.accent200}
                                    hoverColour={theme.colors.accent200}
                                    onClick={() => location.href = "#/terms-and-conditions"}>
                                    {translate("Terms and Conditions")}</LinkButton>
                                <LinkButton
                                    hover={true}
                                    underline={false}
                                    colour={theme.colors.accent200}
                                    hoverColour={theme.colors.accent200}
                                    onClick={() => location.href = "#/contact-us"}>
                                    {translate("Contact Us")}</LinkButton>
                            </ParagraphMedium>
                        </Row>
                        <SpaceTop><Row>
                            <SocialIcon bgColor={theme.colors.accent} fgColor={theme.colors.accent200}
                                        url={"https://www.instagram.com/atest.me"}/>
                            <SocialIcon bgColor={theme.colors.accent} fgColor={theme.colors.accent200}
                                        url={"https://www.x.com/atest_me"}/>
                            <SocialIcon bgColor={theme.colors.accent} fgColor={theme.colors.accent200}
                                        url={"https://www.facebook.com/atest.me"}/>
                        </Row></SpaceTop>
                        <Row>
                            <SpaceTop>

                                <ParagraphXSmall color={theme.colors.accent200}>&copy; 2023 FutureTank
                                    Ltd.<FreePikImageCredit/>, Ver. {version}</ParagraphXSmall>
                            </SpaceTop>
                        </Row>
                    </div>
                </Cell>
                <Cell span={4}>
                    <DesktopOnly>
                        <NeedHelp />
                    </DesktopOnly>              
                </Cell>
            </Grid>
        </div>
    );
}

export function NeedHelp() {
    const [css, theme] = useStyletron();
    return (
        <Row spacing={"0px"}>
            <div className={css({})}>
                <PrimaryActionMenuButton onClick={()=> location.href="#/help"}><ParagraphMedium
                    color={"white"}> {translate("Need Help?")}</ParagraphMedium></PrimaryActionMenuButton>
            </div>
            <div className={css({
                marginLeft: "20px",
                marginTop: "15px"
            })}>
                <img src={alan} width={75} alt={translate("Need Help?")}/>
            </div>
        </Row>
    );
}

function Header() {
    const [css, theme] = useStyletron();

    return (
        <div className={css({
            // justifyContent: 'center',
            // alignItems: 'center',
            // display: 'flex',
            marginBottom: '50px'
        })}>
            <NavBar/>
        </div>
    )
}

function FreePikImageCredit() {
    
    const [css, theme] = useStyletron();
    
    const path = useLocation();
    switch (path.pathname) {
        case "/pricing":
        case "/features":
            return (
                <>
                    &nbsp;{translate("Images by")} <LinkButton
                    underline={false}
                    hover={true}
                    hoverColour={theme.colors.accent200}
                    colour={theme.colors.accent200}
                    onClick={() => location.href="https://www.freepik.com/"}
                >Freepik</LinkButton>
                </>
            );
        default:
            return null;
    }
}