﻿import {useStyletron} from "baseui";
import * as React from "react";
import {translate} from "@soap/modules";
import {CenteredColumn} from "./centered-column";
import {Card} from "baseui/card";
import robot from "../z30-images/robot-s.png";
import {StyledBackLinkWithTitle} from "./back-link-with-title";
import {SpaceBottom} from "./layout";

export function SlimCard(props) {
    const [css, theme] = useStyletron();
    const {children, backgroundColor, ...rest} = props;

    return (
        <Card
            {...rest}
            overrides={{
                Body: {
                    style: ({$theme}) => ({
                        overflow: "auto",
                    })
                },
                Root: {
                    style: ({$theme}) => ({
                        backgroundColor: backgroundColor,
                        padding: theme.sizing.scale700,
                        borderTopLeftRadius: "6px",
                        borderTopRightRadius: "6px",
                        borderBottomLeftRadius: "6px",
                        borderBottomRightRadius: "6px",
                        borderTopWidth: "1px",
                        borderBottomWidth: "1px",
                        borderLeftWidth: "1px",
                        borderRightWidth: "1px",
                        borderTopStyle: "solid",
                        borderBottomStyle: "solid",
                        borderLeftStyle: "solid",
                        borderRightStyle: "solid",

                    })
                },

            }}>{children}</Card>
    )
}

export function CenteredCard({showRobot = true, title, children, onBack, maxWidth = 500, backgroundColor}) {

    const [css, theme] = useStyletron();

    return (
        <CenteredColumn noMargins={true}>
            <div className={css({
                width: '100%'
                // container to fill parent rather than defaulting to width of children
            })}>

                <div className={css({
                    margin: '0 auto', // center it 
                    maxWidth: `${maxWidth}px` // restrain the width from filling parent
                })}>
                    <SlimCard
                        backgroundColor={backgroundColor}
                        title={(!title) ? null :
                            <SpaceBottom>
                                <div className={css({
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: "center"
                                })}>
                                    {onBack ? <StyledBackLinkWithTitle onPress={onBack}
                                                                       title={"Back"}/> : null}
                                    <div className={css({
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center"
                                    })}>
                                        {title}
                                    </div>

                                    {showRobot ?
                                        <><img src={robot} width={40} height={40} alt={translate("ATest Robot")}/></>
                                        : null}
                                </div>
                            </SpaceBottom>}

                    >
                        <CenteredColumn> {/* ensure children all align left by stretching all items in column to size of largest item */}

                            {children}

                        </CenteredColumn>
                    </SlimCard>
                </div>
            </div>
        </CenteredColumn>);
}